// src/models/Document.ts

import { INode, NodeAPI, NodeData, jsonToNode, nodeToJson } from "./BoxDiagram/Node";

import { Edge, Node } from "reactflow";
import { EdgeData, IEdge, IEdgeAPI, edgeToJson, jsonToEdge } from "./BoxDiagram/Edge";
import { getInitialDocument } from "src/utils/initialDocument";
import { v4 as uuidv4 } from "uuid";
import { SubConnection, defaultConnections } from "./Connection";
import { colorTypeToName, nameToColorType } from "./BoxDiagram/Colors";

export type DocumentContainer = {
  connections: SubConnection[];
  created_at_ms: number;
  id: string;
  id_prefix: string;
  permissions: DocumentPermissions;
  name: string;
  owner_id: string;
  // Box Diagram
  nodes: Node<NodeData>[];
  edges: Edge<EdgeData>[];

  updated_at_ms: number;
};

export type DocumentContainerAPI = {
  connections: SubConnection[];
  created_at_ms: number;
  id: string;
  id_prefix: string;
  permissions: DocumentPermissions;
  name: string;
  owner_id: string;
  // Box Diagram
  nodes: Node<NodeData>[];
  edges: IEdgeAPI[];
  updated_at_ms: number;
};

export enum DocumentPermissions {
  // EDIT_AND_SHARE = "edit_and_share",
  EDIT = "edit",
  VIEW = "view",
}

export const emptyDocument: DocumentContainer = {
  connections: defaultConnections,
  created_at_ms: 0,
  id: "",
  id_prefix: "",
  permissions: DocumentPermissions.VIEW,
  name: "",
  owner_id: "",
  nodes: [],
  edges: [],
  updated_at_ms: 0,
};

export function jsonToDocument(jsonObj: DocumentContainerAPI): DocumentContainer {
  const documentConnections = jsonObj.connections.map((connection: SubConnection) => {
    return { ...connection, color: nameToColorType(connection.color as any) };
  });

  const _nodes = jsonObj.nodes.map((nodeAPI: NodeAPI<NodeData>) => {
    return jsonToNode(nodeAPI);
  });

  return {
    // Incase a user has no connections, use the default connections. This is due to migration
    connections: documentConnections.length > 0 ? documentConnections : defaultConnections,
    created_at_ms: jsonObj.created_at_ms,
    id: jsonObj.id,
    id_prefix: jsonObj.id_prefix,
    permissions: jsonObj.permissions || DocumentPermissions.VIEW,
    name: jsonObj.name,
    owner_id: jsonObj.owner_id,
    nodes: _nodes,
    updated_at_ms: jsonObj.updated_at_ms,
    edges: jsonObj.edges.map((edge: IEdgeAPI) => {
      return jsonToEdge(edge);
    }),
  };
}

export function documentToJson(document: Partial<DocumentContainer>): any {
  // When we may not connections because we allow for partial documents updates
  let updatedConnections = undefined;
  if (document.connections) {
    updatedConnections = document.connections.map((connection: SubConnection) => {
      return { ...connection, color: colorTypeToName(connection.color as any) };
    });
  }

  let updatedEdges = undefined;
  if (document.edges) {
    updatedEdges = document.edges.map((edge: IEdge) => {
      return edgeToJson(edge);
    });
  }

  let updatedNodes = undefined;
  if (document.nodes) {
    updatedNodes = document.nodes.map((node: INode) => {
      return nodeToJson(node);
    });
  }

  // Update edge keys to match API
  const updatedDoc = {
    ...document,
    nodes: updatedNodes,
    edges: updatedEdges,
    connections: updatedConnections,
  };

  return updatedDoc;
}

export function createNewDocument(owner_id: string): DocumentContainer {
  return {
    ...getInitialDocument(),
    id: uuidv4(),
    owner_id: owner_id,
    created_at_ms: Date.now(),
    updated_at_ms: Date.now(),
  };
}
