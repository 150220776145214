import { Sparkle, Sparkles } from "lucide-react";
import { Footer, HorizontalDivider, Toolbar, useScrollToTop } from "./LandingPage";
import { sendHubSpotEmail } from "src/api/service/hubspotService";
import { v4 as uuidv4 } from "uuid";
import {
  DynamicTemplateData,
  EmailAddress,
  MailSendRequest,
  Personalization,
  sendProjectInterestEmail,
} from "src/api/service/sendgridService";
import { useState } from "react";
import { getUser } from "src/api/service/userService";
import { useDispatch } from "react-redux";

const FAQ_QUESTIONS = [
  {
    question: "How does this work?",
    answer: `If you are the leader at your company, a business owner, or a startup founder,
      and you have a problem that you think can be solved with software, I will work with you to understand the problem, 
      and then build a solution for you.`,
  },
  {
    question: "What commitment do I have to make?",
    answer: `I will need to meet with you or a few colleagues about once a week for an hour to understand 
    the problem and get feedback on the solution. As for the time commitment, I will work on the project 
    in my time, so there is no need for you to commit to anything.`,
  },
  {
    question: "Why are you doing this for free?",
    answer: `I'm an entrepreneur and I'm looking for my next big project. I'm doing this to find a problem 
    that I'm passionate about solving. I believe that if I can solve a real problem for someone, then I can
     build a successful business around it. A win for you is a win for me.`,
  },
  {
    question: "How many projects are you taking on?",
    answer: `Just one. I want to be able to give my full attention to the project. I'll be accepting applications and 
    performing interviews until I find the right problem.`,
  },
  {
    question: "What can you build?",
    answer: `I've built mobile apps, web apps, and APIs. I've spent the years at Lyft building products for millions of
    drivers. I've built the frontend, backend, and I've designed the UI/UX in every personal project. I'm confident that I can build
    a solution for you. If you're not sure if I can build what you need, let's talk. We can figure something out.`,
  },
];
function Contact() {
  useScrollToTop();

  return (
    <div className="flex flex-col items-center w-full min-h-screen ">
      <Toolbar />
      <HeroSection />
      <HorizontalDivider />

      <FAQSection />
      <Footer contact noDivider />
    </div>
  );
}

function HeroSection() {
  return (
    <div className="flex items-start justify-center w-full mt-12 pb-10 ">
      <div className="flex justify-center flex-col items-center space-y-16 w-full max-w-screen-xl">
        <Hero />
        <ContactUsForm />
      </div>
    </div>
  );
}

function Hero() {
  return (
    <div className="flex flex-col justify-center space-y-8 w-full px-4 text-neutral-700">
      <Header />
      <CareerCarousel />
    </div>
  );
}

function Header() {
  return (
    <div className="text-7xl lg:text-8xl text-center font-extrabold">
      <span>Struggling with a problem? </span>
    </div>
  );
}

function CareerCarousel() {
  return (
    <div className="flex flex-col md:flex-row items-center justify-center mt-4 text-2xl font-bold">
      <span>I'll build the solution.</span>
      <span className=" underline mx-2">For free.</span>
      <span>Let's get in touch.</span>
    </div>
  );
}

function ContactUsForm() {
  const dispatch = useDispatch();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [description, setDescription] = useState("");

  const nameErrorText = "Please enter at least 2 characters";
  const emailErrorText = "Please enter a valid email";
  const descriptionErrorText = "Please enter at least 10 characters";
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const emailRegexTest = emailRegex.test(email);
  const successMessage = "Thanks for your interest! I will be in touch soon.";
  const errorMessage = "Something went wrong. Please try again later.";
  const [isSuccess, setIsSuccess] = useState(false);

  function handleSubmit() {
    if (name.length < 2) {
      alert(nameErrorText);
      return;
    }

    if (!emailRegexTest) {
      alert(emailErrorText);
      return;
    }

    if (description.length < 10) {
      alert(descriptionErrorText);
      return;
    }

    const emailAddress: EmailAddress = {
      email: "darrelmuonekwu@gmail.com",
    };

    const dynamicTemplateData: DynamicTemplateData = {
      name: "string",
      email: "string",
      description: "string",
      landing_page_url: "string",
    };

    const personalization: Personalization = {
      to: [emailAddress],
      dynamic_template_data: dynamicTemplateData,
    };

    const requestBody: MailSendRequest = {
      name: name,
      email: email,
      description: description,
    };

    sendProjectInterestEmail(requestBody)
      .then((response) => {
        if (response) {
          setIsSuccess(true);
          setName("");
          setEmail("");
          setDescription("");
        } else {
          alert(errorMessage);
        }
      })
      .catch((error) => {
        alert(errorMessage);
      });
  }

  return (
    <div className="flex flex-col items-center justify-center w-11/12 lg:w-3/5 rounded-3xl border bg-white shadow-xl px-6 pb-10 pt-5">
      <div className="flex w-full items-start ">
        <div className="flex items-center space-x-2 p-1 px-2 my-4 mb-6  rounded-full text-sm border border-red-600 bg-red-200">
          <Sparkles size={16} />
          <span>Applications closed</span>
        </div>
      </div>
      <div className="space-y-2 w-full">
        <div
          className="flex 
        flex-col space-x-0 space-y-4
        lg:flex-row lg:space-x-4 lg:space-y-0"
        >
          <input
            type="text"
            placeholder="Name"
            className="w-full p-3 border-2 rounded-2xl "
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
          />

          <input
            type="email"
            placeholder="Email"
            className="w-full p-3 border-2 rounded-2xl "
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
        </div>
        <div className="flex space-x-4"></div>
        <textarea
          placeholder="Describe your problem."
          className="w-full p-3 border-2 rounded-2xl h-32"
          value={description}
          onChange={(e) => {
            setDescription(e.target.value);
          }}
        ></textarea>
        <button
          onClick={handleSubmit}
          className="flex items-center justify-center w-full py-3 text-white rounded-full bg-gradient-to-br from-neutral-600 to-neutral-700"
          disabled
        >
          SUBMIT
        </button>

        {isSuccess && (
          <div>
            <span className="flex justify-center w-full mt-4 font-medium text-red-600">
              {successMessage}
            </span>
          </div>
        )}
      </div>
    </div>
  );
}

function FAQSection() {
  return (
    <div className="flex flex-col items-center justify-center w-full md:w-5/6 max-w-screen-lg mt-4 px-4 text-neutral-100 ">
      <FAQTitle />
      <FAQCard />
    </div>
  );
}

function FAQTitle() {
  return (
    <div className="flex flex-col items-center justify-start w-full mt-14">
      <div className="flex flex-col items-start  w-full">
        <div className="text-start text-lg font-bold text-neutral-500">FAQs</div>
        <div className="text-start text-2xl font-bold text-neutral-800">
          You've got questions, and I have answers.
        </div>
      </div>
    </div>
  );
}

function FAQCard() {
  return (
    <div className="flex flex-col items-center justify-center w-full my-10 rounded-3xl shadow-xl border bg-white  ">
      {FAQ_QUESTIONS.map((faq, index) => (
        <div className="flex flex-col items-start w-full" key={index}>
          <FAQSubSection question={faq.question} answer={faq.answer} />
          {index !== FAQ_QUESTIONS.length - 1 && <HorizontalDivider />}
        </div>
      ))}
    </div>
  );
}

function FAQSubSection({ question, answer }: { question: string; answer: string }) {
  return (
    <div className="flex flex-col items-start justify-center space-y-4 w-full max-w-screen-lg p-10 text-neutral-800 ">
      <div className="flex flex-col items-start justify-start w-full text-xl font-semibold ">
        {question}
      </div>
      <div className="flex flex-col items-start justify-start w-full text-neutral-500">
        {answer}
      </div>
    </div>
  );
}

export default Contact;
