// src/models/Connection.ts

import { nanoid } from "nanoid";
import { Color, ColorType } from "./BoxDiagram/Colors";

// src/models/Connection.ts

/**
 * Represents a single legend item
 */
export interface SubConnection {
  id: string;
  // color: utes;
  color: ColorType;
  // We should use build in EdgeLabelOptions instead of creating our own
  label: string;
  // User defined name of input
  input?: string;
  // User defined name of output
  output?: string;
}

/**
 * Represents the collection of legend items.
 */
export interface Legend {
  connection: SubConnection[];
}

/**
 * Creates a new connection
 */
export function createConnection(
  color: ColorType = Color.Blue,
  label: string = "Label",
  input?: string,
  output?: string
): SubConnection {
  return {
    id: nanoid(3),
    color: color,
    label: label,
    input: input,
    output: output,
  };
}

/**
 * Adds a new legend item to the legend.
 *
 * @param {Legend} legend - The existing legend.
 * @param {SubConnection} legendItem - The new legend item to add.
 * @returns {Legend} Updated legend with the new item added.
 */
export function addInputType(legend: Legend, legendItem: SubConnection): Legend {
  return {
    ...legend,
    connection: [...legend.connection, legendItem],
  };
}

/**
 * Removes a legend item from the legend by its ID.
 *
 * @param {Legend} legend - The existing legend.
 * @param {string} id - The ID of the legend item to remove.
 * @returns {Legend} Updated legend with the specified item removed.
 */
export function removeInputTypeByIndex(legend: Legend, id: string): Legend {
  return {
    ...legend,
    connection: legend.connection.filter((inputType) => inputType.id !== id),
  };
}

/**
 * Updates the color of a specific legend item identified by its ID.
 *
 * @param {Legend} legend - The existing legend.
 * @param {string} id - The ID of the legend item to update.
 * @param {string} color - The new color for the legend item.
 * @returns {Legend} Updated legend with the specified item's color changed.
 */
export function setInputTypeColor(legend: Legend, id: string, color: ColorType): Legend {
  return {
    ...legend,
    connection: legend.connection.map((inputType) => {
      if (inputType.id === id) {
        return { ...inputType, color: color };
      }
      return inputType;
    }),
  };
}

/**
 * Updates the label of a specific legend item identified by its ID.
 *
 * @param {Legend} legend - The existing legend.
 * @param {string} id - The ID of the legend item to update.
 * @param {string} label - The new label for the legend item.
 * @returns {Legend} Updated legend with the specified item's label changed.
 */
export function setInputLabel(legend: Legend, id: string, label: string): Legend {
  return {
    ...legend,
    connection: legend.connection.map((inputType) => {
      if (inputType.id === id) {
        return { ...inputType, label: label };
      }
      return inputType;
    }),
  };
}

export const defaultLegendItem = createConnection(Color.Gray, "Default");
export const electricalLegendItem = createConnection(Color.Red, "Electrical");
export const massLegendItem = createConnection(Color.Purple, "Mass");
export const informationLegendItem = createConnection(Color.Green, "Information");
export const mechanicalLegendItem = createConnection(Color.Blue, "Mechanical");
// Default Legend Items to initialize the Legend
export const defaultConnections: SubConnection[] = [
  defaultLegendItem,
  electricalLegendItem,
  massLegendItem,
  informationLegendItem,
  mechanicalLegendItem,
];
