import { ChevronRight, Lock, LockKeyhole } from "lucide-react";
import React, { useState, useRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import useOutsideClick from "src/components/LandingPage/hooks/useOutsideClick";
import { Color } from "src/models/BoxDiagram/Colors";

interface BaseContextMenuProps {
  children?: React.ReactNode;
  className?: string;
  styles?: React.CSSProperties;
  onClose: () => void;
}

function BaseContextMenu({ children, className, styles, onClose }: BaseContextMenuProps) {
  const menuRef = useRef<HTMLDivElement>(null);
  // Close menu when clicking outside
  useOutsideClick(menuRef, onClose);

  function handleStopPropagation(event: React.MouseEvent) {
    event.stopPropagation();
  }

  function handleContextMenu(event: React.MouseEvent) {
    // Prevent the default context menu from showing up
    event.preventDefault();
    // Don't show another context menu when right clicking on the context menu
    event.stopPropagation();
    // Close the context menu
    onClose();
  }

  return (
    <div
      ref={menuRef}
      className={"absolute z-30 py-1 w-48 bg-white rounded-3xl drop-shadow-2xl " + className || ""}
      style={styles}
      onClick={handleStopPropagation}
      onContextMenu={handleContextMenu}
    >
      <div>{children}</div>
    </div>
  );
}

interface BaseContextMenuButtonProps {
  title: string;
  locked?: boolean;
  showChevron?: boolean;
  disabled?: boolean;
  onClick: () => void;
}

function BaseContextMenuButton({
  title,
  locked,
  showChevron,
  disabled,
  onClick,
}: BaseContextMenuButtonProps) {
  function handleContextMenu(event: React.MouseEvent) {
    event.preventDefault();
    if (disabled) return;
    onClick();
  }

  const classNames = disabled ? "text-gray-400" : "";
  const lockColor = locked ? Color.Neutral.css.eightHundred : "transparent";
  const hoverEffect = locked ? "" : "hover:bg-neutral-100";
  return (
    <button
      className={`flex justify-start items-center w-full space-x-4 text-left px-4 py-3 ${hoverEffect} ${classNames}`}
      onClick={onClick}
      onContextMenu={handleContextMenu}
      disabled={disabled || locked}
    >
      <LockKeyhole size={20} color={lockColor} />
      <span>{title}</span>
      {showChevron && <ChevronRight size={20} />}
    </button>
  );
}

export { BaseContextMenu, BaseContextMenuButton };
