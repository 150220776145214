import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getDocumentsAPI } from "src/api/service/documentService";
import { BaseContextMenu, BaseContextMenuButton } from "src/components/LandingPage/BaseContextMenu";
import { useToast } from "src/components/ui/use-toast";
import { DocumentContainer } from "src/models/Document";
import { updateConnection } from "src/state/reducers/connectionsReducers";
import { RootState } from "src/state/slices";
import { setUserDocuments } from "src/state/slices/userSlice";
import { GeneralResponse } from "src/utils/Api";
import { saveDocToApiWithDebounce } from "src/utils/autoSave";
import { CLIENT_ROUTE, CLIENT_URL } from "src/utils/constants";
import { v4 as uuidv4 } from "uuid";

interface DocumentCardContextMenuProps {
  isOpen: boolean;
  onClose?: () => void;
  documentObj?: DocumentContainer;
  handleOpenDeleteModal?: () => void;
  handleOpenRenameDialog?: () => void;
}
// Context Menu component
export function DocumentCardContextMenu({
  isOpen,
  onClose,
  documentObj,
  handleOpenDeleteModal,
  handleOpenRenameDialog,
}: DocumentCardContextMenuProps) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { toast } = useToast();

  const documents = useSelector((state: RootState) => state.user.documents);

  // Define menu options handlers
  function handleOpen() {
    navigate(CLIENT_ROUTE.DOCUMENT(documentObj.id));
  }
  async function handleDelete() {
    handleOpenDeleteModal();

    // Logic for "Delete" action
  }
  function handleRename() {
    handleOpenRenameDialog();
  }
  function handleGetLink() {
    const shareableLink = `${CLIENT_URL}${CLIENT_ROUTE.DOCUMENT(documentObj.id)}`;
    copyToClipboard(shareableLink);
  }

  async function handleDuplicate() {
    const newDocument = {
      ...documentObj,
      name: `${documentObj.name} Copy`,
      id: uuidv4(),
      created_at_ms: Date.now(),
      updated_at_ms: Date.now(),
    };
    await saveDocToApiWithDebounce(newDocument, 0).then((resp: GeneralResponse) => {
      if (resp.status === 200) {
        dispatch(setUserDocuments({ documents: [newDocument, ...documents] }));
      } else {
        alert("Failed to duplicate document. Please try again.");
      }
      onClose();
    });
  }

  function copyToClipboard(text: string) {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        toast({
          title: "Link copied to clipboard",
          className:
            "flex justify-center rounded-xl text-4xl bg-gray-900 text-white py-8 w-64 mb-20",
          duration: 5000,
        });
        onClose();
      })
      .catch((error) => {
        console.error(error);
        alert("Could not copy link to clipboard. Please try again.");
      });
  }

  if (!isOpen) return null;
  return (
    <BaseContextMenu className="-right-20 top-4" onClose={onClose}>
      <BaseContextMenuButton title={"Open"} onClick={handleOpen} />
      <BaseContextMenuButton title={"Rename"} onClick={handleRename} />
      <BaseContextMenuButton title={"Duplicate"} onClick={handleDuplicate} />
      <HorizontalDivider />
      <BaseContextMenuButton title={"Get shareable link"} onClick={handleGetLink} />
      <HorizontalDivider />
      <BaseContextMenuButton title={"Delete"} onClick={handleDelete} />
    </BaseContextMenu>
  );
}

export function HorizontalDivider() {
  return <div className="h-px my-1 w-full bg-gray-300"></div>;
}
