// src/slices/gridSlice.ts

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Edge, XYPosition } from "reactflow";
import { EdgeData } from "src/models/BoxDiagram/Edge";

export interface DSMContextMenuState {
  position?: XYPosition;
  cell?: { source: string; target: string };
  offset?: { x: number; y: number };
}

export interface DSMState {
  selectedState: { source?: string; target?: string } | null;
  scrollToEdge: Edge<EdgeData> | null;
  // To show the text box for a RowHeader or ColHeader
  selectedTextMenu: { source?: string; target?: string } | null;
  // To show the context menu for a cell
  contextMenu: DSMContextMenuState;
}

const initialState: DSMState = {
  selectedState: null,
  scrollToEdge: null,
  selectedTextMenu: null,
  contextMenu: { position: null, cell: null, offset: { x: 0, y: 0 } },
};

export const dsmSlice = createSlice({
  name: "dsm",
  initialState: initialState,
  reducers: {
    setSelectedState: (
      state,
      action: PayloadAction<{ rowCol: { source: string; target: string } | null }>
    ) => {
      const { rowCol } = action.payload;
      state.selectedState = rowCol;
    },
    setScrollToEdge: (state, action: PayloadAction<{ edge: Edge<EdgeData> | null }>) => {
      const { edge } = action.payload;
      state.scrollToEdge = edge;
    },
    setSelectedTextMenu: (
      state,
      action: PayloadAction<{ rowCol: { source: string; target: string } | null }>
    ) => {
      const { rowCol } = action.payload;
      state.selectedTextMenu = rowCol;
    },
    setContextMenu: (
      state,
      action: PayloadAction<{ ctxMenuState: Partial<DSMContextMenuState> }>
    ) => {
      const { ctxMenuState } = action.payload;
      state.contextMenu = { ...state.contextMenu, ...ctxMenuState };
    },
  },
});

export const { setContextMenu, setSelectedState, setScrollToEdge, setSelectedTextMenu } =
  dsmSlice.actions;
export default dsmSlice.reducer;
