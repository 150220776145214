// src/models/Node.ts

import { Dimensions, XYPosition } from "reactflow";
import { NodeIdPrefix } from "../../utils/constants";
import { Color } from "./Colors";
import { v4 as uuidv4 } from "uuid";

export enum BoxDiagramShape {
  Rectangle = "rectangle",
  Circle = "circle",
  Triangle = "triangle",
  Square = "square",
  Diamond = "diamond",
}

export enum BorderStyle {
  Solid = "solid",
  Dashed = "dashed",
  Dotted = "dotted",
}

export type NodeData = {
  // Displayed background color of node
  bg_color: string;
  // Border color of node
  border_color?: string;
  // Border style of node
  border_style?: BorderStyle;
  // Border width of node
  border_width?: number;
  // Height and width of node
  dimensions: Dimensions;
  // String to display in node label
  label: string;
  // Rotation of node in degrees
  rotation: number;
  // Shape of node
  shape: BoxDiagramShape;

  // (Client-only) User is editing node label
  is_editing: boolean;
  // (Client-only) Show toolbar on node
  show_node_toolbar: boolean;
  // (Client-only) Used to remember background color while use is selecting a new color
  temp_bg_color: string;
  // (Client-only) Used to remember border color while use is selecting a new color
  temp_border_color?: string;
  // (Client-only) Used to remember border style while use is selecting a new color
  temp_border_style?: BorderStyle;
};

export enum NodeType {
  BaseNode = "baseNode",
  EdgeToolbar = "edgeToolbar",
  Frame = "frame",
}

export interface INode {
  id: string;
  type: NodeType;
  position: { x: number; y: number };
  data: NodeData;
  parentNode?: string;
  zIndex?: number;
}

export type NodeAPI<T = any, U extends string | undefined = string | undefined> = {
  id: string;
  type: NodeType;
  position: { x: number; y: number };
  data: NodeData;
  parent_node?: string;
  z_index?: number;
};

export function jsonToNode(jsonObj: NodeAPI): INode {
  return {
    id: jsonObj.id,
    type: jsonObj.type,
    position: jsonObj.position,
    data: {
      ...jsonObj.data,
      // We need temp colors to be set to correctly preview color changes.
      temp_bg_color: jsonObj.data.bg_color,
      temp_border_color: jsonObj.data.border_color || Color.Black.css.fifty,
      temp_border_style: jsonObj.data.border_style,
    },
    parentNode: jsonObj.parent_node,
    zIndex: jsonObj.z_index,
  };
}

export function nodeToJson(node: INode): NodeAPI {
  return {
    id: node.id,
    type: node.type,
    position: node.position,
    data: node.data,
    parent_node: node.parentNode,
    z_index: node.zIndex,
  };
}

export const defaultNodeDimensions: Dimensions = { width: 210, height: 75 };
export const baseNodeData: NodeData = {
  bg_color: Color.White.css.fifty,
  dimensions: defaultNodeDimensions,
  is_editing: false,
  label: "",
  rotation: 0,
  show_node_toolbar: false,
  shape: BoxDiagramShape.Rectangle,
  temp_bg_color: Color.White.css.fifty,
};

export function getNodeID(): string {
  return `${NodeIdPrefix}${uuidv4().slice(0, 4).toUpperCase()}`;
}

export function createNewNode(
  id: string,
  data: NodeData,
  position?: XYPosition,
  type?: NodeType,
  parentNode?: string
): INode {
  if (type === NodeType.Frame) {
    return createFrameNode(id, data, position);
  }
  if (type === NodeType.BaseNode) {
    return createBaseNode(id, data, position, parentNode);
  }

  return createBaseNode(id, data, position, parentNode);
}

function createFrameNode(id: string, data: NodeData, position: XYPosition): INode {
  return {
    id: id,
    type: NodeType.Frame,
    position: position,
    data: data,
    zIndex: -1000,
  };
}

function createBaseNode(
  id: string,
  data: NodeData,
  position: XYPosition,
  parentNode?: string
): INode {
  return {
    id: id || getNodeID(),
    type: NodeType.BaseNode,
    position: position,
    data: data || baseNodeData,
    parentNode: parentNode,
  };
}

function getRandPositionBetween(min: number = 0, max: number = 1100): XYPosition {
  return {
    x: getRandomInt(min, max),
    y: getRandomInt(min, max),
  };
}

function getRandomInt(min: number, max: number): number {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}
