// src/components/BoxDiagram/Nodes/BaseNode.tsx

import React, { useEffect, useState, useRef } from "react";
import {
  useUpdateNodeInternals,
  NodeResizer,
  ResizeDragEvent,
  ResizeParams,
  NodeProps,
} from "reactflow";
import { drag } from "d3-drag";
import { select } from "d3-selection";
import { useDispatch, useSelector } from "react-redux";
import { BorderStyle, BoxDiagramShape, NodeData } from "../../../models/BoxDiagram/Node";
import "./styles/nodeStyles.css";
import NodeHandles from "./BaseNodeComponents/NodeHandels";
import RotationHandle from "./BaseNodeComponents/RotationHandle";
import NodeInput from "./BaseNodeComponents/NodeInput";

import BaseNodeToolbar from "../Toolbars/BaseNodeToolBar/BaseNodeToolbar";
import { setAllNodesNotEditing, updateNodeData } from "src/state/reducers/boxDiagramReducers";
import { deleteNode } from "src/state/slices/documentSlice";

const shapeStyles: Record<BoxDiagramShape, string> = {
  rectangle: "h-20 w-52 rounded-md cursor-pointer border",
  circle: "h-32 w-32 rounded-full cursor-pointer border",
  square: "h-32 w-32 cursor-pointer border",
  triangle: "h-10 w-10 cursor-pointer border",
  diamond: "h-32 w-32 cursor-pointer border transform rotate-45",
};

interface BaseNodeProps {
  id: string;
  selected: boolean;
  data: NodeData;
}

/**
 * A default node component for React Flow with rotatable and resizable features.
 *
 * Note: isEditing is set to False in redux when position changes
 * Note: All nodes are deselected when the user clicks on the grid pane in BlockDiagram.tsx
 * Node: EdgeToolbar is displayed by redux when a new connection is made
 */
function BaseNode({ id, selected, data, xPos, yPos }: NodeProps) {
  const dispatch = useDispatch();
  const nodeData: NodeData = data;
  // References to HTML elements for rotation and resizing
  const rotateControlRef = useRef<HTMLDivElement>(null);

  // Ref for the whole node to listen for when the user clicks outside of the node
  const nodeRef = useRef<HTMLDivElement>(null);

  // Hooks to update node internals and manage state
  const updateNodeInternals = useUpdateNodeInternals();
  const [rotation, setRotation] = useState(nodeData.rotation || 0);
  const [nodeDimensions, setNodeDimensions] = useState(nodeData.dimensions);
  const [showHandles, setShowHandles] = useState(false);

  // Make sure to update position after redo/undo
  useEffect(() => {
    setNodeDimensions(nodeData.dimensions);
    setRotation(nodeData.rotation);
  }, [nodeData.dimensions, nodeData.rotation]);

  // Styles for rotation and resizing
  const nodeStyles = {
    transform: `rotate(${rotation}deg)`,
    height: nodeDimensions?.height,
    width: nodeDimensions?.width,
    backgroundColor: nodeData.bg_color,
    borderColor: nodeData.border_color || "black",
    borderWidth: typeof nodeData.border_width === "number" ? nodeData.border_width : 2,
    borderStyle: nodeData.border_style || BorderStyle.Solid,
  };
  const smallHandleStyle = {
    background: "white",
    width: "10px",
    height: "10px",
    borderRadius: "50%",
    border: "1px solid black",
    zIndex: 100,
  };

  // Handles when the user clicks outside of the node
  // Unused now that we let ReactFlow handle 'selected' state
  // useOutsideClickHandler(nodeRef, dispatch, id);

  // Listen for when user clicks on any edge.
  // NOTE: May need to move this to BlockDiagram.tsx
  // addEdgeClickedEventListener(id, () => {
  //   // dispatch(showEdgeToolbar({ targetNodeId: id })); // DEPRECATED
  // });

  // Handles the drag behavior for rotating the node
  useRotationEffect(
    id,
    rotation,
    setRotation,
    rotateControlRef,
    updateNodeInternals,
    dispatch,
    data
  );

  // Node Resizing
  function handleResize(event: ResizeDragEvent, params: ResizeParams) {
    const position = { x: params.x, y: params.y };
    const dimensions = { width: params.width, height: params.height };
    // Update locally in real time
    setNodeDimensions(dimensions);
    if (event.type === "end") {
      // Save the new dimensions and position of the node to reference later
      dispatch(updateNodeData({ id, newData: { dimensions } }));
    }
  }

  // Placeholder functions for connection states
  function handleConnectStart() {
    /* Placeholder */
  }
  function handleConnectEnd() {
    /* Placeholder */
  }

  // Deselect all nodes and select this node
  function handleShowToolbar() {
    // Hide active edge toolbars, if any
    // if (activeEdgeToolbar && activeEdgeToolbar.id !== id) {
    //   const eventTimestamp = new Date().getTime();
    //   dispatch(hideEdgeToolbar({ eventTimestamp }));
    // }
    // Show NodeToolbar, if not already shown
    if (!nodeData.show_node_toolbar) {
      dispatch(updateNodeData({ id, newData: { show_node_toolbar: true } }));
    }
  }

  // Enable editing of the node label and disable editing for all other nodes
  function handleOnDoubleClick() {
    dispatch(setAllNodesNotEditing());
    dispatch(updateNodeData({ id, newData: { is_editing: true } }));
  }

  // Set is editing to false when the textarea loses focus.
  function handleOnBlur() {}

  // Delete node if the user presses backspace and the node label is not being edited
  function handleOnKeyDown(event: React.KeyboardEvent<HTMLDivElement>) {
    if (event.key === "Backspace" && !nodeData.is_editing) {
      dispatch(deleteNode({ sourceNodeId: id }));
    }
  }

  function onMouseEnter() {
    setShowHandles(true);
  }

  function onMouseLeave() {
    setShowHandles(false);
  }

  return (
    <div
      id={id}
      ref={nodeRef}
      style={nodeStyles}
      className={`${shapeStyles[nodeData.shape]} overflow-visible  text-center`}
      onClick={handleShowToolbar}
      onDoubleClick={handleOnDoubleClick}
      onBlur={handleOnBlur}
      onKeyDown={handleOnKeyDown}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {/* Enables the user to make the node larger or smaller */}
      <NodeResizer
        handleStyle={smallHandleStyle}
        onResizeEnd={handleResize}
        onResize={handleResize}
        isVisible={selected}
        minWidth={50}
        minHeight={12}
        lineStyle={{ opacity: 0.5 }}
        // keepAspectRatio={true}
      />

      <BaseNodeToolbar nodeId={id} nodeData={data} />

      <NodeInput id={id} data={data} />

      <RotationHandle isSelected={selected} rotateControlRef={rotateControlRef} />

      <NodeHandles show={showHandles} />
    </div>
  );
}

/**
 * Custom hook to handle the drag behavior for rotating the node
 */
function useRotationEffect(
  id: string,
  rotation: number,
  setRotation: React.Dispatch<React.SetStateAction<number>>,
  rotateControlRef: React.RefObject<HTMLDivElement>,
  updateNodeInternals: (id: string) => void,
  dispatch: any,
  data: NodeData
) {
  useEffect(() => {
    if (!rotateControlRef.current) {
      console.error("Rotate control ref is null");
      return;
    }

    const selection = select(rotateControlRef.current);
    const dragHandler = drag<HTMLDivElement, unknown>()
      // Rotation while dragging
      .on("drag", (event: DragEvent) => handleDrag(id, event, setRotation, updateNodeInternals))
      // Rotation on drag end
      .on("end", (event: DragEvent) =>
        handleDragEnd(event, setRotation, updateNodeInternals, id, dispatch, data)
      );

    selection.call(dragHandler);
  }, [id, rotateControlRef, rotation, updateNodeInternals, dispatch, data]);
}

/**
 * Drag handler for rotation.
 */
function handleDrag(
  id: string,
  event: DragEvent,
  setRotation: React.Dispatch<React.SetStateAction<number>>,
  updateNodeInternals: (id: string) => void
) {
  // Calculate the drag distance and degree of rotation
  const { dx, dy } = calculateDrag(event);
  const deg = calculateDegree(dx, dy);
  const newRotation = 180 - snapToNearest90(deg);
  // Set the rotation of the node locally in real time
  setRotation(newRotation);
  // Update the node internals to reflect new rotation in real time
  updateNodeInternals(id);
}

/**
 * Drag end handler for rotation.
 */
function handleDragEnd(
  event: DragEvent,
  setRotation: React.Dispatch<React.SetStateAction<number>>,
  updateNodeInternals: (id: string) => void,
  id: string,
  dispatch: any,
  data: NodeData
) {
  // Calculate the drag distance and degree of rotation
  const { dx, dy } = calculateDrag(event);
  const deg = calculateDegree(dx, dy);
  const newRotation = 180 - snapToNearest90(deg);
  // Set the rotation of the node locally in real time
  setRotation(newRotation);
  // Save the rotation in Redux incase we need to reference it later
  dispatch(updateNodeData({ id, newData: { rotation: newRotation } }));
  // Update the node internals to reflect new rotation in real time
  updateNodeInternals(id);
}

/**
 * Calculates the drag distance.
 */
function calculateDrag(event: DragEvent) {
  const dx = event.x - 100;
  const dy = event.y - 100;
  return { dx, dy };
}

/**
 * Calculates the degree of rotation.
 */
function calculateDegree(dx: number, dy: number) {
  const rad = Math.atan2(dx, dy);
  const deg = rad * (180 / Math.PI);
  return deg;
}

/**
 * Snaps the rotation to the nearest 90 degrees within +/- 5 degrees tolerance.
 */
function snapToNearest90(deg: number) {
  const nearest90 = Math.round(deg / 90) * 90;
  return Math.abs(deg - nearest90) <= 5 ? nearest90 : deg;
}

/**
 * Handles the click outside of the node AND not on the grid pane.
 * This will run when the user clicks on edge or on anything
 * outside of the grid pane.
 *
 * Clicks on other nodes in the grid pane do not trigger this function.
 */
function useOutsideClickHandler(
  nodeRef: React.RefObject<HTMLDivElement>,
  dispatch: any,
  id: string
) {
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      // If the nodeRef is not null and the click is outside of the node, deselect the node
      if (nodeRef.current && !nodeRef.current.contains(event.target as Node)) {
        // dispatch(setNodeSelectionById({ id, selected: false }));
      }
    };
    // Add event listener to detect clicks outside of the node
    document.addEventListener("mousedown", handleClickOutside);
    // Remove the event listener when the component unmounts
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [nodeRef, dispatch, id]);
}

export default BaseNode;
