import BlockDiagram from "../components/BlockDiagram/BlockDiagram";
import DSM from "../components/DSM/DSM";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../state/slices";
import { SideNavigationBarSection } from "../state/slices/uiSlice";
import { useEffect, useRef } from "react";
import useOutsideClick from "../components/LandingPage/hooks/useOutsideClick";
import { Edge, Node, ReactFlowProvider } from "reactflow";
import { Toaster } from "../components/ui/toaster";
import { useNavigate, useParams } from "react-router-dom";
import { getDocumentAPI } from "src/api/service/documentService";
import { setDocument } from "src/state/slices/documentSlice";
import { applyNewEdgeChanges, applyNewNodeChanges } from "src/state/reducers/boxDiagramReducers";
import { CLIENT_ROUTE } from "src/utils/constants";
import TopDocumentBar from "../components/shared/TopDocumentBar/TopDocumentbar";

function Dashboard() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const divRef = useRef<HTMLDivElement>(null);

  // Close reactflow menus when clicking outside of the container
  useOutsideClick(divRef, () => {});
  // Fetch the document using ID in URL
  useFetchDocumentFromURL(id, dispatch);
  // Save the document when the user closes the window. This does not work
  // useAutoSaveOnWindowClose(document.id, saveDocToApiWithDebounce);

  return (
    <div ref={divRef} className="flex flex-col w-full h-screen">
      {/* <SideNavigationBar /> */}
      <TopDocumentBar />
      <div className="relative flex flex-1 overflow-auto">
        <PresentedScreen />
      </div>
      <Toaster />
    </div>
  );
}

function PresentedScreen() {
  const selectedSection = useSelector(
    (state: RootState) => state.sideNavigationBar.selectedSection
  );
  switch (selectedSection) {
    case SideNavigationBarSection.DSM:
      return (
        <>
          <DSM />
        </>
      );
    case SideNavigationBarSection.BoxDiagram:
      return (
        <>
          {/* Takes up remaining space */}
          <ReactFlowProvider>
            <BlockDiagram />
          </ReactFlowProvider>
        </>
      );
    case SideNavigationBarSection.ICD:
      return (
        <>
        </>
      );
    case SideNavigationBarSection.Profile:
      return (
        <>
          <div className="flex items-center justify-center w-full h-screen">
            Profile screen goes here
          </div>
        </>
      );
    case SideNavigationBarSection.Settings:
      return (
        <>
          <div className="flex items-center justify-center w-full h-screen">
            Settings screen goes here
          </div>
        </>
      );
    case SideNavigationBarSection.Exit:
      return (
        <>
          <div className="flex items-center justify-center w-full h-screen">
            Help page maybe goes here
          </div>
        </>
      );
    default:
      return (
        <>
          <div className="flex items-center justify-center w-full h-screen">
            Empty screen 😰. You should not have gotten here.
          </div>
        </>
      );
  }
}

// Define a custom hook for fetching the document
function useFetchDocumentFromURL(id: string, dispatch: any) {
  const navigate = useNavigate();
  useEffect(() => {
    const fetchDocument = async () => {
      try {
        // Fetch document
        const resp = await getDocumentAPI(id);
        console.log("--> resp", resp);
        // Server error
        if (resp.status == 500) {
          throw new Error("Failed to fetch document 2");
        }
        // Document not found
        else if (resp.status == 404) {
          alert("Document not found. Please check the URL.");
          navigate(CLIENT_ROUTE.DOCUMENT_HOME);
        }
        // Success: document found
        else if (resp.status == 200) {
          // Update redux
          dispatch(setDocument({ document: resp.resp }));
          // Update react flow nodes
          dispatch(
            applyNewNodeChanges(
              resp.resp.nodes.map((node: Node) => ({
                id: node.id,
                type: "position",
                position: node.position,
                data: node.data,
              }))
            )
          );
          // Update react flow edges
          const edges = resp.resp.edges as any;
          dispatch(
            applyNewEdgeChanges(edges.map((edge: Edge) => ({ id: edge.id, type: "added" })))
          );
        }
      } catch (error) {
        console.error("Failed to fetch document:", error);
      }
    };
    if (id) {
      fetchDocument();
    }
  }, [id, dispatch]);
}

export default Dashboard;
