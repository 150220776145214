// Import Axios for making HTTP requests
import axios from "axios";
import { GeneralResponse } from "src/utils/Api";
import { SERVER_ROUTE } from "src/utils/constants";

export const SEND_GRID_API_KEY = process.env.REACT_APP_SENDGRID_API_KEY;

const HEADERS = {
  "Content-Type": "application/json",
};

// Define the types for our request
export type EmailAddress = {
  email: string;
};

export type DynamicTemplateData = {
  name: string;
  email: string;
  description: string;
  landing_page_url: string;
};

export type Personalization = {
  to: EmailAddress[];
  dynamic_template_data: DynamicTemplateData;
};

export type MailSendRequest = {
  name: string;
  email: string;
  description: string;
};

export async function sendProjectInterestEmail(requestBody: MailSendRequest) {
  try {
    const response = await axios.post(
      "https://api.sinkly.co/v1/project-form/create",
      // "http://localhost:8000/v1/project-form/create",
      { ...requestBody },
      { headers: HEADERS }
    );

    console.log("Form is sent", response.data);
    const generalResp = response.data as GeneralResponse;

    return generalResp;
  } catch (error) {
    throw error;
  }
}

// Your custom data
const customData = {
  name: "hello",
  email: "darrel@gmail.com",
  full_name: "Darrel Muonekwu",
  document_name: "My document 1",
  document_url: "http://localhost:3000/documents/r22c89c3-e114-461e-b055-bd43ccb6f2b8",
  landing_page_url: "https://www.sinkly.co/",
};

// Example usage
// const apiKey: string = '<<YOUR_API_KEY>>'; // Use your actual API key
// const templateId: string = '[template_id]'; // Replace [template_id] with your actual template ID
// const requestBody: MailSendRequest = {
//   from: { email: "example@sendgrid.net" }, // Sender's email
//   personalizations: [{
//     to: [{ email: customData.email }], // Recipient's email
//     dynamic_template_data: customData // Your custom data for the dynamic template
//   }],
//   template_id: templateId // The ID of the dynamic template you want to use
// };

// sendEmailWithDynamicTemplate(apiKey, requestBody);
