import { RefObject, useCallback, useEffect, useState } from "react";
import { Edge, Node } from "reactflow";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/state/slices";
import { setSelectedEdge } from "src/state/slices/documentSlice";
import { ContextMenuState } from "../BlockDiagram";

/**
 * This hook handles the right-click edge context menu.
 * When the user clicks down on an edge, we store the edge to know which edge was clicked.
 * When the user releases the right mouse button, we show the context menu on the stored edge
 * if the user did not drag the screen (i.e. if the user was panning, we don't show the context menu)
 */
export const useOnEdgeContextMenu = (reactFlowContainerRef: RefObject<HTMLDivElement>) => {
  const dispatch = useDispatch();
  const [edgeContextState, setEdgeContextState] = useState<ContextMenuState | null>(null);
  const [rightClickedEdge, setRightClickedEdge] = useState<Edge | null>(null);

  const paneDragging = useSelector((state: RootState) => state.boxDiagramUI.paneDragging);

  // Handler for right-click on a node
  const handleEdgeRightClick = useCallback((event: React.MouseEvent, edge: Edge) => {
    event.preventDefault(); // Prevent default context menu
    // dispatch(deselectAllNodes({exceptionID: node.id})); // Deselect all nodes

    setRightClickedEdge(edge); // Store the clicked node
  }, []);

  // Global handler for mouse up event
  const handleGlobalMouseUp = useCallback(
    (event: MouseEvent) => {
      if (paneDragging) return; // If we are panning, don't show the context menu

      if (event.button === 2 && rightClickedEdge) {
        // Get the container's position relative to the viewport
        // This is because the click position is relative to the viewport (entire visible screen), but we
        // want the click position to be relative to only the react flow container incase
        // the container is not full screen (e.g. we add sidebars or headers above the container).
        const rect = reactFlowContainerRef.current.getBoundingClientRect();
        const widthOfMenu = 220;

        // Right mouse button
        setEdgeContextState({
          id: rightClickedEdge.id,
          top: event.clientY - rect.y,
          left: event.clientX - rect.x + widthOfMenu,
        });
        setRightClickedEdge(null); // Reset the stored node
        // Select the edge
        dispatch(setSelectedEdge({ edge: rightClickedEdge }));
      }
    },
    [rightClickedEdge, paneDragging]
  );

  // Add global event listener for mouse up
  useEffect(() => {
    window.addEventListener("mouseup", handleGlobalMouseUp);
    return () => {
      window.removeEventListener("mouseup", handleGlobalMouseUp);
    };
  }, [handleGlobalMouseUp]);

  return { edgeContextState, setEdgeContextState, handleEdgeRightClick };
};
