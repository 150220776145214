import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../state/slices";
import { BaseContextMenu, BaseContextMenuButton } from "src/components/LandingPage/BaseContextMenu";
import { HorizontalDivider } from "src/components/Documents/DocumentCard/DocumentCardContextMenu";
import { NodeData } from "src/models/BoxDiagram/Node";
import { Node } from "reactflow";

interface SelectionContextMenuProps {
  nodes: Node<NodeData>[];
  top?: number;
  left?: number;
  onClose?: () => void;
}

/**
 * Component that renders a context menu for a node
 */
function SelectionContextMenu({ nodes, top, left, onClose, ...props }: SelectionContextMenuProps) {
  const dispatch = useDispatch();
  const edges = useSelector((state: RootState) => state.document.documentContainer.edges);

  function handleDuplicateNode() {
    // const type = node?.type;
    // const position = { x: node?.position?.x + 50, y: node?.position?.y + 50 };
    // const data = node?.data;
    // dispatch(addNode({ type, position, data }));
    onClose();
  }

  const handleDeleteNode = useCallback(() => {
    // dispatch(deleteNode({ sourceNodeId: id }));
  }, []);

  // Early return if the node's position is undefined. Is this needed
  // if (!node?.position) return null;

  const styles = {
    top: top,
    left: left != undefined ? left - 220 : undefined,
  };

  return (
    <BaseContextMenu onClose={onClose} styles={styles}>
      <p className="mb-2 ml-2 text-lg">
        <small>
          Node ID: <b>{"Hi"}</b>
        </small>
      </p>
      <HorizontalDivider />
      <BaseContextMenuButton title={"Duplicate"} onClick={handleDuplicateNode} />
      <HorizontalDivider />
      <BaseContextMenuButton title={"Delete"} onClick={handleDeleteNode} />
    </BaseContextMenu>
  );
}

export default SelectionContextMenu;
