import {
  ArrowRight,
  BookCheck,
  Calendar,
  CircleDashed,
  CircleSlash,
  Code2,
  FileBarChart2,
  FileText,
  FlaskConical,
  Telescope,
  Trophy,
  UserRound,
  UsersRound,
} from "lucide-react";
import { Color } from "src/models/BoxDiagram/Colors";
import { Footer, Toolbar, useScrollToTop } from "./LandingPage";
import { useScreenSize } from "../BlockDiagram/Hooks/useIsMobile";
import { HeroData, HurdleSection2, HurdleSectionMVP, ResearchCards, ResearchDescription, TravelToLeft, TravelToRight } from "./Sinkly";

const sinkly_lg_2 = require("../../assets/images/relevate/relevate_lg_2.png");

export const fire_gif = require("../../assets/videos/fire_gif.gif");
export const dsm_gif = require("../../assets/videos/dsm_gif.mp4");

export const sinkly_hurdles_1 = require("../../assets/images/sinkly_hurdles/sinkly_hurdles_1.png");
export const sinkly_hurdles_2 = require("../../assets/images/sinkly_hurdles/sinkly_hurdles_2.png");

export const sinkly_research_1 = require("../../assets/images/sinkly_research/sinkly_research_1.png");
export const sinkly_research_2 = require("../../assets/images/sinkly_research/sinkly_research_2.png");
export const sinkly_research_3 = require("../../assets/images/sinkly_research/sinkly_research_3.png");

export const aws_logo = require("../../assets/images/logos/aws_logo.png");
export const firebase_logo = require("../../assets/images/logos/firebase_logo.png");
export const full_story_logo = require("../../assets/images/logos/full_story_logo.png");
export const python_logo = require("../../assets/images/logos/python_logo.png");
export const react_logo = require("../../assets/images/logos/react_logo.png");
export const send_grid_logo = require("../../assets/images/logos/send_grid_logo.png");
export const stripe_logo = require("../../assets/images/logos/stripe_logo.png");
export const vercel_logo = require("../../assets/images/logos/vercel_logo.png");
export const axiom_logo = require("../../assets/images/logos/axiom_logo.png");

const app_folio_logo = require("../../assets/images/relevate_logos/app_folio_logo.png");
const building_link_logo = require("../../assets/images/relevate_logos/building_link_logo.png");
const buildium_logo = require("../../assets/images/relevate_logos/buildium_logo.png");
const henri_house_logo = require("../../assets/images/relevate_logos/henri_house_logo.png");
const pay_lease_logo = require("../../assets/images/relevate_logos/pay_lease_logo.png");
const rent_cafe_logo = require("../../assets/images/relevate_logos/rent_cafe_logo.png");
const fb_marketplace_logo = require("../../assets/images/relevate_logos/fb_marketplace_logo.png");
const offer_up_logo = require("../../assets/images/relevate_logos/offer_up_logo.png");

const tech_stack = [
  { src: aws_logo, alt: "AWS" },
  { src: firebase_logo, alt: "Firebase" },
  { src: python_logo, alt: "Python" },
  { src: react_logo, alt: "React" },
  { src: stripe_logo, alt: "Stripe" },
  { src: vercel_logo, alt: "Vercel" },
  { src: send_grid_logo, alt: "Send Grid" },
  { src: full_story_logo, alt: "Full Story" },
  { src: axiom_logo, alt: "Axiom" },
];

interface CompetitiveAnalysis {
  description: string;
  goals: string[];
  results: string;
  images: { src: string; alt: string }[];
}
interface UserNeeds {
  challenge: string;
  quotes: string[];
  goals: string[];
  success_metrics: string;
}

interface ResearchData {
  title: string;
  description: string;
  goals: string[];
  results: string;
  image: { src: string; alt: string };
}

interface HurdleData {
  title: string;
  subTitle: string;
  problemTitle: string;
  problemDescriptions: string[];
  solutionTitle: string;
  solutionDescriptions: string[];
  userHighlight?: string;
  image: string;
}

interface TechStack {
  title: string;
  descriptions: string[];
  tools: { src: string; alt: string }[];
}

const hero_data: HeroData = {
  title: "Relevate",
  description: `Launched a furniture marketplace for luxury NYC apartment. Facilitated the sales and communication. Designed and engineered by me.`,
  timeline: "9 months",
  role: "Engineer, designer, ideation, & marketing",
  team: "Just me",
  tools: "Swift 5, XCode, AWS, Firebase, RabbitMQ, RocketLog & Axiom.",
};

const user_needs_data: UserNeeds = {
  challenge: `Create a low-friction marketplace designed for users without a car.`,
  quotes: [
    `"I move home in 3 weeks. If I don't get rid of all this stuff, I'll just leave it on the sidewalk like everyone else."`,
    `"I've tried facebook groups and marketplace, but every offer I got was a scam. I'm going to try selling on my own."`,
  ],
  goals: [
    `Confirm the problem exists and needs solving.`,
    `Narrow down the problem to a workable MVP.`,
    `Understand my users and what they need.`,
  ],
  success_metrics: `Number of users, time on site, and number of documents created.`,
};

const competitive_analysis: CompetitiveAnalysis = {
  description: `Researched competing apps and interviewed friends to understand how they bought and sold used items in the city.`,
  goals: [
    `Make sure no current software solves this problem.`,
    `Understand why currently solutions fall short.`,
    `Identify must-have features for the MVP.`,
  ],
  results: `Available software charge $10,000 to $30,000 per user, and are complicated. Small and medium sized companies are priced out.`,
  images: [
    { src: offer_up_logo, alt: "Offer Up" },
    { src: fb_marketplace_logo, alt: "FB Marketplace" },
    { src: rent_cafe_logo, alt: "Rent Café" },
    { src: henri_house_logo, alt: "Henri House" },
    { src: app_folio_logo, alt: "App Folio" },
    { src: buildium_logo, alt: "Buildium" },
    { src: building_link_logo, alt: "BuildingLink" },
    { src: pay_lease_logo, alt: "Pay Lease" },
  ],
};

const research_data: ResearchData[] = [
  {
    title: "Ideation & learning",
    // title: "Research & learning",
    description: `I met with a hardware engineer to understand his workflow. The hardest part was learning the jargon of a new industry.`,
    goals: [
      "Learn the end-to-end design process.",
      "Learn how teams and customers collaborate.",
      "Learn the approval process for product designs.",
    ],
    results: `They use 5-10 documents to communicate design changes by email or in person. This was a major pain point.`,
    image: {
      src: sinkly_research_1,
      alt: "Research",
    },
  },
  {
    title: "User research & development",
    description: "I studied lectures and courses on system design and hardware engineering.",
    goals: [
      "Learn the industry's jargon.",
      "Translate my learnings into a digital product.",
      "Learn industry standards for designing systems.",
    ],
    results: "I understood each step in the design process and how each document is used.",
    image: {
      src: sinkly_research_2,
      alt: "Research",
    },
  },
  {
    title: "Marketing frenzy",
    description: "I followed up to validate my ideas and get feedback!",
    goals: [
      "Align on expectations for the MVP.",
      "Ensure my ideas would solve their problems.",
      "Ensure the information from my research was accurate.",
    ],
    results:
      "The engineer was excited and gave me a few more to consider. I felt confident in my understanding of the problem.",
    image: {
      src: sinkly_research_3,
      alt: "Research",
    },
  },
];

const hurdle_data: HurdleData[] = [
  {
    title: "What does the MVP look like?",
    subTitle: "Hurdle #1: Design",
    problemTitle: "Problem: Complex design process",
    problemDescriptions: [
      `I had to simplify processes that used 5-10 documents and make this solution accessible to testers.`, // Corrected
    ],
    solutionTitle: "Solution: Limit documents",
    solutionDescriptions: [
      `1. Only include the 3 most important and time consuming documents.`,
      `2. Build solution into browser app for maximum accessibility.`,
    ],
    userHighlight: `"It would be great to have this in the browser for easier access."`,
    image: sinkly_hurdles_1,
  },
  {
    title: "Syncing data across documents",
    subTitle: "Hurdle #3: Technical",
    problemTitle: "Problem: Out of sync documents",
    problemDescriptions: [
      `Errors constantly led to out of sync documents.`,
      `As the application scaled, this would be impossible to manage.`,
      `I needed a system with little to no chance of error syncing. This was a major technical challenge.`,
    ],
    solutionTitle: "Solution: One source of truth",
    solutionDescriptions: [
      `I created one data model as the source of truth for all documents.`,
      `This required a complete code overhaul, but it was worth it.`,
      `I had no more syncing issues and the app was much easier to maintain.`,
    ],
    // userHighlight: `"It would be great to have this in the browser to make it easier to access."`,
    image: sinkly_hurdles_2,
  },
  {
    title: "Syncing data across documents",
    subTitle: "Hurdle #3: Technical",
    problemTitle: "Problem: Low memory browsers",
    problemDescriptions: [
      `Editing large documents crashed the browser😬.`,
      `I needed a massive improvement in performance.`,
    ],
    solutionTitle: "Solution: Virtualization",
    solutionDescriptions: [
      `After excessive research, I virtualized the document.`,
      `This only rendered visible elements, not the entire document.`,
      `Now documents with 1000s of elements ran smoothly!`,
    ],
    userHighlight: `"It would be great to have this in the browser to make it easier to access."`,

    image: sinkly_hurdles_2,
  },
];

const tech_stack_data: TechStack = {
  title: "My tech stack",
  descriptions: [
    `I kept the tech stack simple and lightweight to move quickly.`,
    `I used React for the frontend and Python for the backend.`,
    `I used AWS for hosting and Firebase for authentication.`,
    `I used Stripe for payments and Send Grid for emails.`,
    `I used Vercel for continuous deployment and Full Story for user analytics.`,
    `I used Axiom for monitoring.`,
  ],
  tools: [
    { src: aws_logo, alt: "AWS" },
    { src: firebase_logo, alt: "Firebase" },
    { src: python_logo, alt: "Python" },
    { src: react_logo, alt: "React" },
    { src: stripe_logo, alt: "Stripe" },
    { src: vercel_logo, alt: "Vercel" },
    { src: send_grid_logo, alt: "Send Grid" },
    { src: full_story_logo, alt: "Full Story" },
    { src: axiom_logo, alt: "Axiom" },
  ],
};

function Relevate() {
  // useScrollToTop();

  return (
    <div className="flex flex-col items-center w-full min-h-screen ">
      <Toolbar />
      <HeroSection hero_data={hero_data} image={sinkly_lg_2} />
      <HorizontalDivider />

      <UserNeedsSection title="Discovering user needs" user_needs_data={user_needs_data} />
      <HorizontalDivider />

      <CompetitiveAnalysisSection competitive_analysis={competitive_analysis} />
      <ResearchSection />
      <HurdleSectionMVP dark feedback data={hurdle_data[0]} />

      <HurdleSection2 />

      <HurdleSectionMVP dark data={hurdle_data[1]} />

      <TechStackSection />
      <FinalThoughtsSection />

      <Footer home noDivider />
    </div>
  );
}

export function HeroSection({ hero_data, image }: { hero_data: HeroData; image: any }) {
  return (
    <div className="flex justify-center w-full mt-12 mx-4 xl:mx-12 pb-10 max-w-screen-xl">
      <Hero data={hero_data} />
      <HeroImage image={image} />
    </div>
  );
}

export function HeroImage({ image }: { image: any }) {
  const { xlOrLarger } = useScreenSize();

  if (!xlOrLarger) {
    return null;
  }

  return (
    <div className="flex justify-center items-center w-3/5">
      <img
        src={image}
        alt="Descriptive text"
        className="max-w-full h-auto rounded-3xl bg-transparent"
      />
    </div>
  );
}

export function Hero({ data }: { data: any }) {
  const { xlOrLarger } = useScreenSize();
  const largeStyles = xlOrLarger ? "w-2/5" : "w-full";
  return (
    <div
      className={`flex flex-col items-start justify-start space-y-6 h-full px-4 text-lg text-neutral-700 
    ${largeStyles}
    `}
    >
      <ProjectTitle title={data.title} />
      <Background description={data.description} />
      <TimelineRole timeline={data.timeline} role={data.role} />
      <Collaborators team={data.team} />
      <Tools tools={data.tools} />
      <CheckoutProject />
    </div>
  );
}

function ProjectTitle({ title }: { title?: string }) {
  return <div className="text-5xl font-medium text-neutral-800">{title}</div>;
}

export function UserNeedsSection({
  title,
  user_needs_data,
}: {
  title?: string;
  user_needs_data?: UserNeeds;
}) {
  return (
    <div className="flex flex-col justify-center items-center w-full space-y-10 max-w-screen-lg py-12">
      <span className="text-center text-3xl font-medium text-neutral-800">{title}</span>

      <div className="flex flex-col space-y-10 xl:flex-row xl:space-y-0 xl:space-x-6 justify-center items-center w-full px-6">
        <UserNeedsCards user_needs_data={user_needs_data} />
        <UserNeedsDescription user_needs_data={user_needs_data} />
      </div>
    </div>
  );
}

export function UserNeedsCards({ user_needs_data }: { user_needs_data?: UserNeeds }) {
  return (
    <div className="grid grid-rows-2 gap-6 items-center justify-center w-full xl:w-3/5 max-w-2xl">
      {user_needs_data.quotes.map((quote, index) => (
        <div
          key={index}
          className="flex flex-col items-center justify-center w-auto h-60 p-2 md:p-8 space-y-4 rounded-xl bg-neutral-200 border border-neutral-700"
        >
          <div className="flex items-center justify-center w-16 h-16 rounded-full bg-neutral-800 ">
            <UserRound size={30} color={Color.Neutral.css.oneHundred} />
          </div>
          <div className="text-sm text-center font-bold text-neutral-800">{quote}</div>
        </div>
      ))}
    </div>
  );
}

export function UserNeedsDescription({ user_needs_data }: { user_needs_data?: UserNeeds }) {
  const extra_data = {
    usability: "Users need to interact real-time with others to coordinate pick-ups.",
    targetAudience: "Primarily 18-35 year olds in dense New York City neighborhoods without a car.",
    userSurvey: `I went door to door and gave snack in my building's lobby to ask about their moving experiences.`,
    surveyFindings: `Non-local residents moving out of state often have to leave high-quality furniture behind and experience high pain selling items.`,
  };

  return (
    <div className="flex flex-col items-start justify-start w-full xl:w-2/5 space-y-4">
      <div className="flex items-center justify-start space-x-2">
        <Telescope size={20} color={Color.Neutral.css.eightHundred} />
        <div className="text-xl font-semibold text-neutral-800">The challenge</div>
      </div>

      <div className="text-start text-neutral-500">{user_needs_data.challenge}</div>

      <div className="flex items-center justify-start space-x-2">
        <Trophy size={20} color={Color.Neutral.css.eightHundred} />
        <div className="text-xl font-semibold text-neutral-800">Goal for the tool</div>
      </div>

      <div className="flex items-start justify-start space-x-4">
        <div className="flex flex-col space-y-2 w-1/2">
          <div className="text-lg font-semibold text-neutral-800">Usability</div>
          <div className="text-start text-neutral-500">{extra_data.usability}</div>
        </div>

        <div className="flex flex-col space-y-2 w-1/2">
          <div className="text-lg font-semibold text-neutral-800">Target audience</div>
          <div className="text-start text-neutral-500">{extra_data.targetAudience}</div>
        </div>
      </div>

      <div className="flex flex-col space-y-2">
        <div className="text-lg font-semibold text-neutral-800">User surveys</div>
        <div className="text-start text-neutral-500">{extra_data.userSurvey}</div>
      </div>

      <div className="flex flex-col space-y-2">
        <div className="text-lg font-semibold text-neutral-800">Survey findings</div>
        <div className="text-start text-neutral-500">{extra_data.surveyFindings}</div>
      </div>
    </div>
  );
}

export function CompetitiveAnalysisSection({
  competitive_analysis,
}: {
  competitive_analysis?: CompetitiveAnalysis;
}) {
  return (
    <div className="flex flex-col justify-center items-center w-full space-y-10 max-w-screen-lg py-12">
      <span className="text-center text-3xl font-medium text-neutral-800">
        Competitive analysis
      </span>

      <div className="flex flex-col space-y-10 xl:flex-row xl:space-y-0 xl:space-x-6 justify-center items-center w-full px-6">
        <CompetitiveAnalysisDescription competitive_analysis={competitive_analysis} />
        <CompetitiveAnalysisCards competitive_analysis={competitive_analysis} />
      </div>
    </div>
  );
}

export function CompetitiveAnalysisCards({
  competitive_analysis,
}: {
  competitive_analysis?: CompetitiveAnalysis;
}) {
  const { mdOrLarger } = useScreenSize();

  if (!mdOrLarger) {
    return (
      <div className="flex justify-center w-full space-x-6 h-min">
        <div className="grid grid-cols-3 w-full h-min gap-3 items-center justify-center ">
          {competitive_analysis.images.map((img_obj, index) => (
            <div className="flex flex-col items-center max-w-32 max-h-32 p-2 rounded-xl border-2 border-neutral-400 bg-white">
              <img src={img_obj.src} className="px-2" />
              <span className="font-semibold text-xs text-neutral-500">{img_obj.alt}</span>
            </div>
          ))}
        </div>
      </div>
    );
  }

  return (
    <div className="flex w-full xl:w-3/5 space-x-6 h-min">
      <div className="grid grid-cols-3 w-auto h-min min-w-32 gap-5 items-start justify-center ">
        {competitive_analysis.images.map((img_obj, index) => (
          <div className="flex flex-col items-center w-32 h-32 p-2 rounded-xl border-2 border-neutral-400 bg-white">
            <img src={img_obj.src} className="px-2" />
            <span className="font-semibold text-xs text-neutral-500">{img_obj.alt}</span>
          </div>
        ))}
      </div>
    </div>
  );
}

export function CompetitiveAnalysisDescription({
  competitive_analysis,
}: {
  competitive_analysis?: CompetitiveAnalysis;
}) {
  return (
    <div className="flex flex-col items-start justify-start w-full xl:w-2/5 space-y-4">
      <div className="flex items-center justify-start space-x-2">
        <FileBarChart2 size={20} color={Color.Neutral.css.eightHundred} />
        <div className="text-xl font-semibold text-neutral-800">Analysis of the market</div>
      </div>

      <div className="text-start text-neutral-500">{competitive_analysis.description}</div>

      <div className="flex items-center justify-start space-x-2 pt-4">
        <Trophy size={20} color={Color.Neutral.css.eightHundred} />
        <div className="text-xl font-semibold text-neutral-800">Goals</div>
      </div>

      {/* Ordered list with numbers */}
      <ol className="list-decimal text-neutral-500 px-4">
        {competitive_analysis.goals.map((goal, index) => (
          <li key={index}>{goal}</li>
        ))}
      </ol>

      <div className="flex items-center justify-start space-x-2 pt-4">
        <BookCheck size={20} color={Color.Neutral.css.eightHundred} />
        <div className="text-xl font-semibold text-neutral-800">Results</div>
      </div>

      <div className="text-start text-neutral-500">{competitive_analysis.results}</div>
    </div>
  );
}

export function ResearchSection() {
  const { xlOrLarger } = useScreenSize();
  return (
    <div className="flex flex-col items-center justify-center space-y-10  w-full mt-4 pb-12 text-neutral-100 bg-neutral-700">
      <span className="pt-12 text-center text-3xl font-medium">The journey</span>

      <div className="flex flex-col items-center w-full space-y-16 px-8 max-w-screen-lg">
        {xlOrLarger ? (
          <div className="flex w-full space-x-4">
            <ResearchDescription data={research_data[0]} />
            <ResearchCards data={research_data[0]} />
          </div>
        ) : (
          <div className="flex flex-col space-y-8 w-full">
            <ResearchCards data={research_data[0]} />
            <ResearchDescription data={research_data[0]} />
          </div>
        )}

        {/* display svg */}
        <div className="px-6">
          <TravelToLeft />
        </div>

        {xlOrLarger ? (
          <div className="flex w-full space-x-4">
            <ResearchCards data={research_data[1]} />
            <ResearchDescription data={research_data[1]} />
          </div>
        ) : (
          <div className="flex flex-col space-y-8 w-full">
            <ResearchCards data={research_data[1]} />
            <ResearchDescription data={research_data[1]} />
          </div>
        )}

        {/* display svg */}
        <div className="px-6">
          <TravelToRight />
        </div>

        {xlOrLarger ? (
          <div className="flex w-full space-x-4">
            <ResearchDescription data={research_data[2]} />
            <ResearchCards data={research_data[2]} />
          </div>
        ) : (
          <div className="flex flex-col space-y-8 w-full">
            <ResearchCards data={research_data[2]} />
            <ResearchDescription data={research_data[2]} />
          </div>
        )}
      </div>
    </div>
  );
}


function Background({ description }: { description: string }) {
  return (
    <div className="flex flex-col items-start justify-start space-y-4">
      <div className="flex items-center justify-start space-x-2">
        <FileText size={20} color={Color.Neutral.css.eightHundred} />
        <div className="font-medium text-neutral-800">Background</div>
      </div>
      <div className="text-sm text-neutral-500">{description}</div>
    </div>
  );
}

function TimelineRole({ timeline, role }: { timeline?: string; role?: string }) {
  return (
    <div className="flex flex-col ">
      <div className="flex items-center justify-start space-x-16">
        <div className="flex flex-col space-y-4">
          <div className="flex items-center justify-start space-x-2">
            <Calendar size={20} color={Color.Neutral.css.eightHundred} />
            <div className=" font-medium text-neutral-800">Timeline</div>
          </div>
          <div className="text-sm text-neutral-500">{timeline}</div>{" "}
        </div>

        <div className="flex flex-col space-y-4">
          <div className="flex items-center justify-start space-x-2">
            <UserRound size={20} color={Color.Neutral.css.eightHundred} />
            <div className=" font-medium text-neutral-800">Role</div>
          </div>
          <div className="text-sm text-neutral-500">{role}</div>{" "}
        </div>
      </div>
    </div>
  );
}

function Collaborators({ team }: { team?: string }) {
  return (
    <div className="flex flex-col space-y-4">
      <div className="flex items-center justify-start space-x-2">
        <UsersRound size={20} color={Color.Neutral.css.eightHundred} />
        <div className=" font-medium text-neutral-800">Team</div>
      </div>
      <div className="text-sm text-neutral-500">{team}</div>{" "}
    </div>
  );
}

function Tools({ tools }: { tools?: string }) {
  return (
    <div className="flex flex-col space-y-4">
      <div className="flex items-center justify-start space-x-2">
        <Code2 size={20} color={Color.Neutral.css.eightHundred} />
        <div className=" font-medium text-neutral-800">Tools</div>
      </div>
      <div className="text-sm text-neutral-500">{tools}</div>
    </div>
  );
}

function CheckoutProject() {
  const { xxlOrLarger } = useScreenSize();

  // Function to handle navigation on button click
  function navToSinkly() {
    console.log("Get started clicked");

    window.open("https://www.sinkly.co", "_blank");
  }
  function navToHackerPulse() {
    console.log("Get started clicked");

    window.open(
      "https://hackerpulse.substack.com/p/sinkly-keeping-your-system-engineering",
      "_blank"
    );
  }
  const divStyles = xxlOrLarger ? "flex space-x-4" : "flex flex-col space-y-4";
  return (
    <div className={divStyles}>
      <button
        // onClick={navToSinkly}
        className="flex items-center justify-center space-x-2 px-8 py-4 mt-6 rounded-full text-xl text-white bg-gradient-to-br from-neutral-600 to-neutral-700 border-2 border-neutral-700 "
        disabled
      >
        <span>No longer on AppStore</span>
        <CircleSlash size={24} color={Color.Neutral.css.oneHundred} />
      </button>
    </div>
  );
}

function FinalThoughtsSection() {
  return (
    <div className="flex flex-col items-center justify-center w-full mt-4 py-12 px-4 text-neutral-100 bg-neutral-300">
      <div className="flex flex-col justify-center items-center space-y-10 w-full max-w-screen-lg">
        <div className="flex flex-col items-center justify-center space-y-10 w-full p-8 rounded-3xl bg-neutral-700">
          <span className="text-center text-3xl font-medium">Final thoughts</span>
          <div className="text-center ">
            After presenting the MVP to multiple teams, I received little to no interest (yikes!). I
            met with senior engineers, managers, and project owners and learned some hard truths!
            <br />
            <br />
            This was not a problem teams wanted to solve. They had other priorities.
            <br />
            <br />
            Most small teams moved fast. They didn't have time to document. Mid-sized teams had a
            suite of tools that are "good enough". Large teams have the budget for expensive
            enterprise tools. The market for my tool was too small.
            <br />
            <br />
            Although it was tough, I decided to pivot. I had a lot of fun, learned a ton, and I'm
            excited to see what's next!
          </div>
        </div>
      </div>
    </div>
  );
}

function TechStackSection() {
  return (
    <div className={`flex flex-col items-center justify-center w-full pb-12 text-neutral-800`}>
      <div className="flex flex-col justify-center items-center w-full pt-12  max-w-screen-lg px-4">
        <span className="pb-10 text-center text-3xl font-medium">{tech_stack_data.title}</span>

        <div className="text-center lg:text-start font-medium text-neutral-500">
          {tech_stack_data.descriptions.map((description: string, index: number) => (
            <div className="mb-4" key={index}>
              {description}
            </div>
          ))}
        </div>

        <div className="flex flex-col mt-8 p-8 w-full rounded-3xl border-2 border-neutral-700 bg-neutral-300">
          <span className="w-full mb-6 text-center text-2xl font-medium ">
            Languages, infrastructure, auth, monitoring, & payments
          </span>
          <div className="grid grid-cols-3 lg:grid-cols-4 w-full min-w-12 gap-5 items-start justify-center ">
            {tech_stack.map((img_obj, index) => (
              <div className="flex flex-col items-center justify-center min-w-12 max-w-32 min-h-12 max-h-32 md:w-32 md:h-32 p-3 rounded-xl border border-neutral-700 bg-white">
                <img src={img_obj.src} className="" />
                <span className="w-full text-center font-semibold text-xs text-neutral-500">
                  {img_obj.alt}
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export function HorizontalDivider() {
  return <div className="h-px my-1 w-full bg-gray-300"></div>;
}

export function VerticalDivider() {
  return <div className="w-px h-full bg-gray-400"></div>;
}
export default Relevate;
