import axios from "axios";
import { GeneralResponse, GetDocumentResponse, StatusCode } from "src/utils/Api";
import { DocumentContainer, documentToJson, jsonToDocument } from "src/models/Document";
import { LOCAL_STORAGE, SERVER_ROUTE } from "src/utils/constants";

const HEADERS = {
  "Content-Type": "application/json",
};

// DO NOT call this function directly. Use saveDocToApiWithDebounce instead to
// batch all changes to a document within a certain time frame
export async function _saveDocumentAPI(document: Partial<DocumentContainer>) {
  try {
    console.log(`${SERVER_ROUTE.DOCUMENT.SAVE}`);
    const documentForAPI = documentToJson(document);

    const response = await axios.post(
      SERVER_ROUTE.DOCUMENT.SAVE,
      { document: documentForAPI },
      { headers: HEADERS }
    );
    const generalResp = response.data as GeneralResponse;
    return generalResp;
  } catch (error) {
    throw error;
  }
}

export async function getDocumentAPI(id: string) {
  try {
    const response = await axios.post(
      SERVER_ROUTE.DOCUMENT.GET,
      { id: id },
      {
        headers: HEADERS,
      }
    );

    const getDocResp = response.data as GetDocumentResponse;

    // If document doesn't exist, return null
    if ([StatusCode.HTTP_404, StatusCode.HTTP_500].includes(getDocResp.status)) {
      console.log("document doesn't exist");
      return getDocResp;
    }

    // Otherwise, convert API response to DocumentContainer and return
    return {
      resp: jsonToDocument(getDocResp.resp),
      status: getDocResp.status,
    };
  } catch (error) {
    throw error;
  }
}

export async function getDocumentsAPI(documentIDs: string[]) {
  try {
    const response = await axios.post(
      SERVER_ROUTE.DOCUMENT.GET_ALL,
      { document_ids: documentIDs },
      { headers: HEADERS }
    );

    console.log("got multiple docs", response.data);
    const data = {
      resp: response.data.resp.map((document: any) => jsonToDocument(document)),
      status: response.status,
    };

    return data;
  } catch (error) {
    throw error;
  }
}

export async function deleteDocumentAPI(id: string) {
  try {
    const response = await axios.post(
      SERVER_ROUTE.DOCUMENT.DELETE,
      { id: id },
      { headers: HEADERS }
    );

    const generalResp = response.data as GeneralResponse;
    return generalResp;
  } catch (error) {
    throw error;
  }
}
