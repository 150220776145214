// src/components/Cell/Cell.tsx

import React, { useMemo, useRef, useState } from "react";
import {
  CellType,
  DSMCell,
  getCellType,
  isHorizontalHeader,
  isVerticalHeader,
} from "../../../models/DSMCell";
import { useDispatch, useSelector } from "react-redux";
import { DisplayCellContent2, DisplayCellContent3 } from "./components/DisplayCellContent";
import { RootState } from "../../../state/slices";
import { Color } from "../../../models/BoxDiagram/Colors";
import { Edge } from "reactflow";
import { ArrowDirection, EdgeData } from "src/models/BoxDiagram/Edge";
import { getEdgesBySourceAndTarget } from "src/state/selectors/boxDiagramSelectors";
import { SubConnection } from "src/models/Connection";
import {
  DSMContextMenuState,
  setContextMenu,
  setSelectedState,
  setSelectedTextMenu,
} from "src/state/slices/dsmSlice";
import DSMContextMenu from "./components/DSMContextMenu";
import { DSMTextArea } from "./components/DSMTextArea";
import { DSMConnectionPicker } from "./components/DSMConnectionPicker";
import { dsmTextMenuIsOpen } from "src/state/selectors/selectors";
import { NodeType } from "src/models/BoxDiagram/Node";

/**
 * This function is used to memoize the cell component. This is important for performance. It
 * ensures that the component only re-renders when the data or style changes.
 */
const areEqual = (prevProps, nextProps) => {
  // If these have not changed, then the component does not need to re-render.
  return (
    // prevProps.data.id === nextProps.data.id &&
    // prevProps.data.row === nextProps.data.row &&
    // prevProps.data.col === nextProps.data.col &&
    // prevProps.data.value === nextProps.data.value &&
    prevProps.data.type == nextProps.data.type &&
    prevProps.data.selected == nextProps.data.selected &&
    prevProps.style == nextProps.style
  );
};

interface CellProps {
  data: DSMCell;
  style: React.CSSProperties;
}

// src/components/DSM/DSMCell/DSMCell.tsx

/**
 * Renders a DSM cell with conditional content and styling.
 * Handles selection and interaction logic.
 */
const DSMCellComponent = React.memo(function DSMCellComponent({ data, style }: CellProps) {
  const dispatch = useDispatch();
  // Used to edit node label in row or column header
  const selectedTextMenu = useSelector((state: RootState) => state.dsm.selectedTextMenu);
  // Used to edit connections in a default cell
  const [showEditConnections, setShowEditConnections] = useState(false);
  // Used to display context menu
  const contextMenu: DSMContextMenuState = useSelector((state: RootState) => state.dsm.contextMenu);
  // Used to display the currently selected cell
  const selectedState = useSelector((state: RootState) => state.dsm.selectedState);
  // Used to place the context menu in the correct position when clicking on last rows or columns
  const nodeLen = useSelector(
    (state: RootState) =>
      state.document.documentContainer.nodes.filter((node) => node.type === NodeType.BaseNode)
        .length
  );

  const isColSelected = selectedState?.target === data.target;
  const isRowSelected = selectedState?.source === data.source;

  // Styles for the cell based on selection and cell type
  const cellStyles = getCellStyles(data);
  const cssStyles = getBackgroundAndSizeStyles(data.type, style);

  // Content for the cell based on cell type
  const cellContent = getCellContent(data);

  function handleClick(event: React.MouseEvent<HTMLDivElement>) {
    handleCellClick(data, dispatch, isRowSelected, isColSelected);
    if (isRowSelected && isColSelected) {
      handleDoubleClick(event);
    } else if (
      (isRowSelected && data.type === CellType.RowHeader) ||
      (isColSelected && data.type === CellType.ColHeader)
    ) {
      handleDoubleClick(event);
    }
  }

  const handleDoubleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const cellType = data.type;
    const CONTEXT_MENU_WIDTH_DISPLACEMENT = 215;
    const CONTEXT_MENU_HEIGHT_DISPLACEMENT = 100;

    // If the cell is a regular cell, open the context menu
    if (cellType === CellType.Default) {
      // Get the bounding rectangle of the target element
      const containerBounds = event.currentTarget.getBoundingClientRect();
      // Calculate the position of the context menu
      let xPosition = event.clientX;
      let yPosition = event.clientY;

      // Adjust the position if the context menu would go off the right side of the screen
      if (data.col >= nodeLen - 1) {
        xPosition -= CONTEXT_MENU_WIDTH_DISPLACEMENT;
      }

      // Adjust the position if the context menu would go off the bottom of the screen
      if (data.row >= nodeLen - 1) {
        yPosition -= CONTEXT_MENU_HEIGHT_DISPLACEMENT;
      }
      dispatch(
        setContextMenu({
          ctxMenuState: {
            // Adjust the position to account for React Window's container dimensions
            position: {
              x: xPosition - containerBounds.left,
              y: yPosition - containerBounds.top,
            },
            cell: { source: data.source, target: data.target },
            offset: {
              x: xPosition - containerBounds.left,
              y: yPosition - containerBounds.top,
            },
          },
        })
      );

      // Set the cell as selected
      dispatch(setSelectedState({ rowCol: { source: data.source, target: data.target } }));
    }
    // If we right click on row or column header, open the text menu
    else if (cellType === CellType.RowHeader || cellType === CellType.ColHeader) {
      // handleClick();
      dispatch(setSelectedTextMenu({ rowCol: { source: data.source, target: data.target } }));
    }
  };

  // console.log("showEditConnections", editConnectionsOffset.current);
  return (
    <div className={`relative ${cellStyles}`} onClick={handleClick} style={cssStyles}>
      {shouldHighlightCell(data, selectedState) && (
        <OverlayText showBorder={isColSelected && isRowSelected} />
      )}
      {cellContent}

      {/* Context Menu */}
      {contextMenu.cell &&
        contextMenu.cell.source === data.source &&
        contextMenu.cell.target === data.target && (
          <DSMContextMenu data={data} setShowEditConnections={setShowEditConnections} />
        )}

      {/* <DSMTextArea /> */}

      {dsmTextMenuIsOpen(
        { source: data.source, target: data.target },
        data.type,
        selectedTextMenu
      ) && <DSMTextArea data={data} />}

      {showEditConnections && data.type === CellType.Default && (
        <DSMConnectionPicker
          offset={contextMenu.offset}
          source={data.source}
          target={data.target}
          setShowEditConnections={setShowEditConnections}
        />
      )}
    </div>
  );
}, areEqual);

// Update the shouldHighlightCell logic
function shouldHighlightCell(
  data: DSMCell,
  selectedState: { source?: string; target?: string } | null
) {
  // Check for no selection
  if (!selectedState) return false;

  // If a horizontal header is selected, highlight the whole column
  if (selectedState.target && isHorizontalHeader(data.row, data.col)) {
    return data.target === selectedState.target;
  }

  // If a vertical header is selected, highlight the whole row
  if (selectedState.source && isVerticalHeader(data.row, data.col)) {
    return data.source === selectedState.source;
  }

  // If a regular cell is selected, only highlight if it matches the selected cell
  return data.source === selectedState.source || data.target === selectedState.target;
}

/**
 * Generates the appropriate content for a cell based on its type.
 */
function getCellContent(data: DSMCell) {
  const connections = getConnectionsForCell(data);
  if (connections.length > 0 && data.type === CellType.Default) {
    return <DisplayCellContent3 connections={connections} />;
  }

  return <DisplayCellContent2 cell={data} />;
}

// 🚨This is wrong🚨
// Each edge should be evaluated individually. Determine whether to place each edge at (row, col) or (col, row)
// for each edge, then combine the results and remove duplicates.
//
// This breaks when there are multiple edges between the same source and target.
/**
 * Returns the connections to display for a given cell.
 */
function getConnectionsForCell(cell: DSMCell): SubConnection[] {
  const edges = useSelector((state) => getEdgesBySourceAndTarget(state, cell.source, cell.target));

  const inverseEdges = useSelector((state) =>
    getEdgesBySourceAndTarget(state, cell.target, cell.source)
  );

  // For each edge, but each connection in a list
  const edgeConnections = edges.flatMap((edge) => edge.data.connections);
  const inverseEdgeConnections = inverseEdges.flatMap(
    (inverseEdge) => inverseEdge.data.connections
  );

  const allConnections = [...edgeConnections, ...inverseEdgeConnections];
  // remove duplicates
  const uniqueConnections = allConnections.filter((item, index) => {
    return allConnections.indexOf(item) === index;
  });

  // If edge is bidirectional, render it at (row, col) and (col, row)
  if (getArrowDirectionByRank([...edges, ...inverseEdges]) === ArrowDirection.Both) {
    return uniqueConnections;
  }
  // If edge has no direction, don't render it
  else if (getArrowDirectionByRank([...edges, ...inverseEdges]) == ArrowDirection.NoDirection) {
    return [];
  }
  // If edge is unidirectional, render it at (row, col) only where row = source and col = target
  else {
    return edgeConnections;
  }
}

function getArrowDirectionByRank(edges: Edge<EdgeData>[]): ArrowDirection {
  if (edges.length === 0) {
    return ArrowDirection.NoDirection;
  }

  // If there is at least one bidirectional edge, return bidirectional
  const bidirectional = edges.some((edge) => edge.data.arrow_direction === ArrowDirection.Both);
  if (bidirectional) {
    return ArrowDirection.Both;
  }

  // If there is at least one unidirectional edge, return unidirectional
  const unidirectional = edges.some(
    (edge) =>
      edge.data.arrow_direction === ArrowDirection.ToSource ||
      edge.data.arrow_direction === ArrowDirection.ToTarget
  );
  if (unidirectional) {
    return ArrowDirection.ToTarget;
  }

  // If there is at least one edge with no direction, return no direction
  const noDirection = edges.some(
    (edge) => edge.data.arrow_direction === ArrowDirection.NoDirection
  );
  if (noDirection) {
    return ArrowDirection.NoDirection;
  }

  // If arrow direction is invalid, throw an error
  throw new Error("Invalid arrow direction");
}

/**
 * Returns event handler for double click action on a cell.
 */
function getDoubleClickHandler(data: DSMCell, dispatch: any) {
  return (e: React.MouseEvent<HTMLDivElement>) => {
    // const position = calculatePosition(e);
    // dispatch(setSelectDialogPosition({ row: data.row, col: data.col, position }));
    // dispatch(setSelectedState2({ cell: { row: data.row, col: data.col } }));
  };
}

/**
 * Calculates the position for the selection dialog based on the event.
 */
function calculatePosition(e: React.MouseEvent<HTMLDivElement>) {
  const rect = e.currentTarget.getBoundingClientRect();
  return {
    top: rect.top + window.scrollY,
    left: rect.left + window.scrollX,
    bottom: window.innerHeight - (rect.bottom + window.scrollY),
    right: window.innerWidth - (rect.right + window.scrollX),
  };
}

/**
 * Handles click action on a cell, potentially setting selected row.
 */
function handleCellClick(
  data: DSMCell,
  dispatch: any,
  isRowSelected: boolean,
  isColSelected: boolean
) {
  // // If this cell is already selected, deselect it
  // if (isRowSelected && isColSelected) {
  //   dispatch(setSelectedState({ rowCol: { source: undefined, target: undefined } }));
  // }
  // If we selecting a RowHeader or RowID, set the selected row
  if (isHorizontalHeader(data.row, data.col)) {
    dispatch(setSelectedState({ rowCol: { source: undefined, target: data.target } }));
  }
  // If we selecting a ColHeader or ColID, set the selected column
  else if (isVerticalHeader(data.row, data.col)) {
    dispatch(setSelectedState({ rowCol: { source: data.source, target: undefined } }));
  }
  // If we selecting a Default cell, set the row and col
  else if (getCellType(data.row, data.col) === CellType.Default) {
    dispatch(setSelectedState({ rowCol: { source: data.source, target: data.target } }));
  }
  // If we select anything else (like the origin cell), deselect everything
  else {
    dispatch(setSelectedState({ rowCol: { source: undefined, target: undefined } }));
  }
}

/**
 * Combines base styles with conditional styles based on cell state.
 */
function getCellStyles(cell: DSMCell): string {
  let selectedStyles = "";
  // isRowSelected || isColSelected ? "bg-blue-100" : "";

  const baseStyles =
    "flex items-center justify-center cursor-pointer text-sm border hover:bg-blue-100 rounded-sm";
  return baseStyles;
}

/**
 * Returns styles specific to the cell type for background color and size.
 */
export function getBackgroundAndSizeStyles(
  type: CellType,
  currentStyles?: React.CSSProperties
): React.CSSProperties {
  switch (type) {
    case CellType.Origin:
      // The origin cell, typically at the top-left corner of the grid
      return {
        ...currentStyles,
        backgroundColor: Color.Gray.css.twoHundred,
        border: "1px solid black",
        width: "20px",
        height: "20px",
      };
    case CellType.Legend:
      // A cell used to display legend items
      return {
        ...currentStyles,
        border: "0.5px solid gray",
        backgroundColor: Color.Gray.css.oneHundred,
        width: "240px",
        height: "240px",
      };
    case CellType.LegendIDHoriz:
      // A cell for horizontal legend identifiers
      return {
        ...currentStyles,
        border: "0.5px solid gray",
        backgroundColor: Color.Gray.css.twoHundred,
        width: "240px",
        height: "20px",
      };
    case CellType.LegendIDVert:
      // A cell for vertical legend identifiers
      return {
        ...currentStyles,
        border: "0.5px solid gray",
        backgroundColor: Color.Gray.css.twoHundred,
        width: "20px",
        height: "240px",
      };
    case CellType.ColID:
      // Column identifier cells at the top of each column
      return {
        ...currentStyles,
        border: "0.5px solid gray",
        backgroundColor: Color.Gray.css.twoHundred,
        width: "64px",
        height: "20px",
      };
    case CellType.RowID:
      // Row identifier cells at the beginning of each row
      return {
        ...currentStyles,
        border: "0.5px solid gray",
        backgroundColor: Color.Gray.css.twoHundred,
        width: "20px",
        height: "64px",
      };
    case CellType.RowHeader:
      // Header cells for rows
      return {
        ...currentStyles,
        border: "0.5px solid gray",
        backgroundColor: Color.Blue.css.oneHundred,
        width: "240px",
        height: "64px",
      };
    case CellType.ColHeader:
      // Header cells for columns
      return {
        ...currentStyles,
        border: "0.5px solid gray",
        backgroundColor: Color.Blue.css.oneHundred,
        width: "64px",
        height: "240px",
      };
    case CellType.Inactive:
      // Inactive cells that are not interactive
      return {
        ...currentStyles,
        border: "0.5px solid gray",
        backgroundColor: Color.Gray.css.threeHundred,
        width: "64px",
        height: "64px",
      };
    default:
      // Default cell styling
      return {
        ...currentStyles,
        border: "0.5px solid gray",
        backgroundColor: "transparent",
        width: "64px",
        height: "64px",
      };
  }
}
/**
 * Renders overlay text on a cell.
 */
function OverlayText({ showBorder }: { showBorder: boolean }) {
  if (showBorder) {
    console.log("showBorder");
  }
  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: "flex",
        justifyContent: "center",
        fontWeight: 500,
        alignItems: "center",
        backgroundColor: "rgba(130, 130, 255, 0.08)", // Semi-transparent black background
        zIndex: 1,
        width: "100%",
        height: "100%",
        border: showBorder ? "1.5px solid blue" : "none",
      }}
    ></div>
  );
}

export default DSMCellComponent;
