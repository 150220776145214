// src/slices/boxDiagramPanelsSlice.ts

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Edge } from "reactflow";
import { EdgeData } from "src/models/BoxDiagram/Edge";

export type MenuItemValue = {
  title: MenuItemName;
};

export enum MenuItemName {
  Shapes = "Shapes",
  Connections = "Connections",
  Frame = "Frame",
  Placeholder = "Placeholder",
}

export const MenuItemLabels: Record<MenuItemName, MenuItemValue> = {
  Shapes: {
    title: MenuItemName.Shapes,
  },
  Connections: {
    title: MenuItemName.Connections,
  },
  Frame: {
    title: MenuItemName.Frame,
  },
  Placeholder: {
    title: MenuItemName.Placeholder,
  },
};

export interface BoxDiagramUIState {
  // Selects which sidebar menu item is highlighted
  activeMenuItem: MenuItemName | null;
  // Selects which sidebar menu detail is displayed
  // activeMenuDetails: MenuItemName | null;
  // Track if pane is dragging to prevent right click to show context menu
  paneDragging: boolean;
  // This is set when we want to zoom to a specific node. Set from from withing or outside the diagram
  zoomToNode: string | null;
  // Track if user is currently connecting two nodes. Used to manager which node handles are shown
  connectionInProgress;
  // Track with edge is currently being hovered over
  quickDisplayEdgeID: string | null;
}

const initialState: BoxDiagramUIState = {
  activeMenuItem: null,
  // activeMenuDetails: null,
  paneDragging: false,
  zoomToNode: null,
  connectionInProgress: false,
  quickDisplayEdgeID: null,
};

export const boxDiagramPanelsSlice = createSlice({
  name: "boxDiagramLeftPanel",
  initialState,
  reducers: {
    setSelectItem: (state, action: PayloadAction<{ item: MenuItemName | null }>) => {
      state.activeMenuItem = action.payload.item;
    },
    // setActiveMenuDetails: (state, action: PayloadAction<{ item: MenuItemName | null }>) => {
    //   state.activeMenuDetails = action.payload.item;
    // },
    setPaneDrag: (state, action: PayloadAction<{ paneDragging: boolean }>) => {
      state.paneDragging = action.payload.paneDragging;
    },
    setZoomToNode: (state, action: PayloadAction<{ nodeID: string | null }>) => {
      state.zoomToNode = action.payload.nodeID;
    },
    setConnectionInProgress: (state, action: PayloadAction<{ inProgress: boolean }>) => {
      state.connectionInProgress = action.payload.inProgress;
    },
    setQuickDisplayEdgeID: (state, action: PayloadAction<{ edgeID: string | null }>) => {
      state.quickDisplayEdgeID = action.payload.edgeID;
    },
  },
});

export const {
  setSelectItem,
  // setActiveMenuDetails,
  setPaneDrag,
  setZoomToNode,
  setConnectionInProgress,
  setQuickDisplayEdgeID,
} = boxDiagramPanelsSlice.actions;
export default boxDiagramPanelsSlice.reducer;
