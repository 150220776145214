import {
  ArrowDirection,
  EdgeData,
  EdgeType,
  baseEdgeData,
  createNewEdge,
  getEdgeID,
} from "src/models/BoxDiagram/Edge";
import { EDGE_TOOLBAR_ID } from "./constants";
import {
  defaultLegendItem,
  defaultConnections,
  electricalLegendItem,
  informationLegendItem,
  massLegendItem,
  mechanicalLegendItem,
} from "src/models/Connection";
import {
  NodeData,
  NodeType,
  baseNodeData,
  createNewNode,
  getNodeID,
} from "src/models/BoxDiagram/Node";
import { Edge, Node } from "reactflow";
import { nanoid } from "nanoid";
import { DocumentContainer, DocumentPermissions } from "src/models/Document";

const initialCell1 = createNewNode(
  getNodeID(),
  { ...baseNodeData, label: "Engine" },
  { x: 800, y: 300 }
);
const initialCell2 = createNewNode(
  getNodeID(),
  { ...baseNodeData, label: "Exhaust" },
  { x: 600, y: 450 }
);
const initialCell3 = createNewNode(
  getNodeID(),
  { ...baseNodeData, label: "Pipe" },
  { x: 800, y: 600 }
);
const initialCell4 = createNewNode(
  getNodeID(),
  { ...baseNodeData, label: "Tank1" },
  { x: 1000, y: 450 }
);
const initialCell5 = createNewNode(
  getNodeID(),
  { ...baseNodeData, label: "Tank2" },
  { x: 1300, y: 300 }
);
const initialCell6 = createNewNode(
  getNodeID(),
  { ...baseNodeData, label: "Tank3" },
  { x: 350, y: 600 }
);
const initialCell7 = createNewNode(
  getNodeID(),
  { ...baseNodeData, label: "Exhaust1" },
  { x: -50, y: 150 }
);
const initialCell8 = createNewNode(
  getNodeID(),
  { ...baseNodeData, label: "Exhaust2" },
  { x: 250, y: 150 }
);
const initialCell9 = createNewNode(
  getNodeID(),
  { ...baseNodeData, label: "Exhaust3" },
  { x: -50, y: 300 }
);
const initialCell10 = createNewNode(
  getNodeID(),
  { ...baseNodeData, label: "Piston" },
  { x: 900, y: 50 }
);
const initialCell11 = createNewNode(
  getNodeID(),
  { ...baseNodeData, label: "Piston" },
  { x: 900, y: -50 }
);
const initialCell12 = createNewNode(
  getNodeID(),
  { ...baseNodeData, label: "Piston" },
  { x: 900, y: -150 }
);
const initialCell13 = createNewNode(
  getNodeID(),
  { ...baseNodeData, label: "Piston" },
  { x: 900, y: -250 }
);
const initialCell14 = createNewNode(
  getNodeID(),
  { ...baseNodeData, label: "Other component" },
  { x: 1400, y: -100 }
);

export const initialEdgeToolbarNode = {
  id: EDGE_TOOLBAR_ID,
  type: NodeType.EdgeToolbar,
  position: { x: 1000, y: 450 },
  deletable: false,
  selectable: false,
  zIndex: 10000,
  data: { ...baseNodeData, label: "Edge toolbar" },
};

export const initialNodes: Node<NodeData>[] = [
  initialCell1,
  initialCell2,
  initialCell3,
  initialCell4,
  initialCell5,
  initialCell6,
  initialCell7,
  initialCell8,
  initialCell9,
  initialCell10,
  initialCell11,
  initialCell12,
  initialCell13,
  initialCell14,

  initialEdgeToolbarNode,
];

export const initialEdges: Edge<EdgeData>[] = [
  createNewEdge({
    id: getEdgeID(),
    source: initialCell1.id,
    target: initialCell2.id,
    type: EdgeType.DefaultEdge,
    data: { ...baseEdgeData },
    sourceHandle: "s-left",
    targetHandle: "t-left",
  }),
  createNewEdge({
    id: getEdgeID(),
    source: initialCell4.id,
    target: initialCell3.id,
    type: EdgeType.DefaultEdge,
    data: { ...baseEdgeData },
    sourceHandle: "s-bottom",
    targetHandle: "t-right",
  }),
  createNewEdge({
    id: getEdgeID(),
    source: initialCell5.id,
    target: initialCell4.id,
    type: EdgeType.DefaultEdge,
    data: {
      ...baseEdgeData,
      arrow_direction: ArrowDirection.ToTarget,
      connections: [mechanicalLegendItem, informationLegendItem],
    },
    sourceHandle: "s-left",
    targetHandle: "t-top",
  }),
  createNewEdge({
    id: getEdgeID(),
    source: initialCell3.id,
    target: initialCell6.id,
    type: EdgeType.DefaultEdge,
    data: {
      ...baseEdgeData,
      arrow_direction: ArrowDirection.Both,
      connections: [electricalLegendItem, massLegendItem, informationLegendItem],
    },
    sourceHandle: "s-left",
    targetHandle: "t-right",
  }),
  createNewEdge({
    id: getEdgeID(),
    source: initialCell7.id,
    target: initialCell8.id,
    type: EdgeType.DefaultEdge,
    data: {
      ...baseEdgeData,
      arrow_direction: ArrowDirection.ToTarget,
      connections: [
        mechanicalLegendItem,
        informationLegendItem,
        electricalLegendItem,
        massLegendItem,
        defaultLegendItem,
      ],
    },
    sourceHandle: "s-right",
    targetHandle: "t-left",
  }),
  createNewEdge({
    id: getEdgeID(),
    source: initialCell9.id,
    target: initialCell8.id,
    type: EdgeType.DefaultEdge,
    data: {
      ...baseEdgeData,
      arrow_direction: ArrowDirection.ToTarget,
      connections: [mechanicalLegendItem],
    },
    sourceHandle: "s-right",
    targetHandle: "t-bottom",
  }),
  createNewEdge({
    id: getEdgeID(),
    source: initialCell7.id,
    target: initialCell9.id,
    type: EdgeType.DefaultEdge,
    data: {
      ...baseEdgeData,
      arrow_direction: ArrowDirection.ToTarget,
      connections: [mechanicalLegendItem, informationLegendItem],
    },
    sourceHandle: "s-bottom",
    targetHandle: "t-top",
  }),
  createNewEdge({
    id: getEdgeID(),
    source: initialCell8.id,
    target: initialCell1.id,
    type: EdgeType.DefaultEdge,
    data: {
      ...baseEdgeData,
      arrow_direction: ArrowDirection.ToTarget,
    },
    sourceHandle: "s-right",
    targetHandle: "t-top",
  }),
  createNewEdge({
    id: getEdgeID(),
    source: initialCell10.id,
    target: initialCell14.id,
    type: EdgeType.DefaultEdge,
    data: {
      ...baseEdgeData,
      arrow_direction: ArrowDirection.ToTarget,
      connections: [informationLegendItem],
    },
    sourceHandle: "s-right",
    targetHandle: "t-left",
  }),
  createNewEdge({
    id: getEdgeID(),
    source: initialCell11.id,
    target: initialCell14.id,
    type: EdgeType.DefaultEdge,
    data: {
      ...baseEdgeData,
      arrow_direction: ArrowDirection.ToTarget,
      connections: [informationLegendItem],
    },
    sourceHandle: "s-right",
    targetHandle: "t-left",
  }),
  createNewEdge({
    id: getEdgeID(),
    source: initialCell12.id,
    target: initialCell14.id,
    type: EdgeType.DefaultEdge,
    data: {
      ...baseEdgeData,
      arrow_direction: ArrowDirection.ToTarget,
      connections: [informationLegendItem],
    },
    sourceHandle: "s-right",
    targetHandle: "t-left",
  }),
  createNewEdge({
    id: getEdgeID(),
    source: initialCell13.id,
    target: initialCell14.id,
    type: EdgeType.DefaultEdge,
    data: {
      ...baseEdgeData,
      arrow_direction: ArrowDirection.ToTarget,
      connections: [informationLegendItem],
    },
    sourceHandle: "s-right",
    targetHandle: "t-left",
  }),

  createNewEdge({
    id: getEdgeID(),
    source: initialCell14.id,
    target: initialCell5.id,
    type: EdgeType.DefaultEdge,
    data: {
      ...baseEdgeData,
      arrow_direction: ArrowDirection.Both,
      connections: [massLegendItem],
    },
    sourceHandle: "s-right",
    targetHandle: "t-top",
  }),
];

export function getInitialDocument() {
  // Sort edges by source node id. We should sort by the labels of the nodes, but we don't have that information here.
  const sortedEdges = [...initialEdges].sort((a, b) => a.source.localeCompare(b.source));

  return {
    connections: defaultConnections,
    id: `doc-${nanoid(4)}`,
    id_prefix: "ID",
    permissions: DocumentPermissions.VIEW,
    name: "Untitled doc",
    nodes: initialNodes,
    edges: sortedEdges,
  };
}
