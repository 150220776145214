// src/components/DocumentsGrid.tsx

import { DocumentContainer } from "src/models/Document";
import DocumentCard from "./DocumentCard/DocumentCard";

interface DocumentGridProps {
  documents: DocumentContainer[];
}

// Updated grid to fit 5 items on large screens, 3 on medium screens, 2 on small screens, and 1 on extra small screens
function DocumentsGrid({ documents }: DocumentGridProps) {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 w-full ">
      {documents.map((doc) => (
        <DocumentCard key={doc.id} documentObj={doc}/>
      ))}
    </div>
  );
}

export default DocumentsGrid;
