// src/state/selectors.ts

import { Edge, Node } from "reactflow";
import { RootState } from "../slices";
import { setSelectedEdge } from "../slices/documentSlice";
import { setSelectedRow } from "../slices/icdSlice";
import { setSelectedSection } from "../slices/sidebarSlice";
import { SideNavigationBarSection } from "../slices/uiSlice";
import { EdgeData } from "src/models/BoxDiagram/Edge";
import { setScrollToEdge, setSelectedState } from "../slices/dsmSlice";
import { applyNewNodeChanges } from "../reducers/boxDiagramReducers";
import { setZoomToNode } from "../slices/BoxDiagram/boxDiagramUISlice";
import { CellType } from "src/models/DSMCell";

// Unused
export function selectIsUserLoggedIn(state: RootState) {
  return state.user.user !== null;
}

export function navigateToICD(dispatch: any, edgeIndex: number) {
  dispatch(setSelectedRow({ rowIndex: edgeIndex }));
  dispatch(setSelectedSection(SideNavigationBarSection.ICD));
  dispatch(setSelectedEdge({ edge: null }));
  // Set selected DSM cell to null
  dispatch(setSelectedState({ rowCol: null }));
}

export function navigateToDSM(dispatch: any, edge: Edge<EdgeData>) {
  // Highlight the cell
  dispatch(setSelectedState({ rowCol: { source: edge.source, target: edge.target } }));
  // Scroll to the cell
  dispatch(setScrollToEdge({ edge: edge }));
  // Highlight the cell in DSM
  dispatch(setSelectedSection(SideNavigationBarSection.DSM));
  // Hide edge toolbar
  dispatch(setSelectedEdge({ edge: null }));
  // Hide node toolbar
  // TBD
}

export function navigateToBoxDiagram(dispatch: any, nodeID: string) {
  dispatch(applyNewNodeChanges([{ id: nodeID, type: "select", selected: true }]));
  dispatch(setZoomToNode({ nodeID }));
  dispatch(setSelectedSection(SideNavigationBarSection.BoxDiagram));
  // Set selected DSM cell to null
  dispatch(setSelectedState({ rowCol: null }));
}

export function dsmRowSelected(selectedState: { source?: string; target?: string } | null) {
  return selectedState?.source !== undefined && selectedState.target === undefined;
}
export function dsmColSelected(selectedState: { source?: string; target?: string } | null) {
  return selectedState?.source === undefined && selectedState.target !== undefined;
}
export function dsmCellSelected(selectedState: { source?: string; target?: string } | null) {
  return selectedState?.source !== undefined && selectedState.target !== undefined;
}
export function dsmTextMenuIsOpen(
  cell: { source: string; target: string } | null,
  cellType: CellType,
  selectedState: { source?: string; target?: string } | null
) {
  if (![CellType.RowHeader, CellType.ColHeader].includes(cellType)) {
    return false;
  }

  return cell.source === selectedState?.source && cell.target === selectedState?.target;
}
