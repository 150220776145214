// src/state/reducers/dsmReducers.ts
import { PayloadAction, createAction } from "@reduxjs/toolkit";
import { DocumentState, updateTimestamp } from "../slices/documentSlice";
import { saveDocToApiWithDebounce } from "src/utils/autoSave";

export const dsmReducers = {
  moveNodePositionDown: (state: DocumentState, action: PayloadAction<{ nodeId: string }>) => {
    const { nodeId } = action.payload;
    const { nodes } = state.documentContainer;
    const nodeIndex = nodes.findIndex((node) => node.id === nodeId);

    // If the node is not found or it is already at the bottom, don't move it.
    if (nodeIndex === -1 || nodeIndex === nodes.length - 1) {
      return;
    }

    // Swap the current node with the one below it
    const temp = nodes[nodeIndex];
    nodes[nodeIndex] = nodes[nodeIndex + 1];
    nodes[nodeIndex + 1] = temp;

    state.documentContainer.nodes = [...nodes];

    state.documentContainer = updateTimestamp(state.documentContainer);
    // Save document to API
    saveDocToApiWithDebounce({
      id: state.documentContainer.id,
      owner_id: state.documentContainer.owner_id,
      nodes: state.documentContainer.nodes,
      permissions: state.documentContainer.permissions,
    });
  },
  moveNodePositionUp: (state: DocumentState, action: PayloadAction<{ nodeId: string }>) => {
    const { nodeId } = action.payload;
    const { nodes } = state.documentContainer;
    const nodeIndex = nodes.findIndex((node) => node.id === nodeId);

    // If the node is not found or it is already at the top, don't move it.
    if (nodeIndex === -1 || nodeIndex === 0) {
      return;
    }

    // Swap the current node with the one above it
    const temp = nodes[nodeIndex];
    nodes[nodeIndex] = nodes[nodeIndex - 1];
    nodes[nodeIndex - 1] = temp;

    state.documentContainer.nodes = [...nodes];

    state.documentContainer = updateTimestamp(state.documentContainer);
    // Save document to API
    saveDocToApiWithDebounce({
      id: state.documentContainer.id,
      owner_id: state.documentContainer.owner_id,
      nodes: state.documentContainer.nodes,
      permissions: state.documentContainer.permissions,
    });
  },
};

export const moveNodePositionDown = createAction<{ nodeId: string }>("dsm/moveNodePositionDown");
export const moveNodePositionUp = createAction<{ nodeId: string }>("dsm/moveNodePositionUp");
