import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { NodeData } from "../../../../models/BoxDiagram/Node";
import BaseToolbar, { ToolbarButton, VerticalDividerOLD } from "../BaseToolbar";
import { updateNodeData } from "src/state/reducers/boxDiagramReducers";
import BorderPickerSection from "../BaseNodeToolBar/BorderPicker";
import { ColorPickerSection, RemoveNodeButton } from "../BaseNodeToolBar/BaseNodeToolbar";

export enum NodeBarMenuItem {
  BackgroundColor,
  BorderColor,
}

interface BaseNodeToolbarProps {
  nodeData: NodeData;
  nodeId: string;
}

function FrameNodeToolbar({ nodeId, nodeData }: BaseNodeToolbarProps) {
  const dispatch = useDispatch();
  const [selectedSubMenu, setSelectedSubMenu] = useState<NodeBarMenuItem | null>(null);

  function onMenuClose() {
    dispatch(updateNodeData({ id: nodeId, newData: { show_node_toolbar: false } }));
    setSelectedSubMenu(null);
  }

  return (
    <BaseToolbar
      nodeOrEdgeId={nodeId}
      showNodeToolbar={nodeData.show_node_toolbar}
      onMenuClose={onMenuClose}
    >
      <ColorPickerSection
        nodeId={nodeId}
        nodeData={nodeData}
        selectedSubMenu={selectedSubMenu}
        setSelectedSubMenu={setSelectedSubMenu}
      />

      <BorderPickerSection
        nodeId={nodeId}
        nodeData={nodeData}
        selectedSubMenu={selectedSubMenu}
        setSelectedSubMenu={setSelectedSubMenu}
      />

      <VerticalDividerOLD />

      <RemoveNodeButton nodeId={nodeId} />
    </BaseToolbar>
  );
}

export default FrameNodeToolbar;
