import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { NodeData } from "../../../../models/BoxDiagram/Node";
import BaseToolbar, { ToolbarButton, VerticalDividerOLD } from "../BaseToolbar";
import { Trash2 } from "lucide-react";
import { Color } from "../../../../models/BoxDiagram/Colors";
import { updateNodeData } from "src/state/reducers/boxDiagramReducers";
import { deleteNode } from "src/state/slices/documentSlice";
import BorderPickerSection from "./BorderPicker";

const COLORS: string[] = [
  Color.Red.css.fifty,
  Color.Red.css.twoHundred,
  Color.Red.css.fiveHundred,
  Color.White.css.fifty,
  Color.Orange.css.fifty,
  Color.Orange.css.twoHundred,
  Color.Orange.css.fiveHundred,
  Color.Gray.css.fifty,
  Color.Yellow.css.fifty,
  Color.Yellow.css.twoHundred,
  Color.Yellow.css.fiveHundred,
  Color.Gray.css.oneHundred,
  Color.Lime.css.fifty,
  Color.Lime.css.twoHundred,
  Color.Lime.css.fiveHundred,
  Color.Gray.css.twoHundred,
  Color.Green.css.fifty,
  Color.Green.css.twoHundred,
  Color.Green.css.fiveHundred,
  Color.Gray.css.fourHundred,
  Color.Blue.css.fifty,
  Color.Blue.css.twoHundred,
  Color.Blue.css.fiveHundred,
  Color.Gray.css.fiveHundred,
  Color.Indigo.css.fifty,
  Color.Indigo.css.twoHundred,
  Color.Indigo.css.fiveHundred,
  Color.Gray.css.sixHundred,
  Color.Purple.css.fifty,
  Color.Purple.css.twoHundred,
  Color.Purple.css.fiveHundred,
  Color.Black.css.fiveHundred,
];

export enum NodeBarMenuItem {
  BackgroundColor,
  BorderColor,
}

interface BaseNodeToolbarProps {
  nodeData: NodeData;
  nodeId: string;
}

function BaseNodeToolbar({ nodeId, nodeData }: BaseNodeToolbarProps) {
  const dispatch = useDispatch();
  const [selectedSubMenu, setSelectedSubMenu] = useState<NodeBarMenuItem | null>(null);

  function onMenuClose() {
    dispatch(updateNodeData({ id: nodeId, newData: { show_node_toolbar: false } }));
    setSelectedSubMenu(null);
  }

  return (
    <BaseToolbar
      nodeOrEdgeId={nodeId}
      showNodeToolbar={nodeData.show_node_toolbar}
      onMenuClose={onMenuClose}
    >
      <ColorPickerSection
        nodeId={nodeId}
        nodeData={nodeData}
        selectedSubMenu={selectedSubMenu}
        setSelectedSubMenu={setSelectedSubMenu}
      />

      <BorderPickerSection
        nodeId={nodeId}
        nodeData={nodeData}
        selectedSubMenu={selectedSubMenu}
        setSelectedSubMenu={setSelectedSubMenu}
      />

      <VerticalDividerOLD />

      <RemoveNodeButton nodeId={nodeId} />
    </BaseToolbar>
  );
}

interface ColorPickerSectionProps {
  nodeId: string;
  nodeData: NodeData;
  selectedSubMenu: NodeBarMenuItem | null;
  setSelectedSubMenu: (selectedSubMenu: NodeBarMenuItem | null) => void;
}

export function ColorPickerSection({
  nodeId,
  nodeData,
  selectedSubMenu,
  setSelectedSubMenu,
}: ColorPickerSectionProps) {
  const dispatch = useDispatch();

  // State for selected color, color picker visibility, and original color
  const [selectedColor, setSelectedColor] = useState<string>(nodeData.temp_bg_color);

  // Sets the selected color and hides the color picker. This is called when use user selects a new color
  function setColor(color: string) {
    // Set local color
    setSelectedColor(color);
    // Hide the color picker
    setSelectedSubMenu(null);
    // Dispatch the color change globally
    dispatch(
      updateNodeData({
        id: nodeId,
        newData: { bg_color: color, temp_bg_color: color },
      })
    );
  }

  // Previews a color without setting it
  function previewColor(color: string) {
    setSelectedColor(color);
    dispatch(updateNodeData({ id: nodeId, newData: { bg_color: color } }));
  }

  // Resets to the original color. This happens hovers and does NOT select a new color
  function resetOriginalColor() {
    // Set local color
    setSelectedColor(nodeData.temp_bg_color);
    // Dispatch the color change globally
    dispatch(updateNodeData({ id: nodeId, newData: { bg_color: nodeData.temp_bg_color } }));
  }

  // Toggle the visibility of the color picker
  function toggleColorPicker() {
    setSelectedSubMenu(
      selectedSubMenu === NodeBarMenuItem.BackgroundColor ? null : NodeBarMenuItem.BackgroundColor
    );
  }

  return (
    <div>
      <ToolbarButton onClick={toggleColorPicker} tooltip="Fill color">
        <ColoredCircle bg={selectedColor} />
        {/* Positioning the ColorPicker below the button */}
        {selectedSubMenu === NodeBarMenuItem.BackgroundColor && (
          <div className="absolute top-full mt-2 ">
            <ColorPicker
              onSelectColor={setColor}
              onPreviewColor={previewColor}
              resetOriginalColor={resetOriginalColor}
            />
          </div>
        )}
      </ToolbarButton>
    </div>
  );
}

interface ColorPickerProps {
  onSelectColor: (color: string) => void;
  onPreviewColor: (color: string) => void;
  resetOriginalColor: () => void;
}

// Component to pick a color from the available options
function ColorPicker({ onSelectColor, onPreviewColor, resetOriginalColor }: ColorPickerProps) {
  return (
    <div className="grid grid-cols-4 gap-3 bg-white rounded-lg shadow-xl w-44 p-2 py-4">
      {COLORS.map((colorStr, index) => (
        <div
          onMouseLeave={resetOriginalColor}
          onMouseEnter={() => onPreviewColor(colorStr)}
          onClick={() => onSelectColor(colorStr)}
          key={colorStr}
        >
          <ColoredCircle bg={colorStr} />
        </div>
      ))}
    </div>
  );
}

function ColoredCircle({ bg }: { bg: string }) {
  return (
    <div className="flex justify-center w-10">
      <div
        className={"w-7 h-7 rounded-full border border-gray-400 cursor-pointer "}
        style={{ backgroundColor: bg }}
      ></div>
    </div>
  );
}

interface RemoveEdgeButtonProps {
  nodeId: string;
}

export function RemoveNodeButton({ nodeId }: RemoveEdgeButtonProps) {
  const dispatch = useDispatch();
  function removeEdge() {
    // Remove the edge from the document
    dispatch(deleteNode({ sourceNodeId: nodeId }));
  }

  return (
    <div onClick={removeEdge}>
      <ToolbarButton tooltip="Delete item">
        <Trash2 className="w-10" strokeWidth={1.3} size={26} />
      </ToolbarButton>
    </div>
  );
}

export default BaseNodeToolbar;
