// src/App.tsx

import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./store";
import Dashboard from "./pages/Dashboard";
import LandingPage from "./components/LandingPage/LandingPage";
import SignIn from "./components/LandingPage/SignIn";
import DocumentsPage from "./components/Documents/DocumentsPage";
import ProtectedRoutes from "./components/shared/ProtectedRoutes";
import { CLIENT_ROUTE } from "./utils/constants";
import "./input.css";
import Timeline from "./components/LandingPage/Timeline";
import Resume from "./components/LandingPage/Resume";
import Sinkly from "./components/LandingPage/Sinkly";
import Contact from "./components/LandingPage/Contact";
import Relevate from "./components/LandingPage/Relevate";

function App() {
  return (
    // Enables Redux store
    <Provider store={store}>
      {/* Enables Redux Persist */}
      <PersistGate loading={null} persistor={persistor}>
        <Router>
          <Routes>
            {/* <Route element={<ProtectedRoutes />}>
              <Route path={CLIENT_ROUTE.DOCUMENT_HOME} element={<DocumentsPage />} />
            </Route> */}
            <Route path={CLIENT_ROUTE.DOCUMENT()} element={<Dashboard />} />
            <Route path={CLIENT_ROUTE.HOME} element={<LandingPage />} />
            <Route path={CLIENT_ROUTE.TIMELINE} element={<Timeline />} />
            <Route path={CLIENT_ROUTE.RESUME} element={<Resume />} />
            <Route path={CLIENT_ROUTE.BUILD} element={<Contact />} />
            <Route path={CLIENT_ROUTE.SINKLY} element={<Sinkly />} />
            <Route path={CLIENT_ROUTE.RELEVATE} element={<Relevate />} />

            {/* Catch-all route */}
            {/* <Route path="*" element={<Navigate to={CLIENT_ROUTE.HOME} />} /> */}
          </Routes>
        </Router>
      </PersistGate>
    </Provider>
  );
}

export default App;
