import { _saveDocumentAPI } from "src/api/service/documentService";
import { dispatchHasUnsavedChanges, dispatchReactFlowClicked } from "src/events/documentEvents";
import { DocumentContainer, DocumentPermissions } from "src/models/Document";
import { LOCAL_STORAGE } from "./constants";

const DELAY_SECONDS = 2500;

// Define arguments in global scope so each call to debounceSaveFunction will use the same arguments
let timer: NodeJS.Timeout | null = null;
let accumulatedChangesMap: { [id: string]: Partial<DocumentContainer> } = {};
let resolveMap: { [id: string]: (value: unknown) => void } = {};

/**
 * Creates a debounced function that delays invoking `saveDocumentAPI` until
 * after `delay` milliseconds have elapsed since the last time the debounced
 * function was invoked. The function will receive a `document` object containing
 * changes to be saved and an optional `delay`.
 *
 * Example usage:
 * const saveChanges = debounceSaveFunction();
 * saveChanges({ id: 'doc123', name: 'New Document Name' }, 4000);
 *
 * Example async usage:
 * const saveChanges = debounceSaveFunction();
 * saveChanges({ id: 'doc123', name: 'New Document Name' }, 4000)
 *   .then(response => {
 *     console.log('Document saved', response);
 *   })
 *   .catch(error => {
 *     console.error('Error saving document', error);
 *   });
 *
 * @param {number} [delay=4000] - The number of milliseconds to delay.
 * @returns {Function} - A new, debounced, function.
 */
function debounceSaveFunction() {
  return (document: Partial<DocumentContainer>, delay: number): Promise<unknown> => {
    // Dispatch an event to let application know that we have unsaved changes
    dispatchHasUnsavedChanges(window, true);

    return new Promise((resolve, reject) => {
      if (!document.id) {
        return reject(new Error("Document must have an id to be saved"));
      }

      // Clear existing timer
      if (timer) {
        clearTimeout(timer);
      }

      // Deep clone the document to avoid reference issues with the original document
      const clonedDocument = JSON.parse(JSON.stringify(document));

      // Accumulate changes per document ID
      accumulatedChangesMap[document.id] = {
        ...(accumulatedChangesMap[document.id] || {}),
        ...clonedDocument,
      };

      // Store the resolve function to call it later when the document is saved
      resolveMap[document.id] = resolve;

      // Set a new timer
      timer = setTimeout(async () => {
        for (const docId in accumulatedChangesMap) {
          try {
            console.log(
              "Saving document to API with debounce",
              docId,
              accumulatedChangesMap[docId]
            );
            const saveResult = await _saveDocumentAPI(accumulatedChangesMap[docId]);
            // Resolve the promise with the result of the save operation
            if (resolveMap[docId]) {
              resolveMap[docId](saveResult);
            }
          } catch (error) {
            // If there's an error, reject the promise
            reject(error);
          }
        }

        // Reset accumulated changes and resolve functions after saving
        accumulatedChangesMap = {};
        resolveMap = {};

        // Dispatch an event to let application know that we no longer have unsaved changes
        dispatchHasUnsavedChanges(window, false);
      }, delay);
    });
  };
}

// Create a debounced version of the save function
const debouncedSave = debounceSaveFunction();

/**
 * Saves the document with debounced calls to prevent frequent API requests.
 * It will return a promise that resolves when the document is eventually saved.
 *
 * Example usage:
 * saveDocToApiWithDebounce({ id: 'doc123', content: '...' })
 *   .then(response => {
 *     console.log('Document saved', response);
 *   })
 *   .catch(error => {
 *     console.error('Error saving document', error);
 *   });
 *
 * @param {Partial<DocumentContainer>} document - The partial document object to save.
 * @param {number} [delay=4000] - Optional delay before saving the document.
 * @returns {Promise<unknown>} - A promise that resolves with the save operation result.
 */
export function saveDocToApiWithDebounce(
  document: Partial<DocumentContainer>,
  delay = DELAY_SECONDS
): Promise<unknown> {
  console.log("Adding document change to save queue", document);
  if (!document.id) {
    return Promise.reject(new Error("Document must have an id to be saved"));
  }

  // Document MUST have an owner_id to be saved
  if (!document.owner_id) {
    throw new Error("Document must have an owner_id to be saved");
  }

  // Document MUST have a permissions object to be saved
  if (!document.permissions) {
    throw new Error("Document must have a permissions object to be saved");
  }

  // Check if the userID is signed in
  // 🐞 User still save document if they manually update their localStorage.
  const userId = localStorage.getItem(LOCAL_STORAGE.USER_ID);
  if (!userId) {
    console.log("User is not signed in");
    dispatchHasUnsavedChanges(window, true);

    return;
  }

  // If doc is not yours
  if (document.owner_id !== userId) {
    // If the user does not have permission to edit the document, do not save
    if (document.permissions === DocumentPermissions.VIEW) {
      console.log("User does not have access to edit");
      return;
    }
  }
  // Directly use the changes without filtering since the debounce function will handle accumulation
  return debouncedSave(document, delay);
}

// If we are within the delay period, do nothing. This means has been called before the delay period was over
let shouldWait = false;
let waitingArgs;

export function throttle(callback, delay = 5000) {
  // If we finish our time and we have waitingArgs (meaning a call was made after we started waiting),
  // call the function one last time

  const timeoutFunc = async () => {
    if (waitingArgs != null) {
      console.log("throttle EXECUTED");
      // Deep clone the waitingArgs to prevent using revoked Proxy objects
      const argsCopy = JSON.parse(JSON.stringify(waitingArgs));
      callback(...argsCopy);
      waitingArgs = null;
    } else {
      shouldWait = false;
    }
    setTimeout(timeoutFunc, delay);
  };

  return async (...args) => {
    // When we wait, save the last arguments passed in, so we can call the function one last time after the delay period
    // This ensures that we don't miss any updates

    // console.log("throttle CALLED", false);
    if (shouldWait) {
      // Save a deep copy of args to prevent issues with revoked Proxy objects
      waitingArgs = JSON.parse(JSON.stringify(args));
      return;
    }

    callback(...args);
    shouldWait = true;
    setTimeout(timeoutFunc, delay);
  };
}
