import {
  ArrowRight,
  Briefcase,
  CheckIcon,
  ChevronDown,
  FileText,
  Linkedin,
  LockKeyhole,
  Menu,
  MessagesSquare,
  Split,
} from "lucide-react";
import { useNavigate } from "react-router-dom";
import { APPLICATION_NAME, CLIENT_ROUTE } from "src/utils/constants";
import { useEffect, useRef, useState } from "react";
import { Color } from "src/models/BoxDiagram/Colors";
import ProjectsContextMenu from "./ProjectsContextMenu";
import useOutsideClick from "src/components/LandingPage/hooks/useOutsideClick";
import "./CareerCarousel.css"; // Ensure you have the CSS file imported
import { motion, AnimatePresence } from "framer-motion";
import { useScreenSize } from "../BlockDiagram/Hooks/useIsMobile";

export const darrels_image = require("../../assets/images/darrel_home.jpeg");
export const sinkly_lg = require("../../assets/images/sinkly_lg.png");
export const relevate_lg = require("../../assets/images/relevate_lg.png");
export const lyft_lg = require("../../assets/images/lyft_lg.png");

export const lyft_logo = require("../../assets/images/lyft_logo.png");
export const relevate_logo = require("../../assets/images/relevate_logo.png");
export const sinkly_logo = require("../../assets/images/sinkly_logo_2.png");
const CASE_STUDY_CARD = [
  {
    title: "Sinkly",
    description:
      "Founder of a design tool for hardware engineers. Worked with a startup in Silicon Valley to develop the concept for their team.",
    highlights: [
      "Product featured in HackerPulse",
      // "Ran UX research",
      "Designed & engineered by me",
    ],
    action: CLIENT_ROUTE.SINKLY,
    image: sinkly_lg,
    logo: sinkly_logo,
    locked: false,
    bgColors: [
      Color.Blue.css.twoHundred,
      Color.Blue.css.twoHundred,
      Color.Blue.css.threeHundred,
      Color.Blue.css.fourHundred,
      Color.Blue.css.threeHundred,
    ],
    // bgColors: [Color.Blue.css.threeHundred, Color.Blue.css.twoHundred, Color.Blue.css.oneHundred, Color.Blue.css.threeHundred],
  },
  {
    title: "Relevate",
    description:
      "Founder of an internal marketplace for luxury NYC apartments. I designed and engineers the full iOS app and backend. Launched in the App Store.",
    highlights: ["+100 weekly active users", "2 luxury NYC apartments", "Launched in App Store"],
    action: "Read case study",
    image: relevate_lg,
    logo: relevate_logo,
    locked: true,
    bgColors: [
      Color.Slate.css.twoHundred,
      Color.Slate.css.twoHundred,
      Color.Slate.css.threeHundred,
      Color.Slate.css.fourHundred,
      Color.Slate.css.threeHundred,
    ],
  },
  {
    title: "Lyft",
    description:
      "Full time Software Engineer at Lyft. I built entire incentive products and payments systems that impact millions of drivers nationwide.",
    highlights: [
      "+150 media reports on my work",
      "+$30M paid by my products",
      "+100M drivers use my products daily",
    ],
    action: "Read case study",
    image: lyft_lg,
    logo: lyft_logo,
    locked: true,
    bgColors: [
      Color.Purple.css.twoHundred,
      Color.Purple.css.twoHundred,
      Color.Purple.css.threeHundred,
      Color.Purple.css.fourHundred,
      Color.Purple.css.threeHundred,
    ],
  },
];

export function useScrollToTop() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
}

type CareerItem = {
  id: number;
  text: string;
};

function LandingPage() {
  // useScrollToTop();

  return (
    <div className="flex flex-col w-full min-h-screen ">
      <Toolbar />
      <HeroSection />
      <CaseStudyContainer />
      <Footer home />
    </div>
  );
}

// Toolbar with logo, login, and register buttons
export function Toolbar() {
  const navigate = useNavigate();
  const [showContextMenu, setShowContextMenu] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const projectsBtnRef = useRef(null);
  const { lgOrLarger } = useScreenSize();

  useOutsideClick(projectsBtnRef, () => {
    setShowContextMenu(false);
    setIsOpen(false);
  });
  function handleNavHome() {
    navigate(CLIENT_ROUTE.HOME);
    setIsOpen(false);
  }
  // Function to handle navigation on button click
  function navToContact() {
    navigate(CLIENT_ROUTE.BUILD);
    setIsOpen(false);
  }
  function navToTimeline() {
    navigate(CLIENT_ROUTE.TIMELINE);
    setIsOpen(false);
  }

  function navToResume() {
    navigate(CLIENT_ROUTE.RESUME);
    setIsOpen(false);
  }

  function navToLinkedIn() {
    window.open("https://www.linkedin.com/in/darrelmuonekwu/", "_blank");
    setIsOpen(false);
  }

  function showProjectsContextMenu() {
    if (showContextMenu) {
      setShowContextMenu(false);
    } else {
      setShowContextMenu(!showContextMenu);
    }
  }

  function closeProjectsContextMenu() {
    if (showContextMenu) {
      setShowContextMenu(false);
      setIsOpen(false);
    }
  }

  function isSelected(path: string) {
    const selectedPath = window.location.pathname;
    if (selectedPath !== path && path !== "projects") {
      return false;
    }

    if (path === CLIENT_ROUTE.HOME) {
      return true;
    } else if (path === CLIENT_ROUTE.TIMELINE) {
      return true;
    } else if (path === CLIENT_ROUTE.RESUME) {
      return true;
    } else if (path === CLIENT_ROUTE.BUILD) {
      return true;
    } else if ([CLIENT_ROUTE.SINKLY].includes(selectedPath)) {
      return true;
    }
    return false;
  }

  const selectedStyle = "border border-neutral-800 bg-neutral-300";
  const unselectedStyle = "border border-transparent ";

  return (
    <div
      id="toolbar"
      ref={projectsBtnRef}
      onClick={closeProjectsContextMenu}
      className="relative flex items-center justify-between w-full h-24 px-4 border-b border-neutral-700"
    >
      <button
        onClick={handleNavHome}
        className="hover:rotate-3 transition-transform duration-100 ease-in-out relative flex w-40 lg:w-fit items-start justify-start text-start text-neutral-700 text-2xl md:text-3xl font-mono font-extrabold"
      >
        {APPLICATION_NAME}
      </button>

      <div className="md:hidden">
        <button onClick={() => setIsOpen(!isOpen)} className="p-4 rounded-2xl bg-neutral-200 ">
          <div className="p-2">{isOpen ? <ChevronDown size={24} /> : <Menu size={24} />}</div>
        </button>
      </div>

      {/* <div className="flex items-center rounded-md"> */}
      <div
        className={`flex flex-col md:flex-row md:items-center ${
          !lgOrLarger && "absolute"
        } top-full md:top-auto left-0 w-full md:w-auto bg-neutral-100 md:bg-transparent transition-all duration-100 ease-in-out ${
          isOpen
            ? "absolute space-y-3 max-h-screen p-4 border-x border-b border-t border-neutral-700 rounded-b-2xl "
            : "max-h-0 overflow-hidden"
        } md:max-h-full z-50`}
      >
        <button
          onClick={showProjectsContextMenu}
          className={`flex items-center justify-center rounded-3xl px-2 md:px-3 py-2 space-x-2 ${
            isSelected("projects") ? selectedStyle : unselectedStyle
          }`}
        >
          <span>Projects</span> <ChevronDown size={22} />
        </button>
        <ProjectsContextMenu visible={showContextMenu} onClose={closeProjectsContextMenu} />
        <button
          onClick={handleNavHome}
          className={`rounded-3xl px-2 md:px-3 py-2 space-x-2 ${
            isSelected(CLIENT_ROUTE.HOME) ? selectedStyle : unselectedStyle
          }`}
          hidden={!lgOrLarger}
        >
          Home
        </button>
        <button
          onClick={navToTimeline}
          className={`rounded-3xl px-2 md:px-3 py-2 space-x-2 ${
            isSelected(CLIENT_ROUTE.TIMELINE) ? selectedStyle : unselectedStyle
          }`}
        >
          Timeline
        </button>
        <button
          onClick={navToResume}
          className={`rounded-3xl px-2 md:px-3 py-2 space-x-2 ${
            isSelected(CLIENT_ROUTE.RESUME) ? selectedStyle : unselectedStyle
          }`}
        >
          Resume
        </button>
        <button
          onClick={navToLinkedIn}
          className="border border-transparent rounded-3xl px-2 md:px-3 py-2 space-x-2"
        >
          Linkedin
        </button>
        <button
          onClick={navToContact}
          className="px-4 py-2  space-x-2 rounded-full bg-gradient-to-br from-neutral-600 to-neutral-700 hover:from-neutral-600 hover:to-neutral-800 text-neutral-100"
        >
          Build
        </button>
      </div>
    </div>
  );
}

function HeroSection() {
  return (
    <div className="flex items-start lg:justify-center w-full max-w-screen-2xl mt-12 md:mt-16  md:px-6 pb-10">
      <Hero />
      <HeroImage />
    </div>
  );
}

function HeroImage() {
  const { xlOrLarger } = useScreenSize();

  if (!xlOrLarger) {
    return null;
  }
  return (
    <img
      src={darrels_image}
      className="flex flex-col items-center justify-center w-2/5 max-w-xl h-full rounded-3xl bg-neutral-700 text-neutral-100"
    />
  );
}

function Hero() {
  return (
    <div className="w-full lg:w-3/5 px-4 md:px-0  text-neutral-700">
      <Header />
      <CareerCarousel />
      <CheckoutCareerBtn />
    </div>
  );
}

function Header() {
  return (
    <div className="text-7xl max-w-7xl lg:text-9xl font-extrabold">Hello, nice to meet you.</div>
  );
}

function CareerCarousel() {
  // State to trigger the animation
  const [index, setIndex] = useState(0);

  const items = [
    { id: 1, content: "web development" },
    { id: 2, content: "product design" },
    { id: 3, content: "iOS development" },
    { id: 4, content: "UX research" },
    { id: 5, content: "android development" },
    { id: 6, content: "QA testing" },
    { id: 10, content: "database design" },
    { id: 8, content: "mentoring" },
  ];

  useEffect(() => {
    const id = setInterval(() => {
      setIndex((state) => {
        if (state >= items.length - 1) return 0;
        return state + 1;
      });
    }, 1800);
    return () => clearInterval(id);
  }, []);

  return (
    <div className="flex items-center justify-start mt-4 w-min sm:text-2xl font-bold md:h-auto">
      <span className="w-36 sm:w-48">Career ranging in</span>
      {/* Apply the animation classes conditionally */}
      <div
        className="relative bottom-4 -ml-1 sm:ml-2 mt-2 sm:mt-0 w-44 sm:w-72"
        style={{ position: "relative" }}
      >
        <AnimatePresence>
          <motion.div
            key={items[index].id}
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: -20, opacity: 0 }}
            // Bounce effect
            transition={{ type: "spring", stiffness: 550, damping: 21 }}
            style={{ position: "absolute" }}
          >
            {items[index].content}
          </motion.div>
        </AnimatePresence>
      </div>
    </div>
  );
}

function CheckoutCareerBtn() {
  const navigate = useNavigate();

  // Function to handle navigation on button click
  function handleGetStartedClick() {
    console.log("Get started clicked");
    navigate(CLIENT_ROUTE.TIMELINE);
  }
  return (
    <button
      onClick={handleGetStartedClick}
      className="px-8 py-4 mt-6 rounded-full text-xl text-white bg-gradient-to-br from-neutral-600 to-neutral-700 hover:from-neutral-600 hover:to-neutral-800"
    >
      Checkout my career timeline
    </button>
  );
}

function CaseStudyContainer() {
  const { xlOrLarger } = useScreenSize();
  const padding = xlOrLarger ? "80px" : "40px";
  return (
    <div
      style={{ borderTopLeftRadius: padding, borderTopRightRadius: padding }}
      className="flex flex-col items-center justify-center mt-4 text-neutral-100 bg-neutral-700 rounded-t-3xl"
    >
      <CaseStudyTitle />
      {CASE_STUDY_CARD.map((caseStudy, index) => (
        <CaseStudyCard card={caseStudy} key={index} />
      ))}
      <div className="py-10"></div>
    </div>
  );
}

function CaseStudyTitle() {
  return (
    <div className="flex flex-col items-center justify-center w-4/5 xl:w-3/5 my-14">
      <div className="flex items-center  w-full">
        <HorizontalDivider />
        <div className="flex items-center justify-center space-x-4  mx-8 lg:mx-20">
          <span className="text-center text-xl font-bold w-28">Highlights</span>
        </div>
        <HorizontalDivider />
      </div>
    </div>
  );
}

function CaseStudyCard({ card }: any) {
  const gradientStyle = {
    background: `linear-gradient(to bottom right, ${card.bgColors[0]}, ${card.bgColors[1]}, ${card.bgColors[2]}, ${card.bgColors[3]}, ${card.bgColors[4]})`,
  };

  return (
    <div
      className="flex items-start justify-center w-10/12 xl:w-3/5 my-4 py-8 rounded-3xl overflow-hidden text-neutral-800"
      style={gradientStyle} // Add the gradient style here
    >
      <CaseStudyCardInfo card={card} />
      <CaseStudyCardImage card={card} />
    </div>
  );
}

function CaseStudyCardInfo({ card }: any) {
  return (
    <div className="flex flex-col items-center justify-center space-y-6 h-full w-full xl:w-2/5 my-10 px-4 xl:px-8 rounded-3xl   ">
      <CaseStudyCardInfoHeader card={card} />
      <CaseStudyCardInfoDescription card={card} />
      <CaseStudyCardInfoHighlights card={card} />
      <CaseStudyCardInfoActionBtn card={card} />
    </div>
  );
}

function CaseStudyCardInfoHeader({ card }: any) {
  return (
    <div className="flex flex-col items-start justify-center space-y-2 w-full rounded-3xl">
      <div className="flex items-center justify-center w-16 h-16 bg-neutral-800 rounded-full">
        <img src={card.logo} alt="" className="p-2" />
      </div>
    </div>
  );
}
function CaseStudyCardInfoDescription({ card }: any) {
  return (
    <div className="flex flex-col items-start justify-center space-y-4 w-full rounded-3xl">
      <div className="text-4xl font-semibold ">{card.title}</div>
      <div className="text-lg">{card.description}</div>
    </div>
  );
}

function CaseStudyCardInfoHighlights({ card }: any) {
  return (
    <div className="flex flex-col items-start justify-center space-y-2 w-full rounded-3xl">
      {card.highlights.map((highlight: string, index: number) => (
        <div
          key={index}
          className="flex items-center w-auto  space-x-2 text-sm p-1 px-2 bg-white shadow-sm rounded-full"
        >
          <div>
            <CheckIcon size={18} />
          </div>
          <span>{highlight}</span>
        </div>
      ))}
    </div>
  );
}
function CaseStudyCardInfoActionBtn({ card }: any) {
  const navigate = useNavigate();
  function handleNavToCaseStudy() {
    if (card.locked) {
      return;
    }
    navigate(card.action);
  }
  return (
    <div className="flex flex-col items-start justify-center space-y-2 w-full rounded-3xl">
      <button
        onClick={handleNavToCaseStudy}
        className="flex space-x-2 text-sm p-3 px-4 bg-neutral-800 text-neutral-100 rounded-full bg-gradient-to-br from-neutral-600 to-neutral-700 hover:from-neutral-600 hover:to-neutral-800"
      >
        {card.locked ? <LockKeyhole size={20} /> : <FileText size={20} />}
        {card.locked ? <span>Project locked</span> : <span>View details</span>}
      </button>
    </div>
  );
}

function CaseStudyCardImage({ card }: any) {
  const { xlOrLarger } = useScreenSize();

  if (!xlOrLarger) {
    return null;
  }
  return <img className="w-3/5" src={card.image} alt="" />;
}

export function Footer({
  home = false,
  timeline = false,
  resume = false,
  contact = false,
  noDivider = false,
}: {
  home?: boolean;
  timeline?: boolean;
  resume?: boolean;
  contact?: boolean;
  noDivider?: boolean;
}) {
  const navigate = useNavigate();

  function getButtonText() {
    if (home) {
      return "Home";
    }
    if (timeline) {
      return "Timeline";
    }
    if (resume) {
      return "Resume";
    }
    if (contact) {
      return "Contact";
    }
  }

  function getTitle() {
    if (home) {
      // To timeline
      return "Learn more about my career path!";
    }
    if (timeline) {
      // To home
      return "Check out my resume!";
    }
    if (resume) {
      return "Let's get in touch!";
    }
    if (contact) {
      return "Connect with me on Linkedin!";
    }
  }

  function getSubtitle() {
    if (home) {
      return "Want to know more about how my career path flows? Great!";
    }
    if (timeline) {
      return "Want to know more about my skills and accomplishments? Great!";
    }
    if (resume) {
      return "Want to know how to get in touch with me? Great!";
    }
    if (contact) {
      return "Let’s connect. I would love to hear what you do and how I can help.";
    }
  }

  function getIcon() {
    if (home) {
      return <Split size={60} color={Color.Neutral.css.oneHundred} />;
    }
    if (timeline) {
      return <Briefcase size={60} color={Color.Neutral.css.oneHundred} />;
    }
    if (resume) {
      return <MessagesSquare size={60} color={Color.Neutral.css.oneHundred} />;
    }
    if (contact) {
      return <Linkedin size={60} color={Color.Neutral.css.oneHundred} />;
    }
  }

  function navToNextPage() {
    if (home) {
      navigate(CLIENT_ROUTE.TIMELINE);
    } else if (timeline) {
      navigate(CLIENT_ROUTE.RESUME);
    } else if (resume) {
      navigate(CLIENT_ROUTE.BUILD);
    } else if (contact) {
      window.open("https://www.linkedin.com/in/darrelmuonekwu/", "_blank");
    }
  }
  return (
    <div className="flex flex-col items-center justify-center w-full space-y-4 pb-20 bg-neutral-700 text-neutral-100 ">
      {!noDivider ? <HorizontalDivider /> : <div className="h-px my-1 w-full bg-transparent"></div>}
      {getIcon()}
      <div className="flex flex-col items-center justify-center space-y-6 px-3">
        <div className="text-center text-4xl font-bold">{getTitle()}</div>
        <div className="text-center font-semibold">{getSubtitle()}</div>
      </div>
      <button
        onClick={navToNextPage}
        className="flex items-center px-8 py-4 text-xl space-x-2 border-2 text-white rounded-full bg-gradient-to-br from-neutral-600 to-neutral-700 hover:from-neutral-600 hover:to-neutral-800"
      >
        <span>Learn more</span>
        <ArrowRight size={24} />
      </button>
    </div>
  );
}

export function HorizontalDivider({ dark }: { dark?: boolean }) {
  const color = dark ? "bg-neutral-800" : "bg-neutral-300";

  return <div className={`h-px my-1 w-full ${color}`}></div>;
}

export default LandingPage;
