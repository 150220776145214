interface RotationHandleProps {
  isSelected: boolean;
  rotateControlRef: React.RefObject<HTMLDivElement>;
}

/**
 * Renders the handles that is used to rotate a node.
 */
function RotationHandle({ isSelected, rotateControlRef }: RotationHandleProps) {
  return (
    <div
      ref={rotateControlRef}
      className="absolute block rotateHandle"
      style={{ display: isSelected ? "block" : "none" }}
    />
  );
}

export default RotationHandle;
