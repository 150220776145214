import {
  ArrowRight,
  Briefcase,
  Calendar,
  Code2,
  FileText,
  Linkedin,
  Telescope,
  Split,
  UserRound,
  UsersRound,
  Trophy,
  FlaskConical,
  FileBarChart2,
  BookCheck,
  PencilLine,
  Lightbulb,
  Brain,
  Key,
} from "lucide-react";
import { Color } from "src/models/BoxDiagram/Colors";
import { Footer, Toolbar, useScrollToTop } from "./LandingPage";
import { useScreenSize } from "../BlockDiagram/Hooks/useIsMobile";

const sinkly_lg_2 = require("../../assets/images/sinkly_lg_2.png");

export const fire_gif = require("../../assets/videos/fire_gif.gif");
export const dsm_gif = require("../../assets/videos/dsm_gif.mp4");

export const sinkly_hurdles_1 = require("../../assets/images/sinkly_hurdles/sinkly_hurdles_1.png");
export const sinkly_hurdles_2 = require("../../assets/images/sinkly_hurdles/sinkly_hurdles_2.png");

export const sinkly_research_1 = require("../../assets/images/sinkly_research/sinkly_research_1.png");
export const sinkly_research_2 = require("../../assets/images/sinkly_research/sinkly_research_2.png");
export const sinkly_research_3 = require("../../assets/images/sinkly_research/sinkly_research_3.png");

export const aws_logo = require("../../assets/images/logos/aws_logo.png");
export const firebase_logo = require("../../assets/images/logos/firebase_logo.png");
export const full_story_logo = require("../../assets/images/logos/full_story_logo.png");
export const python_logo = require("../../assets/images/logos/python_logo.png");
export const react_logo = require("../../assets/images/logos/react_logo.png");
export const send_grid_logo = require("../../assets/images/logos/send_grid_logo.png");
export const stripe_logo = require("../../assets/images/logos/stripe_logo.png");
export const vercel_logo = require("../../assets/images/logos/vercel_logo.png");
export const axiom_logo = require("../../assets/images/logos/axiom_logo.png");

export const lucidchart_logo = require("../../assets/images/sinkly_analysis/lucidchart_logo.png");
export const lucidspark_logo = require("../../assets/images/sinkly_analysis/lucidspark_logo.png");
export const drawio_logo = require("../../assets/images/sinkly_analysis/drawio_logo.png");
export const google_sheets_logo = require("../../assets/images/sinkly_analysis/google_sheets_logo.png");
export const microsoft_exel_logo = require("../../assets/images/sinkly_analysis/microsoft_exel_logo.png");
export const microsoft_visio_logo = require("../../assets/images/sinkly_analysis/microsoft_visio_logo.png");
export const aspen_plus_logo = require("../../assets/images/sinkly_analysis/aspen_plus_logo.png");
export const aspen_hysys_logo = require("../../assets/images/sinkly_analysis/aspen_hysys_logo.png");
export const jama_logo = require("../../assets/images/sinkly_analysis/jama_logo.png");
export const polarion_logo = require("../../assets/images/sinkly_analysis/polarion_logo.png");

const tech_stack = [
  { src: aws_logo, alt: "AWS" },
  { src: firebase_logo, alt: "Firebase" },
  { src: python_logo, alt: "Python" },
  { src: react_logo, alt: "React" },
  { src: stripe_logo, alt: "Stripe" },
  { src: vercel_logo, alt: "Vercel" },
  { src: send_grid_logo, alt: "Send Grid" },
  { src: full_story_logo, alt: "Full Story" },
  { src: axiom_logo, alt: "Axiom" },
];

export interface HeroData {
  title: string;
  description: string;
  timeline: string;
  role: string;
  team: string;
  tools: string;
}
export interface CompetitiveAnalysis {
  description: string;
  goals: string[];
  results: string;
  images: { src: string; alt: string }[];
}
export interface UserNeeds {
  challenge: string;
  quotes: string[];
  goals: string[];
  success_metrics: string;
}

export interface ResearchData {
  title: string;
  description: string;
  goals: string[];
  results: string;
  image: { src: string; alt: string };
}

export interface HurdleData {
  title: string;
  subTitle: string;
  problemTitle: string;
  problemDescriptions: string[];
  solutionTitle: string;
  solutionDescriptions: string[];
  userHighlight?: string;
  image: string;
}

export interface TechStack {
  title: string;
  descriptions: string[];
  tools: { src: string; alt: string }[];
}

const user_needs_data: UserNeeds = {
  challenge: `Introduce a new product to an industry filled with old legacy options.`,
  quotes: [
    `"I want to automatically translate my design into these other docs."`,
    `"It would save me so much work going through each square and copying it over."`,
    `"To add a new line, I'll need to change over 100 IDs to match. So, I just don't update it."`,
    // `"Right now my flow charts are independent of my ICD because it's such a pain to edit each one, so I don't."`,
    `"It takes me 2-3 days to copy everything over. It's such a pain."`,
  ],
  goals: [
    `Confirm the problem exists and needs solving.`,
    `Narrow down the problem to a workable MVP.`,
    `Understand my users and what they need.`,
  ],
  success_metrics: `Number of users, time on site, and number of documents created.`,
};

const competitive_analysis: CompetitiveAnalysis = {
  description: `Researched competing software focused on system design, product management, and requirements management.`,
  goals: [
    `Make sure no current software solves this problem.`,
    `Understand why currently solutions fall short.`,
    `Identify must-have features for the MVP.`,
  ],
  results: `Available software charge $10,000 to $30,000 per user, and are complicated. Small and medium sized companies are priced out.`,
  images: [
    { src: lucidchart_logo, alt: "Lucidchart" },
    { src: lucidspark_logo, alt: "Lucidspark" },
    { src: aspen_plus_logo, alt: "Aspen Plus" },
    { src: microsoft_visio_logo, alt: "Microsoft Visio" },
    { src: polarion_logo, alt: "Polarion" },
    { src: jama_logo, alt: "Jama" },
    { src: aspen_hysys_logo, alt: "Aspen Hysys" },
    { src: google_sheets_logo, alt: "Google Sheets" },
    { src: drawio_logo, alt: "Draw.io" },
    { src: microsoft_exel_logo, alt: "Microsoft Excel" },
  ],
};

const research_data: ResearchData[] = [
  {
    title: "Research & learning",
    description: `I met with a hardware engineer to understand his workflow. The hardest part was learning the jargon of a new industry.`,
    goals: [
      "Learn the end-to-end design process.",
      "Learn how teams and customers collaborate.",
      "Learn the approval process for product designs.",
    ],
    results: `They use 5-10 documents to communicate design changes by email or in person. This was a major pain point.`,
    image: {
      src: sinkly_research_1,
      alt: "Research",
    },
  },
  {
    title: "Deep dive into system design",
    description: "I studied lectures and courses on system design and hardware engineering.",
    goals: [
      "Learn the industry's jargon.",
      "Translate my learnings into a digital product.",
      "Learn industry standards for designing systems.",
    ],
    results: "I understood each step in the design process and how each document is used.",
    image: {
      src: sinkly_research_2,
      alt: "Research",
    },
  },
  {
    title: "Idea validation",
    description: "I followed up to validate my ideas and get feedback!",
    goals: [
      "Align on expectations for the MVP.",
      "Ensure my ideas would solve their problems.",
      "Ensure the information from my research was accurate.",
    ],
    results:
      "The engineer was excited and gave me a few more to consider. I felt confident in my understanding of the problem.",
    image: {
      src: sinkly_research_3,
      alt: "Research",
    },
  },
];

const hurdle_data: HurdleData[] = [
  {
    title: "What does the MVP look like?",
    subTitle: "Hurdle #1: Design",
    problemTitle: "Problem: Complex design process",
    problemDescriptions: [
      `I had to simplify processes that used 5-10 documents and make this solution accessible to testers.`, // Corrected
    ],
    solutionTitle: "Solution: Limit documents",
    solutionDescriptions: [
      `1. Only include the 3 most important and time consuming documents.`,
      `2. Build solution into browser app for maximum accessibility.`,
    ],
    userHighlight: `"It would be great to have this in the browser for easier access."`,
    image: sinkly_hurdles_1,
  },
  {
    title: "Syncing data across documents",
    subTitle: "Hurdle #3: Technical",
    problemTitle: "Problem: Out of sync documents",
    problemDescriptions: [
      `Errors constantly led to out of sync documents.`,
      `As the application scaled, this would be impossible to manage.`,
      `I needed a system with little to no chance of error syncing. This was a major technical challenge.`,
    ],
    solutionTitle: "Solution: One source of truth",
    solutionDescriptions: [
      `I created one data model as the source of truth for all documents.`,
      `This required a complete code overhaul, but it was worth it.`,
      `I had no more syncing issues and the app was much easier to maintain.`,
    ],
    // userHighlight: `"It would be great to have this in the browser to make it easier to access."`,
    image: sinkly_hurdles_2,
  },
  {
    title: "Syncing data across documents",
    subTitle: "Hurdle #3: Technical",
    problemTitle: "Problem: Low memory browsers",
    problemDescriptions: [
      `Editing large documents crashed the browser😬.`,
      `I needed a massive improvement in performance.`,
    ],
    solutionTitle: "Solution: Virtualization",
    solutionDescriptions: [
      `After excessive research, I virtualized the document.`,
      `This only rendered visible elements, not the entire document.`,
      `Now documents with 1000s of elements ran smoothly!`,
    ],
    userHighlight: `"It would be great to have this in the browser to make it easier to access."`,

    image: sinkly_hurdles_2,
  },
];

const hero_data: HeroData = {
  title: "Sinkly",
  description: `Launched a design tool for hardware engineers. Sinkly automates the process of creating, managing, and synchronizing complex design documents. Designed and engineered by me.`,
  timeline: "3 months",
  role: "Engineer, designer, ideation, & marketing",
  team: "Just me",
  tools: "React, Python, AWS, Firebase, Stripe, Vercel, Send Grid, Full Story, & Axiom. (phew)",
};

const tech_stack_data: TechStack = {
  title: "My tech stack",
  descriptions: [
    `I kept the tech stack simple and lightweight to move quickly.`,
    `I used React for the frontend and Python for the backend.`,
    `I used AWS for hosting and Firebase for authentication.`,
    `I used Stripe for payments and Send Grid for emails.`,
    `I used Vercel for continuous deployment and Full Story for user analytics.`,
    `I used Axiom for monitoring.`,
  ],
  tools: [
    { src: aws_logo, alt: "AWS" },
    { src: firebase_logo, alt: "Firebase" },
    { src: python_logo, alt: "Python" },
    { src: react_logo, alt: "React" },
    { src: stripe_logo, alt: "Stripe" },
    { src: vercel_logo, alt: "Vercel" },
    { src: send_grid_logo, alt: "Send Grid" },
    { src: full_story_logo, alt: "Full Story" },
    { src: axiom_logo, alt: "Axiom" },
  ],
};

export function TravelToLeft() {
  const { mdOrLarger } = useScreenSize();
  const height = mdOrLarger ? "118" : "80";
  return (
    <div>
      <svg
        width="auto"
        height={height}
        viewBox="0 0 592 118"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.0394 112.998L10.0522 110.998C10.1968 88.371 10.2691 77.0576 17.3433 70.0732C24.4175 63.0888 35.731 63.1611 58.3579 63.3057L534.348 66.3469C556.975 66.4914 568.289 66.5637 575.363 59.5793C582.437 52.5949 582.509 41.2815 582.654 18.6545L582.667 16.6545"
          stroke="white"
          stroke-width="4"
          stroke-linecap="square"
          stroke-linejoin="round"
          stroke-dasharray="2 10"
        />
        <circle
          cx="582.712"
          cy="9.65473"
          r="7"
          transform="rotate(-89.6339 582.712 9.65473)"
          fill="#26384B"
          stroke="white"
          stroke-width="4"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M18.0904 106.049L10.0394 113.998L2.0907 105.947"
          stroke="white"
          stroke-width="4"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
  );
}

export function TravelToRight() {
  const { mdOrLarger } = useScreenSize();
  const height = mdOrLarger ? "118" : "80";
  return (
    <div>
      <svg
        width="auto"
        height={height}
        viewBox="0 0 593 117"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M582.197 112.755L582.184 110.755C582.04 88.1279 581.968 76.8144 574.893 69.83C567.819 62.8456 556.506 62.9179 533.879 63.0625L57.8885 66.1037C35.2615 66.2483 23.948 66.3205 16.8738 59.3362C9.79961 52.3518 9.72733 41.0383 9.58276 18.4113L9.56998 16.4114"
          stroke="white"
          stroke-width="4"
          stroke-linecap="square"
          stroke-linejoin="round"
          stroke-dasharray="2 10"
        />
        <circle
          r="7"
          transform="matrix(-0.00638907 -0.99998 -0.99998 0.00638907 9.52537 9.41157)"
          fill="#26384B"
          stroke="white"
          stroke-width="4"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M574.147 105.806L582.198 113.755L590.146 105.704"
          stroke="white"
          stroke-width="4"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
  );
}

function Sinkly() {
  useScrollToTop();
  return (
    <div className="flex flex-col items-center w-full min-h-screen ">
      <Toolbar />
      <HeroSection hero_data={hero_data} image={sinkly_lg_2} />
      <HorizontalDivider />
      <UserNeedsSection title="Discovering user needs" user_needs_data={user_needs_data} />
      <HorizontalDivider />
      <CompetitiveAnalysisSection competitive_analysis={competitive_analysis}/>
      <ResearchSection />
      {/* <UserNeedsSection title="What does an MVP look like?" /> */}
      <HurdleSectionMVP dark feedback data={hurdle_data[0]} />

      <HurdleSection2 />

      <HurdleSectionMVP dark data={hurdle_data[1]} />

      <TechStackSection />
      <FinalThoughtsSection />

      <Footer home noDivider />
    </div>
  );
}

export function HeroSection({ hero_data, image }: { hero_data: HeroData; image: any }) {
  return (
    <div className="flex justify-center w-full mt-12 mx-4 xl:mx-12 pb-10 max-w-screen-xl">
      <Hero data={hero_data} />
      <HeroImage image={image} />
    </div>
  );
}

export function HeroImage({ image }: { image: any }) {
  const { xlOrLarger } = useScreenSize();

  if (!xlOrLarger) {
    return null;
  }

  return (
    <div className="flex justify-center items-center w-3/5">
      <img
        src={image}
        alt="Descriptive text"
        className="max-w-full h-auto rounded-3xl bg-transparent"
      />
    </div>
  );
}

export function Hero({ data }: { data: any }) {
  const { xlOrLarger } = useScreenSize();
  const largeStyles = xlOrLarger ? "w-2/5" : "w-full";
  return (
    <div
      className={`flex flex-col items-start justify-start space-y-6 h-full px-4 text-lg text-neutral-700 
    ${largeStyles}
    `}
    >
      <ProjectTitle title={data.title} />
      <Background description={data.description} />
      <TimelineRole timeline={data.timeline} role={data.role} />
      <Collaborators team={data.team} />
      <Tools tools={data.tools} />
      <CheckoutProject />
    </div>
  );
}

export function ProjectTitle({ title }: { title?: string }) {
  return <div className="text-5xl font-medium text-neutral-800">{title}</div>;
}

export function Background({ description }: { description: string }) {
  return (
    <div className="flex flex-col items-start justify-start space-y-4">
      <div className="flex items-center justify-start space-x-2">
        <FileText size={20} color={Color.Neutral.css.eightHundred} />
        <div className="font-medium text-neutral-800">Background</div>
      </div>
      <div className="text-sm text-neutral-500">{description}</div>
    </div>
  );
}

export function TimelineRole({ timeline, role }: { timeline?: string; role?: string }) {
  return (
    <div className="flex flex-col ">
      <div className="flex items-center justify-start space-x-16">
        <div className="flex flex-col space-y-4">
          <div className="flex items-center justify-start space-x-2">
            <Calendar size={20} color={Color.Neutral.css.eightHundred} />
            <div className=" font-medium text-neutral-800">Timeline</div>
          </div>
          <div className="text-sm text-neutral-500">{timeline}</div>{" "}
        </div>

        <div className="flex flex-col space-y-4">
          <div className="flex items-center justify-start space-x-2">
            <UserRound size={20} color={Color.Neutral.css.eightHundred} />
            <div className=" font-medium text-neutral-800">Role</div>
          </div>
          <div className="text-sm text-neutral-500">{role}</div>{" "}
        </div>
      </div>
    </div>
  );
}

export function Collaborators({ team }: { team?: string }) {
  return (
    <div className="flex flex-col space-y-4">
      <div className="flex items-center justify-start space-x-2">
        <UsersRound size={20} color={Color.Neutral.css.eightHundred} />
        <div className=" font-medium text-neutral-800">Team</div>
      </div>
      <div className="text-sm text-neutral-500">{team}</div>{" "}
    </div>
  );
}

export function Tools({ tools }: { tools?: string }) {
  return (
    <div className="flex flex-col space-y-4">
      <div className="flex items-center justify-start space-x-2">
        <Code2 size={20} color={Color.Neutral.css.eightHundred} />
        <div className=" font-medium text-neutral-800">Tools</div>
      </div>
      <div className="text-sm text-neutral-500">{tools}</div>
    </div>
  );
}

export function CheckoutProject() {
  const { xxlOrLarger } = useScreenSize();

  // Function to handle navigation on button click
  function navToSinkly() {
    console.log("Get started clicked");

    window.open("https://www.sinkly.co", "_blank");
  }
  function navToHackerPulse() {
    console.log("Get started clicked");

    window.open(
      "https://hackerpulse.substack.com/p/sinkly-keeping-your-system-engineering",
      "_blank"
    );
  }
  const divStyles = xxlOrLarger ? "flex space-x-4" : "flex flex-col space-y-4";
  return (
    <div className={divStyles}>
      <button
        onClick={navToSinkly}
        className="flex items-center justify-center space-x-2 px-8 py-4 mt-6 rounded-full text-xl text-white bg-gradient-to-br from-neutral-600 to-neutral-700 hover:from-neutral-600 hover:to-neutral-800 border-2 border-neutral-700"
      >
        <span>View app</span>
        <ArrowRight size={24} color={Color.Neutral.css.oneHundred} />
      </button>
      <button
        onClick={navToHackerPulse}
        className="flex items-center justify-center space-x-2 px-8 py-4 mt-6 rounded-full text-xl text-neutral-700 border-2 border-neutral-700 bg-gradient-to-br  from-neutral-100 to-neutral-300 hover:from-neutral-200 hover:to-neutral-400"
      >
        <span>View publication</span>
        <ArrowRight size={24} color={Color.Neutral.css.sevenHundred} />
      </button>
    </div>
  );
}

export function UserNeedsSection({
  title,
  user_needs_data,
}: {
  title?: string;
  user_needs_data?: UserNeeds;
}) {
  return (
    <div className="flex flex-col justify-center items-center w-full space-y-10 max-w-screen-lg py-12">
      <span className="text-center text-3xl font-medium text-neutral-800">{title}</span>

      <div className="flex flex-col space-y-10 xl:flex-row xl:space-y-0 xl:space-x-6 justify-center items-center w-full px-6">
        <UserNeedsCards user_needs_data={user_needs_data} />
        <UserNeedsDescription user_needs_data={user_needs_data} />
      </div>
    </div>
  );
}

export function UserNeedsCards({ user_needs_data }: { user_needs_data?: UserNeeds }) {
  return (
    <div className="grid grid-cols-2 gap-6 items-center justify-center w-full xl:w-3/5 max-w-2xl">
      {user_needs_data.quotes.map((quote, index) => (
        <div
          key={index}
          className="flex flex-col items-center justify-center w-auto h-60 p-2 md:p-8 space-y-4 rounded-xl bg-neutral-200 border border-neutral-700"
        >
          <div className="flex items-center justify-center w-16 h-16 rounded-full bg-neutral-800 ">
            <UserRound size={30} color={Color.Neutral.css.oneHundred} />
          </div>
          <div className="text-sm text-center font-bold text-neutral-800">{quote}</div>
        </div>
      ))}
    </div>
  );
}

export function UserNeedsDescription({ user_needs_data }: { user_needs_data?: UserNeeds }) {
  return (
    <div className="flex flex-col items-start justify-start w-full xl:w-2/5 space-y-4">
      <div className="flex items-center justify-start space-x-2">
        <Telescope size={20} color={Color.Neutral.css.eightHundred} />
        <div className="text-xl font-semibold text-neutral-800">The challenge</div>
      </div>

      <div className="text-start text-neutral-500">{user_needs_data.challenge}</div>

      <div className="flex items-center justify-start space-x-2 pt-4">
        <Trophy size={20} color={Color.Neutral.css.eightHundred} />
        <div className="text-xl font-semibold text-neutral-800">Goal for the tool</div>
      </div>

      {/* Ordered list with numbers */}
      <ol className="list-decimal text-neutral-500 px-4">
        {user_needs_data.goals.map((goal, index) => (
          <li key={index}>{goal}</li>
        ))}
      </ol>

      <div className="flex items-center justify-start space-x-2 pt-4">
        <FlaskConical size={20} color={Color.Neutral.css.eightHundred} />
        <div className="text-xl font-semibold text-neutral-800">Success metrics</div>
      </div>

      <div className="text-start text-neutral-500">{user_needs_data.success_metrics}</div>
    </div>
  );
}

export function CompetitiveAnalysisSection({ competitive_analysis }: { competitive_analysis?: CompetitiveAnalysis }) {
  return (
    <div className="flex flex-col justify-center items-center w-full space-y-10 max-w-screen-lg py-12">
      <span className="text-center text-3xl font-medium text-neutral-800">
        Competitive analysis
      </span>

      <div className="flex flex-col space-y-10 xl:flex-row xl:space-y-0 xl:space-x-6 justify-center items-center w-full px-6">
        <CompetitiveAnalysisDescription competitive_analysis={competitive_analysis} />
        <CompetitiveAnalysisCards competitive_analysis={competitive_analysis} />
      </div>
    </div>
  );
}

export function CompetitiveAnalysisCards({ competitive_analysis }: { competitive_analysis?: CompetitiveAnalysis }) {
  const { mdOrLarger } = useScreenSize();
  // first 4 images
  const images_left = competitive_analysis.images.slice(0, 6);
  // last 4 images
  const images_right = competitive_analysis.images.slice(6, 10);

  if (!mdOrLarger) {
    return (
      <div className="flex justify-center w-full space-x-6 h-min">
        <div className="grid grid-cols-3 w-full h-min gap-3 items-center justify-center ">
          {competitive_analysis.images.map((img_obj, index) => (
            <div className="flex flex-col items-center max-w-32 max-h-32 p-2 rounded-xl border-2 border-neutral-400 bg-white">
              <img src={img_obj.src} className="px-2" />
              <span className="font-semibold text-xs text-neutral-500">{img_obj.alt}</span>
            </div>
          ))}
        </div>
      </div>
    );
  }

  return (
    <div className="flex w-full xl:w-3/5 space-x-6 h-min">
      <div className="grid grid-cols-2 w-auto h-min min-w-32 gap-5 items-start justify-center ">
        {images_left.map((img_obj, index) => (
          <div className="flex flex-col items-center w-32 h-32 p-2 rounded-xl border-2 border-neutral-400 bg-white">
            <img src={img_obj.src} className="px-2" />
            <span className="font-semibold text-xs text-neutral-500">{img_obj.alt}</span>
          </div>
        ))}
      </div>
      <div>
        <VerticalDivider />
      </div>
      <div className="grid grid-cols-2  w-auto h-min  min-w-32 gap-5 items-start justify-start ">
        {images_right.map((img_obj, index) => (
          <div className="flex flex-col items-center w-32 h-32 p-2 rounded-xl border-2 border-neutral-400 bg-white">
            <img src={img_obj.src} className="px-2" />
            <span className="font-semibold text-xs text-neutral-500">{img_obj.alt}</span>
          </div>
        ))}
      </div>
    </div>
  );
}

export function CompetitiveAnalysisDescription({ competitive_analysis }: { competitive_analysis?: CompetitiveAnalysis }) {
  return (
    <div className="flex flex-col items-start justify-start w-full xl:w-2/5 space-y-4">
      <div className="flex items-center justify-start space-x-2">
        <FileBarChart2 size={20} color={Color.Neutral.css.eightHundred} />
        <div className="text-xl font-semibold text-neutral-800">Analysis of the market</div>
      </div>

      <div className="text-start text-neutral-500">{competitive_analysis.description}</div>

      <div className="flex items-center justify-start space-x-2 pt-4">
        <Trophy size={20} color={Color.Neutral.css.eightHundred} />
        <div className="text-xl font-semibold text-neutral-800">Goals</div>
      </div>

      {/* Ordered list with numbers */}
      <ol className="list-decimal text-neutral-500 px-4">
        {competitive_analysis.goals.map((goal, index) => (
          <li key={index}>{goal}</li>
        ))}
      </ol>

      <div className="flex items-center justify-start space-x-2 pt-4">
        <BookCheck size={20} color={Color.Neutral.css.eightHundred} />
        <div className="text-xl font-semibold text-neutral-800">Results</div>
      </div>

      <div className="text-start text-neutral-500">{competitive_analysis.results}</div>
    </div>
  );
}

export function ResearchSection() {
  const { xlOrLarger } = useScreenSize();
  return (
    <div className="flex flex-col items-center justify-center space-y-10  w-full mt-4 pb-12 text-neutral-100 bg-neutral-700">
      <span className="pt-12 text-center text-3xl font-medium">Research & brainstorms</span>

      <div className="flex flex-col items-center w-full space-y-16 px-8 max-w-screen-lg">
        {xlOrLarger ? (
          <div className="flex w-full space-x-4">
            <ResearchDescription data={research_data[0]} />
            <ResearchCards data={research_data[0]} />
          </div>
        ) : (
          <div className="flex flex-col space-y-8 w-full">
            <ResearchCards data={research_data[0]} />
            <ResearchDescription data={research_data[0]} />
          </div>
        )}

        {/* display svg */}

        <div className="px-6">
          <TravelToLeft />
        </div>

        {xlOrLarger ? (
          <div className="flex w-full space-x-4">
            <ResearchCards data={research_data[1]} />
            <ResearchDescription data={research_data[1]} />
          </div>
        ) : (
          <div className="flex flex-col space-y-8 w-full">
            <ResearchCards data={research_data[1]} />
            <ResearchDescription data={research_data[1]} />
          </div>
        )}

        {/* display svg */}
        <div className="px-6">
          <TravelToRight />
        </div>

        {xlOrLarger ? (
          <div className="flex w-full space-x-4">
            <ResearchDescription data={research_data[2]} />
            <ResearchCards data={research_data[2]} />
          </div>
        ) : (
          <div className="flex flex-col space-y-8 w-full">
            <ResearchCards data={research_data[2]} />
            <ResearchDescription data={research_data[2]} />
          </div>
        )}
      </div>
    </div>
  );
}

export function ResearchCards({ data }: any) {
  return (
    <div className="w-full xl:w-1/2 h-full overflow-hidden rounded-xl border-4 border-neutral-100">
      <img src={data.image.src} alt="Research" className="w-full h-full" />
    </div>
  );
}

export function ResearchDescription({ data }: any) {
  return (
    <div className="flex flex-col items-start justify-start w-full xl:w-1/2 space-y-4">
      <div className="flex items-center justify-start space-x-2">
        <FileBarChart2 size={20} color={Color.Neutral.css.oneHundred} />
        <div className="text-xl font-semibold">{data.title}</div>
      </div>

      <div className="text-start font-medium ">{data.description}</div>

      <div className="flex items-center justify-start space-x-2 pt-4">
        <Trophy size={20} color={Color.Neutral.css.oneHundred} />
        <div className="text-xl font-semibold">Goal</div>
      </div>

      {/* Ordered list with numbers */}
      <ol className="list-decimal  px-4">
        {data.goals.map((goal: string, index: number) => (
          <li key={index}>{goal}</li>
        ))}
      </ol>

      <div className="flex items-center justify-start space-x-2 pt-4">
        <BookCheck size={20} color={Color.Neutral.css.oneHundred} />
        <div className="text-xl font-semibold">Results</div>
      </div>

      <div className="text-start font-medium ">{data.results}</div>
    </div>
  );
}

export function HurdleSectionMVP({
  divider = false,
  dark = false,
  feedback = false,
  data,
}: {
  divider?: boolean;
  dark?: boolean;
  feedback?: boolean;
  data: HurdleData;
}) {
  const bgColor = dark ? "bg-neutral-200" : "bg-neutral-100";
  return (
    <div
      className={`flex flex-col items-center justify-center w-full pb-12 text-neutral-800 ${bgColor}`}
    >
      {divider && <HorizontalDivider />}
      <div className="flex flex-col justify-center items-center pt-12  max-w-screen-lg">
        <span className="pb-2 font-medium text-neutral-500">{data.subTitle} </span>
        <span className="pb-10 text-center text-3xl font-medium ">{data.title}</span>

        <div className="flex flex-col w-full items-center space-y-10 px-6">
          <HurdleDescription data={data} />
          <div className="w-full max-w-3xl overflow-hidden rounded-3xl border-2 border-neutral-700">
            <img src={data.image}/>
          </div>
          {feedback && <HurdleAdditionalSection userHighlight={data.userHighlight} />}
        </div>
      </div>
    </div>
  );
}

export function HurdleSection2({
  divider = false,
  dark = false,
}: {
  divider?: boolean;
  dark?: boolean;
}) {
  const bgColor = dark ? "bg-neutral-200" : "bg-neutral-100";
  return (
    <div
      className={`flex flex-col items-center justify-center w-full mt-4 pb-12 text-neutral-800 ${bgColor}`}
    >
      {divider && <HorizontalDivider />}
      <div className="flex flex-col justify-center items-center pt-12  max-w-screen-lg">
        <span className="pb-2 font-medium text-neutral-500">Hurdle #2: Technical </span>
        <span className="pb-10 text-center text-3xl font-medium ">Browsers crash for DSM</span>

        <div className="flex flex-col w-full space-y-10 px-6">
          <HurdleDescription data={hurdle_data[2]} />

          <div className="flex flex-col sm:flex-row items-center w-full space-x-8 space-y-6 sm:space-y-0">
            <div className="flex flex-col lg:w-4/12 h-full">
              <span className="ml-1 mb-2 text-2xl font-medium">Before:</span>
              <img
                className="overflow-hidden rounded-3xl border-2 border-neutral-700"
                src={fire_gif}
                alt="Fire animation"
              />
            </div>

            <div className="flex flex-col lg:w-8/12 h-full ">
              <span className="ml-1 mb-2 text-2xl font-medium">After:</span>
              <video
                className="w-full overflow-hidden rounded-3xl border-2 border-neutral-700"
                autoPlay
                loop
                muted
                playsInline // This is important for autoplay in some browsers
              >
                <source src={dsm_gif} type="video/mp4" />
                Your browser does not support the video.
              </video>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export function HurdleDescription({ data }: { data: HurdleData }) {
  console.log(data);
  const { mdOrLarger } = useScreenSize();
  return (
    <div className="flex items-start justify-start space-x-6 h-full">
      {/* Section #1 */}
      <div className="flex flex-col space-y-4 w-1/2">
        <div className="flex items-center justify-start space-x-2">
          {mdOrLarger && (
            <Brain className="w-8 h-8 xl:w-6 xl:h-6" color={Color.Neutral.css.eightHundred} />
          )}
          <div className="text-xl font-semibold text-neutral-800">{data.problemTitle}</div>
        </div>
        <div className="text-start text-neutral-500">
          {data.problemDescriptions.map((description: string, index: number) => (
            <div className="mb-2" key={index}>
              {description}
            </div>
          ))}
        </div>
      </div>

      {/* Section #2 */}
      <div className="flex flex-col justify-start items-start space-y-4 w-1/2">
        <div className="flex items-center justify-start space-x-2">
          {mdOrLarger && (
            <Key className="w-10 h-10 xl:w-6 xl:h-6" color={Color.Neutral.css.eightHundred} />
          )}
          <div className="text-xl font-semibold text-neutral-800">{data.solutionTitle}</div>
        </div>

        <div className="text-start text-neutral-500">
          {data.solutionDescriptions.map((description: string, index: number) => (
            <div className="mb-2" key={index}>
              {description}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export function HurdleAdditionalSection({ userHighlight }: { userHighlight: string }) {
  return (
    <div className="flex flex-col items-center justify-center mt-4 pb-12 text-neutral-100 ">
      <UserResearchBar />

      <div className="flex flex-col w-full space-y-2  pt-8">
        {/* <HurdleDescription /> */}

        <div className="flex items-center space-x-4">
          <div className="px-6 bg-neutral-200 rounded-full">
            <Lightbulb size={30} color={Color.Neutral.css.eightHundred} />
          </div>
          <span className="text-xl text-neutral-500">{userHighlight}</span>
        </div>
      </div>
    </div>
  );
}

export function UserResearchBar() {
  return (
    <div className="flex items-center justify-start space-x-4 w-full p-3 rounded-lg bg-neutral-700">
      <div className="flex items-center justify-start space-x-2 text-neutral-100">
        <PencilLine size={20} color={Color.Neutral.css.oneHundred} />
        <div className="text-lg font-semibold">User feedback</div>
      </div>
    </div>
  );
}

export function FinalThoughtsSection() {
  return (
    <div className="flex flex-col items-center justify-center w-full mt-4 py-12 px-4 text-neutral-100 bg-neutral-300">
      <div className="flex flex-col justify-center items-center space-y-10 w-full max-w-screen-lg">
        <div className="flex flex-col items-center justify-center space-y-10 w-full p-8 rounded-3xl bg-neutral-700">
          <span className="text-center text-3xl font-medium">Final thoughts</span>
          <div className="text-center ">
            After presenting the MVP to multiple teams, I received little to no interest (yikes!). I
            met with senior engineers, managers, and project owners and learned some hard truths!
            <br />
            <br />
            This was not a problem teams wanted to solve. They had other priorities.
            <br />
            <br />
            Most small teams moved fast. They didn't have time to document. Mid-sized teams had a
            suite of tools that are "good enough". Large teams have the budget for expensive
            enterprise tools. The market for my tool was too small.
            <br />
            <br />
            Although it was tough, I decided to pivot. I had a lot of fun, learned a ton, and I'm
            excited to see what's next!
          </div>
        </div>
      </div>
    </div>
  );
}

export function TechStackSection() {
  return (
    <div className={`flex flex-col items-center justify-center w-full pb-12 text-neutral-800`}>
      <div className="flex flex-col justify-center items-center w-full pt-12  max-w-screen-lg px-4">
        <span className="pb-10 text-center text-3xl font-medium">{tech_stack_data.title}</span>

        <div className="text-center lg:text-start font-medium text-neutral-500">
          {tech_stack_data.descriptions.map((description: string, index: number) => (
            <div className="mb-4" key={index}>
              {description}
            </div>
          ))}
        </div>

        <div className="flex flex-col mt-8 p-8 w-full rounded-3xl border-2 border-neutral-700 bg-neutral-300">
          <span className="w-full mb-6 text-center text-2xl font-medium ">
            Languages, infrastructure, auth, monitoring, & payments
          </span>
          <div className="grid grid-cols-3 lg:grid-cols-4 w-full min-w-12 gap-5 items-start justify-center ">
            {tech_stack.map((img_obj, index) => (
              <div className="flex flex-col items-center justify-center min-w-12 max-w-32 min-h-12 max-h-32 md:w-32 md:h-32 p-3 rounded-xl border border-neutral-700 bg-white">
                <img src={img_obj.src} className="" />
                <span className="w-full text-center font-semibold text-xs text-neutral-500">
                  {img_obj.alt}
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export function HorizontalDivider() {
  return <div className="h-px my-1 w-full bg-gray-300"></div>;
}

export function VerticalDivider() {
  return <div className="w-px h-full bg-gray-400"></div>;
}
export default Sinkly;
