import { useDispatch, useSelector } from "react-redux";
import { HorizontalDivider } from "src/components/Documents/DocumentCard/DocumentCardContextMenu";
import { BaseContextMenu, BaseContextMenuButton } from "src/components/LandingPage/BaseContextMenu";
import { getEdgeById } from "src/state/selectors/boxDiagramSelectors";
import { navigateToDSM, navigateToICD } from "src/state/selectors/selectors";
import { RootState } from "src/state/slices";
import { deleteEdges, setSelectedEdge } from "src/state/slices/documentSlice";

interface EdgeContextMenuProps {
  id: string;
  top?: number;
  left?: number;
  onClose?: () => void;
}

function EdgeContextMenu({ id, top, left, onClose, ...props }: EdgeContextMenuProps) {
  const dispatch = useDispatch();
  const edges = useSelector((state: RootState) => state.document.documentContainer.edges);
  // Fetch selected specific node from the Redux
  const edge = useSelector((state: RootState) => getEdgeById(state, id));

  function handleDeleteEdge() {
    dispatch(deleteEdges({ edges: [edge] }));
    dispatch(setSelectedEdge({ edge: null }));
    onClose();
  }

  function handleViewInICD() {
    let edgeIndex = edges.findIndex((edge) => edge.id === id);
    navigateToICD(dispatch, edgeIndex);
  }

  function handleViewInDSM() {
    navigateToDSM(dispatch, edge);
  }

  // Early return if the node's position is undefined.
  if (!edge) return null;

  const styles = {
    top: top,
    left: left != undefined ? left - 220 : undefined,
  };

  return (
    <BaseContextMenu onClose={onClose} styles={styles}>
      <p className="mb-2 ml-2 text-lg">
        <small>
          Connection ID: <b>{id}</b>
        </small>
      </p>
      <HorizontalDivider />
      <BaseContextMenuButton title={"View in ICD"} onClick={handleViewInICD} showChevron />
      <BaseContextMenuButton title={"View in DSM"} onClick={handleViewInDSM} showChevron />
      <HorizontalDivider />
      <BaseContextMenuButton title="Delete connection" onClick={handleDeleteEdge} />
    </BaseContextMenu>
  );
}

export default EdgeContextMenu;
