import { useNavigate } from "react-router-dom";
import { CLIENT_ROUTE } from "src/utils/constants";
import { Color } from "src/models/BoxDiagram/Colors";
import { Footer, Toolbar, darrels_image, useScrollToTop } from "./LandingPage";
import { Footprints } from "lucide-react";
import { useScreenSize } from "../BlockDiagram/Hooks/useIsMobile";

const GRADIENTS = [
  Color.Purple.css.threeHundred,
  Color.Blue.css.twoHundred,
  Color.Blue.css.threeHundred,
  Color.Orange.css.twoHundred,
  Color.Orange.css.threeHundred,
  Color.Red.css.twoHundred,
  Color.Purple.css.twoHundred,
  Color.Red.css.twoHundred,
];
const sinkly_md = require("../../assets/images/timeline/sinkly_md.png");
const antler_md = require("../../assets/images/timeline/antler_md.png");
const util_md = require("../../assets/images/timeline/util_md.png");
const lyft_md = require("../../assets/images/timeline/lyft_md.png");
const relevate_md = require("../../assets/images/timeline/relevate_md.png");
const cmd_md = require("../../assets/images/timeline/cmd_md.png");
const trackmy_md = require("../../assets/images/timeline/trackmy_md.png");

const TIMELINE_DATA = [
  {
    title: "TrackMy (iOS - B2C)",
    description: `Started my journey in college building TrackMy. A mobile app that notified students when new classes were available and automatically enrolled them.`,
    date: "2019-2020",
    image: trackmy_md,
  },
  {
    title: "CMD Monitors (Web - B2B)",
    description: `Next, I cofounded CMD Monitors. I built systems to track retailors like Walmart and Target to notify clients when items came back in stock during COVID supply shortages.`,
    date: "2021",
    image: cmd_md,
  },
  {
    title: "Relevate (iOS - B2C)",
    description: `Next, I moved to New York City and built Relavate. A private marketplace for people to buy and sell used goods within their apartments.`,
    date: "2022-2023",
    image: relevate_md,
  },
  {
    title: "Lyft (Full-time software)",
    description: `Then I started full time at Lyft. Here, I learned how to build scalable systems and work with a team of engineers to launch products for millions of riders & drivers.`,
    date: "2021-2024",
    image: lyft_md,
  },
  {
    title: "Antler VC (Startup accelerator)",
    description: `Then, I was accepted to Antler, where I began to learn the business side of entrepreneurship. I worked day and night with founders and VC partners build products.`,
    date: "2023",
    image: antler_md,
  },
  {
    title: "Util (Web - B2C)",
    description: `During my time in Antler, I built Util. A service to utilize moving companies to deliver used goods to residents during off-peak hours.`,
    date: "2023",
    image: util_md,
  },
  {
    title: "Sinkly (Web B2B + B2C)",
    description: `Most recently, I built Sinkly! A design tool to help hardware engineers automatically generate requirement docs saving hours of time.`,
    date: "2024",
    image: sinkly_md,
  },
];

function Timeline() {
  useScrollToTop();
  const { xlOrLarger, lgOrLarger, mdOrLarger } = useScreenSize();
  let timelineContainer = <TimelineContainerSmall />;

  if (mdOrLarger) {
    timelineContainer = <TimelineContainerMedium />;
  }
  if (xlOrLarger) {
    timelineContainer = <TimelineContainer />;
  }

  return (
    <div className="flex flex-col w-full min-h-screen">
      <Toolbar />
      <HeroSection />
      {timelineContainer}
      <Footer timeline />
    </div>
  );
}

function HeroSection() {
  return (
    <div className="flex items-center justify-center mt-12 md:mt-16  md:ml-20 pb-10">
      <Hero />
    </div>
  );
}

function Hero() {
  return (
    <div className="flex flex-col justify-center w-full px-4 md:px-0 text-neutral-700">
      <Header />
      <SubHeader />
    </div>
  );
}

function Header() {
  return (
    <div className="text-7xl sm:text-8xl lg:text-9xl text-center font-extrabold">Timeline</div>
  );
}

function SubHeader() {
  return (
    <div className="flex items-center justify-center mt-4 text-xl lg:text-2xl text-center font-bold md:h-auto">
      <span>Let me take you through the twist and turns of my career life.</span>
    </div>
  );
}

function TimelineContainerSmall() {
  const { xlOrLarger } = useScreenSize();
  const padding = xlOrLarger ? "80px" : "40px";

  return (
    <div
      style={{ borderTopLeftRadius: padding, borderTopRightRadius: padding }}
      className="flex flex-col items-center justify-center mt-4 text-neutral-100 bg-neutral-700 rounded-t-3xl"
    >
      <CaseStudyTitle />

      <div className="flex flex-col space-y-10 w-full px-6 ">
        {TIMELINE_DATA.map((project, index) => (
          <div className="flex flex-col items-start justify-start space-y-4 w-full px-5">
            <div className="font-semibold">{project.title}</div>
            <ProjectContainer project1={project} />
          </div>
        ))}

        {/* Additional vertical spacing */}
        <div></div>
      </div>
    </div>
  );
}

function TimelineContainerMedium() {
  const { xlOrLarger } = useScreenSize();
  const padding = xlOrLarger ? "80px" : "40px";

  return (
    <div
      style={{ borderTopLeftRadius: padding, borderTopRightRadius: padding }}
      className="flex flex-col items-center justify-center mt-4 text-neutral-100 bg-neutral-700 rounded-t-3xl"
    >
      <CaseStudyTitle />

      <div className="flex flex-col w-full px-6">
        {/* Center */}
        <div className="flex flex-col p-5">
          <HorizontalBar
            gradientStart={GRADIENTS[0]}
            gradientEnd={GRADIENTS[1]}
            gradientToRight
            leftText={TIMELINE_DATA[0].title}
            rightText={TIMELINE_DATA[1].title}
          />
        </div>

        <div className="flex flex-col justify-start space-y-10 px-5">
          <ProjectContainer project1={TIMELINE_DATA[0]} project2={TIMELINE_DATA[1]} />
          <HorizontalBar
            gradientStart={GRADIENTS[2]}
            gradientEnd={GRADIENTS[3]}
            leftText={TIMELINE_DATA[2].title}
            rightText={TIMELINE_DATA[3].title}
          />
        </div>

        <div className="flex flex-col justify-start space-y-10 p-5">
          <ProjectContainer project1={TIMELINE_DATA[2]} project2={TIMELINE_DATA[3]} />
          <HorizontalBar
            gradientToRight
            gradientStart={GRADIENTS[4]}
            gradientEnd={GRADIENTS[5]}
            leftText={TIMELINE_DATA[4].title}
            rightText={TIMELINE_DATA[5].title}
          />
        </div>

        <div className="flex flex-col justify-start space-y-10 p-5">
          <ProjectContainer project1={TIMELINE_DATA[4]} project2={TIMELINE_DATA[5]} />
          <HorizontalBar
            rightText={TIMELINE_DATA[6].title}
            gradientStart={GRADIENTS[6]}
            gradientEnd={GRADIENTS[7]}
          />
        </div>

        <div className="flex flex-col justify-start space-y-10 p-5">
          <ProjectContainer project1={TIMELINE_DATA[6]} />
        </div>
      </div>
    </div>
  );
}

function TimelineContainer() {
  return (
    <div
      style={{ borderTopLeftRadius: "80px", borderTopRightRadius: "80px" }}
      className="flex flex-col items-center justify-center mt-4 text-neutral-100 bg-neutral-700 rounded-t-3xl"
    >
      <CaseStudyTitle />

      <div className="flex w-full max-w-screen-2xl">
        {/* Left */}

        <div className="w-1/5">
          <div className="flex justify-end w-full">
            <div>
              <GradientHalfCircle onLeft hidden />
              <GradientHalfCircle
                onLeft
                shiftUp
                shiftAmt={1}
                gradientStart={GRADIENTS[4]}
                gradientEnd={GRADIENTS[3]}
              />
              <GradientHalfCircle onLeft hidden />
              <GradientHalfCircle onLeft hidden />
            </div>
          </div>
        </div>

        {/* Center */}
        <div className=" w-3/5 ">
          <HorizontalBar
            gradientStart={GRADIENTS[0]}
            gradientEnd={GRADIENTS[1]}
            gradientToRight
            leftText={TIMELINE_DATA[0].title}
            rightText={TIMELINE_DATA[1].title}
          />

          <div style={{ transform: "translateY(84px)" }}>
            <ProjectContainer project1={TIMELINE_DATA[0]} project2={TIMELINE_DATA[1]} />
            <HorizontalBar
              gradientStart={GRADIENTS[2]}
              gradientEnd={GRADIENTS[3]}
              leftText={TIMELINE_DATA[2].title}
              rightText={TIMELINE_DATA[3].title}
            />
          </div>

          <div style={{ transform: "translateY(168px)" }}>
            <ProjectContainer project1={TIMELINE_DATA[2]} project2={TIMELINE_DATA[3]} />
            <HorizontalBar
              gradientToRight
              gradientStart={GRADIENTS[4]}
              gradientEnd={GRADIENTS[5]}
              leftText={TIMELINE_DATA[4].title}
              rightText={TIMELINE_DATA[5].title}
            />
          </div>

          <div style={{ transform: "translateY(252px)" }}>
            <ProjectContainer project1={TIMELINE_DATA[4]} project2={TIMELINE_DATA[5]} />
            <HorizontalBar
              rightText={TIMELINE_DATA[6].title}
              gradientStart={GRADIENTS[6]}
              gradientEnd={GRADIENTS[7]}
            />
          </div>

          <div className="flex w-full justify-center" style={{ transform: "translateY(326px)" }}>
            <ProjectContainer project1={TIMELINE_DATA[6]} />
          </div>
        </div>

        {/* Right */}
        <div className="w-1/5">
          <div className="flex justify-start w-full">
            <div>
              <GradientHalfCircle gradientStart={GRADIENTS[1]} gradientEnd={GRADIENTS[2]} />
              <GradientHalfCircle shiftUp hidden />
              <GradientHalfCircle
                shiftUp
                shiftAmt={2}
                gradientStart={GRADIENTS[5]}
                gradientEnd={GRADIENTS[6]}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function CaseStudyTitle() {
  return (
    <div className="flex flex-col items-center justify-center w-11/12 lg:w-4/5 my-14">
      <div className="flex items-center  w-full">
        <HorizontalDivider />
        <div className="flex items-center justify-center space-x-4  mx-8 lg:mx-20">
          <span className="text-center text-xl font-bold w-28">My journey</span>
        </div>
        <HorizontalDivider />
      </div>
    </div>
  );
}

function HorizontalBar({
  leftText,
  rightText,
  gradientToRight,
  gradientStart,
  gradientEnd,
}: {
  leftText?: string;
  rightText?: string;
  gradientToRight?: boolean;
  gradientStart?: string;
  gradientEnd?: string;
}) {
  const positioning = leftText && rightText ? "justify-between" : "justify-center";
  const gradient = gradientToRight ? "to right" : "to left";
  return (
    <div
      style={{
        background: `linear-gradient(${gradient}, ${gradientStart}, ${gradientEnd})`,
      }}
      className="flex flex-col items-center justify-center  rounded-3xl bg-neutral-100  "
    >
      <div className={`flex ${positioning} h-10 w-full px-10 `}>
        {leftText && (
          <div className="flex items-center justify-center space-x-2  text-center text-lg font-bold bg-transparent">
            <div className="flex items-center justify-center w-3 h-3 rounded-full bg-neutral-800" />
            <span className="text-neutral-800">{leftText}</span>
          </div>
        )}

        {rightText && (
          <div className="flex items-center justify-center space-x-2  text-center text-lg font-bold bg-transparent">
            <div className="flex items-center justify-center w-3 h-3 rounded-full bg-neutral-800" />
            <span className="text-neutral-800">{rightText}</span>
          </div>
        )}
      </div>
    </div>
  );
}

function GradientHalfCircle({
  onLeft = false,
  hidden = false,
  shiftUp = false,
  shiftAmt = 1,
  gradientStart,
  gradientEnd,
}: {
  onLeft?: boolean;
  hidden?: boolean;
  shiftUp?: boolean;
  shiftAmt?: number;
  gradientStart?: string;
  gradientEnd?: string;
}) {
  const transform = onLeft ? "rotate(180deg)" : "rotate(0deg)";
  const positioning = onLeft ? "left-5" : "-left-5";
  let shift = "top-0";
  if (shiftUp && shiftAmt === 1) {
    shift = "-top-10";
  }
  if (shiftUp && shiftAmt === 2) {
    shift = "-top-20";
  }
  // Inline styles for the half-circle with gradient
  const halfCircleStyle = {
    width: "210px", // Set the width of the circle
    height: "420px", // Set the height to half of the width to show half a circle
    borderRadius: "0 1000px 1000px 0", // Apply border-radius to the top corners to create a half-circle
    backgroundImage: "linear-gradient(to right, #FFD700, #FF8C00)", // Apply a gradient from yellow to orange
    // overflow: "hidden", // Hide the overflow to ensure only half the circle is visible
    // Rotate the half-circle to make it face the right direction
    transform: transform,
    opacity: hidden ? 0 : 1,
    // Add gradient from top to bottom
    background: `linear-gradient(to bottom, ${gradientStart}, ${gradientEnd})`,
  };

  const smallHalfCircleStyle = {
    width: "190px", // Set the width of the circle
    height: "340px", // Set the height to half of the width to show half a circle
    borderRadius: " 0 2220px 2220px 0", // Apply border-radius to the top corners to create a half-circle
    backgroundImage: "linear-gradient(to right, #404040, #404040)", // Apply a gradient from yellow to orange
    // overflow: "hidden", // Hide the overflow to ensure only half the circle is visible
    opacity: hidden ? 0 : 1,
  };

  return (
    <div
      className={`relative flex items-center justify-center ${positioning} ${shift}`}
      style={halfCircleStyle}
    >
      <div className="relative -left-8" style={smallHalfCircleStyle}></div>
    </div>
  );
}

function ProjectVisual({ project }: { project?: any }) {
  return (
    <div className="flex flex-col space-y-0 mb-3 items-start justify-start">
      <img src={project.image} alt={project.title} className="lg:max-h-60" />
      <div className="font-bold">{project.date}</div>
      <div className="max-w-lg text-sm text-neutral-200">{project.description}</div>
    </div>
  );
}

function ProjectContainer({ project1, project2 }: any) {
  return (
    <div className="lg:relative flex items-center justify-center lg:h-64 space-x-8 lg:mx-10 rounded-3xl lg:bottom-10">
      <ProjectVisual project={project1} />
      {project2 && <ProjectVisual project={project2} />}
    </div>
  );
}

function HorizontalDivider() {
  return <div className="h-px my-1 w-full bg-gray-300"></div>;
}
export default Timeline;
