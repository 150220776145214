// src/components/BlockDiagram/Hooks/useShowEdgeToolbar.ts

import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Node } from "reactflow";
import { RootState } from "src/state/slices";
import { MenuItemName, setSelectItem } from "src/state/slices/BoxDiagram/boxDiagramUISlice";

export function useUpdateCursor() {
  const selectedSideBarItem = useSelector((state: RootState) => state.boxDiagramUI.activeMenuItem);

  function changeCursorStyle(newCursorStyle) {
    // Select the element with the class .react-flow__pane
    const pane = document.querySelector(".react-flow__pane") as HTMLElement;

    // Check if the element exists
    if (pane) {
      // Change the cursor style
      pane.style.cursor = newCursorStyle;
    }
  }

  // Set pointer to crosshair when the frame tool is selected
  useEffect(() => {
    if (selectedSideBarItem === MenuItemName.Frame) {
      changeCursorStyle("crosshair");
    } else {
      changeCursorStyle("pointer");
    }
  }, [selectedSideBarItem]);

  // Add global event listener for mouse up to change the cursor style back to pointer
  useEffect(() => {
    window.addEventListener("mouseup", () => {
      changeCursorStyle("pointer");
    });
    return () => {
      window.removeEventListener("mouseup", () => {
        changeCursorStyle("pointer");
      });
    };
  }, []);
}
