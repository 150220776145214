// src/state/slices/documentContainerSlice.ts
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  DocumentContainer as DocumentContainer,
  DocumentPermissions,
  emptyDocument,
} from "src/models/Document";
import {
  deselectAllNodes,
  replaceAllNodes,
  setAllNodesNotEditing,
  updateNodeData,
  applyNewEdgeChanges,
  updateEdgeData,
  replaceAllEdges,
  boxDiagramReducers,
  applyNewNodeChanges,
  updateEdgeToolbarPosition,
} from "../reducers/boxDiagramReducers";
import { icdReducers, updateRowData } from "../reducers/icdReducers";
import { dsmReducers, moveNodePositionDown, moveNodePositionUp } from "../reducers/dsmReducers";
import { Connection as RFConnection, Edge, XYPosition, Node } from "reactflow";
import { NodeData, NodeType, createNewNode, getNodeID } from "src/models/BoxDiagram/Node";
import {
  ArrowDirection,
  createNewEdge,
  EdgeData,
  EdgeType,
  getEdgeID,
  IEdge,
  NewEdgeParams,
  updateMarkersAndSwapEdgeDirection,
} from "../../models/BoxDiagram/Edge";
import { SubConnection } from "src/models/Connection";
import { initialEdgeToolbarNode } from "src/utils/initialDocument";
import { saveDocToApiWithDebounce } from "src/utils/autoSave";
import { dispatchSaveDiagramSnapshot } from "src/events/documentEvents";
import {
  addConnection,
  connectionsReducers,
  removeConnection,
  updateConnection,
} from "../reducers/connectionsReducers";

export interface DocumentState {
  documentContainer: DocumentContainer;
  // Saves the edge that is selected. This is used to display the edge toolbar
  selectedEdge: Edge<EdgeData> | null;
}

export const initialState: DocumentState = {
  documentContainer: emptyDocument,
  selectedEdge: null,
};

export function updateTimestamp(documentContainer: DocumentContainer): DocumentContainer {
  return {
    ...documentContainer,
    updated_at_ms: Date.now(),
  };
}

function _deleteEdges(edges: Edge<EdgeData>[], edgeIdsToDelete: string[]): Edge<EdgeData>[] {
  return edges.filter((edge) => !edgeIdsToDelete.includes(edge.id));
}

export const documentContainerSlice = createSlice({
  name: "documentContainer",
  initialState,
  reducers: {
    /*************************
     ********* Nodes /*********
     *************************/
    addNode(
      state,
      action: PayloadAction<{
        position?: XYPosition;
        data?: NodeData;
        type?: NodeType;
        nodeId?: string;
        parentNode?: string;
      }>
    ) {
      // Update Box Diagram
      const { position, data, type, nodeId, parentNode } = action.payload;
      const _nodeId = nodeId || getNodeID();

      // Save a snapshot for undo/redo
      dispatchSaveDiagramSnapshot(window);

      const newNode = createNewNode(_nodeId, data, position, type, parentNode);

      // Get index of the last frame node. This causes each frame to appear on top of all previous frames
      if (newNode.type === NodeType.Frame) {
        const insertionIndex = getInsertionIndexForFrame(state.documentContainer.nodes, newNode.id);
        state.documentContainer.nodes = [
          ...state.documentContainer.nodes.slice(0, insertionIndex),
          newNode,
          ...state.documentContainer.nodes.slice(insertionIndex),
        ];
      } else {
        state.documentContainer.nodes.push(newNode);
      }

      state.documentContainer = updateTimestamp(state.documentContainer);

      // Save document to API
      saveDocToApiWithDebounce({
        id: state.documentContainer.id,
        owner_id: state.documentContainer.owner_id,
        nodes: state.documentContainer.nodes,
        permissions: state.documentContainer.permissions,
      });
    },

    deleteNode(state, action: PayloadAction<{ sourceNodeId: string }>) {
      const { sourceNodeId } = action.payload;

      // - DELETE NODES -

      const nodeToDelete = state.documentContainer.nodes.find((node) => node.id === sourceNodeId);

      // For frames, delete all children before deleting the frame
      if (nodeToDelete?.type === NodeType.Frame) {
        // Delete all nodes that are children of the frame
        state.documentContainer.nodes = state.documentContainer.nodes.filter(
          (node) => node.parentNode !== sourceNodeId
        );
      }

      // Update Box Diagram
      state.documentContainer.nodes = state.documentContainer.nodes.filter(
        (node) => node.id !== sourceNodeId
      );

      // Save document to API
      saveDocToApiWithDebounce({
        id: state.documentContainer.id,
        owner_id: state.documentContainer.owner_id,
        nodes: state.documentContainer.nodes,
        permissions: state.documentContainer.permissions,
      });

      // - DELETE EDGES -

      // Get edge ids to delete
      const edgeIdsToDelete: string[] = state.documentContainer.edges
        .filter((edge) => edge.source === sourceNodeId || edge.target === sourceNodeId)
        .map((edge) => edge.id);

      if (edgeIdsToDelete.length > 0) {
        state.documentContainer.edges = _deleteEdges(
          state.documentContainer.edges,
          edgeIdsToDelete
        );

        state.documentContainer = updateTimestamp(state.documentContainer);

        // Save document to API
        saveDocToApiWithDebounce({
          id: state.documentContainer.id,
          owner_id: state.documentContainer.owner_id,
          edges: state.documentContainer.edges,
          permissions: state.documentContainer.permissions,
        });
      }
    },

    setNodeLabel(state, action: PayloadAction<{ nodeId: string; label: string }>) {
      const { nodeId, label } = action.payload;

      // Update Box Diagram
      state.documentContainer.nodes = state.documentContainer.nodes.map((node) => {
        if (node.id === nodeId) {
          return { ...node, data: { ...node.data, label } };
        }
        return node;
      });

      state.documentContainer = updateTimestamp(state.documentContainer);

      // Save document to API
      saveDocToApiWithDebounce({
        id: state.documentContainer.id,
        owner_id: state.documentContainer.owner_id,
        nodes: state.documentContainer.nodes,
        permissions: state.documentContainer.permissions,
      });
    },

    setConnections(
      state,
      action: PayloadAction<{ source: string; target: string; connections: SubConnection[] }>
    ) {
      const { connections, source, target } = action.payload;

      // Update Box Diagram
      state.documentContainer.edges = state.documentContainer.edges.map((edge) => {
        if (edge.source === source && edge.target === target) {
          return { ...edge, data: { ...edge.data, connections } };
        }
        return edge;
      });
      state.documentContainer = updateTimestamp(state.documentContainer);

      // Save document to API
      saveDocToApiWithDebounce({
        id: state.documentContainer.id,
        owner_id: state.documentContainer.owner_id,
        edges: state.documentContainer.edges,
        permissions: state.documentContainer.permissions,
      });
    },

    /**************************
     ********* Edges **********
     *************************/

    setSelectedEdge: (state, action: PayloadAction<{ edge: Edge<EdgeData> | null }>) => {
      const { edge } = action.payload;
      state.selectedEdge = edge;
    },

    addNewEdge(state, action: PayloadAction<Edge | RFConnection>) {
      const reactFlowEdge = action.payload as Edge;
      const newEdgeParams: NewEdgeParams = {
        id: getEdgeID(),
        type: EdgeType.DefaultEdge,
        source: reactFlowEdge.source,
        target: reactFlowEdge.target,
        sourceHandle: reactFlowEdge.sourceHandle,
        targetHandle: reactFlowEdge.targetHandle,
        markerStart: reactFlowEdge.markerStart,
        markerEnd: reactFlowEdge.markerEnd,
      };

      // Save a snapshot for undo/redo
      dispatchSaveDiagramSnapshot(window);

      // Create new edge
      const newEdge: IEdge = createNewEdge(newEdgeParams);

      // Get index of the last edge with the same source
      const insertionIndex = getInsertionIndexForEdge(
        state.documentContainer.edges,
        newEdge.source
      );

      // Add new edge to document next to the last edge with the same source
      state.documentContainer.edges = [
        ...state.documentContainer.edges.slice(0, insertionIndex),
        newEdge,
        ...state.documentContainer.edges.slice(insertionIndex),
      ];

      // Show edge toolbar
      state.selectedEdge = newEdge;

      // Update timestamp
      state.documentContainer = updateTimestamp(state.documentContainer);

      // Save document to API
      saveDocToApiWithDebounce({
        id: state.documentContainer.id,
        owner_id: state.documentContainer.owner_id,
        edges: state.documentContainer.edges,
        permissions: state.documentContainer.permissions,
      });
    },

    deleteEdges(state, action: PayloadAction<{ edges: Edge<EdgeData>[] }>) {
      const { edges } = action.payload;
      const edgeIdsToDelete: string[] = edges.map((edge) => edge.id);

      state.documentContainer.edges = _deleteEdges(state.documentContainer.edges, edgeIdsToDelete);
      state.documentContainer = updateTimestamp(state.documentContainer);

      // Save document to API
      saveDocToApiWithDebounce({
        id: state.documentContainer.id,
        owner_id: state.documentContainer.owner_id,
        edges: state.documentContainer.edges,
        permissions: state.documentContainer.permissions,
      });
    },

    setInterfaceIdPrefix(state, action: PayloadAction<{ prefix: string }>) {
      const { prefix } = action.payload;
      state.documentContainer.id_prefix = prefix;
      state.documentContainer = updateTimestamp(state.documentContainer);

      // Save document to API
      saveDocToApiWithDebounce({
        id: state.documentContainer.id,
        owner_id: state.documentContainer.owner_id,
        id_prefix: state.documentContainer.id_prefix,
        permissions: state.documentContainer.permissions,
      });
    },

    setArrowDirection(
      state,
      action: PayloadAction<{ edge: Edge<EdgeData>; arrowDirection: ArrowDirection }>
    ) {
      const { edge, arrowDirection } = action.payload;
      let newEdge: Edge<EdgeData>;

      state.documentContainer.edges = state.documentContainer.edges.map((e) => {
        if (e.id === edge.id) {
          newEdge = updateMarkersAndSwapEdgeDirection(e, arrowDirection);
          return newEdge;
        }
        return e;
      });

      state.documentContainer = updateTimestamp(state.documentContainer);

      // Save document to API
      saveDocToApiWithDebounce({
        id: state.documentContainer.id,
        owner_id: state.documentContainer.owner_id,
        edges: state.documentContainer.edges,
        permissions: state.documentContainer.permissions,
      });
    },

    /*************************
     ******* Documents ********
     *************************/

    setDocumentName(state, action: PayloadAction<{ name: string }>) {
      const { name } = action.payload;
      state.documentContainer.name = name;
      state.documentContainer = updateTimestamp(state.documentContainer);

      // Save document to API
      saveDocToApiWithDebounce({
        id: state.documentContainer.id,
        owner_id: state.documentContainer.owner_id,
        name: state.documentContainer.name,
        permissions: state.documentContainer.permissions,
      });
    },

    setDocumentPermissions(state, action: PayloadAction<{ permissions: DocumentPermissions }>) {
      const { permissions } = action.payload;
      state.documentContainer.permissions = permissions;
      state.documentContainer = updateTimestamp(state.documentContainer);

      console.log("Saving document to API", state.documentContainer.id);
      // Save document to API
      saveDocToApiWithDebounce({
        id: state.documentContainer.id,
        owner_id: state.documentContainer.owner_id,
        permissions: state.documentContainer.permissions,
      });
    },

    setDocument(state, action: PayloadAction<{ document: DocumentContainer }>) {
      const { document } = action.payload;

      // Insert or replace edgeToolbar node
      const newNodes = document.nodes.filter((node) => node.type !== NodeType.EdgeToolbar);
      newNodes.push(initialEdgeToolbarNode);

      const newDocument = {
        ...document,
        nodes: newNodes,
      };

      state.documentContainer = newDocument;
      state.documentContainer = updateTimestamp(state.documentContainer);
    },
  },

  extraReducers: (builder) => {
    builder

      /********************************
       ***** Box diagram reducers *****
       ********************************/
      .addCase(applyNewNodeChanges, boxDiagramReducers.applyNewNodeChanges)
      .addCase(deselectAllNodes, boxDiagramReducers.deselectAllNodes)
      .addCase(replaceAllNodes, boxDiagramReducers.replaceAllNodes)
      .addCase(setAllNodesNotEditing, boxDiagramReducers.setAllNodesNotEditing)
      .addCase(updateNodeData, boxDiagramReducers.updateNodeData)
      .addCase(applyNewEdgeChanges, boxDiagramReducers.applyNewEdgeChanges)
      // .addCase(addNewEdge, boxDiagramReducers.addNewEdge)
      .addCase(updateEdgeData, boxDiagramReducers.updateEdgeData)
      .addCase(replaceAllEdges, boxDiagramReducers.replaceAllEdges)
      .addCase(updateEdgeToolbarPosition, boxDiagramReducers.updateEdgeToolbarPosition)

      /************************
       ***** ICD reducers *****
       ************************/
      .addCase(updateRowData, icdReducers.updateRowData)

      /************************
       ***** DSM reducers *****
       ************************/
      .addCase(moveNodePositionDown, dsmReducers.moveNodePositionDown)
      .addCase(moveNodePositionUp, dsmReducers.moveNodePositionUp)

      /*************************
       **** Legend reducers ****
       ************************/
      .addCase(addConnection, connectionsReducers.addConnection)
      .addCase(removeConnection, connectionsReducers.removeConnection)
      .addCase(updateConnection, connectionsReducers.updateConnection)
      .addCase(setConnections, connectionsReducers.setDocConnections);
  },
});

/**
 * Returns the index where a new edge should be inserted.
 * We insert a new row after the last row with the same source.
 * If there is no row with the same source, we insert the new row at the end.
 */
function getInsertionIndexForEdge(edges: Edge<EdgeData>[], sourceId: string): number {
  const lastIndexWithSameSource = edges.map((edge) => edge.source).lastIndexOf(sourceId);
  return lastIndexWithSameSource === -1 ? edges.length : lastIndexWithSameSource + 1;
}

function getInsertionIndexForFrame(nodes: Node<NodeData>[], sourceId: string): number {
  // Get index of the last node with type frame. Default to index 0 if not found
  const lastIndexWithFrame = nodes.map((node) => node.type).lastIndexOf(NodeType.Frame);
  return lastIndexWithFrame === -1 ? 0 : lastIndexWithFrame + 1;
}

export const {
  // Nodes
  addNode,
  deleteNode,
  setNodeLabel,

  // Edges
  addNewEdge,
  deleteEdges,
  setArrowDirection,
  setConnections,
  setSelectedEdge,

  // Document
  setInterfaceIdPrefix,
  setDocumentName,
  setDocumentPermissions,
  setDocument,
} = documentContainerSlice.actions;

export default documentContainerSlice.reducer;
