import { Edge } from "reactflow";
import { EdgeData } from "../models/BoxDiagram/Edge";
import { useEffect } from "react";

// Extend the global Window interface to include the new event type
declare global {
  interface WindowEventMap {
    closeMenus: CustomEvent<CloseMenusDetail>;
    edgeClicked: CustomEvent<EdgeClickedDetail>;
    reactFlowClicked: CustomEvent<ReactFlowClicked>;
    menuClicked: CustomEvent;
    saveDiagramSnapshot: CustomEvent;
    hasUnsavedChanges: CustomEvent<HasUnsavedChangesEvent>;
  }
}

// Define the structure of the edge data for the custom event
interface EdgeClickedDetail {
  targetId: string;
  sourceId: string;
  edgeData: EdgeData;
}

interface CloseMenusDetail {
  // Id of a node or edge that should not close it's menu
  elementId?: string;
}

interface HasUnsavedChangesEvent {
  hasUnsavedChanges: boolean;
}

// DEPRECATED
// /**
//  * Dispatches a custom event when an edge is clicked.
//  * @param window The global window object.
//  * @param edge The edge object from React Flow that was clicked.
//  */
// export function dispatchEdgeClickedEvent(window: Window, edge: Edge) {
//   // Create a custom event with detailed data about the clicked edge.
//   const edgeClickedEvent = new CustomEvent<EdgeClickedDetail>("edgeClicked", {
//     detail: {
//       targetId: edge.target,
//       sourceId: edge.source,
//       edgeData: edge.data,
//     },
//   });

//   // Dispatch the custom event to the global window object.
//   window.dispatchEvent(edgeClickedEvent);
// }

// /**
//  * Adds an event listener to listen for the custom 'edgeClicked' event.
//  * @param targetNodeId The ID of the node this listener is attached to. We
//  *                     only want to execute the action if the clicked edge's
//  *                     target node ID matches this one.
//  * @param action The function to execute when the event is triggered.
//  *
//  * TODO: Should this function be attached to each individual node? Can this
//  *       be done in a more efficient way?
//  */
// export function addEdgeClickedEventListener(targetNodeId: string, action: () => void) {
//   useEffect(() => {
//     // Function to handle the edge click event.
//     const handleEdgeClick = (event: CustomEvent<EdgeClickedDetail>) => {
//       // Check if the event's target node ID matches the one we're interested in.
//       if (targetNodeId !== event.detail.targetId) return;

//       // Execute the provided action if the IDs match.
//       action();
//     };

//     // Add the event listener to the global window object.
//     window.addEventListener("edgeClicked", handleEdgeClick);

//     // Cleanup function to remove the event listener.
//     return () => {
//       window.removeEventListener("edgeClicked", handleEdgeClick);
//     };
//   }, [targetNodeId, action]);
// }

interface ReactFlowClicked {}

/**
 * Dispatches a custom event when a node, edge, or background is clicked in react flow.
 * @param window The global window object.
 */
export function dispatchReactFlowClicked(window: Window) {
  // Create a custom event with detailed data about the clicked edge.
  const reactFlowClicked = new CustomEvent<ReactFlowClicked>("reactFlowClicked", {});

  // Dispatch the custom event to the global window object.
  window.dispatchEvent(reactFlowClicked);
}

/**
 * Fires when a node, edge, or background is clicked in react flow.
 */
export function addReactFlowClickedListener(action: () => void) {
  useEffect(() => {
    // Function to handle the edge click event.
    const handleEdgeClick = (event: CustomEvent<ReactFlowClicked>) => {
      // Execute the provided action.
      action();
    };

    // Add the event listener to the global window object.
    window.addEventListener("reactFlowClicked", handleEdgeClick);

    // Cleanup function to remove the event listener.
    return () => {
      window.removeEventListener("reactFlowClicked", handleEdgeClick);
    };
  }, [action]);
}

/**
 * Dispatches a custom event when a node, edge, or background is clicked in react flow.
 * @param window The global window object.
 * @param elementId The ID of the node or edge that should NOT close it's menu. All other
 *                 menus will be closed.
 */
export function dispatchCloseMenus(window: Window, elementId?: string) {
  // Create a custom event with detailed data about the clicked edge.
  const reactFlowClicked = new CustomEvent<CloseMenusDetail>("closeMenus", {
    detail: { elementId: elementId },
  });

  // Dispatch the custom event to the global window object.
  window.dispatchEvent(reactFlowClicked);
}

/**
 * Fires when a node, edge, or background is clicked in react flow.
 * @param action The function to execute when the event is triggered.
 * @param id The ID of the node or edge that should NOT close it's menu. The action
 *           will not be executed if the clicked element has this ID.
 */
export function addCloseMenusLister(action: () => void, id?: string) {
  useEffect(() => {
    // Function to handle the edge click event.
    const handleEdgeClick = (event: CustomEvent<CloseMenusDetail>) => {
      console.log("closeMenus event fired");
      // Check if the event's target node ID matches the one we're interested in.
      // If it does, DON'T execute the action.
      if (id && event.detail.elementId && id === event.detail.elementId) {
        return;
      }
      action();
    };

    // Add the event listener to the global window object.
    window.addEventListener("closeMenus", handleEdgeClick);

    // Cleanup function to remove the event listener.
    return () => {
      window.removeEventListener("closeMenus", handleEdgeClick);
    };
  }, [action]);
}

/**
 * Fires when we need to save a snapshot of the diagram for the undo/redo history.
 * @param window The global window object.
 */
export function dispatchSaveDiagramSnapshot(window: Window) {
  // Create a custom event with detailed data about the clicked edge.
  const saveDiagramEvent = new CustomEvent("saveDiagramSnapshot", {});

  // Dispatch the custom event to the global window object.
  window.dispatchEvent(saveDiagramEvent);
}

/**
 * Fires when we need to save a snapshot of the diagram for the undo/redo history.
 * @param action The function to execute when the event is triggered.
 */
export function addSaveDiagramSnapshotListener(action: () => void) {
  useEffect(() => {
    // Function to handle the edge click event.
    const handleEdgeClick = (event: CustomEvent) => {
      // Execute the provided action.
      action();
    };

    // Add the event listener to the global window object.
    window.addEventListener("saveDiagramSnapshot", handleEdgeClick);

    // Cleanup function to remove the event listener.
    return () => {
      window.removeEventListener("saveDiagramSnapshot", handleEdgeClick);
    };
  }, [action]);
}

/**
 * Fires when we need to update whether we have unsaved changes in our document.
 * @param window The global window object.
 */
export function dispatchHasUnsavedChanges(window: Window, hasUnsavedChanges: boolean) {
  // Create a custom event with detailed data about the clicked edge.
  const saveDiagramEvent = new CustomEvent("hasUnsavedChanges", {
    detail: { hasUnsavedChanges: hasUnsavedChanges },
  });

  // Dispatch the custom event to the global window object.
  window.dispatchEvent(saveDiagramEvent);
}

/**
 * Listens for changes to whether we have unsaved changes in the document.
 * @param action The function to execute when the event is triggered.
 */
export function addHasUnsavedChanges(action: (hasChanged: boolean) => void) {
  useEffect(() => {
    // Function to handle the edge click event.
    const handleEdgeClick = (event: CustomEvent<HasUnsavedChangesEvent>) => {
      // Execute the provided action.
      action(event.detail.hasUnsavedChanges);
    };

    // Add the event listener to the global window object.
    window.addEventListener("hasUnsavedChanges", handleEdgeClick);

    // Cleanup function to remove the event listener.
    return () => {
      window.removeEventListener("hasUnsavedChanges", handleEdgeClick);
    };
  }, [action]);
}
