// src/state/reducers/icdReducers.ts
import { PayloadAction, createAction } from "@reduxjs/toolkit";
import { EdgeDataICD } from "src/models/BoxDiagram/Edge";
import { DocumentState, updateTimestamp } from "../slices/documentSlice";
import { saveDocToApiWithDebounce } from "src/utils/autoSave";

export const icdReducers = {
  updateRowData: (
    state: DocumentState,
    action: PayloadAction<{ edgeId: String; data: Partial<EdgeDataICD> }>
  ) => {
    const { edgeId, data } = action.payload;
    const edge = state.documentContainer.edges.find((edge) => edge.id === edgeId);

    if (edge) {
      edge.data.icd = { ...edge.data.icd, ...data };
    }

    updateTimestamp(state.documentContainer);

    // Save document to API
    saveDocToApiWithDebounce({
      id: state.documentContainer.id,
      owner_id: state.documentContainer.owner_id,
      edges: state.documentContainer.edges,
      permissions: state.documentContainer.permissions,
    });
  },
};

export const updateRowData = createAction<{ edgeId: String; data: Partial<EdgeDataICD> }>(
  "icd/updateRowData"
);
