// src/models/Edge.ts

import { colorTypeToName, nameToColorType } from "./Colors";
import { Edge, EdgeMarker, EdgeMarkerType, MarkerType } from "reactflow";
import { SubConnection, defaultLegendItem } from "../Connection";
import { EdgeIdPrefix, SourceHandlePrefix, TargetHandlePrefix } from "src/utils/constants";
import { v4 as uuidv4 } from "uuid";

export enum EdgeType {
  DefaultEdge = "defaultEdge",
  Other = "default", // For testing
}

export type LabelInfo = {
  id: string;
  text: string;
  x: number;
  y: number;
};

export enum EdgePathShape {
  Straight = 0,
  SmoothStep = 1,
  SimpleBezier = 2,
}

export enum EdgePathStyle {
  Line = 0,
  Dashed = 1,
  Dotted = 2,
}

export enum ArrowDirection {
  NoDirection = 0,
  ToTarget = 1,
  ToSource = 2,
  Both = 3,
}

export const DefaultMarker: EdgeMarker = {
  type: MarkerType.ArrowClosed,
  color: "black",
  width: 15,
  height: 15,
};

export const EmptyMarker: EdgeMarker = {
  type: MarkerType.ArrowClosed,
  color: "black",
  width: 0,
  height: 0,
};

export type EdgeData = {
  // Defines appearance of arrow(s)
  arrow_direction: ArrowDirection;
  // Time edge was created
  created_at_ms: number;
  // Array of connections between two components. Used in ICD and DSM. Not used in Box Diagram yet
  connections: SubConnection[];
  // Array of info for labels to display on edge
  labels: LabelInfo[];
  // Line shape of edge
  path_shape: EdgePathShape;
  // Line style of edge
  path_style: EdgePathStyle;
  // (Client-only) Edge has a bidirectional arrows
  is_editing: boolean;
  // (Client-only) Used to remember color while use is selecting a new color
  temp_legend_item: SubConnection;
  // (Client-only) Used to remember path shape while use is selecting a new path shape
  temp_path_shape: EdgePathShape;
  // (Client-only) Used to remember path style while use is selecting a new path style
  temp_path_style: EdgePathStyle;

  // ICD specific fields
  icd: EdgeDataICD;
};

export type EdgeDataICD = {
  description: string;
  responsibility: string;
};

export interface IEdge {
  id: string;
  type: EdgeType;
  data: EdgeData;
  source: string;
  target: string;
  // Must be camelCase bc this is what reactflow expects
  sourceHandle?: string | null;
  // Must be camelCase bc this is what reactflow expects
  targetHandle?: string | null;
  // Must be camelCase bc this is what reactflow expects
  markerStart?: EdgeMarkerType;
  // Must be camelCase bc this is what reactflow expects
  markerEnd?: EdgeMarkerType;
}

export interface IEdgeAPI {
  id: string;
  type: EdgeType;
  data: EdgeData;
  source: string;
  target: string;
  // Must be camelCase bc this is what reactflow expects
  source_handle?: string | null;
  // Must be camelCase bc this is what reactflow expects
  target_handle?: string | null;
  // Must be camelCase bc this is what reactflow expects
  marker_start?: EdgeMarkerType;
  // Must be camelCase bc this is what reactflow expects
  marker_end?: EdgeMarkerType;
}

const baseICDEdgeData: EdgeDataICD = {
  description: "",
  responsibility: "",
};

export const baseEdgeData: EdgeData = {
  arrow_direction: ArrowDirection.ToTarget,
  connections: [defaultLegendItem],
  created_at_ms: Date.now(),
  is_editing: false,
  // label: "Some text",
  labels: [],
  path_shape: EdgePathShape.SmoothStep,
  path_style: EdgePathStyle.Line,
  temp_legend_item: defaultLegendItem,
  temp_path_shape: EdgePathShape.SmoothStep,
  temp_path_style: EdgePathStyle.Line,
  icd: baseICDEdgeData,
};

export interface NewEdgeParams {
  id: string;
  type: EdgeType;
  data?: EdgeData;
  source: string;
  target: string;
  sourceHandle?: string | null;
  targetHandle?: string | null;
  markerStart?: EdgeMarkerType;
  markerEnd?: EdgeMarkerType;
  // (Client-only) Used to set default connection when creating new edge
  default_connection?: SubConnection;
}

export function getEdgeID(): string {
  return `${EdgeIdPrefix}${uuidv4().slice(0, 4).toUpperCase()}`;
}

// This function is used to ensure we create edged consistently
export function createNewEdge(params: NewEdgeParams): IEdge {
  const data = params.data || baseEdgeData;
  if (params.default_connection) {
    data.connections = [params.default_connection];
  }

  return {
    id: params.id,
    type: params.type,
    data: data,
    source: params.source,
    target: params.target,
    sourceHandle: params.sourceHandle,
    targetHandle: params.targetHandle,
    // Markers initially must always be set to something or else reactflow will not render
    // anything when edge is updated in the future.
    markerStart: getMarkerStart(data),
    // If params.data is undefined, then we are creating a new edge and should use the default
    // marker. If params.data is defined, then we are updating an existing edge and should use
    // the existing marker.
    markerEnd: getMarkerEnd(data),
  };
}

// Used to convert API response to something ReactFlow can use
export function jsonToEdge(edgeApi: IEdgeAPI): IEdge {
  // Change color name (string) to color type (ColorType)
  const edgeConnections = edgeApi.data.connections.map((connection) => {
    return { ...connection, color: nameToColorType(connection.color as any) };
  });

  return {
    id: edgeApi.id,
    type: edgeApi.type,
    data: {
      ...edgeApi.data,
      connections: edgeConnections,
      // Use nullish coalescing operator (??) instead of falsy (||) to handle `null` 
      // or `undefined` values but return true for 0
      path_shape: edgeApi.data.path_shape ?? EdgePathShape.SmoothStep,
      path_style: edgeApi.data.path_style ?? EdgePathStyle.Line,

      // Add missing client-only fields
      is_editing: false,
      temp_legend_item: edgeConnections[0],
      temp_path_shape: edgeApi.data.path_shape,
      icd: edgeApi.data.icd || baseICDEdgeData,
    },
    source: edgeApi.source,
    target: edgeApi.target,
    sourceHandle: edgeApi.source_handle,
    targetHandle: edgeApi.target_handle,
    markerStart: edgeApi.marker_start,
    markerEnd: edgeApi.marker_end,
  };
}

// Used to convert ReactFlow edge to something we can send to the API
export function edgeToJson(edge: IEdge): any {
  return {
    id: edge.id,
    type: edge.type as EdgeType,
    data: {
      // Change color type (ColorType) to color name (string)
      ...edge.data,
      connections: edge.data.connections.map((connection) => {
        return {
          ...connection,
          color: colorTypeToName(connection.color),
        };
      }),
    },
    source: edge.source,
    target: edge.target,
    source_handle: edge.sourceHandle,
    target_handle: edge.targetHandle,
    marker_start: edge.markerStart,
    marker_end: edge.markerEnd,
  };
}

function getMarkerStart(edgeData?: EdgeData): EdgeMarker {
  if (!edgeData) {
    return EmptyMarker;
  }

  switch (edgeData.arrow_direction) {
    case ArrowDirection.ToSource:
      return DefaultMarker;
    case ArrowDirection.ToTarget:
      return EmptyMarker;
    case ArrowDirection.Both:
      return DefaultMarker;
    case ArrowDirection.NoDirection:
      return EmptyMarker;
    default:
      return EmptyMarker;
  }
}

function getMarkerEnd(edgeData?: EdgeData): EdgeMarker {
  if (!edgeData) {
    return EmptyMarker;
  }
  switch (edgeData.arrow_direction) {
    case ArrowDirection.ToSource:
      return EmptyMarker;
    case ArrowDirection.ToTarget:
      return DefaultMarker;
    case ArrowDirection.Both:
      return DefaultMarker;
    case ArrowDirection.NoDirection:
      return EmptyMarker;
    default:
      return EmptyMarker;
  }
}

/**
 * This function takes an edge and updates its arrow direction and markers. When we update the arrow direction
 * we also need to swap the source and target nodes and their handles. This is done to ensure other documents update
 * their views correctly.
 */
export function updateMarkersAndSwapEdgeDirection(
  edge: Edge<EdgeData>,
  arrowDirection: ArrowDirection
): Edge<EdgeData> {
  if (arrowDirection === ArrowDirection.NoDirection) {
    return {
      ...edge,
      data: { ...edge.data, arrow_direction: arrowDirection },
      markerStart: EmptyMarker,
      markerEnd: EmptyMarker,
    };
  } else if (arrowDirection === ArrowDirection.ToTarget) {
    return {
      ...edge,
      data: { ...edge.data, arrow_direction: arrowDirection },
      markerStart: EmptyMarker,
      markerEnd: DefaultMarker,
    };
  } else if (arrowDirection === ArrowDirection.ToSource) {
    return {
      ...edge,
      data: { ...edge.data, arrow_direction: arrowDirection },
      markerStart: EmptyMarker,
      markerEnd: DefaultMarker,
      source: edge.target,
      target: edge.source,
      sourceHandle: edge.targetHandle.replace(TargetHandlePrefix, SourceHandlePrefix),
      targetHandle: edge.sourceHandle.replace(SourceHandlePrefix, TargetHandlePrefix),
    };
  } else if (arrowDirection === ArrowDirection.Both) {
    return {
      ...edge,
      data: { ...edge.data, arrow_direction: arrowDirection },
      markerStart: DefaultMarker,
      markerEnd: DefaultMarker,
      source: edge.target,
      target: edge.source,
      sourceHandle: edge.targetHandle.replace(TargetHandlePrefix, SourceHandlePrefix),
      targetHandle: edge.sourceHandle.replace(SourceHandlePrefix, TargetHandlePrefix),
    };
  } else {
    throw new Error(`Invalid arrow direction: ${arrowDirection}`);
  }
}
