import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useRef, useState } from "react";

import { ChevronDown, ChevronUp, Trash2, Plus, Check } from "lucide-react";
import { RootState } from "../../../state/slices";
import { SubConnection } from "../../../models/Connection";
import { MenuItemName } from "../../../state/slices/BoxDiagram/boxDiagramUISlice";
import { Color, ColorType } from "../../../models/BoxDiagram/Colors";
import {
  addConnection,
  removeConnection,
  updateConnection,
} from "src/state/reducers/connectionsReducers";

interface InputTypeComponentProps {
  legendItem: SubConnection;
}

interface ColorSelectorButtonProps {
  legendItem: SubConnection;
}

// Represents the main component to display and manage dropdown options
function ConnectionsMenu() {
  const connections = useSelector(
    (state: RootState) => state.document.documentContainer.connections
  );
  const activeMenuItem = useSelector((state: RootState) => state.boxDiagramUI.activeMenuItem);

  const styles = {
    top: `${0}px`,
    left: `${0 + 70}px`,
  };

  if (activeMenuItem !== MenuItemName.Connections) {
    return null;
  }

  // Handle clicking on the dialog
  function handleOnClick() {
    // Use to notify the color selector to close
    dispatchMenuClicked(window);
  }

  return (
    <div
      onClick={handleOnClick}
      style={styles}
      className="absolute flex flex-col justify-center items-start p-4 pb-4 rounded-lg shadow-lg bg-white"
    >
      {/* Dialog Content */}
      <h1 className="text-xl">Edit connections</h1>

      {/* Render the dropdown options */}
      <div className="mt-4 space-y-2">
        {connections.map((legendItem) => (
          <InputTypeComponent key={legendItem.id} legendItem={legendItem} />
        ))}
      </div>

      {/* Button to add a new option */}
      <AddNewOptionButton />

      {/* Cancel and save buttons */}
      {/* <CancelSaveButtons /> */}
    </div>
  );
}

export function NoStyleButton({
  makeLarge = false,
  action,
}: {
  makeLarge?: boolean;
  action?: () => void;
}) {
  const dimensions = makeLarge ? "h-9 w-9" : "h-7 w-7";
  return (
    <div
      className={
        dimensions +
        " rounded-full border border-gray-500 flex justify-center items-center hover:border-gray-700 hover:bg-gray-100"
      }
      onClick={action}
      style={{ position: "relative", transform: "rotate(-45deg)" }}
    >
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "70%",
          height: "2px",
          backgroundColor: "black",
        }}
      ></div>
    </div>
  );
}

/**
 * Represents a color selector button in each input type.
 */
function ColorSelectorButton({ legendItem }: ColorSelectorButtonProps) {
  const dispatch = useDispatch();
  const [isSelectorVisible, setIsSelectorVisible] = useState(false);

  // Toggle visibility of color selector
  function handleColorButtonClick() {
    setIsSelectorVisible(!isSelectorVisible);
  }

  // Handle color selection
  function handleColorSelect(color: ColorType) {
    dispatch(updateConnection({ id: legendItem.id, data: { color } }));
    setIsSelectorVisible(false);
  }

  return (
    <div>
      <button
        className={`flex justify-center items-center px-1 h-full w-16 space-x-1 border-2 rounded-md hover:bg-gray-50`}
        onClick={handleColorButtonClick}
      >
        {legendItem.color === Color.Black ? (
          <NoStyleButton />
        ) : (
          <div
            className={`w-7 h-7 border border-white rounded-full ${legendItem.color.tailwind.fourHundred}`}
          />
        )}
        {isSelectorVisible ? <ChevronUp size={18} /> : <ChevronDown size={18} />}
      </button>
      {isSelectorVisible && (
        <div className="absolute z-10">
          <ColorSelector selectedLegendItem={legendItem} onSelectColor={handleColorSelect} />
        </div>
      )}
    </div>
  );
}

function DeleteButton({ legendItem }: { legendItem: SubConnection }) {
  const dispatch = useDispatch();
  const connections = useSelector(
    (state: RootState) => state.document.documentContainer.connections
  );

  const [isWiggling, setIsWiggling] = useState(false);

  // User removes an input type option
  function handleDelete() {
    // If there is only one input type, do not allow user to delete
    if (connections.length === 1) {
      setIsWiggling(true);
      setTimeout(() => setIsWiggling(false), 300); // Reset wiggle after 500ms
      return;
    }
    dispatch(removeConnection(legendItem.id));
  }

  // Inline styles for the wiggle animation and color change
  const wiggleAnimation = `
    @keyframes wiggle {
      0%, 100% { transform: rotate(-5deg); }
      50% { transform: rotate(5deg); }
    }
    .wiggle {
      animation: wiggle 0.3s ease;
      color: #b22222; /* Change color to dark red during wiggle */
    }
  `;

  return (
    <>
      <style>{wiggleAnimation}</style>
      <button
        className={`flex items-center h-full pl-1 ${
          isWiggling ? "wiggle" : "text-gray-600 hover:text-slate-500"
        }`}
        onClick={handleDelete}
      >
        <Trash2 size={28} />
      </button>
    </>
  );
}

function InputTypeInputField({ legendItem }: { legendItem: SubConnection }) {
  const dispatch = useDispatch();
  const [inputValue, setInputValue] = useState(legendItem.label);

  const inputRef = useRef<HTMLInputElement>(null);

  function handleLabelChange(e: React.ChangeEvent<HTMLInputElement>) {
    setInputValue(e.currentTarget.value);
    dispatch(updateConnection({ id: legendItem.id, data: { label: e.currentTarget.value } }));
  }

  function handleOnEnterPressed(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key === "Enter") {
      // Remove focus from the input field
      inputRef.current?.blur();
    }
  }

  function handleOnBlur() {
    if (inputValue.trim() === "") {
      dispatch(removeConnection(legendItem.id));
    }
  }

  return (
    <input
      ref={inputRef}
      className="w-52 h-10 pl-4 text-lg border-2 rounded-md hover:bg-slate-50"
      value={inputValue}
      onChange={handleLabelChange}
      onKeyDown={handleOnEnterPressed}
      onBlur={handleOnBlur}
    />
  );
}

function InputTypeComponent({ legendItem }: InputTypeComponentProps) {
  return (
    <div className="flex h-10 space-x-2 justify-center">
      {/* Color selector button */}
      <ColorSelectorButton legendItem={legendItem} />

      {/* Input field for label */}
      <InputTypeInputField legendItem={legendItem} />

      {/* Delete option button */}
      <DeleteButton legendItem={legendItem} />
    </div>
  );
}

interface ColorSelectorProps {
  selectedLegendItem: SubConnection;
  onSelectColor: (color: ColorType) => void;
}

interface ColorButtonProps {
  color: ColorType;
  isSelected: boolean;
  onClick: () => void;
}

// ColorButton component for rendering each color option.
function ColorButton({ color, isSelected, onClick }: ColorButtonProps) {
  const tailwindStyle = `flex justify-center items-center w-9 h-9 rounded-full 
  hover:border-2 hover:shadow-md ${color.tailwind.fourHundred}`;
  return (
    <button className={tailwindStyle} onClick={onClick}>
      {isSelected && <Check size={20} color={"white"} />}
    </button>
  );
}

// ColorSelector component allows users to pick a color from a set of predefined color options.
function ColorSelector({ selectedLegendItem, onSelectColor }: ColorSelectorProps) {
  // Close the color selector when user clicks outside of the color selector
  addMenuClickedListener(() => {
    onSelectColor(selectedLegendItem.color);
  });

  const colors = [
    Color.Gray,
    Color.Blue,
    Color.Purple,
    Color.Red,
    Color.Pink,
    Color.Orange,
    Color.Yellow,
    Color.Green,
  ];
  function isSelectedColor(color: ColorType): boolean {
    return color === selectedLegendItem.color;
  }

  function stopPropagation(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    // Prevent the click from propagating to the parent div so it will
    // not be closed.
    e.stopPropagation();
  }

  // Renders a row of color buttons
  function renderColorRow() {
    return (
      <div className="flex justify-start mt-3 space-x-3">
        {colors.map((color) => (
          <div key={color.tailwind.twoHundred}>
            <ColorButton
              key={color.tailwind.sevenHundred}
              color={color}
              isSelected={isSelectedColor(color)}
              onClick={() => onSelectColor(color)}
            />
          </div>
        ))}
      </div>
    );
  }

  return (
    <div onClick={stopPropagation} className="ml-6 p-6 rounded-lg border shadow-lg bg-white">
      <div className="text-lg font-medium text-gray-600 mb-4">Select a color</div>
      {renderColorRow()}
    </div>
  );
}

function CancelSaveButtons() {
  const dispatch = useDispatch();

  // User cancels changes to the legend
  function handleCancel() {
    // dispatch(showAddItemDialog(false));
  }

  // User saves changes to the legend
  function handleSave() {
    handleCancel();
    // dispatch(setLegend({ legend: legend }));
  }

  return (
    <div className="flex w-full justify-end mt-8 space-x-4">
      <button
        className="w-28 h-12 rounded-md border border-gray-500 hover:bg-gray-50"
        onClick={handleCancel}
      >
        Cancel
      </button>
      <button
        className="w-28 h-12 rounded-md  bg-black hover:bg-gray-800 text-white "
        onClick={handleSave}
      >
        Save
      </button>
    </div>
  );
}

function AddNewOptionButton() {
  const dispatch = useDispatch();

  function addInput() {
    dispatch(addConnection());
  }

  return (
    <button className="my-4 flex items-center space-x-2 text-black" onClick={addInput}>
      <Plus size={22} />
      <span className="text-lg">Add New Option</span>
    </button>
  );
}
function dispatchMenuClicked(window: Window) {
  // Create a custom event with detailed data about the clicked edge.
  const menuClickedEvent = new CustomEvent("menuClicked", {});
  // Dispatch the custom event to the global window object.
  window.dispatchEvent(menuClickedEvent);
}

export function addMenuClickedListener(action: () => void) {
  useEffect(() => {
    // Function to handle the edge click event.
    const handleEdgeClick = (event: CustomEvent) => {
      // Execute the provided action.
      action();
    };

    // Add the event listener to the global window object.
    window.addEventListener("menuClicked", handleEdgeClick);

    // Cleanup function to remove the event listener.
    return () => {
      window.removeEventListener("menuClicked", handleEdgeClick);
    };
  }, [action]);
}

export default ConnectionsMenu;
