import { useLayoutEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { DSMCell } from "../../models/DSMCell";
import DSMCellComponent, { getBackgroundAndSizeStyles } from "./DSMCell/DSMCellComponent";
import { RootState } from "src/state/slices";
import { GridChildComponentProps } from "react-window";
import { MultiGrid, Index } from "react-virtualized";
import { nanoid } from "nanoid";
import { getGridData } from "src/state/selectors/boxDiagramSelectors";
import { useArrowKeys } from "./hooks/useArrowKeys";


// src/components/DSM/DSM.tsx

/**
 * A grid component that displays cells of variable sizes depending on their content and type.
 * It automatically updates its size based on the window size to fill its container.
 */
function MyVirtualizedGrid() {
  const gridRef = useRef<HTMLDivElement>(null);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const gridData = useSelector((state: RootState) => getGridData(state));

  const scrollToEdge = useSelector((state: RootState) => state.dsm.scrollToEdge);
  const nodes = useSelector((state: RootState) => state.document.documentContainer.nodes);
  const scrollRow = nodes.findIndex((node) => node.id === scrollToEdge?.source);
  const scrollColumn = nodes.findIndex((node) => node.id === scrollToEdge?.target);

  const selectedState = useSelector((state: RootState) => state.dsm.selectedState);

  // Handles the arrow key presses to move the selected row or column
  useArrowKeys(selectedState);

  // Effect to update grid size on window resize
  useLayoutEffect(() => {
    // Update the size of the grid based on its parent's dimensions
    function updateSize() {
      if (gridRef.current) {
        setDimensions({
          width: gridRef.current.offsetWidth,
          height: gridRef.current.offsetHeight,
        });
      }
    }
    // Add and remove the resize event listener
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  return (
    <div ref={gridRef} style={{ width: "100%", height: "100%" }}>
      <MultiGrid
        fixedColumnCount={2} // If you have fixed columns like row headers
        fixedRowCount={2} // If you have fixed rows like column headers
        columnWidth={createDynamicColumnWidthGetter(gridData)}
        rowHeight={createDynamicRowHeightGetter(gridData)}
        columnCount={gridData[0].length}
        rowCount={gridData.length}
        width={dimensions.width}
        height={dimensions.height}
        scrollToAlignment={"center"}
        scrollToRow={scrollRow} // Scroll to the 2nd column on mount
        scrollToColumn={scrollColumn} // Scroll to the 2nd column on mount
        overscanRowCount={10}
        overscanColumnCount={10}
        cellRenderer={(props) => Cell({ ...props, data: gridData })}
      />
    </div>
  );
}

interface CellProps extends GridChildComponentProps {
  data: DSMCell[][];
  columnIndex: number;
  rowIndex: number;
  style: React.CSSProperties;
}
/**
 * Represents a single cell within the virtualized grid.
 * It uses the rowIndex and columnIndex to look up the cell's data and render it appropriately.
 */
function Cell({ columnIndex, rowIndex, style, data }: CellProps) {
  // Use rowIndex and columnIndex to access the correct cell data
  const cell = data[rowIndex][columnIndex] as DSMCell;
  return <DSMCellComponent key={nanoid()} data={cell} style={style} />;
}

/**
 * Creates a function that returns the dynamic height for rows based on the cell type.
 * This allows rows to have varying heights within the grid.
 * @param {DSMCell[][]} gridData - The full grid data array.
 * @returns A function that takes an index and returns the height for that row.
 */
function createDynamicRowHeightGetter(gridData: DSMCell[][]) {
  return function getDynamicRowHeight({ index }: Index): number {
    const cellType = gridData[index][0].type;
    const style = getBackgroundAndSizeStyles(cellType, {});
    return parseInt(`${style.height}`, 10);
  };
}

/**
 * Creates a function that returns the dynamic width for columns based on the cell type.
 * This allows columns to have varying widths within the grid.
 * @param {DSMCell[][]} gridData - The full grid data array.
 * @returns A function that takes an index and returns the width for that column.
 */
function createDynamicColumnWidthGetter(gridData: DSMCell[][]) {
  return function getDynamicColumnWidth({ index }: Index): number {
    const cellType = gridData[0][index].type;
    const style = getBackgroundAndSizeStyles(cellType, {});
    return parseInt(`${style.width}`, 10);
  };
}

export default MyVirtualizedGrid;
