import { MonitorCheck, Smartphone } from "lucide-react";
import { useIsMobile } from "../Hooks/useIsMobile";

function MobileBlockPopup() {
  const { isMobile } = useIsMobile();

  if (!isMobile) return null; // Don't render the dialog if it is not visible
  return (
    // The overlay with an opaque background
    <div className="fixed inset-0 z-50 bg-black bg-opacity-50 flex justify-center items-center">
      <div className="flex flex-col justify-center items-center space-y-4 w-full mx-4 p-6 sm:mx-0 sm:max-w-xl h-auto text-center bg-white rounded-lg shadow-lg  z-50">
        <MonitorCheck size={40} />
        <div className="text-3xl">Desktop first for now</div>
        <div className="text-lg text-gray-700">
          Sinkly works best on a desktop. We're still working on the mobile version to make it just
          right. For now, please use a desktop to access all the features without any limits.
        </div>
      </div>
    </div>
  );
}
export default MobileBlockPopup;
