import {
  AppWindowMac,
  Code2,
  Compass,
  DraftingCompass,
  GraduationCap,
  Laugh,
  LibraryBig,
  PencilLine,
  SmilePlus,
  Sparkle,
  Sparkles,
} from "lucide-react";
import { Color } from "src/models/BoxDiagram/Colors";
import { Footer, HorizontalDivider, Toolbar, useScrollToTop } from "./LandingPage";
import InfiniteTextRotator from "./components/InfiniteTextRotator";
import { useScreenSize } from "../BlockDiagram/Hooks/useIsMobile";

const languages_section = {
  title: "LANGUAGES",
  icon: <Code2 size={24} color={Color.Neutral.css.eightHundred} />,
  content: [
    "Python +7 yrs",
    "JavaScript +4 yrs",
    "TypeScript +4 yrs",
    "NodeJS +3 yrs",
    "HTML/CSS +3 yrs",
    "Swift/SwiftUI +3 yrs",
    "ReactJS +2 yrs",
    // "Swift +2 yrs",
    "React Native +1 yr",
    "SQL +1 yr",
    "C++/C +1 yr",
  ],
};

const tools_section = {
  title: "TOOLS",
  icon: <AppWindowMac size={24} color={Color.Neutral.css.eightHundred} />,
  content: [
    "AWS",
    "Google Cloud",
    "Stripe",
    "Vercel",
    "Twillio",
    "Send Grid",
    "Full story",
    "Axiom",
    "Xcode",
    "Grafana",
    "Kibana",
    "Git",
    "VS Code",
    "Protobuf",
    // "Agile",
    // "Docker",
    // "Kubernetes",
    "Redis",
    // "Jira",
    // "Pager Duty",
    // "Bubble (proxy tool)",
    "AB Testing / Experimentation",
  ],
};

const leadership_section = {
  title: "LEADERSHIP SKILLS",
  icon: <Compass size={24} color={Color.Neutral.css.eightHundred} />,
  content: ["Mentor & Educator", "Interviewer", "Decision Maker", "Onboarding"],
};

const product_section = {
  title: "PRODUCT SKILLS",
  icon: <DraftingCompass size={24} color={Color.Neutral.css.eightHundred} />,
  content: [
    "Web Design ",
    "iOS Development",
    "End-to-end Product Design",
    "User Research",
    "Wire Framing",
    "Quality Assurance & Testing",
    "Customer Interviews",
    "Android Development",
  ],
};

const resume_sections = [
  {
    position: "Software Engineer",
    company: "Lyft",
    date: "Aug 2021 - Now",
    content: [
      "Designed and built a large scale payment system responsible for +$42M in annual payouts",
      "Designed and built the California Electric Vehicle Ride Challenge boosting Lyft's EV adoption by +20% in one year",
      "Enabled Bikes & Scooter referrals to be created and sent across all Lyft-owned apps (Citibike, Divvy, Bay Wheels, etc.)",
      "Designed and built the 'Bonus Zone Boost' product allowing drivers to earn multipliers in high demand areas, increasing retention and preference for Lyft",
      "And, a lot more",
    ],
  },
  {
    position: "Founder",
    company: "Sinkly",
    date: "Nov 2023 - Feb 2024",
    content: [
      "Created design tool enabling hardware engineers to automatically generate requirement documents and keep them synchronized",
      "Performed all user research, interviews, market research, and competitive analysis to understand landscape",
      "Built and designed the web application from scratch to be compatible with all major browsers and devices",
      // "Performed user research and interviews to understand customer needs and pain points",
      // "Performed all market research of the competitive landscape and identify opportunities",
    ],
  },
  {
    position: "Resident",
    company: "Antler VC",
    date: "Aug 2023 - Nov 2023",
    content: [
      "Worked with the Director of Growth at a major tech company to build a service solving microdelivery logistics in New York City",
      "Worked directly with Venture Capital partners and other founders build the MVP, acquire customer feedback, and iterate",
      // "Built MVP and tested with 20+ customers",
    ],
  },
  {
    position: "Founder",
    company: "Relevate",
    date: "Nov 2022 - Oct 2023",
    content: [
      "Designed, built, and launched an iOS furniture marketplace app for luxury NYC apartments",
      "Successfully launched in two buildings and grew to 100+ weekly active users",
    ],
  },
  {
    position: "Cofounder",
    company: "CMD Monitors",
    date: "Apr 2021 - Oct 2021",
    content: [
      "Led technical development of a system to monitor the inventory of large retailers (Walmart, Target, etc.) and notify clients when elusive items came in stock",
      "Generated +$2,000 MRR with 15 clients",
    ],
  },
];

function Resume() {
  useScrollToTop();
  return (
    <div className="flex flex-col w-full min-h-screen">
      <Toolbar />
      <HeroSection />
      <ResumeContainer />
      <Footer resume />
    </div>
  );
}

function HeroSection() {
  return (
    <div className="flex items-center justify-center mt-12 md:mt-16 pb-10">
      <Hero />
    </div>
  );
}

function Hero() {
  return (
    <div className="flex flex-col justify-center w-full space-y-10 md:px-0 text-neutral-700">
      <Header />
      <InfiniteTextRotator />
    </div>
  );
}

function Header() {
  return <div className="text-7xl sm:text-8xl lg:text-9xl text-center font-extrabold">Resume</div>;
}

function ResumeContainer() {
  const { lgOrLarger } = useScreenSize();
  const outsidePadding = lgOrLarger ? "80px" : "40px";
  const insidePadding = lgOrLarger ? "50px 50px 50px 50px" : "30px";

  return (
    <div
      style={{ borderTopLeftRadius: outsidePadding, borderTopRightRadius: outsidePadding }}
      className="flex flex-col items-center justify-center mt-4 px-4 text-neutral-100 bg-neutral-700 rounded-t-3xl"
    >
      <div
        style={{ borderRadius: insidePadding }}
        className="flex flex-col max-w-screen-lg py-16 my-10 bg-neutral-100 "
      >
        <div className="flex w-full px-5 ">
          {/* Left */}

          {lgOrLarger && (
            <div className="w-2/5 ">
              <div className="flex flex-col justify-end w-full px-5 pb-10 ">
                <LeftResumeSectionSplit data={languages_section} />
                <div className="py-8">
                  <HorizontalDivider />
                </div>
                <LeftResumeSectionSplit data={product_section} />
                <div className="py-8">
                  <HorizontalDivider />
                </div>
                <LeftResumeSectionSplit data={leadership_section} />
                <div className="py-8">
                  <HorizontalDivider />
                </div>
                <LeftResumeSectionSplit data={tools_section} />
                <div className="py-8">
                  <HorizontalDivider />
                </div>
                <LeftResumeEducation />
              </div>
            </div>
          )}

          {/* Right */}
          <div className="w-full lg:w-3/5 px-6 lg:px-0 text-neutral-800 ">
            {/* <div className="mb-6 text-sm font-medium">
              <LeftResumeTitleNoData />
            </div> */}
            <div className="flex flex-col justify-start space-y-6">
              {resume_sections.map((section, index) => (
                <RightResumeSection key={index} data={section} />
              ))}
            </div>
          </div>
        </div>
        <ContactInfo />
      </div>
    </div>
  );
}

function ContactInfo() {
  function navToLetterOfRec() {
    console.log("Get started clicked");

    window.open(
      "https://docs.google.com/document/d/1chJzVW2wa2_FiTFaauz8sV-D-S-g0Iss1ALHWQvSzB4/edit",
      "_blank"
    );
  }

  return (
    <div className="flex flex-col items-center justify-center w-full pt-8 text-neutral-800">
      <HorizontalDivider dark />
      <div className="flex flex-col items-center justify-center space-y-6 font-bold text-neutral-500 mt-8 px-6">
        <div className="flex w-full items-center justify-center">
          <Laugh size={40} color={Color.Neutral.css.eightHundred} />
        </div>
        <button onClick={navToLetterOfRec} className="text-center font-medium hover:underline">
          "Darrel displayed exceptional adaptability when the team’s product manager went on leave,
          taking on multiple roles including Product Manager, Engineer, and resource advocate. His
          product-centric engineering approach facilitated decision-making for the MVP, ensuring the
          team stayed unblocked. He proactively scoped an alternative fallback solution, addressed
          the complexities and risks of the cross-service orchestration, and bolstered
          cross-functional team confidence in meeting the deadline."
        </button>

        <div className="mt-4 text-center text-neutral-800 text-xl font-medium">
          <div>Paul Schultz</div>
          <div>Engineering Manager, Lyft</div>
        </div>

        {/* <div className="flex space-x-4">
          <div>darrelmuonekwu@gmail.com</div>
          <div>949.345.0330</div>
        </div> */}
      </div>
    </div>
  );
}

function LeftResumeEducation() {
  const data = {
    title: "EDUCATION",
    icon: <GraduationCap size={24} color={Color.Neutral.css.eightHundred} />,
  };

  return (
    <div className="flex flex-col justify-end w-full text-sm font-medium">
      <LeftResumeTitle data={data} />
      <div className="mt-6 text-2xl font-normal text-neutral-500">
        University of California, Irvine
      </div>
    </div>
  );
}

function LeftResumeSectionSplit({ data }: any) {
  return (
    <div className="flex flex-col justify-end w-full text-sm font-medium">
      <LeftResumeTitle data={data} />
      <LeftResumeLanguages data={data} />
    </div>
  );
}

function LeftResumeTitle({ data }: any) {
  console.log(data);
  return (
    <div className="flex justify-start items-center w-full space-x-4">
      {data.icon}
      <div className="text-neutral-800">{data.title}</div>
    </div>
  );
}

function LeftResumeLanguages({ data }: any) {
  const split = Math.floor(data.content.length / 2);
  const left = data.content.slice(0, split);
  const right = data.content.slice(split);
  return (
    <div className="flex flex-row w-full mt-6 text-neutral-500">
      <div className="flex flex-col justify-start items-start space-y-4 w-1/2">
        {left.map((content: string, index: number) => (
          <div key={index}>{content}</div>
        ))}
      </div>
      <div className="flex flex-col justify-start items-start space-y-4 w-1/2 ml-4">
        {right.map((content: string, index: number) => (
          <div key={index}>{content}</div>
        ))}
      </div>
    </div>
  );
}

function RightResumeSection({ data }: { data: any }) {
  return (
    <div className="flex flex-col space-y-4">
      <RightResumePosition data={data} />
      <RightResumeJobName data={data} />
      <RightResumeContent data={data} />
    </div>
  );
}

function RightResumeJobName({ data }: any) {
  return <div className="text-2xl ">{data.company}</div>;
}

function RightResumePosition({ data }: any) {
  return (
    <div className="flex space-x-4 w-fit p-2 px-4 bg-neutral-200 rounded-lg text-neutral-500">
      <div className="">{data.position}</div>
      <div className="">|</div>
      <div className="">{data.date}</div>
    </div>
  );
}

function RightResumeContent({ data }: any) {
  return (
    <div className="w-full text-neutral-500">
      <ul className="list-disc list-inside space-y-4">
        {data.content.map((content: string, index: number) => (
          <li key={index}>{content}</li>
        ))}
      </ul>
    </div>
  );
}

export default Resume;
