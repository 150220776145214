type ColorShades = {
  fifty: string;
  oneHundred: string;
  twoHundred: string;
  threeHundred: string;
  fourHundred: string;
  fiveHundred: string;
  sixHundred: string;
  sevenHundred: string;
  eightHundred: string;
  nineHundred: string;
  nineHundredFifty: string;
};

export type ColorType = {
  tailwind: ColorShades;
  css: ColorShades;
};

type AllColors = {
  [colorName: string]: ColorType;
};

export function getColorFromString(colorString: string): ColorType | undefined {
  for (const colorName in Color) {
    const colorStyle = Color[colorName];
    if (
      Object.values(colorStyle.tailwind).includes(colorString) ||
      Object.values(colorStyle.css).includes(colorString)
    ) {
      return colorStyle;
    }
  }
  return undefined; // return undefined if no match is found
}

export enum ColorName {
  Black = "black",
  White = "white",
  Slate = "slate",
  Gray = "gray",
  Neutral = "neutral",
  Red = "red",
  Orange = "orange",
  Yellow = "yellow",
  Lime = "lime",
  Green = "green",
  Cyan = "cyan",
  Blue = "blue",
  Indigo = "indigo",
  Purple = "purple",
  Pink = "pink",
}

/*
 * We represent colors as a string in the database. This function is used to send
 * the color to the server.
 */
export function colorTypeToName(color: ColorType): ColorName {
  switch (color.tailwind.fifty) {
    case Color.Black.tailwind.fifty:
      return ColorName.Black;
    case Color.White.tailwind.fifty:
      return ColorName.White;
    case Color.Slate.tailwind.fifty:
      return ColorName.Slate;
    case Color.Gray.tailwind.fifty:
      return ColorName.Gray;
    case Color.Neutral.tailwind.fifty:
      return ColorName.Neutral;
    case Color.Red.tailwind.fifty:
      return ColorName.Red;
    case Color.Orange.tailwind.fifty:
      return ColorName.Orange;
    case Color.Yellow.tailwind.fifty:
      return ColorName.Yellow;
    case Color.Lime.tailwind.fifty:
      return ColorName.Lime;
    case Color.Green.tailwind.fifty:
      return ColorName.Green;
    case Color.Cyan.tailwind.fifty:
      return ColorName.Cyan;
    case Color.Blue.tailwind.fifty:
      return ColorName.Blue;
    case Color.Indigo.tailwind.fifty:
      return ColorName.Indigo;
    case Color.Purple.tailwind.fifty:
      return ColorName.Purple;
    case Color.Pink.tailwind.fifty:
      return ColorName.Pink;
    default:
      throw new Error(`Unknown color ${color.tailwind.eightHundred}`);
  }
}

export function nameToColorType(colorName: ColorName): ColorType {
  switch (colorName) {
    case ColorName.Black:
      return Color.Black;
    case ColorName.White:
      return Color.White;
    case ColorName.Slate:
      return Color.Slate;
    case ColorName.Gray:
      return Color.Gray;
    case ColorName.Neutral:
      return Color.Neutral;
    case ColorName.Red:
      return Color.Red;
    case ColorName.Orange:
      return Color.Orange;
    case ColorName.Yellow:
      return Color.Yellow;
    case ColorName.Lime:
      return Color.Lime;
    case ColorName.Green:
      return Color.Green;
    case ColorName.Cyan:
      return Color.Cyan;
    case ColorName.Blue:
      return Color.Blue;
    case ColorName.Indigo:
      return Color.Indigo;
    case ColorName.Purple:
      return Color.Purple;
    case ColorName.Pink:
      return Color.Pink;
  }
}

/*
 * Included colors are:
 * Slate, Gray, Neutral, Red, Orange, Yellow,
 * Lime, Green, Cyan, Blue, Purple, Pink
 *
 * Black, White
 */

export const Color: AllColors = {
  Black: {
    tailwind: {
      fifty: "bg-black",
      oneHundred: "bg-black",
      twoHundred: "bg-black",
      threeHundred: "bg-black",
      fourHundred: "bg-black",
      fiveHundred: "bg-black",
      sixHundred: "bg-black",
      sevenHundred: "bg-black",
      eightHundred: "bg-black",
      nineHundred: "bg-black",
      nineHundredFifty: "bg-black",
    },
    css: {
      fifty: "#000000",
      oneHundred: "#000000",
      twoHundred: "#000000",
      threeHundred: "#000000",
      fourHundred: "#000000",
      fiveHundred: "#000000",
      sixHundred: "#000000",
      sevenHundred: "#000000",
      eightHundred: "#000000",
      nineHundred: "#000000",
      nineHundredFifty: "#000000",
    },
  },
  White: {
    tailwind: {
      fifty: "bg-white",
      oneHundred: "bg-white",
      twoHundred: "bg-white",
      threeHundred: "bg-white",
      fourHundred: "bg-white",
      fiveHundred: "bg-white",
      sixHundred: "bg-white",
      sevenHundred: "bg-white",
      eightHundred: "bg-white",
      nineHundred: "bg-white",
      nineHundredFifty: "bg-white",
    },
    css: {
      fifty: "#FFFFFF",
      oneHundred: "#FFFFFF",
      twoHundred: "#FFFFFF",
      threeHundred: "#FFFFFF",
      fourHundred: "#FFFFFF",
      fiveHundred: "#FFFFFF",
      sixHundred: "#FFFFFF",
      sevenHundred: "#FFFFFF",
      eightHundred: "#FFFFFF",
      nineHundred: "#FFFFFF",
      nineHundredFifty: "#FFFFFF",
    },
  },
  Slate: {
    tailwind: {
      fifty: "bg-slate-50:",
      oneHundred: "bg-slate-100",
      twoHundred: "bg-slate-200",
      threeHundred: "bg-slate-300",
      fourHundred: "bg-slate-400",
      fiveHundred: "bg-slate-500",
      sixHundred: "bg-slate-600",
      sevenHundred: "bg-slate-700",
      eightHundred: "bg-slate-800",
      nineHundred: "bg-slate-900",
      nineHundredFifty: "bg-slate-950",
    },
    css: {
      fifty: "#f8fafc",
      oneHundred: "#f1f5f9",
      twoHundred: "#e2e8f0",
      threeHundred: "#cbd5e1",
      fourHundred: "#94a3b8",
      fiveHundred: "#64748b",
      sixHundred: "#475569",
      sevenHundred: "#334155",
      eightHundred: "#1e293b",
      nineHundred: "#0f172a",
      nineHundredFifty: "#020617",
    },
  },
  Gray: {
    tailwind: {
      fifty: "bg-gray-50",
      oneHundred: "bg-gray-100",
      twoHundred: "bg-gray-200",
      threeHundred: "bg-gray-300",
      fourHundred: "bg-gray-400",
      fiveHundred: "bg-gray-500",
      sixHundred: "bg-gray-600",
      sevenHundred: "bg-gray-700",
      eightHundred: "bg-gray-800",
      nineHundred: "bg-gray-900",
      nineHundredFifty: "bg-gray-950",
    },
    css: {
      fifty: "#f9fafb",
      oneHundred: "#f3f4f6",
      twoHundred: "#e5e7eb",
      threeHundred: "#d1d5db",
      fourHundred: "#9ca3af",
      fiveHundred: "#6b7280",
      sixHundred: "#4b5563",
      sevenHundred: "#374151",
      eightHundred: "#1f2937",
      nineHundred: "#111827",
      nineHundredFifty: "#030712",
    },
  },
  Neutral: {
    tailwind: {
      fifty: "bg-neutral-50",
      oneHundred: "bg-neutral-100",
      twoHundred: "bg-neutral-200",
      threeHundred: "bg-neutral-300",
      fourHundred: "bg-neutral-400",
      fiveHundred: "bg-neutral-500",
      sixHundred: "bg-neutral-600",
      sevenHundred: "bg-neutral-700",
      eightHundred: "bg-neutral-800",
      nineHundred: "bg-neutral-900",
      nineHundredFifty: "bg-neutral-950",
    },
    css: {
      fifty: "#fafafa",
      oneHundred: "#f5f5f5",
      twoHundred: "#e5e5e5",
      threeHundred: "#d4d4d4",
      fourHundred: "#a3a3a3",
      fiveHundred: "#737373",
      sixHundred: "#525252",
      sevenHundred: "#404040",
      eightHundred: "#262626",
      nineHundred: "#171717",
      nineHundredFifty: "#0a0a0a",
    },
  },
  Red: {
    tailwind: {
      fifty: "bg-red-50",
      oneHundred: "bg-red-100",
      twoHundred: "bg-red-200",
      threeHundred: "bg-red-300",
      fourHundred: "bg-red-400",
      fiveHundred: "bg-red-500",
      sixHundred: "bg-red-600",
      sevenHundred: "bg-red-700",
      eightHundred: "bg-red-800",
      nineHundred: "bg-red-900",
      nineHundredFifty: "bg-red-950",
    },
    css: {
      fifty: "#fef2f2",
      oneHundred: "#fee2e2",
      twoHundred: "#fecaca",
      threeHundred: "#fca5a5",
      fourHundred: "#f87171",
      fiveHundred: "#ef4444",
      sixHundred: "#dc2626",
      sevenHundred: "#b91c1c",
      eightHundred: "#991b1b",
      nineHundred: "#7f1d1d",
      nineHundredFifty: "#450a0a",
    },
  },
  Orange: {
    tailwind: {
      fifty: "bg-orange-50",
      oneHundred: "bg-orange-100",
      twoHundred: "bg-orange-200",
      threeHundred: "bg-orange-300",
      fourHundred: "bg-orange-400",
      fiveHundred: "bg-orange-500",
      sixHundred: "bg-orange-600",
      sevenHundred: "bg-orange-700",
      eightHundred: "bg-orange-800",
      nineHundred: "bg-orange-900",
      nineHundredFifty: "bg-orange-950",
    },
    css: {
      fifty: "#fff7ed",
      oneHundred: "#ffedd5",
      twoHundred: "#fed7aa",
      threeHundred: "#fdba74",
      fourHundred: "#fb923c",
      fiveHundred: "#f97316",
      sixHundred: "#ea580c",
      sevenHundred: "#c2410c",
      eightHundred: "#9a3412",
      nineHundred: "#7c2d12",
      nineHundredFifty: "#431407",
    },
  },
  Yellow: {
    tailwind: {
      fifty: "bg-yellow-50",
      oneHundred: "bg-yellow-100",
      twoHundred: "bg-yellow-200",
      threeHundred: "bg-yellow-300",
      fourHundred: "bg-yellow-400",
      fiveHundred: "bg-yellow-500",
      sixHundred: "bg-yellow-600",
      sevenHundred: "bg-yellow-700",
      eightHundred: "bg-yellow-800",
      nineHundred: "bg-yellow-900",
      nineHundredFifty: "bg-yellow-950",
    },
    css: {
      fifty: "#fefce8",
      oneHundred: "#fef9c3",
      twoHundred: "#fef08a",
      threeHundred: "#fde047",
      fourHundred: "#facc15",
      fiveHundred: "#eab308",
      sixHundred: "#ca8a04",
      sevenHundred: "#a16207",
      eightHundred: "#854d0e",
      nineHundred: "#713f12",
      nineHundredFifty: "#422006",
    },
  },
  Lime: {
    tailwind: {
      fifty: "bg-lime-50",
      oneHundred: "bg-lime-100",
      twoHundred: "bg-lime-200",
      threeHundred: "bg-lime-300",
      fourHundred: "bg-lime-400",
      fiveHundred: "bg-lime-500",
      sixHundred: "bg-lime-600",
      sevenHundred: "bg-lime-700",
      eightHundred: "bg-lime-800",
      nineHundred: "bg-lime-900",
      nineHundredFifty: "bg-lime-950",
    },
    css: {
      fifty: "#f7fee7",
      oneHundred: "#ecfccb",
      twoHundred: "#d9f99d",
      threeHundred: "#bef264",
      fourHundred: "#a3e635",
      fiveHundred: "#84cc16",
      sixHundred: "#65a30d",
      sevenHundred: "#4d7c0f",
      eightHundred: "#3f6212",
      nineHundred: "#365314",
      nineHundredFifty: "#1a2e05",
    },
  },
  Green: {
    tailwind: {
      fifty: "bg-green-50",
      oneHundred: "bg-green-100",
      twoHundred: "bg-green-200",
      threeHundred: "bg-green-300",
      fourHundred: "bg-green-400",
      fiveHundred: "bg-green-500",
      sixHundred: "bg-green-600",
      sevenHundred: "bg-green-700",
      eightHundred: "bg-green-800",
      nineHundred: "bg-green-900",
      nineHundredFifty: "bg-green-950",
    },
    css: {
      fifty: "f0fdf4",
      oneHundred: "#dcfce7",
      twoHundred: "#bbf7d0",
      threeHundred: "#86efac",
      fourHundred: "#4ade80",
      fiveHundred: "#22c55e",
      sixHundred: "#16a34a",
      sevenHundred: "#15803d",
      eightHundred: "#166534",
      nineHundred: "#14532d",
      nineHundredFifty: "#052e16",
    },
  },
  Cyan: {
    tailwind: {
      fifty: "bg-cyan-50",
      oneHundred: "bg-cyan-100",
      twoHundred: "bg-cyan-200",
      threeHundred: "bg-cyan-300",
      fourHundred: "bg-cyan-400",
      fiveHundred: "bg-cyan-500",
      sixHundred: "bg-cyan-600",
      sevenHundred: "bg-cyan-700",
      eightHundred: "bg-cyan-800",
      nineHundred: "bg-cyan-900",
      nineHundredFifty: "bg-cyan-950",
    },
    css: {
      fifty: "#3ecfeff",
      oneHundred: "#cffafe",
      twoHundred: "#a5f3fc",
      threeHundred: "#67e8f9",
      fourHundred: "#22d3ee",
      fiveHundred: "#06b6d4",
      sixHundred: "#0891b2",
      sevenHundred: "#0e7490",
      eightHundred: "#155e75",
      nineHundred: "#164e63",
      nineHundredFifty: "#083344",
    },
  },
  Blue: {
    tailwind: {
      fifty: "bg-blue-50",
      oneHundred: "bg-blue-100",
      twoHundred: "bg-blue-200",
      threeHundred: "bg-blue-300",
      fourHundred: "bg-blue-400",
      fiveHundred: "bg-blue-500",
      sixHundred: "bg-blue-600",
      sevenHundred: "bg-blue-700",
      eightHundred: "bg-blue-800",
      nineHundred: "bg-blue-900",
      nineHundredFifty: "bg-blue-950",
    },
    css: {
      fifty: "#eff6ff",
      oneHundred: "#dbeafe",
      twoHundred: "#bfdbfe",
      threeHundred: "#93c5fd",
      fourHundred: "#60a5fa",
      fiveHundred: "#3b82f6",
      sixHundred: "#2563eb",
      sevenHundred: "#1d4ed8",
      eightHundred: "#1e40af",
      nineHundred: "#1e3a8a",
      nineHundredFifty: "#172554",
    },
  },
  Indigo: {
    tailwind: {
      fifty: "bg-indigo-50",
      oneHundred: "bg-indigo-100",
      twoHundred: "bg-indigo-200",
      threeHundred: "bg-indigo-300",
      fourHundred: "bg-indigo-400",
      fiveHundred: "bg-indigo-500",
      sixHundred: "bg-indigo-600",
      sevenHundred: "bg-indigo-700",
      eightHundred: "bg-indigo-800",
      nineHundred: "bg-indigo-900",
      nineHundredFifty: "bg-indigo-950",
    },
    css: {
      fifty: "#eef2ff",
      oneHundred: "#e0e7ff",
      twoHundred: "#c7d2fe",
      threeHundred: "#a5b4fc",
      fourHundred: "#818cf8",
      fiveHundred: "#6366f1",
      sixHundred: "#4f46e5",
      sevenHundred: "#4338ca",
      eightHundred: "#3730a3",
      nineHundred: "#312e81",
      nineHundredFifty: "#1e1b4b",
    },
  },
  Purple: {
    tailwind: {
      fifty: "bg-purple-50",
      oneHundred: "bg-purple-100",
      twoHundred: "bg-purple-200",
      threeHundred: "bg-purple-300",
      fourHundred: "bg-purple-400",
      fiveHundred: "bg-purple-500",
      sixHundred: "bg-purple-600",
      sevenHundred: "bg-purple-700",
      eightHundred: "bg-purple-800",
      nineHundred: "bg-purple-900",
      nineHundredFifty: "bg-purple-950",
    },
    css: {
      fifty: "#faf5ff",
      oneHundred: "#f3e8ff",
      twoHundred: "#e9d5ff",
      threeHundred: "#d8b4fe",
      fourHundred: "#c084fc",
      fiveHundred: "#a855f7",
      sixHundred: "#9333ea",
      sevenHundred: "#7e22ce",
      eightHundred: "#6b21a8",
      nineHundred: "#581c87",
      nineHundredFifty: "#3b0764",
    },
  },
  Pink: {
    tailwind: {
      fifty: "bg-pink-50",
      oneHundred: "bg-pink-100",
      twoHundred: "bg-pink-200",
      threeHundred: "bg-pink-300",
      fourHundred: "bg-pink-400",
      fiveHundred: "bg-pink-500",
      sixHundred: "bg-pink-600",
      sevenHundred: "bg-pink-700",
      eightHundred: "bg-pink-800",
      nineHundred: "bg-pink-900",
      nineHundredFifty: "bg-pink-950",
    },
    css: {
      fifty: "#fdf2f8",
      oneHundred: "#fce7f3",
      twoHundred: "#fbcfe8",
      threeHundred: "#f9a8d4",
      fourHundred: "#f472b6",
      fiveHundred: "#ec4899",
      sixHundred: "#db2777",
      sevenHundred: "#be185d",
      eightHundred: "#9d174d",
      nineHundred: "#831843",
      nineHundredFifty: "#500724",
    },
  },
};
