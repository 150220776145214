// src/slices/index.ts

import { combineReducers } from "@reduxjs/toolkit";
import userSlice, { UserState } from "./userSlice";
import sideNavigationBarSlice, { SideNavigationBarState } from "./sidebarSlice";
import dsmSlice, { DSMState } from "./dsmSlice";

import uiSlice, { UIState } from "./uiSlice";
import boxDiagramUISlice, { BoxDiagramUIState } from "./BoxDiagram/boxDiagramUISlice";
import icdSlice, { ICDState } from "./icdSlice";
import documentSlice, { DocumentState } from "./documentSlice";

const rootReducer = combineReducers({
  user: userSlice,
  sideNavigationBar: sideNavigationBarSlice,
  dsm: dsmSlice,
  ui: uiSlice,
  boxDiagramUI: boxDiagramUISlice,
  icd: icdSlice,
  document: documentSlice,
});

export type RootState = {
  user: UserState;
  sideNavigationBar: SideNavigationBarState;
  dsm: DSMState;
  ui: UIState;
  boxDiagramUI: BoxDiagramUIState;
  icd: ICDState;
  document: DocumentState;
};

export type Pledge = {
  date: string;
  name: string;
  amount: number;
};

export default rootReducer;
