import { useEffect, useRef } from "react";
import { Connection, Edge } from "reactflow";
import { EdgeData } from "src/models/BoxDiagram/Edge";
import useOutsideClick from "src/components/LandingPage/hooks/useOutsideClick";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/state/slices";
import { calculateOptimalHandles } from "src/utils/boxDiagramUtils";
import { getEdgeBySourceAndTarget, getNodeById } from "src/state/selectors/boxDiagramSelectors";
import { addNewEdge, deleteEdges } from "src/state/slices/documentSlice";
import { setSelectedState } from "src/state/slices/dsmSlice";

interface DSMConnectionPickerProps {
  offset: { x: number; y: number };
  source: string;
  target: string;
  setShowEditConnections: (showEditConnections: boolean) => void;
}
export function DSMConnectionPicker({
  offset,
  source,
  target,
  setShowEditConnections,
}: DSMConnectionPickerProps) {
  const dispatch = useDispatch();
  const containerRef = useRef<HTMLDivElement>(null);
  // Ref to keep track if the edge was created by this component
  const createdEdgeRef = useRef<boolean>(false);

  const edge: Edge<EdgeData> | undefined = useSelector((state: RootState) =>
    getEdgeBySourceAndTarget(state.document.documentContainer.edges, source, target)
  );
  const sourceNode = useSelector((state: RootState) =>
    getNodeById(state.document.documentContainer.nodes, source)
  );
  const targetNode = useSelector((state: RootState) =>
    getNodeById(state.document.documentContainer.nodes, target)
  );

  // If edge does not exist, create a new edge
  useEffect(() => {
    if (!edge) {
      const { sourceHandle, targetHandle } = calculateOptimalHandles(sourceNode, targetNode);
      const rfConnection: Connection = {
        source: source,
        target: target,
        sourceHandle: sourceHandle,
        targetHandle: targetHandle,
      };
      dispatch(addNewEdge(rfConnection));
      createdEdgeRef.current = true; // Set the flag to true since we've created an edge
    }

    return () => {
      // If the edge was created by this component, remove it from the store IF, the user removed all connections
      if (createdEdgeRef.current && edge && edge.data.connections.length === 0) {
        dispatch(deleteEdges({ edges: [edge] }));
      }
    };
    // only run this effect when the dispatch function changes in this component.
    // This is to prevent us from deleting the edge when user removes a connection
  }, [dispatch]);

  useOutsideClick(containerRef, () => {
    setShowEditConnections(false);
    // Set selected DSM cell to null
    dispatch(setSelectedState({ rowCol: null }));
  });
  return (
    <div
      className="relative"
      style={{ top: offset.y, left: offset.x * 2 }}
      ref={containerRef}
      onClick={(e) => e.stopPropagation()}
    >
    </div>
  );
}
