import { CircleSlash, Frame, Plug, Shapes } from "lucide-react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../state/slices";
import {
  MenuItemLabels,
  MenuItemName,
  MenuItemValue,
  setSelectItem,
} from "../../../state/slices/BoxDiagram/boxDiagramUISlice";
import ShapesMenu from "./ShapesMenu";
import ConnectionsMenu from "./ConnectionsMenu";

/**
 * Main sidebar component for the block diagram.
 */
const BlockDiagramSidebar = () => {
  // Tailwind CSS classes to style the sidebar menu
  const sidebarClasses = `
    absolute top-1/2 left-0 transform -translate-y-1/2
    bg-white p-2 shadow-lg rounded-lg flex flex-col items-center space-y-4
    z-10 ml-2 // Ensure the sidebar is above the ReactFlow pane
  `;

  return (
    // Using Tailwind CSS to position the sidebar at the center left
    <div className={sidebarClasses}>
      <MenuItems />
      {/* Handles it's own state */}
      <ShapesMenu />
      <ConnectionsMenu />
    </div>
  );
};

function MenuItems() {
  const dispatch = useDispatch();

  function handleOnClick(item: MenuItemName) {
    dispatch(setSelectItem({ item }));
  }

  return (
    <div>
      <ul className="flex-1">
        <MenuItem
          onClick={handleOnClick}
          icon={<Shapes size={20} />}
          label={MenuItemLabels.Shapes}
        />
        <MenuItem
          onClick={handleOnClick}
          icon={<Plug size={20} />}
          label={MenuItemLabels.Connections}
        />
        <MenuItem
          onClick={handleOnClick}
          icon={<Frame size={20} />}
          label={MenuItemLabels.Frame}
          // alert
        />
        
      </ul>
    </div>
  );
}

function MenuItem({
  icon,
  label,
  alert,
  onClick,
}: {
  icon: React.ReactNode;
  label: MenuItemValue;
  alert?: boolean;
  onClick: (item: MenuItemName) => void;
}) {
  const activeItem = useSelector((state: RootState) => state.boxDiagramUI.activeMenuItem);
  const isActive = activeItem?.toString() === label.title;

  return (
    <li
      className={`
          relative flex items-center py-2 px-3 my-1
          font-medium rounded-md cursor-pointer
          transition-colors group
          ${
            isActive
              ? "bg-gradient-to-tr from-indigo-200 to-indigo-100 text-indigo-800"
              : "hover:bg-indigo-50 text-gray-600"
          }
        `}
      onClick={() => onClick(label.title)}
    >
      {icon}
      {/* <ItemLabel text={label.title} /> */}
      {alert && <AlertIndicator />}
      <Tooltip text={label.title} />
    </li>
  );
}

// Tooltip component for sidebar items when collapsed
function Tooltip({ text, hide = false }: { text: string; hide?: boolean }) {
  return (
    <div
      hidden={hide}
      className={`
          absolute left-full rounded-md px-2 py-1 ml-6 z-20
          bg-indigo-100 text-indigo-800 text-sm
          invisible opacity-0 transition-opacity duration-300 ease-in-out
          group-hover:visible group-hover:opacity-100 group-hover:translate-x-0
        `}
    >
      {text}
    </div>
  );
}

// Alert indicator component for sidebar items
function AlertIndicator() {
  const expanded = useSelector((state: RootState) => state.sideNavigationBar.expanded);

  return (
    <div className={`absolute right-1 w-2 h-2 rounded bg-indigo-400 ${expanded ? "" : "top-2"}`} />
  );
}

// Label for the sidebar item
function ItemLabel({ text }: { text: string }) {
  const expanded = useSelector((state: RootState) => state.sideNavigationBar.expanded);

  return (
    <span className={`overflow-hidden transition-all ${expanded ? "w-52 ml-3" : "w-0"}`}>
      {text}
    </span>
  );
}

/**
 * Sidebar component containing draggable shapes for the block diagram.
 */
export default BlockDiagramSidebar;
