import { RefObject, useCallback, useEffect, useState } from "react";
import { Node } from "reactflow";

import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/state/slices";
import { deselectAllNodes } from "src/state/reducers/boxDiagramReducers";
import { ContextMenuState } from "../BlockDiagram";

/**
 * This hook handles the right-click context menu.
 * When the user clicks down on a node, we store the node to know which node was clicked.
 * When the user releases the right mouse button, we show the context menu on the stored node.
 */
export const useOnNodeContextMenu = (reactFlowContainerRef: RefObject<HTMLDivElement>) => {
  const dispatch = useDispatch();
  const [menu, setMenu] = useState<ContextMenuState | null>(null);
  const [rightClickedNode, setRightClickedNode] = useState<Node | null>(null);

  const paneDragging = useSelector((state: RootState) => state.boxDiagramUI.paneDragging);

  // Handler for right-click on a node
  const handleNodeRightClick = useCallback((event: React.MouseEvent, node: Node) => {
    event.preventDefault(); // Prevent default context menu
    dispatch(deselectAllNodes({ exceptionID: node.id })); // Deselect all nodes

    setRightClickedNode(node); // Store the clicked node
  }, []);

  // Global handler for mouse up event
  const handleGlobalMouseUp = useCallback(
    (event: MouseEvent) => {
      if (paneDragging) return; // If we are panning, don't show the context menu

      if (event.button === 2 && rightClickedNode) {
        // Get the container's position relative to the viewport
        // This is because the click position is relative to the viewport (entire visible screen), but we
        // want the click position to be relative to only the react flow container incase
        // the container is not full screen (e.g. we add sidebars or headers above the container).
        const rect = reactFlowContainerRef.current.getBoundingClientRect();
        const widthOfMenu = 220;

        // Right mouse button
        setMenu({
          id: rightClickedNode.id,
          top: event.clientY - rect.y,
          left: event.clientX - rect.x + widthOfMenu,
        });
        setRightClickedNode(null); // Reset the stored node
      }
    },
    [rightClickedNode, paneDragging]
  );

  // Add global event listener for mouse up
  useEffect(() => {
    window.addEventListener("mouseup", handleGlobalMouseUp);
    return () => {
      window.removeEventListener("mouseup", handleGlobalMouseUp);
    };
  }, [handleGlobalMouseUp]);

  return { menu, setMenu, handleNodeRightClick };
};
