// src/components/Documents/DocumentsPage.tsx
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/state/slices";
import { useNavigate } from "react-router-dom";
import { DocumentContainer, createNewDocument, emptyDocument } from "src/models/Document";
import { getDocumentsAPI } from "src/api/service/documentService";
import { setDocument } from "src/state/slices/documentSlice";
import { GeneralResponse, StatusCode } from "src/utils/Api";
import { Coffee, Plus, Sparkles } from "lucide-react";
import DocumentNoBodyView from "./DocumentNoBodyView";
import DocumentsGrid from "./DocumentsGrid";
import { useEffect, useRef, useState } from "react";
import { getUser } from "src/api/service/userService";
import { SideNavigationBarSection } from "src/state/slices/uiSlice";
import { setSelectedSection } from "src/state/slices/sidebarSlice";
import { APPLICATION_NAME, CLIENT_ROUTE, LOCAL_STORAGE } from "src/utils/constants";
import {
  setFirebaseUser,
  setSubscription,
  setUser,
  setUserDocuments,
} from "src/state/slices/userSlice";
import { Toaster } from "../ui/toaster";
import { saveDocToApiWithDebounce } from "src/utils/autoSave";
import { StripeProductType, Subscription } from "src/models/Subscription";
import { BaseContextMenu, BaseContextMenuButton } from "../LandingPage/BaseContextMenu";
import { HorizontalDivider } from "./DocumentCard/DocumentCardContextMenu";
import { createCustomerPortalSession } from "src/api/service/stripeService";

function DocumentsPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userID = useSelector((state: RootState) => state.user.user.id);

  useEffect(() => {
    // Update the current user to pull in the latest document ids
    getUser(userID, dispatch).then((resp) => {

    });

    dispatch(setDocument({ document: emptyDocument }));
  }, []);

  return (
    <div className="flex flex-col w-screen min-h-screen pb-20 bg-neutral-100">
      <Toolbar />
      <DocumentsBody />
      <Toaster />
    </div>
  );
}

export function DocumentsBody() {
  const dispatch = useDispatch();
  const documents = useSelector((state: RootState) => state.user.documents);
  const userDocIds = useSelector((state: RootState) => state.user.user.document_ids);

  useEffect(() => {
    fetchDocuments();
  }, [userDocIds]);

  function fetchDocuments() {
    getDocumentsAPI(userDocIds).then((resp) => {
      const _documents = resp.resp as DocumentContainer[];

      if (resp.status == StatusCode.HTTP_200) {
        console.log("setting documents", _documents);
        dispatch(setUserDocuments({ documents: _documents }));
      } else if (resp.status == StatusCode.HTTP_500) {
        alert("Could not get documents. Please try again.");
        console.error("Failed to get documents");
      }
    });
  }

  console.log("Documents", documents);
  return (
    <div className="mx-20 mt-10">
      <DocumentsHeader showButton={documents.length !== 0} />
      {documents.length === 0 ? <DocumentNoBodyView /> : <DocumentsGrid documents={documents} />}
    </div>
  );
}

function getSubscriptionTag(subscription: Subscription | null) {
  if (
    subscription == null ||
    subscription.product_type == StripeProductType.HOBBY ||
    StripeProductType.UNKNOWN
  ) {
    return (
      <div className="flex items-center justify-start space-x-2 p-1 text-sm font-medium text-center text-white px-2  rounded-xl bg-neutral-500">
        {/* <Sparkles size={16} /> */}
        Hobby
      </div>
    );
  } else {
    return (
      <div className="flex items-center justify-center space-x-2 p-1 text-sm text-center text-white md:px-2 w-32 rounded-xl bg-neutral-700">
        <Sparkles size={16} />
        <span>Individual</span>
      </div>
    );
  }
}

function DocumentsHeader({ showButton }: { showButton: boolean }) {
  const subscription = useSelector((state: RootState) => state.user.subscription);
  console.log("Subscription", subscription);

  return (
    <div className="flex justify-between items-center mb-8 ">
      <div className="flex flex-col items-start justify-start space-x-2">
        {getSubscriptionTag(subscription)}
        <h1 className="text-3xl">Documents</h1>
      </div>
      {showButton && <CreateDocumentButton />}
    </div>
  );
}

function Toolbar() {
  const navigate = useNavigate();

  function navToHome() {
    navigate(CLIENT_ROUTE.HOME);
  }

  function navToPricing() {
    navigate(CLIENT_ROUTE.PRICING);
  }
  return (
    <div className="flex items-center px-10 justify-between border-b border-b-neutral-400  w-full h-16 bg-neutral-200">
      <button
        onClick={navToHome}
        className="flex items-center justify-start text-black text-2xl font-mono"
      >
        <Coffee size={24} className="mr-2" />
        {APPLICATION_NAME}
      </button>

      <div className="flex items-center ">
        <button onClick={navToPricing} className=" py-2 mx-2 space-x-2 text-black">
          Pricing
        </button>
        <div className="px-6">
          <div className="w-px h-6 bg-neutral-800"></div>
        </div>

        <ProfileCircle />
      </div>
    </div>
  );
}

function ProfileCircle() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userName = useSelector((state: RootState) => state.user.user.first_name);
  const displayName = userName ? userName[0] : "A";
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);

  // Function to handle click outside of the menu
  const handleClickOutside = (event: MouseEvent) => {
    // If the click is outside the menu, close the menu
    if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
      setIsMenuVisible(false);
    }
  };

  // Hook for handling clicks outside of the menu to close it
  useEffect(() => {
    // Only add listener if the menu is visible
    if (isMenuVisible) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    // Cleanup listener
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isMenuVisible]); // Only re-run if isMenuVisible changes

  // Function to toggle the context menu's visibility
  const toggleMenu = () => {
    setIsMenuVisible(!isMenuVisible);
  };

  function handleSignOut() {
    handleSignOutUtil(dispatch, navigate);
  }

  return (
    <div ref={menuRef} className="relative">
      {/* Relative position for absolute positioning of context menu */}
      <button
        className={`flex items-center justify-center w-12 h-12 rounded-full bg-indigo-600 ${
          isMenuVisible && "border-2  border-indigo-900"
        } `}
        onClick={toggleMenu} // Attach the toggle function to the button's onClick event
      >
        <div className="text-white">{displayName}</div>
      </button>
      {/* Conditional rendering for the context menu */}
      {isMenuVisible && <SignOutContextMenu handleSignOut={handleSignOut} />}
    </div>
  );
}

export function SignOutContextMenu({ handleSignOut }: { handleSignOut: () => void }) {
  const subscription = useSelector((state: RootState) => state.user.subscription);

  async function handleGetBilling() {
    const returnUrl = window.location.href;
    await createCustomerPortalSession(subscription.customer_id, returnUrl)
      .then((portal_url) => {
        window.location.href = portal_url;
      })
      .catch((err) => {
        alert("Could not open billing portal. Please try again.");
      });
  }
  const styles = {
    top: 50,
    right: 0,
  };

  return (
    <BaseContextMenu onClose={() => {}} styles={styles}>
      {subscription && (
        <>
          <BaseContextMenuButton title={"Manage billing"} onClick={handleGetBilling} />
          <HorizontalDivider />
        </>
      )}
      <BaseContextMenuButton title={"Sign out"} onClick={handleSignOut} />
    </BaseContextMenu>
  );
}

export function CreateDocumentButton() {
  const user_id = useSelector((state: RootState) => state.user.user.id);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  async function handleClick() {
    const newDocument = createNewDocument(user_id);
    saveDocToApiWithDebounce(newDocument, 0)
      .then((resp: GeneralResponse) => {
        if (resp.status == StatusCode.HTTP_200) {
          // Set view to Box Diagram
          dispatch(setSelectedSection(SideNavigationBarSection.BoxDiagram));

          // Set the document in the store
          dispatch(setDocument({ document: newDocument }));

          // Navigate to the document
          navigate(CLIENT_ROUTE.DOCUMENT(newDocument.id));
        }
        // Server error
        else if (resp.status == StatusCode.HTTP_500) {
          alert("Could not create document. Please try again.");
        }
      })
      .catch((err) => {
        alert("Could not create document. Please try again.");
      });
  }
  return (
    <button
      onClick={handleClick}
      className="flex items-center px-4 py-4 mx-2 mt-4 space-x-2 text-white rounded-md bg-gradient-to-br from-indigo-500 to-indigo-600"
    >
      <div>Create document</div>
      <Plus size={18} />
    </button>
  );
}

export function handleSignOutUtil(dispatch, navigate) {
}

export default DocumentsPage;
