// src/models/MatrixCell.ts

import { Edge, Node } from "reactflow";
import { NodeData } from "./BoxDiagram/Node";
import { ArrowDirection, EdgeData } from "./BoxDiagram/Edge";
import { getNodeById } from "src/state/selectors/boxDiagramSelectors";
import { nanoid } from "nanoid";

export enum CellType {
  Origin = "Origin",
  Legend = "Legend",
  LegendIDHoriz = "LegendIDHoriz",
  LegendIDVert = "LegendIDVert",
  RowHeader = "RowHeader",
  RowID = "RowID",
  ColHeader = "ColHeader",
  ColID = "ColID",
  Inactive = "Inactive",
  Default = "Default",
}

export type DSMCell = {
  id: string;
  row: number;
  col: number;
  source: string; // Row
  target: string; // Col
  value: string;
  selected: boolean;
  type: CellType;
  position: { top: number; left: number };
};

/**
 * Creates a 2D array of MatrixCell objects. We manually add the first two rows and columns
 * to render the Origin and Legend cells. The rest of the cells are created from the edges.
 */
export function createMatrixV3(nodes: Node<NodeData>[]): DSMCell[][] {
  const matrix: DSMCell[][] = [];

  for (let row = 0; row < nodes.length + 2; row++) {
    // Create each row
    const matrixRow: DSMCell[] = [];

    // Handle the first two rows and columns
    if (row === 0) {
      // First row is the origin cell and the horizontal legend and the rest are the column IDs
      const row1: DSMCell[] = [
        defaultOriginCell,
        defaultLegendHorizCell,
        ...nodes.map((_, index) => getColIDCell(index + 2)),
      ];
      matrix.push(row1);
      continue;
    }
    if (row === 1) {
      // Second row is the vertical legend and the rest are the row IDs and the row headers
      const row2: DSMCell[] = [
        defaultLegendVertCell,
        defaultLegendCell,
        // We can use index + 1 because the first cells are the legend and the origin
        // We set source to "" because the col headers don't need a source
        ...nodes.map((_, index) => getColHeaderCell(index + 2, "", nodes[index].id)),
      ];
      matrix.push(row2);
      continue;
    }

    for (let col = 0; col < nodes.length + 2; col++) {
      // Handle the first two cells in each row
      if (col === 0) {
        // First cell in each row is a row ID
        matrixRow.push(getRowIDCell(row));
        continue;
      }
      if (col === 1) {
        // Second cell in each row is a row header after index 1
        // We set target to "" because the row headers don't need a target
        matrixRow.push(getRowHeaderCell(row, nodes[row - 2].id, ""));
        continue;
      }

      // Create the rest of the cells
      // We subtract 2 from the row and col to get the correct index for the nodes array
      const cell = createCellV3(row, col, nodes[row - 2].id, nodes[col - 2].id);
      matrixRow.push(cell);
    }
    matrix.push(matrixRow);
  }

  return matrix;
}

export function createCellV3(
  row: number,
  col: number,
  source: string,
  target: string,
  value: string = "",
  selected: boolean = false,
  position: { top: number; left: number } = { top: 0, left: 0 }
): DSMCell {
  return {
    id: `${row}-${col}`,
    row,
    col,
    source,
    target,
    value: value || getInitialCellValue(row, col),
    selected,
    type: getCellType(row, col),
    position: position,
  };
}

const defaultOriginCell: DSMCell = {
  id: "origin",
  row: 0,
  col: 0,
  source: "",
  target: "",
  value: "",
  selected: false,
  type: CellType.Origin,
  position: { top: 0, left: 0 },
};
const defaultLegendVertCell: DSMCell = {
  id: "legendVert",
  row: 1,
  col: 0,
  source: "",
  target: "",
  value: "",
  selected: false,
  type: CellType.LegendIDVert,
  position: { top: 0, left: 0 },
};
const defaultLegendHorizCell: DSMCell = {
  id: "legendHoriz",
  row: 0,
  col: 1,
  source: "",
  target: "",
  value: "",
  selected: false,
  type: CellType.LegendIDHoriz,
  position: { top: 0, left: 0 },
};
const defaultLegendCell: DSMCell = {
  id: "legend",
  row: 1,
  col: 1,
  source: "",
  target: "",
  value: "",
  selected: false,
  type: CellType.Legend,
  position: { top: 0, left: 0 },
};

function getColIDCell(col: number): DSMCell {
  return {
    id: `colID-${col}`,
    row: 0,
    col: col,
    source: "",
    target: "",
    value: "",
    selected: false,
    type: CellType.ColID,
    position: { top: 0, left: 0 },
  };
}

function getRowIDCell(row: number): DSMCell {
  return {
    id: `rowID-${row}`,
    row: row,
    col: 0,
    source: "",
    target: "",
    value: "",
    selected: false,
    type: CellType.RowID,
    position: { top: 0, left: 0 },
  };
}

function getColHeaderCell(col: number, source: string, target: string): DSMCell {
  return {
    id: `colHeader-${col}`,
    row: 1,
    col: col,
    source: source,
    target: target,
    value: "",
    selected: false,
    type: CellType.ColHeader,
    position: { top: 0, left: 0 },
  };
}

function getRowHeaderCell(row: number, source: string, target: string): DSMCell {
  return {
    id: `rowHeader-${row}`,
    row: row,
    col: 1,
    source: source,
    target: target,
    value: "",
    selected: false,
    type: CellType.RowHeader,
    position: { top: 0, left: 0 },
  };
}

export const getCellType = (row: number, col: number): CellType => {
  if (row === 0 && col === 0) return CellType.Origin; // (0,0) ex. (0,0)
  if (row === 0 && col === 1) return CellType.LegendIDHoriz; // (0,1) ex. (0,1)
  if (row === 1 && col === 0) return CellType.LegendIDVert; // (1,0) ex. (1,0)
  if (row === 1 && col === 1) return CellType.Legend; // (1,1) ex. (1,1)
  if (row === 0 && col !== 0) return CellType.ColID; // (0,n>=2) ex. (0,2), (0,3), (0,4)
  if (row !== 0 && col === 0) return CellType.RowID; // (n>=2,0) ex. (2,0), (3,0), (4,0)
  if (row === 1 && col >= 2) return CellType.ColHeader; // (1,n>=2) ex. (1,2), (1,3), (1,4)
  if (row >= 2 && col === 1) return CellType.RowHeader; // (n>=,1) ex. (2,1), (3,1), (4,1)
  if (row === col) return CellType.Inactive; // (n>=2,n>=2) ex. (2,2), (3,3), (4,4)
  return CellType.Default; // (n>=2,m>=2) ex. (2,3), (3,4), (4,5)
};

export function isHorizontalHeader(row: number, col: number): boolean {
  return [CellType.ColID, CellType.ColHeader].includes(getCellType(row, col));
}

export function isVerticalHeader(row: number, col: number): boolean {
  return [CellType.RowID, CellType.RowHeader].includes(getCellType(row, col));
}

// Function to convert column number to Excel-like column ID
function toExcelColumn(col: number): string {
  let columnID = "";
  while (col > 0) {
    let remainder = (col - 1) % 26;
    columnID = String.fromCharCode(65 + remainder) + columnID;
    col = Math.floor((col - 1) / 26);
  }
  return columnID;
}

// Function to convert row number to Excel-like row ID
function toExcelRow(row: number): string {
  // Excel-like row IDs are simply the row number
  return row.toString();
}

export function getInitialCellValue(row: number, col: number): string {
  const cellType = getCellType(row, col);

  switch (cellType) {
    case CellType.ColID:
      // Generate Excel-like column ID
      return toExcelColumn(col);
    case CellType.RowID:
      // Generate Excel-like row ID
      return toExcelRow(row);
    case CellType.LegendIDHoriz:
      return toExcelColumn(col);
    case CellType.LegendIDVert:
      return toExcelRow(row);
    case CellType.RowHeader:
      // Return formatted component name for row headers
      return `Component ${row - 1}`;
    case CellType.ColHeader:
      // Return formatted component name for column headers
      return `Component ${col - 1}`;
    case CellType.Legend:
      // Return static text for legend cells
      return `Legend goes here`;
    case CellType.Inactive:
      // Return 'X' for inactive cells
      return "X";
    default:
      // Return cell position as a fallback
      return "";
  }
}

export function edgeToDSMId(edge: Edge<EdgeData>, nodes: Node[]): string {
  const arrowDirection: ArrowDirection = edge.data.arrow_direction;

  const colNum = nodes.findIndex((node) => node.id === edge.target) + 2;
  const rowNum = nodes.findIndex((node) => node.id === edge.source) + 2;

  if (arrowDirection === ArrowDirection.Both) {
    return `(${toExcelColumn(colNum)},${rowNum}), (${rowNum},${toExcelColumn(colNum)})`;
  }
  if (arrowDirection === ArrowDirection.NoDirection) {
    return `-`;
  }

  return `(${toExcelColumn(colNum)},${rowNum})`;
}

interface GetDSMRowColLabelProps {
  cell: DSMCell;
  nodes: Node<NodeData>[];
}
export function getDSMCellLabel({ cell, nodes }: GetDSMRowColLabelProps): string {
  const sourceNode = getNodeById(nodes, cell.source);
  const targetNode = getNodeById(nodes, cell.target);

  let cellContent: string = cell.value;
  if (cell.type === CellType.RowHeader) {
    cellContent = sourceNode.data.label;
  } else if (cell.type === CellType.ColHeader) {
    cellContent = targetNode.data.label;
  } else {
    cellContent = getInitialCellValue(cell.row, cell.col);
  }
  return cellContent;
}
