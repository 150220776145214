import { useEffect, MutableRefObject } from 'react';

/**
 * Custom hook for handling outside click events.
 * 
 * @param ref - A ref object attached to a DOM element.
 * @param onOutsideClick - A callback function that is executed when a click occurs outside the referenced element.
 */
function useOutsideClick(ref: MutableRefObject<HTMLElement | null>, onOutsideClick: () => void) {
  useEffect(() => {
    /**
     * Detects a click outside of the referenced element and triggers the callback.
     * @param event - The mouse event object.
     */
    function handleClickOutside(event: MouseEvent) {
      // console.log('Clicked outside menu');
      if (ref.current && !ref.current.contains(event.target as Node)) {
        onOutsideClick();
      }
    }

    // Attach the event listener to the document
    document.addEventListener('mousedown', handleClickOutside);

    // Cleanup function to remove the event listener
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [ref, onOutsideClick]);
}

export default useOutsideClick;
