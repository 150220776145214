// src/components/BlockDiagram/Hooks/useRightClickPanning.ts
import { useState, useCallback, useEffect, RefObject } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReactFlowInstance, Viewport, useViewport } from "reactflow";
import { setPaneDrag } from "src/state/slices/BoxDiagram/boxDiagramUISlice";

export const useRightClickPanning = (
  reactFlowInstance: ReactFlowInstance | null,
  reactFlowContainerRef: RefObject<HTMLDivElement>
) => {
  const dispatch = useDispatch();
  // Used internally to track if we are panning
  const [_isPanning, _setIsPanning] = useState(false);
  // Used internally to track the initial click position so we know where to move the
  // screen as the user drags the mouse
  const [panStart, setPanStart] = useState({ x: 0, y: 0 });
  // Pixels threshold to distinguish between drag and click
  const dragThreshold = 10;
  // Get the current zoom level to adjust pan start position when user zooms in/out
  const { zoom } = useViewport();
  const paneDragging = useSelector((state: any) => state.boxDiagramUI.paneDragging);

  // Communicate with the rest of the application to let it know if we are panning
  function setDragExternally(paneDragging: boolean): void {
    dispatch(setPaneDrag({ paneDragging }));
  }

  const handleRightMouseDown = useCallback(
    (event: MouseEvent) => {
      if (!reactFlowInstance) return;

      // Right click == 2; Scroll wheel click == 1
      if (event.button === 2 || event.button === 1) {
        _setIsPanning(true);
        // Get the container's position relative to the viewport
        const rect = reactFlowContainerRef.current.getBoundingClientRect();
        // Adjust the click position by adding the container's offset. This is because
        // the click position is relative to the viewport (entire visible screen), but we
        // want the click position to be relative to only the react flow container incase
        // the container is not full screen (e.g. we add sidebars or headers above the container).
        const adjustedX = event.clientX + rect.x;
        const adjustedY = event.clientY + rect.y;

        const clickPosition = reactFlowInstance.screenToFlowPosition({
          x: adjustedX,
          y: adjustedY,
        });
        setPanStart({ x: clickPosition.x, y: clickPosition.y });
      }
    },
    [reactFlowInstance, reactFlowContainerRef]
  );

  const handleMouseMove = useCallback(
    (event: MouseEvent) => {
      if (!_isPanning || !reactFlowInstance) return;

      // Check if the drag threshold is exceeded to distinguish between drag and click
      if (Math.abs(event.clientX) > dragThreshold || Math.abs(event.clientY) > dragThreshold) {
        if (!paneDragging) {
          setDragExternally(true); // Set drag in Redux only once
        }
        const viewport: Viewport = {
          x: event.clientX - panStart.x * zoom,
          y: event.clientY - panStart.y * zoom,
          zoom: reactFlowInstance.getZoom(),
        };
        reactFlowInstance.setViewport(viewport);
      }
    },
    [_isPanning, panStart, paneDragging, reactFlowInstance, zoom]
  );

  const handleRightMouseUp = useCallback(() => {
    _setIsPanning(false);
    setDragExternally(false);
  }, [paneDragging]);

  useEffect(() => {
    window.addEventListener("mousedown", handleRightMouseDown);
    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("mouseup", handleRightMouseUp);

    return () => {
      window.removeEventListener("mousedown", handleRightMouseDown);
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleRightMouseUp);
    };
  }, [handleRightMouseDown, handleMouseMove, handleRightMouseUp]);

  return;
};
