import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { HorizontalDivider } from "src/components/Documents/DocumentCard/DocumentCardContextMenu";
import { BaseContextMenu, BaseContextMenuButton } from "src/components/LandingPage/BaseContextMenu";
import { CLIENT_ROUTE } from "src/utils/constants";
import { useScreenSize } from "../BlockDiagram/Hooks/useIsMobile";

interface ProjectsContextMenuProps {
  visible: boolean;
  onClose?: () => void;
}

function ProjectsContextMenu({ visible, onClose }: ProjectsContextMenuProps) {
  const { mdOrLarger } = useScreenSize();
  const navigate = useNavigate();

  function navToSinkly() {
    navigate(CLIENT_ROUTE.SINKLY);
    onClose();
  }

  const classes = mdOrLarger ? "right-80 top-16 overflow-clip" : "w-80 top-16 overflow-clip";
  if (!visible) return null;
  return (
    <BaseContextMenu className={classes} onClose={() => {}}>
      <BaseContextMenuButton title={"Sinkly"} onClick={navToSinkly} />
      <BaseContextMenuButton title={"Relevate"} onClick={navToSinkly} locked />
      <BaseContextMenuButton title={"Util"} onClick={navToSinkly} locked />
      <BaseContextMenuButton title={"CMD Monitors"} onClick={navToSinkly} locked />
      <BaseContextMenuButton title={"Track My"} onClick={navToSinkly} locked />
    </BaseContextMenu>
  );
}

export default ProjectsContextMenu;
