import { ArrowBigRight, ArrowRight } from "lucide-react";
import { useDispatch, useSelector } from "react-redux";
import { HorizontalDivider } from "src/components/Documents/DocumentCard/DocumentCardContextMenu";
import { ArrowDirection } from "src/models/BoxDiagram/Edge";
import { getEdgeById, getNodeById } from "src/state/selectors/boxDiagramSelectors";
import { navigateToICD } from "src/state/selectors/selectors";
import { RootState } from "src/state/slices";
import { getArrowDirection } from "../Nodes/EdgeToolbar/BaseEdgeToolbar";

interface QuickDisplayEdgeProps {
  edgeID: string | null;
}
function QuickDisplayEdge({ edgeID }: QuickDisplayEdgeProps) {
  const dispatch = useDispatch();
  const edge = useSelector((state: RootState) => getEdgeById(state, edgeID));
  const edges = useSelector((state: RootState) => state.document.documentContainer.edges);

  const sourceNode = useSelector((state: RootState) =>
    getNodeById(state.document.documentContainer.nodes, edge?.source)
  );
  const targetNode = useSelector((state: RootState) =>
    getNodeById(state.document.documentContainer.nodes, edge?.target)
  );

  function viewInICD() {
    let edgeIndex = edges.findIndex((e) => e.id === edge.id);
    navigateToICD(dispatch, edgeIndex);
  }

  if (!edge) {
    return null;
  }
  return (
    <div className="flex flex-col space-y-4 p-2 w-72 shadow-md rounded-md border border-gray-400 bg-white select-text">
      <div className="ml-2">
        <span>{"ID: "}</span>
        {edge && <span className="font-bold">{edge ? edge.id : "No edge"}</span>}
      </div>

      <button
        onClick={viewInICD}
        className="flex items-center justify-between w-auto text-start = mx-2 "
      >
        <span
        style={{ wordBreak: 'break-word' }}
        >{sourceNode.data.label}</span>
        {getArrowDirection(edge.data.arrow_direction, 20, "w-12 m-1")}
        <span
        style={{ wordBreak: 'break-word' }}
        >{targetNode.data.label}</span>
      </button>

      {edge && (
        <>
          <div className="flex flex-wrap space-x-2">
            {edge.data.connections.map((connection) => (
              <div key={connection.id} className="ml-2 mb-2 ">
              </div>
            ))}
          </div>
          <HorizontalDivider />
          <div className="p-2">
            {edge.data.icd.description ? edge.data.icd.description : "No additional info"}
          </div>
        </>
      )}
      {/* <button className="p-2 rounded-md text-sm   border-indigo-700 text-indigo-800 hover:bg-indigo-50">
        View in ICD
      </button> */}
    </div>
  );
}

export default QuickDisplayEdge;
