import {
  ChevronLeft,
  ChevronRight,
  Coffee,
  Eye,
  FileCheck2,
  Link,
  Menu,
  Pencil,
  RefreshCwOff,
  Share2,
  Sparkles,
  UploadCloud,
  UserRound,
} from "lucide-react";
import { useEffect, useRef, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { SignOutContextMenu, handleSignOutUtil } from "src/components/Documents/DocumentsPage";
import { toast } from "src/components/ui/use-toast";
import useOutsideClick from "src/components/LandingPage/hooks/useOutsideClick";
import { Color } from "src/models/BoxDiagram/Colors";
import { RootState } from "src/state/slices";
import { setDocumentName, setDocumentPermissions } from "src/state/slices/documentSlice";
import { saveDocToApiWithDebounce } from "src/utils/autoSave";
import { CLIENT_ROUTE, CLIENT_URL, LOCAL_STORAGE } from "src/utils/constants";
import DocumentContextMenu from "../DocumentContextMenu";
import { HorizontalDivider } from "src/components/Documents/DocumentCard/DocumentCardContextMenu";
import { addHasUnsavedChanges, addReactFlowClickedListener } from "src/events/documentEvents";
import { Tooltip } from "@mui/material";
import { DocumentPermissions } from "src/models/Document";
import ShareDocumentDialog from "./ShareDocumentDialog";

function TopDocumentRow() {
  return (
    <div className="flex items-center justify-between h-full w-full py-2">
      <div className="flex items-center justify-start space-x-2">
        <Logo />
        <DocumentNameInput />
        <SavingIndicator />
        <OptionsMenu />
        {/* Div allows us to add margin */}
        <div>
          <UnsavedChangesIndicator />
        </div>
      </div>
      <div className="flex items-center space-x-4">
        <ShareButtons />
        <UserAvatar />
      </div>
    </div>
  );
}

function Logo() {
  const navigate = useNavigate();
  const document = useSelector((state: RootState) => state.document.documentContainer);

  function navigateToDocuments() {
    // Commit any changes to the document before exiting
    if (document) {
      saveDocToApiWithDebounce(
        { id: document.id, owner_id: document.owner_id, permissions: document.permissions },
        0
      );
    }
    navigate(CLIENT_ROUTE.DOCUMENT_HOME);
  }
  return (
    <button onClick={navigateToDocuments}>
      <Coffee
        size={32}
        color={`${Color.Indigo.css.sixHundred}`}
        className="transition-opacity duration-300"
      />
    </button>
  );
}
export function SavingIndicator() {
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  // Handles clicks anywhere in reactFlow
  addHasUnsavedChanges((hasChanged: boolean) => {
    setHasUnsavedChanges(hasChanged);
  });

  const toolTipText = hasUnsavedChanges
    ? "Document has unsaved changes."
    : "Document successfully saved";

  return (
    <div className="p-2 rounded-full ">
      <Tooltip title={toolTipText} arrow>
        <div className="flex justify-center items-center">
          {!hasUnsavedChanges && <FileCheck2 size={25} color="gray" />}
          {hasUnsavedChanges && <UploadCloud size={25} color="gray" />}
        </div>
      </Tooltip>
    </div>
  );
}
export function OptionsMenu() {
  const [isMenuVisible, setIsMenuVisible] = useState(false);

  const menuRef = useRef<HTMLDivElement>(null);
  useOutsideClick(menuRef, hideMenu);

  function hideMenu() {
    setIsMenuVisible(false);
  }

  function toggleMenu() {
    setIsMenuVisible(!isMenuVisible);
  }

  return (
    <div onClick={toggleMenu} className=" p-2 hover:bg-slate-200 rounded-full cursor-pointer">
      <div className="flex justify-center items-center">
        <Menu size={25} />
      </div>

      {isMenuVisible && <DocumentContextMenu onClose={hideMenu} />}
    </div>
  );
}
export function UnsavedChangesIndicator() {
  // Used to display the unsaved changes indicator
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  // Used to enable the indicator
  const [enableIndicator, setEnableIndicator] = useState(false);
  const user = useSelector((state: RootState) => state.user.user);

  // Used to ensure we don't show the unsaved changes indicator when the user
  // first opens the web page. React requires that we render the same number
  // of hooks so we must use a dummy hook
  if (enableIndicator) {
    addHasUnsavedChanges((hasChanged: boolean) => {
      if (hasChanged) {
        setHasUnsavedChanges(hasChanged);
      }
    });
  } else {
    addHasUnsavedChanges((_: boolean) => {
      // Do nothing
    });
  }

  // Enable the unsaved changes indicator after 10 seconds
  setTimeout(() => {
    setEnableIndicator(true);
  }, 10000);

  // Open new web page
  function handleOnClick() {
    const url = `${CLIENT_URL}${CLIENT_ROUTE.SIGN_IN}`;

    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  }

  // Only show the unsaved changes indicator if the user NOT logged in and has unsaved changes
  if (user || !hasUnsavedChanges) {
    return null;
  }
  return (
    <button
      onClick={handleOnClick}
      className="flex space-x-2 ml-4 p-1 w-auto rounded-md bg-red-100 border-2 border-red-200"
    >
      <RefreshCwOff size={20} />
      <span>Unsaved changes. Click here to save your progress.</span>
    </button>
  );
}
export function ShareButtons() {
  return (
    <div className="flex space-x-1">
      <SharePermissionsButton />
      <ShareableLinkButton />
    </div>
  );
}

function SharePermissionsButton() {
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const document = useSelector((state: RootState) => state.document.documentContainer);

  const currentUserId: string | null = localStorage.getItem(LOCAL_STORAGE.USER_ID);
  const isOwner = document.owner_id === currentUserId;

  const menuRef = useRef<HTMLDivElement>(null);
  useOutsideClick(menuRef, hideMenu);

  function hideMenu() {
    setIsMenuVisible(false);
  }

  function toggleMenu() {
    setIsMenuVisible(!isMenuVisible);
  }

  return (
    <div ref={menuRef} className="relative ">
      <button
        onClick={toggleMenu}
        className="flex justify-center items-center px-3 py-2 bg-indigo-200 hover:bg-indigo-300 space-x-4 rounded-l-3xl"
      >
        <Share2 size={20} />
        <span className="text-xl">Share</span>
      </button>
      {/* {isOwner && isMenuVisible && <ShareDocumentDialog />} */}
      {isOwner && isMenuVisible && <GetPermissionsPopup onClose={hideMenu} />}

      {!isOwner && isMenuVisible && <GetPermissionsPopupViewOnly onClick={hideMenu} />}
    </div>
  );
}

function GetPermissionsPopupViewOnly({ onClick }: { onClick: () => void }) {
  return (
    <div className="absolute right-0 mt-2 p-3 w-64 bg-white rounded-md drop-shadow-xl  z-20">
      <div className="text-sm font-medium text-gray-600">{"This button isn't quite ready:)"}</div>
      <div className="text-center mt-4 text-sm text-gray-500">Check back soon for some magic!</div>
    </div>
  );
}

function GetPermissionsPopup({ onClose }: { onClose: () => void }) {
  const document = useSelector((state: RootState) => state.document.documentContainer);
  const docPermission = document.permissions;

  return (
    <div className="absolute right-0 mt-2 p-3 w-72 sm:w-96 h-52 bg-white rounded-md drop-shadow-xl z-20">
      <div className=" text  text-gray-600">{"Other users can:"}</div>
      <div className="flex flex-col h-full mt-4 space-y-1">
        {Object.values(DocumentPermissions).map((permission) => {
          return (
            <PermissionSelection
              key={permission}
              permission={permission}
              selectedPermission={docPermission}
              onClose={onClose}
            />
          );
        })}
      </div>
      {/* <div className="text-center mt-4 text-sm text-gray-500">Check back soon for some magic!</div> */}
    </div>
  );
}

function PermissionSelection({
  permission,
  selectedPermission,
  onClose,
}: {
  permission: DocumentPermissions;
  selectedPermission: DocumentPermissions;
  onClose: () => void;
}) {
  const dispatch = useDispatch();
  const permissionMap = {
    // [DocumentPermissions.EDIT_AND_SHARE]: {
    //   title: "Edit and share",
    //   description: "Can edit and share the document",
    //   image: <UserRound color={Color.Gray.css.sixHundred} size={22} />,
    // },
    [DocumentPermissions.EDIT]: {
      title: "Edit",
      description: "Any user can edit this document.",
      image: <Pencil color={Color.Gray.css.sixHundred} size={22} />,
    },
    [DocumentPermissions.VIEW]: {
      title: "View",
      description: "Any user can view this document. Only you can edit.",
      image: <Eye color={Color.Gray.css.sixHundred} size={27} />,
    },
  };

  function handleOnClick() {
    // Save the new permission to the DB
    dispatch(setDocumentPermissions({ permissions: permission }));
    toast({
      title: "Permissions updated",
      className: "flex justify-center rounded-xl text-4xl bg-gray-900 text-white py-8 w-64 mb-20",
      duration: 3000,
    });
    onClose();
  }
  const checked = permission === selectedPermission;
  return (
    <div
      onClick={handleOnClick}
      className={`flex items-center space-x-2 h-auto py-1 px-1 cursor-pointer hover:bg-indigo-100 ${
        checked ? "bg-indigo-50" : ""
      }`}
    >
      {/* Black bar taking up full hight */}
      <div className={`w-1 h-full ${checked ? "bg-gray-600" : "bg-transparent"}`}></div>
      <div className="flex h-full item-start pt-1">{permissionMap[permission].image}</div>
      <div className="flex flex-col p-0 m-0 items-start w-full h-full">
        <div className="text-lg mb-0 text-gray-700"> {permissionMap[permission].title} </div>
        <div className="text-sm mt-0 text-gray-500"> {permissionMap[permission].description} </div>
      </div>
    </div>
  );
}

function ShareableLinkButton() {
  const [isMenuVisible, setIsMenuVisible] = useState(false);

  const menuRef = useRef<HTMLDivElement>(null);
  const document = useSelector((state: RootState) => state.document.documentContainer);
  useOutsideClick(menuRef, hideMenu);

  function hideMenu() {
    setIsMenuVisible(false);
  }

  function toggleMenu() {
    setIsMenuVisible(!isMenuVisible);
  }

  function copyToClipboard() {
    const shareableLink = `${CLIENT_URL}${CLIENT_ROUTE.DOCUMENT(document.id)}`;

    navigator.clipboard
      .writeText(shareableLink)
      .then(() => {
        toast({
          title: "Link copied to clipboard",
          className:
            "flex justify-center rounded-xl text-4xl bg-gray-900 text-white py-8 w-64 mb-20",
          duration: 5000,
        });
        hideMenu();
      })
      .catch((error) => {
        console.error(error);
        alert("Could not copy link to clipboard. Please try again.");
      });
  }

  return (
    <div ref={menuRef} className="relative ">
      <button
        onClick={toggleMenu}
        className="px-3 py-3 bg-indigo-200 hover:bg-indigo-300 space-x-4 rounded-r-3xl"
      >
        <Link size={20} />
      </button>
      {isMenuVisible && <GetLinkPopup copyToClipboard={copyToClipboard} />}
    </div>
  );
}

function GetLinkPopup({ copyToClipboard }: { copyToClipboard: () => void }) {
  return (
    <div className="absolute right-0 mt-2 p-3 w-64 bg-white rounded-md drop-shadow-xl  z-20">
      <div className="text-sm font-medium text-gray-600">Get sharable link to this document</div>
      <button
        onClick={copyToClipboard}
        className="flex justify-center p-2 mt-4 text-sm border  border-gray-400 rounded-md text-gray-700 hover:bg-slate-50  hover:border-indigo-600 w-full text-left"
      >
        Copy link to clipboard
      </button>

      <div className="text-center mt-4 text-sm text-gray-500">
        Link is view only for other users.
      </div>
    </div>
  );
}
export function DocumentNameInput() {
  const dispatch = useDispatch();
  const document = useSelector((state: RootState) => state.document.documentContainer);
  const numDocIds = useSelector((state: RootState) => state.user.documents.length);
  const [value, setValue] = useState("document.name");
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setValue(document.name);
    // Highlight the full document name when the user clicks on the input
  }, [document.name]);

  function handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    setValue(event.target.value);
  }

  function handleKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.key === "Enter") {
      event.preventDefault();
      event.currentTarget.blur();
    }
  }

  function handleOnBlur() {
    const backupName = `Untitled doc ${numDocIds + 1}`;

    if (value.trim() === "") {
      setValue("Untitled doc");
    }

    dispatch(setDocumentName({ name: value || backupName }));
    // Avoid sending two requests since the component is re-rendered when the document name is updated
    if (value !== document.name) {
      // Save the document in DB
      const docChanges = {
        id: document.id,
        name: value || backupName,
        owner_id: document.owner_id,
        permissions: document.permissions,
      };
      saveDocToApiWithDebounce(docChanges, 1000);
    }

    if (value === "") {
      setValue(backupName);
    }
  }

  return (
    <input
      ref={inputRef}
      className={
        "text-2xl pl-2 rounded-lg font-semibold text-gray-800 border focus:border-2 border-gray-500 focus:outline-none focus:border-indigo-500 hover:border-indigo-400 cursor-text"
      }
      style={{ maxWidth: "400px" }}
      value={value}
      onChange={handleInputChange}
      onKeyDown={handleKeyDown}
      onBlur={handleOnBlur}
    />
  );
}

// User avatar component
export function UserAvatar() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isMenuVisible, setIsMenuVisible] = useState(false);

  const user = useSelector((state: RootState) => state.user.user);
  const menuRef = useRef<HTMLDivElement>(null);

  const firstNameInitial = user ? user.first_name[0] : "G";
  const lastNameInitial = user ? (user.last_name ? user.last_name[0] : "") : "";

  useOutsideClick(menuRef, hideMenu);

  function hideMenu() {
    setIsMenuVisible(false);
  }

  function handleSignOut() {
    handleSignOutUtil(dispatch, navigate);
  }
  function toggleMenu() {
    console.log("toggleMenu", isMenuVisible);
    setIsMenuVisible(!isMenuVisible);
  }

  return (
    <div ref={menuRef} className="relative ">
      <button
        onClick={toggleMenu}
        className="flex items-center justify-center w-11 h-11 rounded-full bg-indigo-200 select-none text-lg font-semibold text-indigo-800"
      >
        {firstNameInitial}
        {lastNameInitial}
      </button>

      {isMenuVisible && user && <SignOutContextMenu handleSignOut={handleSignOut} />}
      {isMenuVisible && !user && <CreateAccountContextMenu />}
    </div>
  );
}

export function CreateAccountContextMenu() {
  function handleCreateAccount() {
    const url = `${CLIENT_URL}${CLIENT_ROUTE.SIGN_IN}`;
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  }

  return (
    <div className="absolute right-0 mt-2 p-2 w-48 bg-white rounded-md shadow-xl z-20">
      <button
        onClick={handleCreateAccount}
        className="flex justify-center items-center space-x-2 px-2 py-4 text-sm rounded-sm text-gray-800 hover:bg-indigo-100 w-full text-left"
      >
        <div>Create your account</div>
        <ChevronRight size={15} />
      </button>
      <HorizontalDivider />
      <div className="my-2 text-center text-xs text-gray-400">
        Create your account to edit and add documents
      </div>
    </div>
  );
}

export default TopDocumentRow;
