import { CSSProperties, useEffect, useRef } from 'react';
import { ReactFlowState, useStore } from 'reactflow';

const canvasStyle: CSSProperties = {
  width: '100%',
  height: '100%',
  position: 'absolute',
  zIndex: 100,
  pointerEvents: 'none',
  opacity: 0.5,
};

const storeSelector = (state: ReactFlowState) => ({
  width: state.width,
  height: state.height,
  transform: state.transform,
});

export type HelperLinesProps = {
  horizontal?: number;
  vertical?: number;
};

function HelperLines({ horizontal, vertical }: HelperLinesProps) {
  const { width, height, transform } = useStore(storeSelector);
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas?.getContext('2d');

    if (!ctx || !canvas) {
      return;
    }

    const dpi = window.devicePixelRatio;
    canvas.width = width * dpi;
    canvas.height = height * dpi;

    ctx.scale(dpi, dpi);
    ctx.clearRect(0, 0, width, height);
    ctx.strokeStyle = '#0041d0';
    // Set dashed line style
    ctx.setLineDash([5, 5]); // This will create a dashed line with 5px dashes and 5px gaps

    if (typeof vertical === 'number') {
      ctx.beginPath(); // Begin a new path for the vertical line
      ctx.moveTo(vertical * transform[2] + transform[0], 0);
      ctx.lineTo(vertical * transform[2] + transform[0], height);
      ctx.stroke(); // Draw the vertical line
    }

    if (typeof horizontal === 'number') {
      ctx.beginPath(); // Begin a new path for the horizontal line
      ctx.moveTo(0, horizontal * transform[2] + transform[1]);
      ctx.lineTo(width, horizontal * transform[2] + transform[1]);
      ctx.stroke(); // Draw the horizontal line
    }
  }, [width, height, transform, horizontal, vertical]);

  return <canvas ref={canvasRef} style={canvasStyle} />;
}

export default HelperLines;
