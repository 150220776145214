// src/slices/uiSlice.ts

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export enum SideNavigationBarSection {
  BoxDiagram = "Diagram",
  DSM = "DSM",
  ICD = "ICD",
  Profile = "Profile",
  Orders = "Orders",
  Billings = "Billings",
  Settings = "Settings",
  Exit = "Back to documents",
}
export interface UIState {
  // Used for DSM will need to refactor
  selectDialogPosition: { top: 0; left: 0; bottom: 0; right: 0 };
  currentScreen: SideNavigationBarSection;
}

const initialState: UIState = {
  selectDialogPosition: { top: 0, left: 0, bottom: 0, right: 0 },
  currentScreen: SideNavigationBarSection.BoxDiagram, // Unused i think
};

export const uiSlice = createSlice({
  name: "legend",
  initialState,
  reducers: {
    setSelectDialogPosition: (state, action) => {
      state.selectDialogPosition = action.payload.position;
    },
    setCurrentScreen: (state, action: PayloadAction<{ screen: SideNavigationBarSection }>) => {
      state.currentScreen = action.payload.screen;
    },
  },
});

export const { setSelectDialogPosition, setCurrentScreen } = uiSlice.actions;
export default uiSlice.reducer;
