import { MousePointerClick, MousePointerSquare } from "lucide-react";
import { HorizontalDivider } from "../../Documents/DocumentCard/DocumentCardContextMenu";
import { BaseContextMenu } from "../../LandingPage/BaseContextMenu";
import { useDispatch, useSelector } from "react-redux";
import {
  NodeType,
  baseNodeData,
  createNewNode,
  defaultNodeDimensions,
  getNodeID,
} from "src/models/BoxDiagram/Node";
import { addNewEdge, addNode } from "src/state/slices/documentSlice";
import { Node, XYPosition, useReactFlow } from "reactflow";
import { RefObject, useMemo } from "react";
import { EdgeType, NewEdgeParams, createNewEdge, getEdgeID } from "src/models/BoxDiagram/Edge";
import { RootState } from "src/state/slices";
import { getNodeById } from "src/state/selectors/boxDiagramSelectors";
import {
  calculateOptimalHandles,
  childPosRelativeToFrame,
  isChildInsideFrame,
} from "src/utils/boxDiagramUtils";

export interface NodeDropMenuState {
  top: number;
  left: number;
  right?: number;
  bottom?: number;
  source: string;
  handle: string;
}

interface NodeDropMenuProps {
  rfContainerRef: RefObject<HTMLDivElement>;
  onClose: () => void;
  state: NodeDropMenuState;
}
function NodeDropMenu({ rfContainerRef, onClose, state }: NodeDropMenuProps) {
  const dispatch = useDispatch();
  const rfInstance = useReactFlow();
  const nodes = useSelector((state: RootState) => state.document.documentContainer.nodes);
  const frameNodes = useMemo(() => nodes.filter((node) => node.type === NodeType.Frame), [nodes]);

  //   const frameNodes = useSelector((state: RootState) =>
  //     state.document.documentContainer.nodes.filter((node) => node.type === NodeType.Frame)
  //   );

  const sourceNode = useSelector((_state: RootState) =>
    getNodeById(_state.document.documentContainer.nodes, state.source)
  );
  const styles = {
    top: state.top,
    left: state.left,
    width: 160,
  };

  function getHandle(position: XYPosition) {
    const dummyNode = createNewNode("dummyNode", baseNodeData, position);
    const { sourceHandle, targetHandle } = calculateOptimalHandles(sourceNode, dummyNode);
    return targetHandle;
  }

  function getParentNode(position: XYPosition): Node | undefined {
    const dummyNode = createNewNode("dummyNode", baseNodeData, position);

    for (const frame of frameNodes) {
      if (isChildInsideFrame(frame, dummyNode)) {
        return frame;
      }
    }
  }

  function handleOnClick() {
    // Get container bounds. Need to adjust position since we account for container position
    // when initially placing this component with coordinates from usePromptOnEdgeDrop
    const rect = rfContainerRef.current.getBoundingClientRect();
    // Get position of the mouse click in the grid
    const flowPos = rfInstance.screenToFlowPosition({
      x: state.left + rect.x,
      y: state.top + rect.y,
    });
    // Calculate position of the new node
    let targetNodePos = {
      x: flowPos.x,
      y: flowPos.y,
    };
    // Get the handle of the target node
    const targetHandle = getHandle(targetNodePos);
    // Create the new node
    const targetNodeId = getNodeID();
    // Get the parent node if the target node is inside a frame
    const parentNode = getParentNode(targetNodePos);
    // Adjust the position of the target node if it is inside a frame
    if (parentNode) {
      targetNodePos = childPosRelativeToFrame(parentNode.position, targetNodePos);
    }
    dispatch(
      addNode({
        nodeId: targetNodeId,
        position: targetNodePos,
        parentNode: parentNode?.id,
      })
    );

    // Create the new edge to connect the source and target nodes
    const params: NewEdgeParams = {
      id: getEdgeID(),
      type: EdgeType.DefaultEdge,
      source: state.source,
      target: targetNodeId,
      sourceHandle: state.handle,
      targetHandle: targetHandle,
    };

    const newEdge = createNewEdge(params);
    dispatch(addNewEdge(newEdge));
    onClose();
  }

  return (
    <BaseContextMenu onClose={onClose} styles={styles}>
      <div className="flex justify-center items-center w-full py-1">
        <button
          className="flex justify-center items-center w-full py-2 mx-2 rounded-md border border-indigo-700 text-indigo-900 hover:bg-indigo-50 "
          onClick={handleOnClick}
        >
          <MousePointerSquare size={16} className="mr-2" />
          <div className="text-sm">Add new block</div>
        </button>
      </div>
    </BaseContextMenu>
  );
}

export default NodeDropMenu;
