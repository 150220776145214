// src/selectors/boxDiagramSelectors.js

import { Edge, Node } from "reactflow";
import { RootState } from "../slices";
import { EdgeData } from "src/models/BoxDiagram/Edge";
import { NodeData, NodeType } from "src/models/BoxDiagram/Node";
import { createSelector } from "reselect";
import { createMatrixV3 } from "src/models/DSMCell";

export function getNodeById(nodes: Node<NodeData>[], nodeId: string): Node<NodeData> | undefined {
  return nodes.find((node) => node.id === nodeId);
}

export function getEdgeById(state: RootState, edgeId: string): Edge<EdgeData> | undefined {
  return state.document.documentContainer.edges.find((edge) => edge.id === edgeId);
}

export function getEdgeBySourceAndTarget(
  edges: Edge<EdgeData>[],
  source: string,
  target: string | undefined
): Edge<EdgeData> | undefined {
  // If there is no target, the edge is not connected to anything
  if (!target) {
    return null;
  }
  // Find the edge that has the same source and target
  return edges.find((edge) => edge.source === source && edge.target === target);
}

// This is a basic selector function.
// It takes the entire Redux state and returns the 'edges' part of the state.
// 'edges' are assumed to be part of the 'documentContainer' which is in turn part of the 'document' slice of the state.
const getEdges = (state: RootState) => state.document.documentContainer.edges;

// This is a memoized selector created using 'createSelector' from Reselect.
// A memoized selector only recomputes the result when its input selectors return different values than previous calls.
export const getEdgesBySourceAndTarget = createSelector(
  // The first argument to 'createSelector' is an array of input selectors.
  // 'getEdges' is the first input selector - it gets the edges from the state.
  // The second and third are inline selectors that simply pass through the 'source' and 'target' arguments.
  [getEdges, (state, source) => source, (state, source, target) => target],

  // The second argument is the output selector.
  // It receives the results of the input selectors as its arguments - in this case, edges, source, and target.
  (edges, source, target) => {
    // If 'target' is not defined, return an empty array.
    // This is a guard clause to handle cases where 'target' might be undefined or null.
    if (!target) {
      return [];
    }

    // Return a filtered list of edges where the edge's source and target match the provided source and target.
    // This filters the 'edges' array to include only those edges that connect the specified 'source' and 'target'.
    return edges.filter((edge) => edge.source === source && edge.target === target);
  }
);

// First, define a basic selector that retrieves the nodes from the state.
const getNodes = (state: RootState) => state.document.documentContainer.nodes;

// Now, use createSelector to create a memoized selector that calculates gridData only when nodes change.
export const getGridData = createSelector(
  [getNodes], // This is an array of input selectors.
  (nodes) => {
    // This function is the output selector that computes the grid data.
    // It only runs when `getNodes` returns a different array than before.
    return createMatrixV3(
      nodes.filter((node) => node.type !== NodeType.EdgeToolbar && node.type !== NodeType.Frame)
    );
  }
);

// Memoized selector for edges for ICD so we only rerender when edges change
export const getMemoizedEdgesNotConnectedToFrame = createSelector(
  [getEdges, getNodes],
  (edges, nodes) => {
    return edges.filter((edge) => {
      const sourceNode = getNodeById(nodes, edge.source);
      const targetNode = getNodeById(nodes, edge.target);
      // Incase there are no edges
      if (!sourceNode || !targetNode) {
        return false;
      }
      return sourceNode.type !== NodeType.Frame && targetNode.type !== NodeType.Frame;
    });
  }
);
