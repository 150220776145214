import {
  EdgeProps,
  getSmoothStepPath,
  EdgeLabelRenderer,
  BaseEdge,
  getStraightPath,
  getSimpleBezierPath,
  Position,
} from "reactflow";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { EdgeData, EdgePathShape, EdgePathStyle, LabelInfo } from "../../../models/BoxDiagram/Edge";
import { RootState } from "../../../state/slices";
import { getEdgeById } from "../../../state/selectors/boxDiagramSelectors";
import { nanoid } from "nanoid";
import { updateEdgeData } from "src/state/reducers/boxDiagramReducers";
import { deleteEdges, setSelectedEdge } from "src/state/slices/documentSlice";
import { setQuickDisplayEdgeID } from "src/state/slices/BoxDiagram/boxDiagramUISlice";

interface EdgeLabelInputProps {
  id: string;
  label: LabelInfo;
  edgeData: EdgeData;
}

/**
 * Custom edge that can show a label when clicked.
 */
function DefaultEdge({
  id,
  source,
  target,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style,
  data,
  markerStart,
  markerEnd,
}: EdgeProps) {
  const dispatch = useDispatch();
  const edgeData = data as EdgeData;

  const [isHovered, setIsHovered] = useState(false);
  const selectedEdge = useSelector((state: RootState) => state.document.selectedEdge);
  const isSelected = selectedEdge?.id === id;

  const edge = useSelector((state: RootState) => getEdgeById(state, id));

  // Original: Calculate the path for the edge
  const [edgePath, labelX, labelY] = getPathByStyle({
    style: edgeData.path_shape,
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
  });

  // Handler for double click event to show the input
  function handleOnDoubleClick(event: React.MouseEvent) {
    if (edgeData.labels.length > 0) return;

    const newLabel: LabelInfo = {
      id: nanoid(4),
      text: "",
      x: labelX,
      y: labelY,
    };
    const updatedLabels = [...edgeData.labels, newLabel];

    // Update edge data in Redux to include the new label
    dispatch(updateEdgeData({ id, newData: { labels: updatedLabels } }));
  }

  // Update label position when the edge is moved
  useEffect(() => {
    if (edgeData.labels.length > 0) {
      const updatedLabels = edgeData.labels.map((label) => {
        return { ...label, x: labelX, y: labelY };
      });
      dispatch(updateEdgeData({ id, newData: { labels: updatedLabels } }));
    }
  }, [labelX, labelY]);

  // Sometimes, a user can remove the edge and change screens before we have time to delete the edge from the store.
  // If that happens, we should delete the edge from the store.
  useEffect(() => {
    if (edgeData.connections.length === 0) {
      console.log("Deleting edge with no connection");
      dispatch(deleteEdges({ edges: [{ id, source, target }] }));
    }
  }, []);

  const handleMouseEnter = () => {
    dispatch(setQuickDisplayEdgeID({ edgeID: id}));
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);

    if (selectedEdge) {
      dispatch(setQuickDisplayEdgeID({ edgeID: selectedEdge.id }));
    }
    else {
      dispatch(setQuickDisplayEdgeID({ edgeID: null }));
    }
  };

  const isHoveredBg = "blue";
  const isSelectedBg = isSelected
    ? edgeData.connections.length > 0 && edgeData.connections[0].color.css.sixHundred
    : edgeData.connections.length > 0 && edgeData.connections[0].color.css.fourHundred;

  // Styles for solid, dashed, and dotted lines
  const solidStrokeDasharray = "0";
  const dashedStrokeDasharray = "20 10"; // Dashed pattern (dash length and space between)
  const dottedStrokeDasharray = "8 8"; // Dots pattern (dot length and space between)

  function getStrokeDasharray() {
    if (edgeData.path_style === EdgePathStyle.Line) {
      return solidStrokeDasharray;
    }
    if (edgeData.path_style === EdgePathStyle.Dashed) {
      return dashedStrokeDasharray;
    }
    if (edgeData.path_style === EdgePathStyle.Dotted) {
      return dottedStrokeDasharray;
    }

    // Default to solid line
    return solidStrokeDasharray;
  }

  const edgeStyle = {
    strokeWidth: 2,
    stroke: isHovered ? isHoveredBg : isSelectedBg,
    opacity: isSelected ? 1 : 0.75,
    zIndex: 100000,
    strokeDasharray: getStrokeDasharray(),
  };

  const invisibleStyle = {
    strokeWidth: 28,
    stroke: "transparent",
  };

  return (
    <g
      onDoubleClick={handleOnDoubleClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{ zIndex: 100000 }}
    >
      {/* This path is visible to user. It manage interaction with Reactflow */}
      <BaseEdge
        id={id}
        path={edgePath}
        markerEnd={markerEnd}
        markerStart={markerStart}
        style={edgeStyle}
      />
      {/* This path is invisible to user, but is used for detecting mouse events like hovering */}
      <path
        id={id}
        fill="none"
        d={edgePath}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        style={invisibleStyle}
      />
      <EdgeLabelRenderer>
        {edgeData.labels.map((label) => (
          <EdgeLabelInput
            key={label.id}
            id={id} // edge ID
            edgeData={edgeData}
            label={label}
          />
        ))}
      </EdgeLabelRenderer>
    </g>
  );
}

/**
 * Renders the input for editing the edge label.
 */
function EdgeLabelInput({ id, edgeData, label }: EdgeLabelInputProps) {
  const MIN_WIDTH = 80; // pixels
  const MIN_HEIGHT = 20; // pixels

  const [inputValue, setInputValue] = useState(label.text);

  const dispatch = useDispatch();

  const edge = useSelector((state: RootState) => getEdgeById(state, id));
  const selectedEdge = useSelector((state: RootState) => state.document.selectedEdge);
  const isSelected = selectedEdge?.id === id;

  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const textMeasureRef = useRef<HTMLSpanElement>(null);

  // Adjust the size of the textarea to fit its content
  function adjustTextAreaSize() {
    if (textAreaRef.current && textMeasureRef.current) {
      // Reset the textarea height to minimal before adjusting based on content
      textAreaRef.current.style.height = "auto";
      // Set the text of the hidden span to the textarea value
      textMeasureRef.current.textContent = textAreaRef.current.value || " ";
      const newWidth = Math.max(textMeasureRef.current.offsetWidth, MIN_WIDTH);
      const newHeight = Math.max(textAreaRef.current.scrollHeight, MIN_HEIGHT);

      textAreaRef.current.style.width = `${newWidth}px`;
      textAreaRef.current.style.height = `${newHeight}px`;
    }
  }

  // Focus and adjust the textarea when it's shown
  useEffect(() => {
    if (textAreaRef.current) {
      adjustTextAreaSize();
      textAreaRef.current.focus();
      // Highlight the text
      textAreaRef.current.setSelectionRange(0, inputValue.length);
    }
  }, []);

  // Update input value and adjust size when the label changes
  function handleOnChange(e: React.ChangeEvent<HTMLTextAreaElement>) {
    setInputValue(e.target.value);
    adjustTextAreaSize();
  }

  // Handler for when the input loses focus
  function handleOnBlur() {
    // If label is empty, remove it
    if (!inputValue) {
      const updatedLabels = edgeData.labels.filter((l) => l.id !== label.id);
      dispatch(updateEdgeData({ id, newData: { labels: updatedLabels } }));
    } else {
      // Otherwise, update the label text
      const updatedLabels = edgeData.labels.map((l) =>
        l.id === label.id ? { ...l, text: inputValue } : l
      );
      dispatch(updateEdgeData({ id, newData: { labels: updatedLabels } }));
    }
  }

  function onKeyDown(e: React.KeyboardEvent<HTMLTextAreaElement>) {
    // If the user presses Enter without Shift, deselect the edge
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault(); // Prevent default to avoid a new line on Enter
      textAreaRef.current?.blur();
      dispatch(setSelectedEdge({ edge: null }));
    }
  }

  function handleOnFocus() {
    if (!edge) throw new Error("Edge not found");
    // dispatch(setSelectedEdge({ edge })); // Is this needed?
  }

  // Prevent double click from adding new labels
  function stopPropagation(e: React.MouseEvent) {
    e.stopPropagation();
  }

  // Border styling
  const borderStyle = isSelected ? "border border-blue-500" : "border ";

  return (
    <div>
      <textarea
        onKeyDown={onKeyDown}
        ref={textAreaRef}
        style={{
          position: "absolute",
          transform: `translate(-50%, -50%) translate(${label.x}px, ${label.y}px)`,
          pointerEvents: "all",
          resize: "none", // Disable manual resizing
        }}
        value={inputValue}
        onChange={handleOnChange}
        onFocus={handleOnFocus}
        onBlur={handleOnBlur}
        onDoubleClick={stopPropagation}
        className={
          "flex text-center py-1 text-xs bg-blue  rounded multiline overflow-hidden outline-none " +
          borderStyle
        }
      />
      {/* Hidden span for measuring text width */}
      <span
        ref={textMeasureRef}
        style={{
          visibility: "hidden",
          position: "absolute",
          whiteSpace: "pre",
          fontSize: "inherit",
          fontFamily: "inherit",
          backgroundColor: "black",
        }}
      />
    </div>
  );
}

function getPathByStyle({
  style,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
}: {
  style: EdgePathShape;
  sourceX: number;
  sourceY: number;
  targetX: number;
  targetY: number;
  sourcePosition?: Position;
  targetPosition?: Position;
}): [string, number, number] {
  switch (style) {
    case EdgePathShape.Straight:
      const [edgePathStraight, labelXStraight, labelYStraight] = getStraightPath({
        sourceX,
        sourceY,
        targetX,
        targetY,
      });
      return [edgePathStraight, labelXStraight, labelYStraight];

    case EdgePathShape.SmoothStep:
      const [edgePathSmoothStep, labelXSmoothStep, labelYSmoothStep] = getSmoothStepPath({
        sourceX,
        sourceY,
        targetX,
        targetY,
        sourcePosition,
        targetPosition,
        borderRadius: 15, // or any other parameter specific to SmoothStep
      });
      return [edgePathSmoothStep, labelXSmoothStep, labelYSmoothStep];

    case EdgePathShape.SimpleBezier:
      const [edgePathSimpleBezier, labelXSimpleBezier, labelYSimpleBezier] = getSimpleBezierPath({
        sourceX,
        sourceY,
        targetX,
        targetY,
        sourcePosition,
        targetPosition,
      });
      return [edgePathSimpleBezier, labelXSimpleBezier, labelYSimpleBezier];

    default:
      throw new Error(`Invalid edge path shape: ${style}`);
  }
}

export default DefaultEdge;
