import React from "react";
import styled, { keyframes } from "styled-components";

const items = [
  "Web Development",
  "iOS Development",
  "Mentoring",
  "QA Testing",
  "Product Design",
  // "Competitive Analysis",
  "API Development",
  "UX Research",
  // "Product Management",
  "Android Development",
  "System Architecture",
  "Database Design",
];

// Create the keyframes for the continuous scroll effect
const scroll = keyframes`
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
`;

// Styled div that will contain the scrolling text
const ScrollingTextContainer = styled.div`
  white-space: nowrap;
  overflow: hidden;
  padding: 0px 0;
`;

// Styled div for the text that will be animated
const ScrollingText = styled.div`
  display: inline-block;
  animation: ${scroll} ${items.length * 2500}s linear infinite; // Adjust the duration as needed
`;

const InfiniteTextRotator = () => {
  // Array of skills or any other items you want to scroll

  let finalItems = [];
  for (let i = 0; i < 500; i++) {
    finalItems = finalItems.concat(items);
  }

  return (
    <ScrollingTextContainer className="flex items-center justify-center mt-4 text-2xl text-center font-bold text-neutral-800">
      {/* Render two copies of the text to create an infinite effect */}
      <ScrollingText>{finalItems.join(" • ") + " • "}</ScrollingText>
      {/* <ScrollingText>{finalItems.join(" • ") + " • "}</ScrollingText> */}
    </ScrollingTextContainer>
  );
};

export default InfiniteTextRotator;
