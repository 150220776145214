// src/components/BoxDiagram/Nodes/BaseNode.tsx

import React, { useEffect, useState, useRef } from "react";
import {
  NodeResizer,
  ResizeDragEvent,
  ResizeParams,
  NodeProps,
} from "reactflow";
import { useDispatch } from "react-redux";
import { BorderStyle, BoxDiagramShape, NodeData } from "../../../models/BoxDiagram/Node";
import "./styles/nodeStyles.css";
import NodeHandles from "./BaseNodeComponents/NodeHandels";
import {
  deselectAllNodes,
  setAllNodesNotEditing,
  updateNodeData,
} from "src/state/reducers/boxDiagramReducers";
import { deleteNode, setNodeLabel } from "src/state/slices/documentSlice";
import FrameNodeToolbar from "../Toolbars/FrameToolbar/FrameToolbar";
import { Color } from "src/models/BoxDiagram/Colors";

const shapeStyles: Record<BoxDiagramShape, string> = {
  rectangle: "h-20 w-52 rounded-md cursor-pointer border border-black",
  circle: "h-32 w-32 rounded-full cursor-pointer border border-black",
  square: "h-32 w-32 cursor-pointer border border-black",
  triangle: "h-10 w-10 cursor-pointer border border-black",
  diamond: "h-32 w-32 cursor-pointer border border-black transform rotate-45",
};

function FrameNode({ id, selected, data, zIndex }: NodeProps) {
  const nodeData = data as NodeData;
  const dispatch = useDispatch();

  // Ref for the whole node to listen for when the user clicks outside of the node
  const nodeRef = useRef<HTMLDivElement>(null);

  const [nodeDimensions, setNodeDimensions] = useState(nodeData.dimensions);
  const [showHandles, setShowHandles] = useState(false);

  // Make sure to update position after redo/undo
  useEffect(() => {
    setNodeDimensions(nodeData.dimensions);
  }, [nodeData.dimensions, nodeData.rotation]);

  // Styles for rotation and resizing
  const nodeStyles = {
    height: nodeDimensions?.height,
    width: nodeDimensions?.width,
    zIndex: zIndex,
    backgroundColor: nodeData.bg_color,
    borderColor: nodeData.border_color || "black",
    borderWidth: typeof nodeData.border_width === "number" ? nodeData.border_width : 2,
    borderStyle: nodeData.border_style || BorderStyle.Solid,
  };
  const smallHandleStyle = {
    background: "white",
    width: "10px",
    height: "10px",
    borderRadius: "50%",
    border: "1px solid black",
    zIndex: 100,
  };

  // Node Resizing
  function handleResize(event: ResizeDragEvent, params: ResizeParams) {
    // const position = { x: params.x, y: params.y };
    const dimensions = { width: params.width, height: params.height };
    // Update locally in real time
    setNodeDimensions(dimensions);
    if (event.type === "end") {
      // Save the new dimensions and position of the node to reference later
      dispatch(updateNodeData({ id, newData: { dimensions } }));
    }
  }

  function handleDeselectAllNodes() {
    dispatch(deselectAllNodes({}));
  }

  // Deselect all nodes and select this node
  function handleShowToolbar() {
    // Show NodeToolbar, if not already shown
    if (!nodeData.show_node_toolbar) {
      dispatch(updateNodeData({ id, newData: { show_node_toolbar: true } }));
    }
  }

  // Enable editing of the node label and disable editing for all other nodes
  function handleOnDoubleClick() {
    dispatch(setAllNodesNotEditing());
    dispatch(updateNodeData({ id, newData: { is_editing: true } }));
  }

  // Set is editing to false when the textarea loses focus.
  function handleOnBlur() {}

  // Delete node if the user presses backspace and the node label is not being edited
  function handleOnKeyDown(event: React.KeyboardEvent<HTMLDivElement>) {
    if (event.key === "Backspace" && !nodeData.is_editing) {
      dispatch(deleteNode({ sourceNodeId: id }));
    }
  }

  function onMouseEnter() {
    setShowHandles(true);
  }

  function onMouseLeave() {
    setShowHandles(false);
  }

  return (
    <div
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      style={{ position: "relative", zIndex: zIndex }}
    >
      {/* Frame label */}
      <AutoExpandingTextarea
        zIndex={zIndex}
        handleShowToolbar={handleShowToolbar}
        nodeId={id}
        nodeData={nodeData}
      />

      {/* The main node div */}
      <div
        id={id}
        ref={nodeRef}
        style={nodeStyles}
        className={`${shapeStyles[nodeData.shape]} nodrag overflow-visible text-center rounded-lg`}
        onClick={handleDeselectAllNodes}
      >
        {/* Node content and handles here */}
        {/* Enables the user to make the node larger or smaller */}
        <NodeResizer
          handleStyle={smallHandleStyle}
          onResizeEnd={handleResize}
          onResize={handleResize}
          isVisible={selected}
          minWidth={50}
          minHeight={12}
          lineStyle={{ opacity: 0.5 }}

          // keepAspectRatio={true}
        />
        <NodeHandles show={showHandles} />
        <FrameNodeToolbar nodeId={id} nodeData={data} />
      </div>
    </div>
  );
}

interface AutoExpandingTextareaProps {
  nodeId: string;
  nodeData: NodeData;
  zIndex: number;
  handleShowToolbar: () => void;
}
function AutoExpandingTextarea({
  nodeId,
  nodeData,
  zIndex,
  handleShowToolbar,
}: AutoExpandingTextareaProps) {
  const dispatch = useDispatch();

  const [text, setText] = useState(nodeData.label || "Frame");
  const textAreaRef = useRef(null);
  const textMeasureRef = useRef(null);

  const updateSize = () => {
    const textArea = textAreaRef.current;
    const textMeasure = textMeasureRef.current;
    if (!textArea || !textMeasure) return;

    // Set the text for the hidden span
    textMeasure.textContent = text || " "; // Use a space to get a minimum width

    // Adjust the width of the textarea to match the hidden span
    const textWidth = textMeasure.offsetWidth;
    textArea.style.width = `${textWidth}px`;

    // Adjust the height of the textarea to fit its content
    textArea.style.height = "auto"; // Temporarily auto to get the correct scrollHeight
    const textHeight = textArea.scrollHeight;
    const minHeight = 5; // Minimum height for empty textarea
    textArea.style.height = `${Math.max(textHeight, minHeight)}px`;
  };

  useEffect(() => {
    updateSize();
  }, [text]);

  const handleChange = (event) => {
    setText(event.target.value);
  };

  // Use the 'input' event for instant updating
  const handleInput = () => {
    updateSize();
  };

  const handleClick = (event) => {
    handleShowToolbar();
    event.preventDefault(); // Prevent the textarea from focusing on single click
  };

  const handleDoubleClick = () => {
    const textArea = textAreaRef.current;
    textArea.focus(); // Focus the textarea on double click
    textArea.select(); // Select all text on double click
  };

  function handleOnBlur() {
    // Set is editing to false when the textarea loses focus
    // dispatch(updateNodeData({ id, newData: { is_editing: false } }));
    if (text.length > 0 && text !== nodeData.label) {
      dispatch(setNodeLabel({ nodeId, label: text }));
    }
  }

  function handleOnKeyDown(event: React.KeyboardEvent<HTMLTextAreaElement>) {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      const textArea = textAreaRef.current;
      textArea.blur();
    }
  }

  return (
    <div onDragEnd={handleShowToolbar} className="relative flex items-center justify-center">
      {/* Hidden span to measure text width */}
      <span
        ref={textMeasureRef}
        className="px-2"
        style={{
          position: "absolute",
          visibility: "hidden",
          height: "auto",
          width: "auto",
          whiteSpace: "pre",
        }}
      />

      {/* Textarea that auto-expands */}
      <textarea
        ref={textAreaRef}
        value={text}
        onChange={handleChange}
        onInput={handleInput}
        onClick={handleClick}
        onDoubleClick={handleDoubleClick}
        onKeyDown={handleOnKeyDown}
        onBlur={handleOnBlur}
        className="mb-1 text-start resize-none rounded-md cursor-move text-white"
        style={{
          position: "absolute",
          bottom: "100%", // Position from the bottom of the parent
          left: 0,
          zIndex: zIndex,
          boxSizing: "border-box", // Include padding in the width calculation
          overflow: "hidden", // Prevent scrollbars
          padding: "5px", // Some padding
          resize: "none", // No resizing
          lineHeight: "normal", // Makes each line a bit smaller
          backgroundColor: nodeData.border_color || Color.Blue.css.fiveHundred,
        }}
        rows={1} // Fixes height issues when there is only one line
      />
    </div>
  );
}

export default FrameNode;
