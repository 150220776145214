// src/app/store.ts

import { configureStore } from "@reduxjs/toolkit";
import rootReducer, { RootState } from "./state/slices/index";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";

// Persist configuration
const persistConfig = {
  key: "root",
  version: 1,
  storage,
  whitelist: ["user"], // Add more slices if you want to persist them as well
};

// Persisted reducer
const persistedReducer = persistReducer<RootState>(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      // serializableCheck: {
      //   // Ignore these action types
      //   ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      // },
      
      // Disable these checks for performance. When you are ready to debug, remove them from the param list
      // Redux scans entire state to check if it is serializable. This is a performance hit when 
      // I have large DSM or ICD loaded. When i move blocks in box diagram, the state is changed every
      // few milliseconds. This causes the app to freeze for a few seconds.
      immutableCheck: false,
      serializableCheck: false,
  
    }),
});

export const persistor = persistStore(store);
