// src/components/BlockDiagram/Hooks/useIsMobile.ts

import { useEffect, useState } from "react";

export function useIsMobile() {
  // State to control the visibility of the popup
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    // Function to check screen width
    const checkScreenWidth = () => {
      const screenWidth = window.innerWidth;
      // Define the maximum width you consider to be mobile-sized
      if (screenWidth <= 572) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };

    // Check on mount and subsequent resize events
    checkScreenWidth();
    window.addEventListener("resize", checkScreenWidth);

    // Cleanup listener to prevent memory leaks
    return () => window.removeEventListener("resize", checkScreenWidth);
  }, []);

  return { isMobile };
}

export function useScreenSize() {
  // State to control the visibility of the popup
  const [isSmall, setIsSmall] = useState(false);
  const [isMedium, setIsMedium] = useState(false);
  const [isLarge, setIsLarge] = useState(false);
  const [isXLarge, setIsXLarge] = useState(false);
  const [is2XLarge, setIs2XLarge] = useState(false);

  useEffect(() => {
    // Function to check screen width
    const checkScreenWidth = () => {
      const screenWidth = window.innerWidth;

      if (screenWidth <= 640) {
        setIsSmall(true);
        setIsMedium(false);
        setIsLarge(false);
        setIsXLarge(false);
        setIs2XLarge(false);
      } else if (screenWidth <= 768) {
        setIsSmall(false);
        setIsMedium(true);
        setIsLarge(false);
        setIsXLarge(false);
        setIs2XLarge(false);
      } else if (screenWidth <= 1024) {
        setIsSmall(false);
        setIsMedium(false);
        setIsLarge(true);
        setIsXLarge(false);
        setIs2XLarge(false);
      } else if (screenWidth <= 1536) {
        setIsSmall(false);
        setIsMedium(false);
        setIsLarge(false);
        setIsXLarge(true);
        setIs2XLarge(false);
      } else {
        setIsSmall(false);
        setIsMedium(false);
        setIsLarge(false);
        setIsXLarge(false);
        setIs2XLarge(true);
      }
    };
    // Check on mount and subsequent resize events
    checkScreenWidth();
    window.addEventListener("resize", checkScreenWidth);

    // Cleanup listener to prevent memory leaks
    return () => window.removeEventListener("resize", checkScreenWidth);
  }, []);

  const smOrLarger = isSmall || isMedium || isLarge || isXLarge || is2XLarge;
  const mdOrLarger = isMedium || isLarge || isXLarge || is2XLarge;
  const lgOrLarger = isLarge || isXLarge || is2XLarge;
  const xlOrLarger = isXLarge || is2XLarge;
  const xxlOrLarger = is2XLarge;

  return { smOrLarger, mdOrLarger, lgOrLarger, xlOrLarger, xxlOrLarger };
}
