import { Node, Edge, NodePositionChange, XYPosition, Position } from "reactflow";
import { NodeData } from "src/models/BoxDiagram/Node";
import { SourceHandlePrefix, TargetHandlePrefix } from "./constants";

type GetHelperLinesResult = {
  horizontal?: number;
  vertical?: number;
  snapPosition: Partial<XYPosition>;
};

// this utility function can be called with a position change (inside onNodesChange)
// it checks all other nodes and calculated the helper line positions and the position where the current node should snap to
export function getHelperLines(
  change: NodePositionChange,
  nodes: Node[],
  distance = 5
): GetHelperLinesResult {
  const defaultResult = {
    horizontal: undefined,
    vertical: undefined,
    snapPosition: { x: undefined, y: undefined },
  };
  const nodeA = nodes.find((node) => node.id === change.id);

  if (!nodeA || !change.position) {
    return defaultResult;
  }

  let nodeABounds = {
    left: change.position.x,
    right: change.position.x + (nodeA.width ?? 0),
    top: change.position.y,
    bottom: change.position.y + (nodeA.height ?? 0),
    width: nodeA.width ?? 0,
    height: nodeA.height ?? 0,
  };

  let nodeAParent = undefined;
  // If node has a parent, we need to calculate the position relative to the canvas
  // to compare it with the position of the other nodes
  if (nodeA.parentNode) {
    nodeAParent = nodes.find((node) => node.id === nodeA.parentNode);

    let nodeAPosRelativeToPane = childPosRelativeToCanvas(nodeAParent.position, nodeA.position);

    nodeABounds = {
      ...nodeABounds,
      left: nodeAPosRelativeToPane.x,
      right: nodeAPosRelativeToPane.x + (nodeA.width ?? 0),
      top: nodeAPosRelativeToPane.y,
      bottom: nodeAPosRelativeToPane.y + (nodeA.height ?? 0),
    };
  }

  let horizontalDistance = distance;
  let verticalDistance = distance;

  return nodes
    .filter((node) => node.id !== nodeA.id)
    .reduce<GetHelperLinesResult>((result, nodeB) => {
      let nodeBBounds = {
        left: nodeB.position.x,
        right: nodeB.position.x + (nodeB.width ?? 0),
        top: nodeB.position.y,
        bottom: nodeB.position.y + (nodeB.height ?? 0),
        width: nodeB.width ?? 0,
        height: nodeB.height ?? 0,
      };

      let nodeBParent = undefined;
      // If node has a parent, we need to calculate the position relative to the canvas
      // to compare it with the position of the other nodes
      if (nodeB.parentNode) {
        nodeBParent = nodes.find((node) => node.id === nodeB.parentNode);

        let nodeBPosRelativeToPane = childPosRelativeToCanvas(nodeBParent.position, nodeB.position);

        nodeBBounds = {
          ...nodeBBounds,
          left: nodeBPosRelativeToPane.x,
          right: nodeBPosRelativeToPane.x + (nodeB.width ?? 0),
          top: nodeBPosRelativeToPane.y,
          bottom: nodeBPosRelativeToPane.y + (nodeB.height ?? 0),
        };
      }

      //  |‾‾‾‾‾‾‾‾‾‾‾|
      //  |     A     |
      //  |___________|
      //  |
      //  |
      //  |‾‾‾‾‾‾‾‾‾‾‾|
      //  |     B     |
      //  |___________|
      const distanceLeftLeft = Math.abs(nodeABounds.left - nodeBBounds.left);

      if (distanceLeftLeft < verticalDistance) {
        result.snapPosition.x = nodeBBounds.left;
        result.vertical = nodeBBounds.left;
        verticalDistance = distanceLeftLeft;
      }

      //  |‾‾‾‾‾‾‾‾‾‾‾|
      //  |     A     |
      //  |___________|
      //              |
      //              |
      //  |‾‾‾‾‾‾‾‾‾‾‾|
      //  |     B     |
      //  |___________|
      const distanceRightRight = Math.abs(nodeABounds.right - nodeBBounds.right);

      if (distanceRightRight < verticalDistance) {
        result.snapPosition.x = nodeBBounds.right - nodeABounds.width;
        result.vertical = nodeBBounds.right;
        verticalDistance = distanceRightRight;
      }

      //              |‾‾‾‾‾‾‾‾‾‾‾|
      //              |     A     |
      //              |___________|
      //              |
      //              |
      //  |‾‾‾‾‾‾‾‾‾‾‾|
      //  |     B     |
      //  |___________|
      const distanceLeftRight = Math.abs(nodeABounds.left - nodeBBounds.right);

      if (distanceLeftRight < verticalDistance) {
        result.snapPosition.x = nodeBBounds.right;
        result.vertical = nodeBBounds.right;
        verticalDistance = distanceLeftRight;
      }

      //  |‾‾‾‾‾‾‾‾‾‾‾|
      //  |     A     |
      //  |___________|
      //              |
      //              |
      //              |‾‾‾‾‾‾‾‾‾‾‾|
      //              |     B     |
      //              |___________|
      const distanceRightLeft = Math.abs(nodeABounds.right - nodeBBounds.left);

      if (distanceRightLeft < verticalDistance) {
        result.snapPosition.x = nodeBBounds.left - nodeABounds.width;
        result.vertical = nodeBBounds.left;
        verticalDistance = distanceRightLeft;
      }

      //  |‾‾‾‾‾‾‾‾‾‾‾|‾‾‾‾‾|‾‾‾‾‾‾‾‾‾‾‾|
      //  |     A     |     |     B     |
      //  |___________|     |___________|
      const distanceTopTop = Math.abs(nodeABounds.top - nodeBBounds.top);

      if (distanceTopTop < horizontalDistance) {
        result.snapPosition.y = nodeBBounds.top;
        result.horizontal = nodeBBounds.top;
        horizontalDistance = distanceTopTop;
      }

      //  |‾‾‾‾‾‾‾‾‾‾‾|
      //  |     A     |
      //  |___________|_________________
      //                    |           |
      //                    |     B     |
      //                    |___________|
      const distanceBottomTop = Math.abs(nodeABounds.bottom - nodeBBounds.top);

      if (distanceBottomTop < horizontalDistance) {
        result.snapPosition.y = nodeBBounds.top - nodeABounds.height;
        result.horizontal = nodeBBounds.top;
        horizontalDistance = distanceBottomTop;
      }

      //  |‾‾‾‾‾‾‾‾‾‾‾|     |‾‾‾‾‾‾‾‾‾‾‾|
      //  |     A     |     |     B     |
      //  |___________|_____|___________|
      const distanceBottomBottom = Math.abs(nodeABounds.bottom - nodeBBounds.bottom);

      if (distanceBottomBottom < horizontalDistance) {
        result.snapPosition.y = nodeBBounds.bottom - nodeABounds.height;
        result.horizontal = nodeBBounds.bottom;
        horizontalDistance = distanceBottomBottom;
      }

      //                    |‾‾‾‾‾‾‾‾‾‾‾|
      //                    |     B     |
      //                    |           |
      //  |‾‾‾‾‾‾‾‾‾‾‾|‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾
      //  |     A     |
      //  |___________|
      const distanceTopBottom = Math.abs(nodeABounds.top - nodeBBounds.bottom);

      if (distanceTopBottom < horizontalDistance) {
        result.snapPosition.y = nodeBBounds.bottom;
        result.horizontal = nodeBBounds.bottom;
        horizontalDistance = distanceTopBottom;
      }

      return result;
    }, defaultResult);
}

// Handle types enumeration

// Function to get the center point of a node
function getCenter(node: Node<NodeData>): XYPosition {
  return {
    x: node.position.x + node.data.dimensions.width / 2,
    y: node.position.y + node.data.dimensions.height / 2,
  };
}

// Main function to calculate the optimal handles
export function calculateOptimalHandles(
  sourceNode: Node<NodeData>,
  targetNode: Node<NodeData>
): { sourceHandle: string; targetHandle: string } {
  const sourceCenter = getCenter(sourceNode);
  const targetCenter = getCenter(targetNode);

  const xDiff = sourceCenter.x - targetCenter.x;
  const yDiff = sourceCenter.y - targetCenter.y;

  let sourceHandle: Position;
  let targetHandle: Position;

  // Determine the source handle
  if (Math.abs(xDiff) > Math.abs(yDiff)) {
    sourceHandle = xDiff > 0 ? Position.Left : Position.Right;
  } else {
    sourceHandle = yDiff > 0 ? Position.Top : Position.Bottom;
  }

  // Determine the target handle
  if (Math.abs(xDiff) > Math.abs(yDiff)) {
    targetHandle = xDiff > 0 ? Position.Right : Position.Left;
  } else {
    targetHandle = yDiff > 0 ? Position.Bottom : Position.Top;
  }

  const formattedSourceHandle = `${SourceHandlePrefix}${sourceHandle}`;
  const formattedTargetHandle = `${TargetHandlePrefix}${targetHandle}`;

  return { sourceHandle: formattedSourceHandle, targetHandle: formattedTargetHandle };
}

export function isChildInsideFrame(
  frame: Node<NodeData>,
  child: Node<NodeData>,
  parentOfChild?: Node<NodeData>
): boolean {
  // Get child's dimensions and position (relative to canvas)
  let childPosRelativeToPane = child.position;
  if (parentOfChild) {
    childPosRelativeToPane = childPosRelativeToCanvas(parentOfChild.position, child.position);
  }

  // Get frame's dimensions and position
  const framePos: XYPosition = frame.position;
  const frameWidth: number = frame.data.dimensions.width;
  const frameHeight: number = frame.data.dimensions.height;

  // Get child's dimensions and position (relative to frame's top-left corner)
  const childWidth: number = child.data.dimensions.width;
  const childHeight: number = child.data.dimensions.height;

  // Check if child is within the bounds of the frame
  const isInsideHorizontally: boolean =
    childPosRelativeToPane.x >= frame.position.x &&
    childPosRelativeToPane.x + childWidth <= framePos.x + frameWidth;
  const isInsideVertically: boolean =
    childPosRelativeToPane.y >= frame.position.y &&
    childPosRelativeToPane.y + childHeight <= framePos.y + frameHeight;

  return isInsideHorizontally && isInsideVertically;
}

export function childPosRelativeToFrame(framePos: XYPosition, childPos: XYPosition): XYPosition {
  // If a node has a parent, it's position is relative to the parent, not the canvas
  // So we calculate the position of node relative to the canvas and subtract as usual
  return {
    x: childPos.x - framePos.x,
    y: childPos.y - framePos.y,
  };
}

export function childPosRelativeToCanvas(framePos: XYPosition, childPos: XYPosition): XYPosition {
  return {
    x: framePos.x + childPos.x,
    y: framePos.y + childPos.y,
  };
}
