import { Color, ColorType } from "../../../../models/BoxDiagram/Colors";
import { SubConnection } from "../../../../models/Connection";
import { CellType, DSMCell, getDSMCellLabel } from "../../../../models/DSMCell";
import { useSelector } from "react-redux";
import { RootState } from "src/state/slices";

/**
 * Display component for cell content.
 */
export function DisplayCellContent2({ cell }: { cell: DSMCell }) {
  const nodes = useSelector((state: RootState) => state.document.documentContainer.nodes);

  let cellContent: string = getDSMCellLabel({ cell, nodes });
  const cellColumnHeaderStyles = getColumnHeaderStyles(cell);

  // Apply additional styles to ensure the rotated text takes up the full cell space
  const style = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  return (
    <div className={`${cellColumnHeaderStyles}`} style={style}>
      {cellContent}
    </div>
  );
}

function getColumnHeaderStyles(cell: DSMCell): string {
  if (cell.type === CellType.ColHeader) {
    return "transform -rotate-90 whitespace-nowrap";
  }
  return "";
}

// Helper component to render a colored section
interface ColoredSectionProps {
  color: ColorType;
  style?: React.CSSProperties;
}
function ColoredSection({ color, style }: ColoredSectionProps) {
  const tailwind = color === Color.White ? "" : "border border-gray-600";
  return <div className={tailwind} style={{ backgroundColor: color.css.threeHundred, ...style }} />;
}

interface DisplayCellContentProps {
  connections: SubConnection[];
}

export function DisplayCellContent3({ connections }: DisplayCellContentProps) {
  const legendItems = connections;
  // Render different content based on the number of legend items
  switch (connections.length) {
    case 1:
      // Render a square with the color of that legend item
      return (
        <div className="flex flex-col w-full h-full ">
          <div className="flex w-full h-full">
            <ColoredSection color={legendItems[0].color} style={{ width: "50%", height: "100%" }} />
            <ColoredSection color={Color.White} style={{ width: "50%", height: "100%" }} />
          </div>
          <div className="flex w-full h-full">
            <ColoredSection color={Color.White} style={{ width: "50%", height: "100%" }} />
            <ColoredSection color={Color.White} style={{ width: "50%", height: "100%" }} />
          </div>
        </div>
      );

    case 2:
      // Render a square split down the middle vertically with each color
      return (
        <div className="flex flex-col w-full h-full">
          <div className="flex w-full h-full">
            <ColoredSection color={legendItems[0].color} style={{ width: "50%", height: "100%" }} />
            <ColoredSection color={Color.White} style={{ width: "50%", height: "100%" }} />
          </div>
          <div className="flex w-full h-full">
            <ColoredSection color={Color.White} style={{ width: "50%", height: "100%" }} />
            <ColoredSection color={legendItems[1].color} style={{ width: "50%", height: "100%" }} />
          </div>
        </div>
      );

    case 3:
      // Render a square split into three sections as specified
      return (
        <div className="flex flex-col w-full h-full">
          <div className="flex w-full h-full">
            <ColoredSection color={legendItems[0].color} style={{ width: "50%", height: "100%" }} />
            <ColoredSection color={legendItems[2].color} style={{ width: "50%", height: "100%" }} />
          </div>
          <div className="flex w-full h-full">
            <ColoredSection color={Color.White} style={{ width: "50%", height: "100%" }} />
            <ColoredSection color={legendItems[1].color} style={{ width: "50%", height: "100%" }} />
          </div>
        </div>
      );
    case 4:
      // Render a square split down the middle vertically and horizontally
      return (
        <div className="flex flex-col w-full h-full">
          <div className="flex w-full h-full">
            <ColoredSection color={legendItems[0].color} style={{ width: "50%", height: "100%" }} />
            <ColoredSection color={legendItems[2].color} style={{ width: "50%", height: "100%" }} />
          </div>
          <div className="flex w-full h-full">
            <ColoredSection color={legendItems[3].color} style={{ width: "50%", height: "100%" }} />
            <ColoredSection color={legendItems[1].color} style={{ width: "50%", height: "100%" }} />
          </div>
        </div>
      );
    default:
      return (
        <div className="flex flex-col w-full h-full">
          <div className="flex w-full h-full">
            <ColoredSection color={legendItems[0].color} style={{ width: "50%", height: "100%" }} />
            <ColoredSection color={legendItems[2].color} style={{ width: "50%", height: "100%" }} />
          </div>
          <div className="flex w-full h-full">
            <ColoredSection
              color={legendItems[3].color}
              style={{ width: "100%", height: "100%" }}
            />
            <div className="relative flex w-full h-full">
              {/* Overlay appears only on the fourth section */}
              <OverlayText text={`4+`} />
              <ColoredSection
                color={legendItems[1].color}
                style={{ width: "100%", height: "100%" }}
              />
            </div>
          </div>
        </div>
      );
  }
}

// Overlay component to show the "+5" text
const OverlayText = ({ text }: { text: string }) => (
  <div
    style={{
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      display: "flex",
      justifyContent: "center",
      fontWeight: 500,
      alignItems: "center",
      backgroundColor: "rgba(0, 0, 0, 0.1)", // Semi-transparent black background
      color: "white",
      fontSize: "14px", // Adjust the font size as needed
    }}
  >
    {text}
  </div>
);

export default DisplayCellContent3;
