// src/components/BlockDiagram/BlockDiagram.tsx

import React, { createContext, useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../state/slices";
import ReactFlow, {
  ReactFlowInstance,
  Edge,
  Node,
  NodeChange,
  Controls,
  MiniMap,
  Background,
  Panel,
  NodeResizer,
  EdgeChange,
  Connection as RFConnection,
  DefaultEdgeOptions,
  MarkerType,
  SelectionMode,
  NodeDragHandler,
  SelectionDragHandler,
  OnNodesDelete,
  useNodesInitialized,
  FitViewOptions,
  NodePositionChange,
  OnConnectStartParams,
} from "reactflow";
import "reactflow/dist/style.css";

import BaseNode from "./Nodes/BaseNode";

import NodeContextMenu from "./Nodes/Menus/NodeContextMenu";
import { BoxDiagramShape, NodeData, NodeType, baseNodeData } from "../../models/BoxDiagram/Node";
import {
  addReactFlowClickedListener,
  addSaveDiagramSnapshotListener,
  dispatchCloseMenus,
  dispatchReactFlowClicked,
} from "../../events/documentEvents";
import DefaultEdge from "./Edges/DefaultEdge";
import BlockDiagramSidebar from "./Sidebar/BlockDiagramSidebar";
import {
  setConnectionInProgress,
  setQuickDisplayEdgeID,
  setSelectItem,
  setZoomToNode,
} from "../../state/slices/BoxDiagram/boxDiagramUISlice";
import useUndoRedo from "../../models/useUndoRedo";
import BaseEdgeToolbar from "./Nodes/EdgeToolbar/BaseEdgeToolbar";
import { applyNewEdgeChanges, applyNewNodeChanges } from "src/state/reducers/boxDiagramReducers";
import {
  addNewEdge,
  addNode,
  deleteEdges,
  deleteNode,
  setSelectedEdge,
} from "src/state/slices/documentSlice";
import { useRightClickPanning } from "./Hooks/useRightClickPanning";
import { useOnNodeContextMenu } from "./Hooks/useOnNodeContextMenu";
import { useBackspaceDelete } from "./Hooks/useBackspaceDelete";
import EdgeContextMenu from "./Edges/EdgeContextMenu";
import { useOnEdgeContextMenu } from "./Hooks/useOnEdgeContextMenu";
import { childPosRelativeToFrame, getHelperLines } from "src/utils/boxDiagramUtils";
import HelperLines from "./Nodes/HelperLines";
import { useOnSelectionContextMenu } from "./Hooks/useOnSelectionContextMenu";
import SelectionContextMenu from "./Nodes/Menus/SelectionContextMenu";
import { useUpdateCursor } from "./Hooks/useUpdateCursor";
import { useSetFrameNode } from "./Hooks/useSetFrameNode";
import FrameNode from "./Nodes/FrameNode";
import { usePromptOnEdgeDrop } from "./Hooks/usePromptOnEdgeDrop";
import NodeDropMenu from "./Other/NewNodeMenu";
import QuickDisplayEdge from "./Other/QuickDisplayEdge";
import MobileBlockPopup from "./Other/MobileBlockPopup";

export const ReactFlowInstanceContext = createContext<ReactFlowInstance | null>(null);

// Define the nodeTypes outside of the component to prevent re-renderings
const nodeTypes = {
  baseNode: BaseNode,
  edgeToolbar: BaseEdgeToolbar,
  frame: FrameNode,
};
const edgeTypes = { defaultEdge: DefaultEdge };
const defaultEdgeOptions: DefaultEdgeOptions = {};

// Interface for the context menu
export interface ContextMenuState {
  id: string;
  top?: number;
  left?: number;
  right?: number;
  bottom?: number;
}

function nodeColor(node: Node) {
  switch (node.type) {
    case "edgeToolbar":
      return "#ffffff00";
    case "baseNode":
      return "#ff0072";
    case "frame":
      return "#ff007250";
    default:
      return undefined;
  }
}

function BlockDiagram() {
  const dispatch = useDispatch();
  const nodes = useSelector((state: RootState) => state.document.documentContainer.nodes);
  const edges = useSelector((state: RootState) => state.document.documentContainer.edges);
  const zoomToNode = useSelector((state: RootState) => state.boxDiagramUI.zoomToNode);

  const quickDisplayEdgeID = useSelector(
    (state: RootState) => state.boxDiagramUI.quickDisplayEdgeID
  );

  const [helperLineHorizontal, setHelperLineHorizontal] = useState<number | undefined>(undefined);
  const [helperLineVertical, setHelperLineVertical] = useState<number | undefined>(undefined);

  // const [edgeContextState, setEdgeContextState] = useState<ContextMenuState | null>(null);

  // Initialize the reactFlowInstance to display the grid
  const [reactFlowInstance, setReactFlowInstance] = useState<ReactFlowInstance | null>(null);
  const reactFlowRef = useRef<HTMLDivElement>(null);

  // Get hook to handle undo/redo
  const { takeSnapshot } = useUndoRedo();

  // Updates the cursor based on the selected sidebar menu item
  useUpdateCursor();

  const { handleFrameDragStart, handleFrameDragEnd, handleUpdateParentNodeOnDrop } =
    useSetFrameNode(reactFlowRef);

  // Get hook to handle right click and scroll wheel panning
  useRightClickPanning(reactFlowInstance, reactFlowRef);

  // Get hook to handle right click node context menu
  const { menu, setMenu, handleNodeRightClick } = useOnNodeContextMenu(reactFlowRef);
  const { edgeContextState, setEdgeContextState, handleEdgeRightClick } =
    useOnEdgeContextMenu(reactFlowRef);
  const { selectionContextMenu, setSelectionContextMenu, handleSelectionRightClick } =
    useOnSelectionContextMenu(reactFlowRef);

  const {
    onConnectStartEdgeDrop,
    onConnectEndEdgeDrop,
    onConnectEdgeDrop,
    nodeDropMenuState,
    setNodeDropMenuState,
  } = usePromptOnEdgeDrop(reactFlowRef);

  // Get hook to handle deleting nodes and edges when Backspace is pressed
  useBackspaceDelete();

  // Handles clicks anywhere in reactFlow
  addReactFlowClickedListener(() => {
    // Close the context menu, if open
    setMenu(null);
    // Close edge context menu, if open
    setEdgeContextState(null);
    // Close node drop menu, if open
    setNodeDropMenuState(null);
    // Close sidebar menu, if open
    dispatch(setSelectItem({ item: null }));
  });

  // Saves grid state for undo/redo
  addSaveDiagramSnapshotListener(() => {
    // console.log("saving snapshot");
    takeSnapshot();
  });

  // Handles clicks anywhere in reactFlow
  function reactFlowComponentClicked(elementId?: string) {
    dispatchReactFlowClicked(window);
  }

  // Initialize the reactFlowInstance
  function handleOnInit(instance: ReactFlowInstance) {
    setReactFlowInstance(instance);

    const options: FitViewOptions = {
      padding: 5.0,
      nodes: nodes.filter((node) => node.id === zoomToNode),
      duration: 500,
      minZoom: 0.6,
      maxZoom: 3,
    };

    // Zoom into node is selected, zoom into the node
    if (zoomToNode) {
      instance.fitView(options);
      // Reset zoomToNode to null
      dispatch(setZoomToNode({ nodeID: null }));
    }
  }

  // Handles adding a new edge (connection) between nodes
  function onConnectHandler(newConnection: Edge | RFConnection) {
    dispatch(addNewEdge(newConnection));

    // Close all menus, if open. Except for the menu of the edge that was just connected
    dispatchCloseMenus(window, newConnection.target);

    // Notify app that connection was made so we don't prompt user to add node
    onConnectEdgeDrop(newConnection as RFConnection);
  }

  // Handles when user starts connecting an edge
  function onConnectStart(event: React.MouseEvent, params: OnConnectStartParams) {
    // Reset connection status for prompting user to add node if they don't connect the edge
    onConnectStartEdgeDrop(params);
    // Close all menus, if open
    dispatchCloseMenus(window);

    // Hide edge toolbar
    dispatch(setSelectedEdge({ edge: null }));

    // Hide quick display edge
    dispatch(setQuickDisplayEdgeID({ edgeID: null }));

    // Close the context menu, if open
    setMenu(null);

    // Close edge context menu, if open
    setEdgeContextState(null);

    // Close node drop menu, if open
    setNodeDropMenuState(null);

    // Notify application that creating a connection is currently in progress
    dispatch(setConnectionInProgress({ inProgress: true }));
  }

  function onConnectionEnd(event: MouseEvent) {
    // Notify application when user drops a connection
    dispatch(setConnectionInProgress({ inProgress: false }));

    // Prompt user to add node if they don't connect the edge
    onConnectEndEdgeDrop(event);
    console.log("connection end");
  }

  // Handles updating nodes based on changes (position, data, etc.). Does NOT handle deleted nodes.
  function onNodesChangeHandler(changes: NodeChange[]) {
    // reset the helper lines (clear existing lines, if any)
    setHelperLineHorizontal(undefined);
    setHelperLineVertical(undefined);

    // this will be true if it's a single node being dragged
    // inside we calculate the helper lines and snap position for the position where the node is being moved to
    if (
      changes.length === 1 &&
      changes[0].type === "position" &&
      changes[0].dragging &&
      changes[0].position
    ) {
      // 1️⃣ calculate the helper lines based on the position where the node has been dragged
      const helperLines = getHelperLines(
        changes[0],
        nodes.filter((node) => node.type !== NodeType.EdgeToolbar)
      );

      const node = nodes.find((n) => n.id === (changes[0] as NodePositionChange).id);

      // 2️⃣ if we have a helper line, we snap the node to the helper line position
      // this is being done by manipulating the node position inside the change object

      // 🐞 If node has a parent, we can't figure out how to position it back onto the frame.
      // For now, we just don't snap any nodes who have parents.
      if (!node.parentNode) {
        changes[0].position.x = helperLines.snapPosition.x ?? changes[0].position.x;
        changes[0].position.y = helperLines.snapPosition.y ?? changes[0].position.y;
      }

      // 3️⃣ if helper lines are returned, we set them so that they can be displayed
      setHelperLineHorizontal(helperLines.horizontal);
      setHelperLineVertical(helperLines.vertical);
    }
    // apply node changes
    dispatch(applyNewNodeChanges(changes));
  }

  // Handles updating edges based on changes (addition, deletion, updating, etc.)
  function onEdgesChangeHandler(changes: EdgeChange[]) {
    dispatch(applyNewEdgeChanges(changes));
  }

  // Handles when user drag a node from the sidebar onto grid
  const handleDragOver = useCallback((event: React.DragEvent<HTMLDivElement>) => {
    // Prevent default to allow drag
    event.preventDefault();
    // 'move' indicates that the data will be moved to the drop target
    event.dataTransfer.dropEffect = "move";
  }, []);

  // Handles when user drops something on the ReactFlow container
  const handleDrop = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      // Prevent default to allow drop
      event.preventDefault();
      // Get shape passed in from calling function
      const shape = event.dataTransfer.getData("application/reactflow") as BoxDiagramShape;
      // Ensure we have a valid React Flow instance and dragged shape is not empty
      if (reactFlowInstance && shape) {
        const position = reactFlowInstance.screenToFlowPosition({
          x: event.clientX - 50,
          y: event.clientY - 50,
        });
        const data: NodeData = { ...baseNodeData, shape: shape };
        // Add node
        dispatch(addNode({ type: NodeType.BaseNode, position, data }));
      }
    },
    [reactFlowInstance]
  );

  // Handler when the grid pane is clicked directly (not on a node) or an edge connection is made
  const onGridPaneClick = useCallback(
    (event: React.MouseEvent<Element, MouseEvent>) => {
      // Deselect the selected edge to hide edge toolbar
      dispatch(setSelectedEdge({ edge: null }));

      // Remove quick display edge
      dispatch(setQuickDisplayEdgeID({ edgeID: null }));

      // Close all menus, if open
      dispatchCloseMenus(window);

      reactFlowComponentClicked();
    },
    [takeSnapshot, setMenu]
  );

  // Handler when the grid pane is clicked directly (not on a node) or an edge connection is made
  function closeContextMenu() {
    // Close the context menu, if open
    setMenu(null);
    // Close edge context menu, if open
    setEdgeContextState(null);
    // Close node drop menu, if open
    setNodeDropMenuState(null);
    // Close selection context menu, if open
    setSelectionContextMenu(null);
  }

  // When edge is clicked, dispatch an event which is picked up by relevant components
  function onEdgeClick(event: React.MouseEvent, edge: Edge) {
    // Set the selected edge in Redux
    dispatch(setSelectedEdge({ edge: edge }));

    // Close all menus, if open
    dispatchCloseMenus(window, edge.id);

    reactFlowComponentClicked();
  }

  // When node is clicked
  function onNodeClick(event: React.MouseEvent, node: Node) {
    // Unused. We use the onNodeDragStart handler instead.
  }

  function onPaneContextMenu(event: React.MouseEvent) {
    // Block context menu to enable right click dragging without node context menu popping up
    event.preventDefault();
    setMenu(null);
    setEdgeContextState(null);
    setNodeDropMenuState(null);
  }

  // When node is dragged OR clicked
  const onNodeDragStart: NodeDragHandler = useCallback(
    (e: React.MouseEvent, node: Node, nodes: Node[]) => {
      // 👇 make dragging a node undoable
      takeSnapshot();

      // This function fires when we click on EdgeToolbar. So we need to make sure
      // we don't hide the toolbar when we click on it so we can drag the toolbar.
      if (node.type !== "edgeToolbar") {
        // Hide edge toolbar
        dispatch(setSelectedEdge({ edge: null }));
        dispatch(setQuickDisplayEdgeID({ edgeID: null }));
      }

      // Close all menus, if open when dragging a node
      dispatchCloseMenus(window, node.id);

      // Hide the context menu and sidebar menu
      reactFlowComponentClicked();
    },
    [takeSnapshot]
  );

  const onSelectionDragStart: SelectionDragHandler = useCallback(() => {
    // Make dragging multiple nodes undoable
    takeSnapshot();

    // Close all menus, if open when dragging multiple nodes
    dispatchCloseMenus(window);
  }, [takeSnapshot]);

  function onNodesDelete(nodes: Node[]) {
    // Make deleting nodes undoable. This function is ONLY called when we delete multiple nodes at once.
    takeSnapshot();

    // This function is called when we delete MULTIPLE nodes at once. We have special logic
    // for deleting a single node in the Redux that must be executed for each node.
    for (const node of nodes) {
      dispatch(deleteNode({ sourceNodeId: node.id }));
    }
  }

  function handleOnEdgesDelete(edges: Edge[]) {
    // Make deleting an edge undoable
    // takeSnapshot();

    // Hide edge toolbar
    dispatch(setSelectedEdge({ edge: null }));

    // Hide quick display edge
    dispatch(setQuickDisplayEdgeID({ edgeID: null }));

    // Delete edge from Redux
    dispatch(deleteEdges({ edges: edges }));
  }

  const onEdgeUpdateStart = useCallback(() => {
    // Should fire when an already connected edge is dragged
    // Does not fire at all unless onEdgeUpdateEnd or onEdgeUpdate is defined
    // takeSnapshot();
  }, [takeSnapshot]);

  function handleOnSelectionEnd(event: React.MouseEvent) {
    handleFrameDragEnd(event);
  }

  function handleOnSelectionStart(event: React.MouseEvent) {
    handleFrameDragStart(event);
  }

  function handleSelectionDragEnd(event: React.MouseEvent, nodes: Node<NodeData>[]) {
    handleUpdateParentNodeOnDrop(event, null, nodes);
  }

  return (
    <div className="relative w-full h-full select-none">
      <ReactFlowInstanceContext.Provider value={reactFlowInstance}>
        <ReactFlow
          ref={reactFlowRef}
          nodes={nodes}
          edges={edges}
          nodeTypes={nodeTypes}
          edgeTypes={edgeTypes}
          onInit={handleOnInit}
          onNodesChange={onNodesChangeHandler}
          onNodeDragStart={onNodeDragStart}
          onNodeDragStop={handleUpdateParentNodeOnDrop}
          onNodesDelete={onNodesDelete}
          onNodeContextMenu={handleNodeRightClick}
          onEdgeClick={onEdgeClick}
          onEdgeUpdateStart={onEdgeUpdateStart}
          onEdgesChange={onEdgesChangeHandler}
          onEdgesDelete={handleOnEdgesDelete}
          onEdgeContextMenu={handleEdgeRightClick}
          // onSelectionContextMenu={handleSelectionRightClick}
          onConnect={onConnectHandler}
          onConnectStart={onConnectStart}
          onConnectEnd={onConnectionEnd}
          onDrop={handleDrop}
          onDragOver={handleDragOver}
          onPaneClick={onGridPaneClick}
          onPaneContextMenu={onPaneContextMenu}
          onSelectionDragStart={onSelectionDragStart}
          onSelectionDragStop={handleSelectionDragEnd}
          // handleUpdateParentNodesOnDrop
          onSelectionEnd={handleOnSelectionEnd}
          onSelectionStart={handleOnSelectionStart}
          deleteKeyCode={null}
          panOnScroll={true}
          zoomOnScroll={false} // Set to false because my custom right click pan doesn't work with zoom on scroll
          zoomOnPinch={true}
          zoomOnDoubleClick={false}
          panOnDrag={false} // Currently using my own custom pan on drag
          selectionMode={SelectionMode.Partial} // Node needs to be partially selected by click and drag
          selectionOnDrag={true} // Enables click and drag to select multiple nodes
          multiSelectionKeyCode={"Shift"} // Hold shift to select multiple nodes
          proOptions={{ hideAttribution: true }}
          panOnScrollSpeed={1.2}
          minZoom={0.05}
          maxZoom={50}
          // FitView will not work 1 single node is hidden or has an opacity of 0. Because of
          // this, we need to hide the EdgeToolbar node when we call fitView.
          fitView={true}
          fitViewOptions={{
            padding: 0.3,
            nodes: nodes.filter((node) => node.type !== NodeType.EdgeToolbar),
            // When we have 1 node, the view is zoomed in too much. So we set the maxZoom to 1 to widen the view.
            maxZoom: 1.5, // The smaller the number, the more zoomed out the view is
          }}
          // snapToGrid={true}
        >
          <BlockDiagramSidebar />

          {menu && <NodeContextMenu onClose={closeContextMenu} {...menu} />}
          {edgeContextState && <EdgeContextMenu onClose={closeContextMenu} {...edgeContextState} />}
          {selectionContextMenu && (
            <SelectionContextMenu onClose={closeContextMenu} {...selectionContextMenu} />
          )}
          {nodeDropMenuState && (
            <NodeDropMenu
              rfContainerRef={reactFlowRef}
              onClose={closeContextMenu}
              state={nodeDropMenuState}
            />
          )}

          <Panel position="top-right" className="space-x-4">
            <QuickDisplayEdge edgeID={quickDisplayEdgeID} />
          </Panel>
          <NodeResizer />
          <Controls />
          <MiniMap nodeColor={nodeColor} zoomable={false} pannable={false} />
          <Background gap={80} size={2} style={{ backgroundColor: "#f5f5f5" }} />
          <HelperLines horizontal={helperLineHorizontal} vertical={helperLineVertical} />
          <MobileBlockPopup />
        </ReactFlow>
      </ReactFlowInstanceContext.Provider>
    </div>
  );
}

export default BlockDiagram;
