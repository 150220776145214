// src/slices/userSlice.ts

import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { User } from "../../models/User";
import { User as FirebaseUser } from "firebase/auth";
import { DocumentContainer } from "src/models/Document";
import { Subscription } from "src/models/Subscription";

export type UserState = {
  user: User | null;
  firebaseUser: FirebaseUser | null;
  documents: DocumentContainer[];
  subscription: Subscription | null;
};

const initialState: UserState = {
  user: null,
  firebaseUser: null,
  documents: [],
  subscription: null,
};

export const userSlice = createSlice({
  name: "user",
  initialState: initialState,
  reducers: {
    setUser: (state, action: PayloadAction<{ user: User }>) => {
      const { user } = action.payload;
      state.user = user;
    },
    setFirebaseUser: (state, action: PayloadAction<{ firebaseUser: FirebaseUser }>) => {
      const { firebaseUser } = action.payload;
      state.firebaseUser = firebaseUser;
    },
    setUserDocuments: (state, action: PayloadAction<{ documents: DocumentContainer[] }>) => {
      const { documents } = action.payload;
      state.documents = documents;
    },
    deleteDocument: (state, action: PayloadAction<{ id: string }>) => {
      const { id } = action.payload;
      state.documents = state.documents.filter((doc) => doc.id !== id);
      state.user.document_ids = state.user.document_ids.filter((docId) => docId !== id);
    },
    setSubscription: (state, action: PayloadAction<{ subscription: Subscription }>) => {
      const { subscription } = action.payload;
      state.subscription = subscription;
    },
  },
});

export const { setUser, setFirebaseUser, setUserDocuments, deleteDocument, setSubscription } =
  userSlice.actions;
export default userSlice.reducer;
