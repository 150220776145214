// src/slices/sidebarSlice.ts

import { createSlice } from "@reduxjs/toolkit";
import { SideNavigationBarSection } from "./uiSlice";

export interface SideNavigationBarState {
  expanded: boolean;
  selectedSection: SideNavigationBarSection;
}

const initialState: SideNavigationBarState = {
  expanded: false,
  selectedSection: SideNavigationBarSection.BoxDiagram,
};

export const sideNavigationBarSlice = createSlice({
  name: "sideNavigationBar",
  initialState: initialState,
  reducers: {
    setExpanded: (state, action) => {
      state.expanded = action.payload;
    },
    setSelectedSection: (state, action) => {
      state.selectedSection = action.payload;
    },
  },
});

export const { setExpanded, setSelectedSection } = sideNavigationBarSlice.actions;
export default sideNavigationBarSlice.reducer;
