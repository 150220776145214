import { FileSpreadsheet, LayoutDashboard, Workflow } from "lucide-react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "src/state/slices";
import { setSelectedSection } from "src/state/slices/sidebarSlice";
import { SideNavigationBarSection } from "src/state/slices/uiSlice";
import { saveDocToApiWithDebounce } from "src/utils/autoSave";
import { CLIENT_ROUTE } from "src/utils/constants";

function BottomDocumentBar() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const expanded = useSelector((state: RootState) => state.sideNavigationBar.expanded);
  const activeSection = useSelector((state: RootState) => state.sideNavigationBar.selectedSection);
  const document = useSelector((state: RootState) => state.document.documentContainer);

  function setToActive(section: SideNavigationBarSection) {
    dispatch(setSelectedSection(section));
  }
  return (
    <div className="flex space-x-1">
      <Diagram
        section={SideNavigationBarSection.BoxDiagram}
        setToActive={setToActive}
        activeSection={activeSection}
      />
      <Diagram
        section={SideNavigationBarSection.ICD}
        setToActive={setToActive}
        activeSection={activeSection}
      />
      <Diagram
        section={SideNavigationBarSection.DSM}
        setToActive={setToActive}
        activeSection={activeSection}
      />
    </div>
  );
}
interface DocumentTabProps {
  section: SideNavigationBarSection;
  setToActive: (section: SideNavigationBarSection) => void;
  activeSection: SideNavigationBarSection;
}
function Diagram({ section, setToActive, activeSection }: DocumentTabProps) {
  const isActive = section === activeSection;
  const bgColor = isActive ? "bg-slate-200 border-indigo-400" : "bg-slate-100 border-slate-200";
  return (
    <button
      className={`flex w-40 h-9 px-3 justify-center items-center space-x-2 ${bgColor} 
      border-2  hover:bg-slate-200 rounded-t-lg`}
      onClick={() => setToActive(section)}
    >
      {getIcon(section)}
      <div>{section.toString()}</div>
    </button>
  );
}

function getIcon(section: SideNavigationBarSection) {
  switch (section) {
    case SideNavigationBarSection.BoxDiagram:
      return <Workflow size={18} />;
    case SideNavigationBarSection.ICD:
      return <FileSpreadsheet size={18} />;
    case SideNavigationBarSection.DSM:
      return <LayoutDashboard size={18} />;
  }
}

export default BottomDocumentBar;
