import TopDocumentRow from "./TopRow/TopDocumentRow";
import BottomDocumentBar from "./BottomRow/BottomDocumentRow";

function TopDocumentBar() {
  return (
    <div className="flex flex-col space-y-2 px-4 border-b-2">
      <TopDocumentRow />
      <BottomDocumentBar />
    </div>
  );
}

// User information component
export default TopDocumentBar;
