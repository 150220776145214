// src/state/reducers/connectionsReducers.ts
import { PayloadAction, createAction } from "@reduxjs/toolkit";
import { DocumentState, updateTimestamp } from "../slices/documentSlice";
import { saveDocToApiWithDebounce } from "src/utils/autoSave";
import { SubConnection, createConnection } from "src/models/Connection";

export const connectionsReducers = {
  addConnection: (state: DocumentState) => {
    state.documentContainer.connections.push(createConnection());

    updateTimestamp(state.documentContainer);

    // Save document to API
    saveDocToApiWithDebounce({
      id: state.documentContainer.id,
      owner_id: state.documentContainer.owner_id,
      connections: state.documentContainer.connections,
      permissions: state.documentContainer.permissions,
    });
  },
  removeConnection: (state: DocumentState, action: PayloadAction<string>) => {
    state.documentContainer.connections = state.documentContainer.connections.filter(
      (inputType) => inputType.id !== action.payload
    );

    updateTimestamp(state.documentContainer);

    // Save document to API
    saveDocToApiWithDebounce({
      id: state.documentContainer.id,
      owner_id: state.documentContainer.owner_id,
      connections: state.documentContainer.connections,
      permissions: state.documentContainer.permissions,
    });
  },
  // Removes a connection by index
  //   removeInputTypeByIndex: (state: DocumentState, action: PayloadAction<string>) => {
  //     state.documentContainer.connections = state.documentContainer.connections.filter(
  //       (inputType) => inputType.id !== action.payload
  //     );
  //   },
  // Sets the color of a specific connection
  updateConnection: (
    state: DocumentState,
    action: PayloadAction<{ id: string; data: Partial<SubConnection> }>
  ) => {
    const { id, data } = action.payload;


    state.documentContainer.connections = state.documentContainer.connections.map((conn) => {
      if (conn.id === id) {
        return { ...conn, ...data };
      }
      return conn;
    });

    // Update color of all edges that use this connection id color changes
    state.documentContainer.edges = state.documentContainer.edges.map((edge) => {
      return {
        ...edge,
        data: {
          ...edge.data,
          connections: edge.data.connections.map((connection) => {
            if (connection.id === id) {
              return { ...connection, ...data };
            }
            return connection;
          }),
        },
      };
    });

    // Save document to API
    saveDocToApiWithDebounce({
      id: state.documentContainer.id,
      owner_id: state.documentContainer.owner_id,
      edges: state.documentContainer.edges,
      permissions: state.documentContainer.permissions,
    });

    updateTimestamp(state.documentContainer);

    // Save document to API
    saveDocToApiWithDebounce({
      id: state.documentContainer.id,
      owner_id: state.documentContainer.owner_id,
      connections: state.documentContainer.connections,
      permissions: state.documentContainer.permissions,
    });
  },
  setDocConnections: (
    state: DocumentState,
    action: PayloadAction<{ connections: SubConnection[] }>
  ) => {
    state.documentContainer.connections = action.payload.connections;

    updateTimestamp(state.documentContainer);

    // Save document to API
    saveDocToApiWithDebounce({
      id: state.documentContainer.id,
      owner_id: state.documentContainer.owner_id,
      connections: state.documentContainer.connections,
      permissions: state.documentContainer.permissions,
    });
  },
};

export const addConnection = createAction("legend/addConnection");
export const removeConnection = createAction<string>("legend/removeConnection");
export const updateConnection = createAction<{ id: string; data: Partial<SubConnection> }>(
  "legend/updateConnection"
);
export const setDocConnections = createAction<{ connections: SubConnection[] }>(
  "legend/setDocConnections"
);
