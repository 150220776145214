// UNUSED
// /**
//  * Calculate the number of lines of text in a textarea.
//  * @param textArea - The textarea element to measure.
//  * @returns The number of lines of text.
//  */
// export function calculateNumberOfLines(textArea: HTMLTextAreaElement): number {
//   const style = window.getComputedStyle(textArea, null);
//   const textAreaWidth =
//     textArea.clientWidth - parseFloat(style.paddingLeft) - parseFloat(style.paddingRight);
//   const charWidth = parseFloat(style.fontSize);
//   // Assume the average character per line using monospace as a reference, could be around half the font size
//   const avgCharPerLine = textAreaWidth / (charWidth / 2);
//   // Calculate the number of lines
//   return Math.ceil(textArea.value.length / avgCharPerLine);
// }

import { User } from "src/models/User";
import { User as FirebaseUser } from "firebase/auth";

/**
 * Converts a past timestamp into a relative time string.
 * @param timestamp - The past time in milliseconds since the Unix epoch.
 * @returns A string representing how much time has passed since the timestamp, such as "1 min ago", "2 days ago", etc.
 */
export function timeAgoSting(timestamp: number): string {
  const now = Date.now();
  const secondsPast = (now - timestamp) / 1000;

  if (secondsPast < 60) {
    return `${Math.floor(secondsPast)} sec${Math.floor(secondsPast) > 1 ? "s" : ""} ago`;
  } else if (secondsPast < 3600) {
    const minutes = Math.floor(secondsPast / 60);
    return `${minutes} min${minutes > 1 ? "s" : ""} ago`;
  } else if (secondsPast < 86400) {
    const hours = Math.floor(secondsPast / 3600);
    return `${hours} hr${hours > 1 ? "s" : ""} ago`;
  } else if (secondsPast < 604800) {
    const days = Math.floor(secondsPast / 86400);
    return `${days} day${days > 1 ? "s" : ""} ago`;
  } else if (secondsPast < 2419200) {
    const weeks = Math.floor(secondsPast / 604800);
    return `${weeks} week${weeks > 1 ? "s" : ""} ago`;
  } else {
    const months = Math.floor(secondsPast / 2419200);
    return `${months} month${months > 1 ? "s" : ""} ago`;
  }
}

/**
 * Converts a string to title case.
 *
 * Example: "hello world" -> "Hello World"
 * @param str - The string to convert.
 * @returns The string in title case.
 */
export function titleCase(str) {
  return str
    .toLowerCase()
    .split(" ")
    .map(function (word) {
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(" ");
}

/**
 * Checks if the user is signed in.
 */
export function isSignedIn(firebaseUser: FirebaseUser | null, user: User | null): boolean {
  if (firebaseUser && user && firebaseUser.uid === user.id) {
    return true;
  }
  return false;
}
