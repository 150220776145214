import { useEffect } from "react";
import { useKeyPress } from "reactflow";
import { useDispatch } from "react-redux";
import { dsmCellSelected, dsmColSelected, dsmRowSelected } from "src/state/selectors/selectors";
import { moveNodePositionDown, moveNodePositionUp } from "src/state/reducers/dsmReducers";


export const useArrowKeys = (selectedState: { source?: string; target?: string } | null) => {
  const dispatch = useDispatch();
  const arrowUpPressed = useKeyPress("ArrowUp");
  const arrowDownPressed = useKeyPress("ArrowDown");
  const arrowLeftPressed = useKeyPress("ArrowLeft");
  const arrowRightPressed = useKeyPress("ArrowRight");

  useEffect(() => {
    // If up arrow is pressed and a row or cell is selected, move the node position up
    if (arrowUpPressed && (dsmRowSelected(selectedState) || dsmCellSelected(selectedState))) {
      dispatch(moveNodePositionUp({ nodeId: selectedState.source }));
    }
    // If left arrow is pressed and a col or cell is selected, move the node position up
    else if (
      arrowLeftPressed &&
      (dsmColSelected(selectedState) || dsmCellSelected(selectedState))
    ) {
      dispatch(moveNodePositionUp({ nodeId: selectedState.target }));
    }
    // If down arrow is pressed and a row or cell is selected, move the node position down
    else if (
      arrowDownPressed &&
      (dsmRowSelected(selectedState) || dsmCellSelected(selectedState))
    ) {
      dispatch(moveNodePositionDown({ nodeId: selectedState.source }));
    }
    // If right arrow is pressed and a col or cell is selected, move the node position down
    else if (
      arrowRightPressed &&
      (dsmColSelected(selectedState) || dsmCellSelected(selectedState))
    ) {
      dispatch(moveNodePositionDown({ nodeId: selectedState.target }));
    }
  }, [arrowUpPressed, arrowLeftPressed, arrowDownPressed, arrowRightPressed]);

  return {};
};
