import axios from "axios";
import { Dispatch } from "redux";
import { Subscription } from "src/models/Subscription";
import { setSubscription } from "src/state/slices/userSlice";
import { GetSubscriptionResponse, StatusCode, StripeSessionURLResponse } from "src/utils/Api";
import { CLIENT_URL, SERVER_ROUTE } from "src/utils/constants";

const HEADERS = {
  "Content-Type": "application/json",
};

export async function createCheckoutSession(optionalEmail: string, isYearly: boolean) {
  const is_test = CLIENT_URL.includes("localhost") ? true : false;
  try {
    const response = await axios.post(
      SERVER_ROUTE.STRIPE.CREATE_CHECKOUT_SESSION,
      { optional_email: optionalEmail, is_test: is_test, is_yearly: isYearly },
      { headers: HEADERS }
    );
    const data: StripeSessionURLResponse = response.data;
    const checkoutUrl = data.resp;
    return checkoutUrl;
  } catch (error) {
    throw error;
  }
}

export async function createCheckoutChooseWhatToPay(
  optionalEmail: string,
  amount_minor: number,
  cancel_url: string
) {
  const is_test = CLIENT_URL.includes("localhost") ? true : false;
  try {
    const response = await axios.post(
      SERVER_ROUTE.STRIPE.CREATE_CHECKOUT_CHOOSE_PAYMENT,
      {
        optional_email: optionalEmail,
        is_test: is_test,
        amount_minor: amount_minor,
        cancel_url: cancel_url,
      },
      { headers: HEADERS }
    );
    const data: StripeSessionURLResponse = response.data;
    const checkoutUrl = data.resp;
    return checkoutUrl;
  } catch (error) {
    throw error;
  }
}

export async function createCustomerPortalSession(customer_id: string, return_url: string) {
  const is_test = CLIENT_URL.includes("localhost") ? true : false;
  try {
    const response = await axios.post(
      SERVER_ROUTE.STRIPE.CREATE_CUSTOMER_PORTAL_SESSION,
      { customer_id: customer_id, is_test: is_test, return_url: return_url },
      { headers: HEADERS }
    );
    const data: StripeSessionURLResponse = response.data;
    const checkoutUrl = data.resp;
    return checkoutUrl;
  } catch (error) {
    throw error;
  }
}

export async function getAndSetSubscription(
  user_id: string,
  dispatch: Dispatch
): Promise<GetSubscriptionResponse> {
  try {
    const response = await axios.post(
      SERVER_ROUTE.SUBSCRIPTION.GET,
      { user_id: user_id },
      {
        headers: HEADERS,
      }
    );

    // Success
    if (response.status === StatusCode.HTTP_200) {
      const data = response.data as GetSubscriptionResponse;
      const subscription: Subscription = data.resp;
      dispatch(setSubscription({ subscription: subscription }));
      return data;
    }
    // Subscription not found
    else if (response.status === StatusCode.HTTP_404) {
      return response.data as GetSubscriptionResponse;
    }
    // Server error
    else {
      throw new Error("Failed to get subscription");
    }
  } catch (error) {
    throw error;
  }
}
