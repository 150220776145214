import { Position } from "reactflow";
import TwoWayHandle from "../../Handles/TwoWayHandle";

/**
 * Used to render the four two-way handles we see on the nodes
 */
function NodeHandles({ show }: { show: boolean }) {
  return (
    <>
      <TwoWayHandle show={show} position={Position.Left} id="left" />
      <TwoWayHandle show={show} position={Position.Right} id="right" />
      <TwoWayHandle show={show} position={Position.Top} id="top" />
      <TwoWayHandle show={show} position={Position.Bottom} id="bottom" />
    </>
  );
}

export default NodeHandles;
