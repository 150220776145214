import { ToolbarButton } from "../../Toolbars/BaseToolbar";
import { EdgeData, EdgePathStyle } from "../../../../models/BoxDiagram/Edge";
import { EdgeBarMenuItem } from "./BaseEdgeToolbar";
import { updateEdgeData } from "src/state/reducers/boxDiagramReducers";
import { setSelectedEdge } from "src/state/slices/documentSlice";
import { useDispatch, useSelector } from "react-redux";
import { Edge } from "reactflow";
import { BoxSelect, CornerRightDown, Minus, MoveUpRight, Redo, Scan, Square } from "lucide-react";
import { RootState } from "src/state/slices";

interface EdgeLineStylePickerProps {
  selectedSubMenu: EdgeBarMenuItem | null;
  setSelectedSubMenu: (selectedSubMenu: EdgeBarMenuItem | null) => void;
}
function EdgeLineStylePicker({ selectedSubMenu, setSelectedSubMenu }: EdgeLineStylePickerProps) {
  function toggleVisibility() {
    setSelectedSubMenu(EdgeBarMenuItem.LineStyle);
  }

  return (
    <div onClick={toggleVisibility}>
      <ToolbarButton tooltip="Line style">{getPathStyleIcon(EdgePathStyle.Dashed)}</ToolbarButton>
      {selectedSubMenu === EdgeBarMenuItem.LineStyle && (
        <div className="absolute top-full mt-2">
          <LineStyleMenu />
        </div>
      )}
    </div>
  );
}

interface LineStyleButtonProps {
  pathStyle: EdgePathStyle;
  selectedEdge: Edge<EdgeData> | null;
}

function LineStyleButton({ pathStyle, selectedEdge }: LineStyleButtonProps) {
  const dispatch = useDispatch();
  const selectedBtnStyle = "bg-gray-100";
  const isSelected = selectedEdge?.data?.temp_path_style === pathStyle;

  function handleOnClick() {
    dispatch(
      updateEdgeData({
        id: selectedEdge?.id,
        newData: { path_style: pathStyle, temp_path_style: pathStyle },
      })
    );
    dispatch(setSelectedEdge({ edge: null }));
  }

  function handleMouseEnter() {
    dispatch(updateEdgeData({ id: selectedEdge?.id, newData: { path_style: pathStyle } }));
  }

  function handleMouseLeave() {
    dispatch(
      updateEdgeData({
        id: selectedEdge?.id,
        newData: { path_style: selectedEdge.data.temp_path_style },
      })
    );
  }

  return (
    <div
      onClick={handleOnClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className={
        `flex justify-center items-center p-2 rounded-lg hover:bg-gray-100 w-11 h-11` +
        (isSelected ? ` ${selectedBtnStyle}` : "")
      }
    >
      {getPathStyleIcon(pathStyle)}
    </div>
  );
}

function getPathStyleIcon(pathStyle: EdgePathStyle) {
  if (pathStyle === EdgePathStyle.Line) {
    return <Minus className="w-12" strokeWidth={2} size={30} />;
  }
  if (pathStyle === EdgePathStyle.Dashed) {
    return (
      <div className="flex w-12 items-center justify-center">
        <div className="flex w-9 m-0 space-x-0 items-center justify-between">
          <Minus strokeWidth={12} size={5} />
          <Minus strokeWidth={6} size={9} />
          <Minus strokeWidth={6} size={9} />
          <Minus strokeWidth={12} size={5} />
        </div>
      </div>
    );
  }
  if (pathStyle === EdgePathStyle.Dotted) {
    return (
      <div className="flex w-12 justify-between">
        <Minus strokeWidth={20} size={3.5} />
        <Minus strokeWidth={20} size={3.5} />
        <Minus strokeWidth={20} size={3.5} />
        <Minus strokeWidth={20} size={3.5} />
        <Minus strokeWidth={20} size={3.5} />
      </div>
    );
  }
  throw new Error("Invalid path style");
}

function LineStyleMenu() {
  const selectedEdge = useSelector((state: RootState) => state.document.selectedEdge);

  return (
    <div className="flex p-1 bg-white rounded-lg shadow-lg">
      <LineStyleButton pathStyle={EdgePathStyle.Line} selectedEdge={selectedEdge} />
      <LineStyleButton pathStyle={EdgePathStyle.Dashed} selectedEdge={selectedEdge} />
      <LineStyleButton pathStyle={EdgePathStyle.Dotted} selectedEdge={selectedEdge} />
    </div>
  );
}

export default EdgeLineStylePicker;
