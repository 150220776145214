import { Circle, Square, RectangleHorizontal, Diamond } from "lucide-react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../state/slices";
import { createRoot } from "react-dom/client";
import { MenuItemName, setSelectItem } from "../../../state/slices/BoxDiagram/boxDiagramUISlice";

const Shape = {
  Circle: {
    name: "Circle",
    icon: <Circle className="bg-transparent" size={50} strokeWidth={1.5} />,
  },
  Square: {
    name: "Square",
    icon: <Square size={50} strokeWidth={1.5} />,
  },
  Rectangle: {
    name: "Rectangle",
    icon: <RectangleHorizontal size={50} strokeWidth={1.5} />,
  },
  Diamond: {
    name: "Diamond",
    icon: <Diamond size={50} strokeWidth={1.5} />,
  },
};

interface DraggableShapeProps {
  shape: {
    name: string;
    icon: React.ReactNode;
  };
}

function ShapesMenu() {
  const activeMenuItem = useSelector((state: RootState) => state.boxDiagramUI.activeMenuItem);
  const showMenu = activeMenuItem === MenuItemName.Shapes;

  const styles = {
    top: `${0}px`,
    left: `${0 + 70}px`,
  };

  if (!showMenu) {
    return null;
  }

  return (
    <div style={styles} className="absolute flex flex-col py-4 px-2 w-40 h-32 rounded-md shadow-lg bg-white">
      <div className="text-xl mb-4 ml-1">Drag & drop</div>

      <div className="flex justify-between items-center space-x-1 mb-1">
        <DraggableShape shape={Shape.Rectangle} />
        {/* <DraggableShape shape={Shape.Circle} />
        <DraggableShape shape={Shape.Square} /> */}
      </div>
    </div>
  );
}

/**
 * Handles the drag start event for shape components.
 * @param shape The type of the shape being dragged.
 * @param onDragStart The function to be called when dragging starts.
 */
function DraggableShape({ shape }: DraggableShapeProps) {
  const dispatch = useDispatch();

  function handleDragStart(event: React.DragEvent<HTMLDivElement>) {
    onDragStart(event, shape.name.toLowerCase());
  }

  function onDragStart(event: React.DragEvent<HTMLDivElement>, nodeType: string) {
    event.dataTransfer.setData("application/reactflow", nodeType);
    event.dataTransfer.effectAllowed = "move";

    createCustomDragIcon(event);
  }

  /**
   * Creates a custom drag image with transparency. Here's how:
   * 1. Create a temporary node for the drag preview
   * 2. Render the React component to the temporary node
   * 3. Use the temporary node as the drag image
   * 4. Clean up after the drag starts
   *
   */
  function createCustomDragIcon(event: React.DragEvent<HTMLDivElement>) {
    const tempNode = document.createElement("div");
    tempNode.style.position = "absolute";
    document.body.appendChild(tempNode);

    // Create a root for the temporary node
    const root = createRoot(tempNode);

    // Render the React component to the temporary node
    root.render(shape.icon as React.ReactElement);

    // Use the temporary node as the drag image
    event.dataTransfer.setDragImage(tempNode, 20, 20);

    // Clean up after the drag starts
    setTimeout(() => {
      document.body.removeChild(tempNode);
      root.unmount();
    }, 0);
  }

  function onDragEnd() {
    dispatch(setSelectItem({ item: null }));
  }

  return (
    <div
      draggable
      onDragStart={handleDragStart}
      onDragEnd={onDragEnd}
      className="p-1 cursor-pointer rounded-md bg-transparent hover:bg-gray-100"
    >
      {shape.icon}
    </div>
  );
}

export default ShapesMenu;
