// src/state/reducers/boxDiagramReducers.ts
import { PayloadAction, createAction } from "@reduxjs/toolkit";
import {
  Edge,
  EdgeChange,
  Node,
  NodeChange,
  XYPosition,
  applyEdgeChanges,
  applyNodeChanges,
} from "reactflow";
import { dispatchSaveDiagramSnapshot } from "src/events/documentEvents";
import { EdgeData } from "src/models/BoxDiagram/Edge";
import { NodeData, NodeType } from "src/models/BoxDiagram/Node";
import { EDGE_TOOLBAR_ID } from "src/utils/constants";
import { updateTimestamp } from "../slices/documentSlice";
import { saveDocToApiWithDebounce, throttle } from "src/utils/autoSave";
import { DocumentPermissions } from "src/models/Document";
// src/state/reducers/boxDiagramReducers.ts

// Utility functions for reducers
function _updateNode(
  documentID: string,
  documentOwnerID: string,
  nodes: any[],
  nodeId: string,
  newData: Partial<NodeData> = {},
  permissions: DocumentPermissions,
  position?: XYPosition,
  parentNode?: string
): Node[] {
  // If new data contains a dimensions or rotation, save a snapshot
  // Note: Background color must be saved in node since we support previewing background color
  // Position
  if (
    (newData.dimensions || newData.rotation || newData.temp_bg_color,
    newData.temp_border_color,
    newData.temp_border_style)
  ) {
    dispatchSaveDiagramSnapshot(window);
  }

  if (
    newData.dimensions ||
    newData.rotation ||
    position ||
    newData.temp_bg_color ||
    newData.temp_border_color ||
    newData.temp_border_style
  ) {
    // Save document to API. Throttle calls to avoid performance issues
    throttle(saveDocToApiWithDebounce)({
      id: documentID,
      owner_id: documentOwnerID,
      nodes: nodes,
      permissions: permissions,
    });
  }

  return nodes.map((node) =>
    node.id === nodeId
      ? {
          ...node,
          data: { ...node.data, ...newData },
          // When removing parent, we pass in null
          parentNode: parentNode !== undefined ? parentNode : node.parentNode,
          position: position && parentNode ? position : node.position,
        }
      : node
  );
}

function _updateEdge(edges: Edge[], edgeId: string, newData: Partial<EdgeData>): Edge[] {
  // If new data contains color or path style, save a snapshot
  // Note: Arrow direction is a cross-document change which is not supported yet
  if (newData.temp_legend_item || newData.temp_path_shape) {
    dispatchSaveDiagramSnapshot(window);
  }
  return edges.map((edge) =>
    edge.id === edgeId ? { ...edge, data: { ...edge.data, ...newData } } : edge
  );
}

export const boxDiagramReducers = {
  /**
   *************************
   ***** Node reducers *****
   *************************
   */

  // Update nodes based on changes (position, data, etc.)
  applyNewNodeChanges: (state, action: PayloadAction<NodeChange[]>) => {
    const nodeChanges: NodeChange[] = action.payload;
    state.documentContainer.nodes = applyNodeChanges(nodeChanges, state.documentContainer.nodes);

    // If the node position changes set isEditing to false
    for (const change of nodeChanges) {
      if (change.type === "position" && change.id !== EDGE_TOOLBAR_ID) {
        state.documentContainer.nodes = _updateNode(
          state.documentContainer.id,
          state.documentContainer.owner_id,
          state.documentContainer.nodes,
          change.id,
          undefined,
          state.documentContainer.permissions,
          change.position
        );
      }
    }
    state.documentContainer = updateTimestamp(state.documentContainer);
  },

  // Remove selection from all nodes and hide toolbars
  deselectAllNodes: (state, action?: PayloadAction<{ exceptionID: string }>) => {
    const { exceptionID } = action.payload;

    const nodeChanges: NodeChange[] = state.documentContainer.nodes.map((node) => ({
      id: node.id,
      type: "select",
      // Delete all node selections except for the exceptionID
      isSelected: node.id === exceptionID,
    }));

    state.documentContainer.nodes = applyNodeChanges(
      nodeChanges,
      state.documentContainer.nodes
    ).map((node) => ({
      ...node,
      data: { ...node.data, show_node_toolbar: false },
    }));
  },

  // Used for redo/undo to reset state of nodes
  replaceAllNodes: (state, action: PayloadAction<{ nodes: Node<NodeData>[] }>) => {
    state.documentContainer.nodes = action.payload.nodes;
    state.documentContainer = updateTimestamp(state.documentContainer);
  },

  // Set all notes to not editing
  setAllNodesNotEditing: (state) => {
    state.documentContainer.nodes.forEach((node: Node<NodeData>) => {
      node.data.is_editing = false;
    });
  },
  // Update a specific node's data by its ID
  updateNodeData: (
    state,
    action: PayloadAction<{
      id: string;
      newData: Partial<NodeData>;
      position?: XYPosition;
      parentNode?: string;
    }>
  ) => {
    const { id, newData, position, parentNode } = action.payload;
    state.documentContainer.nodes = _updateNode(
      state.documentContainer.id,
      state.documentContainer.owner_id,
      state.documentContainer.nodes,
      id,
      newData,
      state.documentContainer.permissions,
      position,
      parentNode
    );

    if (newData.label !== undefined) {
      throw new Error(
        "updateNodeData: Label should be set with setNodeLabel for cross document consistency"
      );
    }
    state.documentContainer = updateTimestamp(state.documentContainer);
  },

  /**
   *************************
   ***** Edge reducers *****
   *************************
   */

  // Update edges based on changes (select, etc.)
  applyNewEdgeChanges: (state, action: PayloadAction<EdgeChange[]>) => {
    const edgeChanges = action.payload;
    state.documentContainer.edges = applyEdgeChanges(edgeChanges, state.documentContainer.edges);
  },

  updateEdgeData: (state, action: PayloadAction<{ id: string; newData: Partial<EdgeData> }>) => {
    const { id, newData } = action.payload;
    state.documentContainer.edges = _updateEdge(state.documentContainer.edges, id, newData);

    if (newData.arrow_direction !== undefined) {
      throw new Error(
        "updateEdgeData: Arrow direction should be set with setArrowDirection for cross document consistency"
      );
    }

    // Changes to Diagram only edge attributes (e.g. label, path shape) should be saved here.
    // Cross-document changes (e.g. source, connections) should be saved in the document slice
    if (
      newData.labels !== undefined ||
      newData.temp_path_shape !== undefined ||
      newData.temp_path_style !== undefined ||
      newData.temp_legend_item !== undefined
    ) {
      state.documentContainer = updateTimestamp(state.documentContainer);
      // Save document to API
      saveDocToApiWithDebounce({
        id: state.documentContainer.id,
        owner_id: state.documentContainer.owner_id,
        edges: state.documentContainer.edges,
        permissions: state.documentContainer.permissions,
      });
    }

    // No longer needed since we save the ID and reference the node directly now
    // Update selected edge if it matches
    // if (id === state.documentContainer.selectedEdge?.id) {
    //   state.documentContainer.selectedEdge = {
    //     ...state.documentContainer.selectedEdge,
    //     data: { ...state.documentContainer.selectedEdge.data, ...newData },
    //   };
    // }
  },

  // Used for redo/undo to reset state of nodes
  replaceAllEdges: (state, action: PayloadAction<{ edges: Edge<EdgeData>[] }>) => {
    state.documentContainer.edges = action.payload.edges;
  },

  /**
   ************************
   ***** Edge toolbar *****
   ************************
   */
  updateEdgeToolbarPosition: (state, action: PayloadAction<{ position: XYPosition }>) => {
    const { position } = action.payload;
    // Find node
    const edgeToolbar = state.documentContainer.nodes.find(
      (node) => node.type === NodeType.EdgeToolbar
    );
    if (!edgeToolbar) throw new Error("updateEdgeToolbarPosition: Node not found");

    // Update node position
    state.documentContainer.nodes = state.documentContainer.nodes.map((node) =>
      node.type === NodeType.EdgeToolbar
        ? { ...node, position, data: { ...node.data, position } }
        : node
    );
    const nodeChange: NodeChange = {
      id: edgeToolbar.id,
      type: "position",
      position,
    };
    state.documentContainer.nodes = applyNodeChanges([nodeChange], state.documentContainer.nodes);
  },
};

export const applyNewNodeChanges = createAction<NodeChange[]>("boxDiagram/applyNewNodeChanges");
export const deselectAllNodes = createAction<{ exceptionID?: string }>(
  "boxDiagram/deselectAllNodes"
);
export const replaceAllNodes = createAction<{ nodes: Node<NodeData>[] }>(
  "boxDiagram/replaceAllNodes"
);
export const setAllNodesNotEditing = createAction("boxDiagram/setAllNodesNotEditing");
export const updateNodeData = createAction<{
  id: string;
  newData: Partial<NodeData>;
  position?: XYPosition;
  parentNode?: string;
}>("boxDiagram/updateNodeData");
export const applyNewEdgeChanges = createAction<EdgeChange[]>("boxDiagram/applyNewEdgeChanges");
// export const addNewEdge = createAction<Edge | Connection>("boxDiagram/addNewEdge");
export const updateEdgeData = createAction<{ id: string; newData: Partial<EdgeData> }>(
  "boxDiagram/updateEdgeData"
);
export const replaceAllEdges = createAction<{ edges: Edge<EdgeData>[] }>(
  "boxDiagram/replaceAllEdges"
);
export const updateEdgeToolbarPosition = createAction<{ position: XYPosition }>(
  "boxDiagram/updateEdgeToolbarPosition"
);
