// src/components/BoxDiagram/Handles/TwoWayHandle.tsx

import { Tooltip } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Handle, Position, useViewport } from "reactflow";
import { Color } from "src/models/BoxDiagram/Colors";
import { RootState } from "src/state/slices";
import { SourceHandlePrefix, TargetHandlePrefix } from "src/utils/constants";

type TwoWayHandleProps = {
  show: boolean;
  position: Position;
  id: string;
};

function TwoWayHandle({ show, position, id }: TwoWayHandleProps) {
  const [handleColor, setHandleColor] = useState(Color.Indigo.css.twoHundred);
  const [handleSize, setHandleSize] = useState(8);
  const connectionInProgress = useSelector(
    (state: RootState) => state.boxDiagramUI.connectionInProgress
  );

  const { zoom } = useViewport();

  // We hide the source handle when a user is about to drop a connection on it because
  // reactflow will auto drop the connection if the source handle is visible
  function getZIndex(type: string) {
    if (type === "source") {
      return connectionInProgress ? -1 : 100;
    } else {
      return connectionInProgress ? 100 : -1;
    }
  }

  const handleStyle = {
    width: `${handleSize}px`,
    height: `${handleSize}px`,
    background: show ? handleColor : "transparent",
    borderColor: show ? Color.Indigo.css.eightHundred : "transparent",
  };

  useEffect(() => {
    // Calculate new size based on zoom level

    const newSize = 11 / zoom; // adjust this formula as needed
    setHandleSize(Math.min(Math.max(newSize, 7), 25)); // 25 px is the max size
  }, [zoom]);

  function onMouseEnter() {
    setHandleColor(Color.Indigo.css.eightHundred);
  }

  function onMouseLeave() {
    setHandleColor(Color.Indigo.css.twoHundred);
  }

  return (
    <div onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      {/* Target handle (for incoming edges) */}
      <Handle
        type={"target"}
        position={position}
        id={`${TargetHandlePrefix}${id}`}
        style={{ ...handleStyle, zIndex: getZIndex("target") }}
      />
      {/* Source handle (for outgoing edges) */}

      <Tooltip title="Drag to connect to another node" placement="top" enterDelay={1000} arrow>
        <Handle
          type={"source"}
          position={position}
          id={`${SourceHandlePrefix}${id}`}
          style={{ ...handleStyle, zIndex: getZIndex("source") }}
        />
      </Tooltip>
    </div>
  );
}

export default TwoWayHandle;
