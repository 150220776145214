// src/slices/icdSlice.ts

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ICDCell } from "src/models/ICDRow";

export interface ICDState {
  selectedCell: ICDCell | null;
  selectedRow: number | null;
}

const initialState: ICDState = {
  selectedCell: null,
  selectedRow: null,
};

export const icdSlice = createSlice({
  name: "icd",
  initialState,
  reducers: {
    setSelectedCell: (state, action: PayloadAction<{ cell: ICDCell | null }>) => {
      const { cell } = action.payload;
      state.selectedCell = cell;
    },
    setSelectedRow: (state, action: PayloadAction<{ rowIndex: number | null }>) => {
      const { rowIndex } = action.payload;
      state.selectedRow = rowIndex;
    }
  },
});

export const { setSelectedCell, setSelectedRow } = icdSlice.actions;
export default icdSlice.reducer;
