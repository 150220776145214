import { useDispatch } from "react-redux";
import { NodeData } from "../../../../models/BoxDiagram/Node";
import { useEffect, useRef, useState } from "react";
// import { calculateNumberOfLines } from "../../../../utils/helpers";
import { updateNodeData } from "src/state/reducers/boxDiagramReducers";
import { setNodeLabel } from "src/state/slices/documentSlice";
import { useReactFlow } from "reactflow";

interface NodeInputProps {
  id: string;
  data: NodeData;
}
/**
 * Renders the editable text input within the node with a transparent overlay to manage focus.
 */
function NodeInput({ id, data }: NodeInputProps) {
  const reactFlowInstance = useReactFlow();

  const dispatch = useDispatch();
  // State to hold the input value
  const [inputValue, setInputValue] = useState(data.label);
  // Ref for the textarea
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  // Ref for the overlay
  const overlayRef = useRef<HTMLDivElement>(null);

  // Adjust the textarea height to fit content
  useAutoSizeTextAreaHeight(textAreaRef, inputValue);

  // Make the overlay focusable and attach the keydown event listener
  useEffect(() => {
    if (overlayRef.current && textAreaRef.current) {
      overlayRef.current.tabIndex = -1; // Make the div focusable
      textAreaRef.current.setSelectionRange(inputValue.length, inputValue.length);
    }
  }, []);

  // Handle changes to the textarea
  function handleOnChange(event: React.ChangeEvent<HTMLTextAreaElement>) {
    setInputValue(event.target.value); // Update local state
    // Mark isEditing to begin showing input caret
    if (!data.is_editing) {
      dispatch(updateNodeData({ id, newData: { is_editing: true } }));
    }
  }

  // Handle focus on double click or key press
  function handleOnKeydownOnNode(event: React.KeyboardEvent<HTMLDivElement> | React.MouseEvent) {
    if (event.type === "keydown") {
      textAreaRef.current?.focus(); // Focus the textarea if a key is pressed to allow typing
    }
  }

  function handleKeyDownOnTextArea(event: React.KeyboardEvent<HTMLTextAreaElement>) {
    // Check if Enter is pressed without the Shift key
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault(); // Prevent default to avoid a new line being added

      // Deselect the node
      reactFlowInstance.setNodes((prevNodes) =>
        prevNodes.map((node) => {
          if (node.id === id) {
            return { ...node, selected: false };
          }
          return node;
        })
      );
      // Remove focus from the textarea & save the new value
      handleOnBlur();
    }

    // No need to explicitly handle Shift + Enter as it's the default behavior of the textarea
  }

  function handleDoubleClick(event: React.MouseEvent<HTMLDivElement>) {
    textAreaRef.current?.focus();
    textAreaRef.current?.select(); // Select all text in the textarea
  }
  // Set the editing state to false when the textarea loses focus
  function handleOnBlur() {
    dispatch(updateNodeData({ id, newData: { is_editing: false } }));
    dispatch(setNodeLabel({ nodeId: id, label: inputValue.trim() }));
  }

  // Styling to make the caret invisible and the textarea look like plain text when not editing
  const caretStyle = data.is_editing ? "" : "caret-transparent cursor-pointer";

  return (
    <div
      ref={overlayRef}
      className="absolute flex justify-center items-center w-full h-full px-3 text-xl"
      onDoubleClick={handleDoubleClick}
      onKeyDown={handleOnKeydownOnNode}
    >
      <textarea
        ref={textAreaRef}
        id="node-input"
        className={`relative w-full text-center top-2 resize-none no-outline bg-transparent overflow-hidden outline-none border-none ${caretStyle}`}
        value={inputValue}
        onChange={handleOnChange}
        onKeyDown={handleKeyDownOnTextArea}
        onBlur={handleOnBlur}
      />
    </div>
  );
}

/**
 * This React hook dynamically adjusts the height of a textarea element to ensure that it
 * fits its content. We need this to update the hight has the user adds and deletes text.
 * It achieves this by creating a hidden 'mirror' div element that allows us to measure the
 * natural content height, including any new lines. This measured height is then applied
 * to the textarea to accommodate the content fully.
 *
 * The additional 25px added to the height accounts for the top padding, ensuring the
 * content does not overflow the textarea after the first newline character.
 *
 * Note: ❕The hook must be used within a functional component, and the textarea must have a
 * unique id attribute for the hook to work correctly.❕
 *
 * @param {React.RefObject<HTMLTextAreaElement>} textAreaRef - A ref object pointing to the textarea DOM element.
 * @param {string} value - The current text value of the textarea.
 */
function useAutoSizeTextAreaHeight(
  textAreaRef: React.RefObject<HTMLTextAreaElement>,
  value: string
) {
  // This effect will run whenever the 'value' changes.
  useEffect(() => {
    const mirrorDivId = `mirror-${textAreaRef.current?.id}`;
    let mirrorDiv = document.getElementById(mirrorDivId) as HTMLDivElement;

    // Create a mirror div if it doesn't exist yet
    if (!mirrorDiv && textAreaRef.current) {
      mirrorDiv = document.createElement("div");
      mirrorDiv.id = mirrorDivId;
      document.body.appendChild(mirrorDiv);

      // Apply the same styles to the mirror div as the textarea
      const style = window.getComputedStyle(textAreaRef.current);
      mirrorDiv.style.width = style.width;
      mirrorDiv.style.padding = style.padding;
      mirrorDiv.style.font = style.font;
      mirrorDiv.style.lineHeight = style.lineHeight;
      mirrorDiv.style.letterSpacing = style.letterSpacing;
      mirrorDiv.style.visibility = "hidden";
      mirrorDiv.style.position = "absolute";
      mirrorDiv.style.whiteSpace = "pre-wrap";
      mirrorDiv.style.wordWrap = "break-word";
    }

    // Copy the textarea content to the mirror div
    if (mirrorDiv) {
      mirrorDiv.textContent = value || " "; // Add a space to ensure the div has height
    }

    // Adjust the textarea height to the mirror div's scrollHeight
    if (textAreaRef.current) {
      const mirrorHeight = mirrorDiv?.scrollHeight;
      if (mirrorHeight) {
        // Add 20px to the height because this method doesn't account for top
        // padding after the first newline character is added
        const textAreaHeight = mirrorHeight + 20;
        textAreaRef.current.style.height = `${textAreaHeight}px`;
      }
    }

    // Clean up the mirror div when the component unmounts
    return () => {
      mirrorDiv?.remove();
    };
  }, [value, textAreaRef]);
}

export default NodeInput;
