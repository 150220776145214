// src/components/BlockDiagram/Hooks/useShowEdgeToolbar.ts

import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useKeyPress } from "reactflow";
import { RootState } from "src/state/slices";
import { deleteEdges, deleteNode, setSelectedEdge } from "src/state/slices/documentSlice";

export function useBackspaceDelete() {
  const dispatch = useDispatch();
  const nodes = useSelector((state: RootState) => state.document.documentContainer.nodes);
  const edges = useSelector((state: RootState) => state.document.documentContainer.edges);

  const backspacePressed = useKeyPress("Backspace");

  useEffect(() => {
    if (backspacePressed) {
      // Get selected nodes and edges
      const selectedNodes = nodes.filter((node) => node.selected);
      const selectedEdges = edges.filter((edge) => edge.selected);

      // Delete selected nodes
      if (selectedNodes.length > 0) {
        for (const node of selectedNodes) {
          dispatch(deleteNode({ sourceNodeId: node.id }));
        }
      }

      // Delete selected edges
      if (selectedEdges.length > 0) {
        dispatch(deleteEdges({ edges: selectedEdges }));
        // Hide edge toolbar
        dispatch(setSelectedEdge({ edge: null }));
      }
    }
  }, [backspacePressed]);
};
