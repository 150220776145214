// src/components/BlockDiagram/Hooks/useShowEdgeToolbar.ts

import { RefObject, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useUpdateNodeInternals,
  Node,
  ReactFlowInstance,
  XYPosition,
  useReactFlow,
  useNodes,
} from "reactflow";
import { Color } from "src/models/BoxDiagram/Colors";
import { NodeData, NodeType, baseNodeData, getNodeID } from "src/models/BoxDiagram/Node";
import {
  applyNewNodeChanges,
  deselectAllNodes,
  updateNodeData,
} from "src/state/reducers/boxDiagramReducers";
import { RootState } from "src/state/slices";
import { MenuItemName, setSelectItem } from "src/state/slices/BoxDiagram/boxDiagramUISlice";
import { addNode } from "src/state/slices/documentSlice";
import {
  childPosRelativeToCanvas,
  childPosRelativeToFrame,
  isChildInsideFrame,
} from "src/utils/boxDiagramUtils";

export function useSetFrameNode(reactFlowContainerRef: RefObject<HTMLDivElement>) {
  const dispatch = useDispatch();
  const [dragStartPos, setDragStartPos] = useState<XYPosition | null>(null);
  const selectedSideBarItem = useSelector((state: RootState) => state.boxDiagramUI.activeMenuItem);
  const nodes = useSelector((state: RootState) => state.document.documentContainer.nodes);
  const reactFlowInstance = useReactFlow();

  function handleFrameDragStart(event: React.MouseEvent) {
    if (!reactFlowContainerRef) return;
    if (!(selectedSideBarItem === MenuItemName.Frame)) return;

    const startPos = reactFlowInstance.screenToFlowPosition({
      x: event.clientX,
      y: event.clientY,
    });

    setDragStartPos(startPos);
  }

  function handleFrameDragEnd(event: React.MouseEvent) {
    // De-select the frame tool menu
    dispatch(setSelectItem({ item: null }));

    // Only show if the frame tool is selected
    if (!reactFlowContainerRef) return;
    if (!(selectedSideBarItem === MenuItemName.Frame)) return;

    // Deselect all nodes so we don't get the multiple selection bug
    dispatch(deselectAllNodes({ exceptionID: null }));

    // Get position where the user released the mouse
    const endPos = reactFlowInstance.screenToFlowPosition({
      x: event.clientX,
      y: event.clientY,
    });

    // Calculate the dimensions of the frame
    const dimensions = {
      width: Math.abs(endPos.x - dragStartPos.x),
      height: Math.abs(endPos.y - dragStartPos.y),
    };

    // Position is the top left corner of the frame
    const frameStartPos = {
      x: Math.min(dragStartPos.x, endPos.x),
      y: Math.min(dragStartPos.y, endPos.y),
    };

    // Get all nodes that are within the frame
    const selectedNodes = nodes.filter(
      (node: Node) =>
        node.selected && node.type !== NodeType.Frame && node.type !== NodeType.EdgeToolbar
    );

    console.log(
      "selectedNodes",
      selectedNodes.map((node: Node) => node.id)
    );

    const nodeId = getNodeID();
    // Create the frame node
    dispatch(
      addNode({
        position: frameStartPos,
        type: NodeType.Frame,
        data: {
          ...baseNodeData,
          dimensions,
          bg_color: Color.White.css.fifty,
        },
        nodeId: nodeId,
      })
    );

    // Set the selected nodes to be children of the frame
    for (const node of selectedNodes) {
      // This position will be relative to canvas if there is no parent and relative to parent if there is a parent
      let childPosRelativeToPane = { x: node.position.x, y: node.position.y };

      // If the node already has a parent, we need to adjust the position of the node
      let parentNode = undefined;
      if (node.parentNode) {
        parentNode = nodes.find((n) => n.id === node.parentNode);
        // If a node has a parent, it's position is relative to the parent, not the canvas
        // So we calculate the position of node relative to the canvas and subtract as usual
        childPosRelativeToPane = childPosRelativeToCanvas(parentNode.position, node.position);
      }

      // Update the node data to set the parent node in it's child
      dispatch(
        updateNodeData({
          id: node.id,
          newData: {},
          parentNode: nodeId,
          position: childPosRelativeToFrame(frameStartPos, childPosRelativeToPane),
        })
      );
    }
  }

  function handleUpdateParentNodeOnDrop(
    event: React.MouseEvent,
    droppedNode: Node<NodeData>,
    droppedNodes: Node<NodeData>[]
  ) {
    // Get all frames
    const frames = nodes.filter((node) => node.type === NodeType.Frame);
    if (frames.length === 0) return;
    if (droppedNodes[0].type !== NodeType.BaseNode) return;

    // Step 1. Look at every node dropped to see if it's inside a frame
    for (const node of droppedNodes) {
      let nodeInFrame = false;
      // Step 2. Since nodes in a frame are relative to the frame, we need the parent
      // to get absolute position of node
      let parentNode = undefined;
      if (node.parentNode) {
        parentNode = nodes.find((n) => n.id === node.parentNode);
      }

      // Step 3. Check if the node is inside a frame
      for (const frame of frames) {
        // Check if in frame
        if (isChildInsideFrame(frame, node, parentNode)) {
          nodeInFrame = true;
          // Step 4. If node is moved inside the same frame, do nothing
          if (node.parentNode === frame.id) {
            break;
          }

          // Step 5. If node is moved inside a different frame, update the node's position
          // relative to the new frame

          // Step 5.1. Get the position to the canvas incase this node was previous inside a frame
          const childPosRelativeToPane = parentNode
            ? childPosRelativeToCanvas(parentNode.position, node.position)
            : node.position;

            // Step 5.2. Update the node's position and parent node
          dispatch(
            updateNodeData({
              id: node.id,
              newData: {},
              parentNode: frame.id,
              position: childPosRelativeToFrame(frame.position, childPosRelativeToPane),
            })
          );
          // Step 6. If node is moved inside a frame, return. No other words needed
          break;
        }
      }

      // Step 7. If node is not inside any frame above, but it previously was
      if (node.parentNode && !nodeInFrame) {
        // Step 8. Get the parent node so we can set the position relative to the canvas
        parentNode = nodes.find((n) => n.id === node.parentNode);

        // Step 9. Update internal React Flow state to reflect new position. Redux state does not update position
        dispatch(
          applyNewNodeChanges([
            {
              id: node.id,
              type: "position",
              position: childPosRelativeToCanvas(parentNode.position, node.position),
            },
          ])
        );

        // Step 10. Remove the parent node from the node
        dispatch(
          updateNodeData({
            id: node.id,
            newData: {},
            parentNode: null,
            position: childPosRelativeToCanvas(parentNode.position, node.position),
          })
        );
      }
      // Step 11. If node is not inside any frame above, and it previously was not, do nothing
    }
  }


  return { handleFrameDragStart, handleFrameDragEnd, handleUpdateParentNodeOnDrop };
}
