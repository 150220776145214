import { DocumentContainerAPI } from "src/models/Document";
import { Subscription } from "src/models/Subscription";
import { User } from "src/models/User";

export enum StatusCode {
  HTTP_200 = 200,
  HTTP_404 = 404,
  HTTP_500 = 500,
}

export type GeneralResponse = {
  resp: string;
  status: StatusCode;
};

export type GetUserResponse = {
  resp: User;
  status: StatusCode;
};

export type GetDocumentResponse = {
  resp: DocumentContainerAPI | null;
  status: StatusCode;
};

export type GetDocumentsResponse = {
  resp: DocumentContainerAPI[];
  status: StatusCode;
};

export type StripeSessionURLResponse = {
  resp: string; // checkout url or customer portal url
  status: StatusCode;
};

export type GetSubscriptionResponse = {
  resp: Subscription | null;
  status: StatusCode;
};
