// useEdgeConnectPrompt.ts
import { useState, useEffect, RefObject } from "react";
import { Connection, OnConnectStartParams } from "reactflow";
import { NodeDropMenuState } from "../Other/NewNodeMenu";

type UseEdgeConnectPromptReturn = {
  onConnectStartEdgeDrop: (params: OnConnectStartParams) => void;
  onConnectEndEdgeDrop: (event: MouseEvent) => void;
  onConnectEdgeDrop: (connection: Connection) => void;
  setNodeDropMenuState: (state: NodeDropMenuState | null) => void;
  nodeDropMenuState: NodeDropMenuState | null;
};

/*
 * This hook is used to prompt the user to select a node when they start dragging an edge
 * and then drop the edge without connecting it to a handle. If the user does not drop the
 * edge on a handle, we show a menu to allow the user to create a new node.
 */
export function usePromptOnEdgeDrop(
  reactFlowContainerRef: RefObject<HTMLDivElement>
): UseEdgeConnectPromptReturn {
  const [isDraggingEdge, setIsDraggingEdge] = useState(null);
  const [isEdgeConnected, setIsEdgeConnected] = useState(null);

  const [dropLocation, setDropLocation] = useState<{ top: number; left: number }>(null);
  const [nodeDropMenuState, setNodeDropMenuState] = useState<NodeDropMenuState | null>(null);
  const [sourceNode, setSourceNode] = useState<string | null>(null);
  const [handle, setHandle] = useState<string | null>(null);

  useEffect(() => {
    if (isDraggingEdge === null || isEdgeConnected === null) {
      return;
    }
    if (!isDraggingEdge && !isEdgeConnected) {
      setNodeDropMenuState({
        handle: handle,
        source: sourceNode,
        top: dropLocation.top,
        left: dropLocation.left,
      });
    }
  }, [isDraggingEdge, isEdgeConnected]); // Depend on isDraggingEdge and isEdgeConnected

  function onConnectStartEdgeDrop(params: OnConnectStartParams) {
    setIsDraggingEdge(true);
    setIsEdgeConnected(false); // Reset connection status
    setSourceNode(params.nodeId);
    setHandle(params.handleId);
  }

  function onConnectEndEdgeDrop(event: MouseEvent) {
    setIsDraggingEdge(false); // Reset drag status
    // Get the container's position relative to the viewport
    // This is because the click position is relative to the viewport (entire visible screen), but we
    // want the click position to be relative to only the react flow container incase
    // the container is not full screen (e.g. we add sidebars or headers above the container).
    const rect = reactFlowContainerRef.current.getBoundingClientRect();
    // const flowPos = reactFlowInstance.screenToFlowPosition({ x: event.clientX, y: event.clientY });
    setDropLocation({
      top: event.clientY - rect.y,
      left: event.clientX - rect.x,
    });
  }

  function onConnectEdgeDrop(connection: Connection) {
    setIsDraggingEdge(false); // Reset drag status
    setIsEdgeConnected(true); // Edge was successfully connected
  }

  return {
    onConnectStartEdgeDrop,
    onConnectEndEdgeDrop,
    onConnectEdgeDrop,
    nodeDropMenuState,
    setNodeDropMenuState,
  };
}
